import PropTypes from 'prop-types';
/* eslint-disable */
import _, { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useRef, createContext, useState } from 'react';
import axiosInstance from '../utils/axios';
import { categorizeFiles, resizeAndWatermarkImages } from '../utils/fileUtils';
import { getUIDOfFacebookGroupFromURL } from '../utils/formatString';
import {
  compareVersions,
  convertFeedBackToId,
  convertIdToFeedBackId,
  convertStoryIdToFeedBackId,
  convertToMD5,
  delayFunc,
  getCheckpointListConfig,
  randomInRange,
  shuffleArray,
} from '../utils/others';
import { ExtractResponse, FBTool } from '../utils/tool';
import { useStatsGuard } from '../hooks/lead';
import useLocales from '../hooks/useLocales';
import usePostMessageRequest from '../hooks/usePostMessageRequest';
import useRefCustom from '../hooks/useRefCustom';
import { EmitterService } from '../utils/event';
import useAuth from '../hooks/useAuth';
import uuidv4 from '../utils/uuidv4';
import { getFacebookInfomation, matchProduct } from '../utils/tool/api';
import useBoolean from '../hooks/useBoolean';
import { useErrorsDetect } from '../hooks/tool-utils';
import { fDateTime, reduceMinutes } from '../utils/formatTime';
import { IS_LOCALHOST } from '../config';
import { haveErrorsInList } from '../utils/tool/error';

// Delay time of actions lead seeding
const DEFAULT_DELAY = { from: 50, to: 60 };

// Maximum pending posts allow
const ENABLED_SKIP_POST = false;
const MAXIMUM_PENDING_POSTS_ALLOW = 5;

// Retry delay time, in ms
const DELAY_RETRY = 10 * 60 * 1000;

const ERRORS_CATEGORIZE = {
  join: 'participate_approval',
  pending_posts: 'amount_pending_posts',
  rate_limit: 'rate_limit',
  exceeded_pending_posts: 'exceeded_pending_posts',
};

// In seconds
const TOTAL_DELAY = IS_LOCALHOST ? 30 : 3 * 60;

const CANCEL_MSG = `Canceling tasks!`;

const ABORT_MSG = 'Operation canceled';

const PREVIOUS_VERSION = '2.1.1';

const POST_TYPES = {
  sequence: 'sequence',
  rotate: 'rotate',
};

const MENTIONS_SET = ['@everyone@', '@mọi người@', '@highlight@', '@nêu bật@'];

const EXCEEDED_MENTION_TEXT = ['once a day', 'lần/ngày', 'lần/tuần', '3 times a week'];

const RETRY_DEFAULT_VALUE = { isCancelled: false, continueAfter: null };

const initialState = {
  // Emitter
  emitter: { on: () => {}, off: () => {}, send: () => {} },
  // Slowdown
  slowdown: false,
  // Funcs
  onChangeFacebookPayloadConfigs: () => {},
  getProfile: () => {},
  getGroupsJoined: () => {},
  postToGroupsWithMultiContent: () => {},
  postSeedingComments: () => {},
  getSiteInfo: () => {},
  getSearchPosts: () => {},
  leadsSeeding: () => {},
  leadsSeedingSavedLink: () => {},
  stopQueue: () => {},
  getCommentsFromFeedback: () => {},
  getRepliesFromComment: () => {},
  getExtensionVersion: () => {},
  checkExtensionActive: () => {},
  updateFBData: () => {},
  getPostsInGroupByKeyword: () => {},
  ACTIONS_DELAY: DEFAULT_DELAY,
  updateVideoConfigs: () => {},
  getTextFormatPresets: () => {},
  commentUpPosts: () => {},
  checkFacebookInternet: () => {},
  getPendingPosts: () => {},
  removePendingPosts: () => {},
  isValidGroup: () => {},
  onCallbackWarningActionPopup: () => {},
  infiniteRemovePendingPosts: () => {},
  onLoadAccessToken: () => {},
  getMessengerInfo: () => {},
  getReactionsInPost: () => {},
  getPostsInProfile: () => {},
  getCommentInfoInPost: () => {},
  getFriends: () => {},
  getTimelinePostsOfUser: () => {},
  getFollowers: (cursor = null, callback = () => {}) => {},
  getReactionsFromFeedback: () => {},
  getTimelinePostsOfPage: () => {},
  addListFriends: () => {},
  getGroupActivity: () => {},
  getUsersInfoByUIDS: (uids = [], callback = () => {}) => {},
  getPageLikedOrFollowed: () => {},
  readDataWithUrl: () => {},
  getReactorsOfPost: () => {},
  getReShareData: () => {},
  getShareLinkOfPost: () => {},
  getPageInfo: () => {},
  getFBLocale: () => {},
};

const FBFunctionContext = createContext(initialState);

// ---------------------- PROPS VALIDATE ---------------------
FBFunctionProvider.propTypes = {
  children: PropTypes.any,
};
// -----------------------------------------------------------

function FBFunctionProvider({ children }) {
  const { sendRequest } = usePostMessageRequest();

  const [emitter, setEmitter] = useState(new EmitterService());

  const slowdown = useBoolean();

  const [, setVideoConfigs, videoConfigsRef] = useRefCustom(null);

  const {
    markAsGroupIssue,
    markAsPostIssue,
    onResetGroupIssues,
    onResetPostIssues,
    checkExceededGroupIssues,
    checkExceededPostIssues,
  } = useErrorsDetect();

  const { enqueueSnackbar } = useSnackbar();

  const { translate } = useLocales('tool.messages');

  const { getCurrentInboxSent, DAILY_LIMIT_INBOX, getCurrentReadInfo, DAILY_LIMIT_READ_FACEBOOK_INFO, canUseTier } =
    useStatsGuard();

  const [FBData, setFBData, FBDataRef] = useRefCustom({
    userCookie: [],
    userFBdtsg: null,
    userDyn: null,
    otherData: {},
  });

  const [, setFBLocale, FBLocaleRef] = useRefCustom(null);

  const responseHelper = new ExtractResponse();

  const checkFBData = useRef();

  const delegatePageIdRef = useRef();

  const [, setExcludeGroupList, excludeGroupListRef] = useRefCustom([]);

  const [, setRetryInfo, retryInfoRef] = useRefCustom(RETRY_DEFAULT_VALUE);

  const [, setUserAccessToken, userAccessTokenRef] = useRefCustom(null);

  const [, setFacebookPayloadConfigs, facebookPayloadConfigsRef] = useRefCustom({});

  const [, setSkipCheckGroup, skipCheckGroupRef] = useRefCustom(true);

  // useEffect(() => {
  //   // After 10 seconds if FBData has't enough data
  //   const { userCookie, userFBdtsg, userDyn } = FBDataRef?.current || {};
  //   if (checkFBData?.current) {
  //     clearTimeout(checkFBData?.current);
  //   }
  //   if ([userCookie, userFBdtsg, userDyn]?.some((item) => isEmpty(item))) {
  //     checkFBData.current = setTimeout(() => {
  //       onClearCache();
  //     }, 10 * 1000);
  //   }
  // }, [FBData]);

  const { configs, user } = useAuth();
  const [, setUser, userRef] = useRefCustom(null);

  const onChangeFacebookPayloadConfigs = (data) => {
    setFacebookPayloadConfigs(data);
  };

  useEffect(() => {
    setUser(user);
  }, [user]);

  const [, setCheckpointList, checkpointListRef] = useRefCustom(null);

  useEffect(() => {
    if (configs) {
      const checkpointList = getCheckpointListConfig(configs?.checkpoint_messages);
      setCheckpointList(checkpointList);
    }
  }, [configs]);

  // Control flow, queue, tasks
  let controller = useRef(null);
  let signal = useRef(null);

  const onLoadLocale = async () => {
    const facebookLocale = await getFacebookLocale();
    setFBLocale(facebookLocale);
  };

  const getFBLocale = () => FBLocaleRef?.current;

  const updateFBData = async (data = {}) => {
    try {
      const fbData = await sendRequest('GET_FB_DATA', data);
      if (fbData) {
        const { userCookie, userDyn, otherData } = fbData;
        let userFBdtsg = fbData?.userFBdtsg;

        // Load fbDtsg from facebook helper page
        // const fbDtsgFromHelpPage = await getFbDstgFromHelpPage();
        // if (fbDtsgFromHelpPage) {
        //   console.log('fbDtsgFromHelpPage', fbDtsgFromHelpPage);
        //   userFBdtsg = fbDtsgFromHelpPage;
        // }

        setFBData({ userCookie, userFBdtsg, userDyn, otherData });
        if (userFBdtsg && userDyn) {
          localStorage.setItem('fb_info', JSON.stringify({ userCookie, userFBdtsg, userDyn }));
        }
        onLoadLocale();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFBServiceInstance = () =>
    new FBTool(
      FBDataRef?.current?.userCookie,
      FBDataRef?.current?.userFBdtsg,
      FBDataRef?.current?.userDyn,
      FBDataRef?.current?.otherData,
      userAccessTokenRef?.current,
      facebookPayloadConfigsRef?.current
    );

  const getFbDstgFromHelpPage = async () => {
    const FBToolInstance = getFBServiceInstance();
    const helpPageRequest = FBToolInstance.getHelpPageToFindFBDtsg();
    const response = await sendRequest('FETCH', helpPageRequest);
    return responseHelper.extractFbDstg(response);
  };

  const getFacebookLocale = async () => {
    const FBToolInstance = getFBServiceInstance();
    const currentLocaleRequest = FBToolInstance.getLocale();
    const response = await sendRequest('FETCH', currentLocaleRequest);
    return responseHelper.extractFacebookLocale(response);
  };

  const getExtensionVersion = async () => {
    const payload = await sendRequest('GET_VERSION');
    return payload;
  };

  const checkExtensionActive = async () => {
    return await sendRequest('CHECK_ACTIVE_EXTENSION');
  };

  // Get text format presets
  const getTextFormatPresets = useCallback(async () => {
    try {
      const FBToolInstance = getFBServiceInstance();
      const textFormatPresetsRequest = FBToolInstance?.getTextFormatPresetsPayload();
      const response = await sendRequest('FETCH', textFormatPresetsRequest);

      return responseHelper.extractPresets(response);
    } catch (error) {
      console.log(error);
      return null;
    }
  }, [FBData]);

  const updateVideoConfigs = (configs) => {
    setVideoConfigs(configs);
  };

  // ============================================================================
  const TAG_WARP_CHAR = '@';
  const TAG_UID_CHAR = '|';

  const findAndMatchTag = async (text, groupId, postId, source = []) => {
    try {
      const [query, uid] = text?.split(TAG_UID_CHAR);

      const mentionOutsideGroup = !groupId && !postId;
      if (mentionOutsideGroup && source?.length !== 0) {
        return source?.find((item) => item?.id == uid) || {};
      }

      const tags = await getTagsRelateQueryInGroup(groupId, query, postId);
      let matchedWithUID = tags[0];
      if (uid) {
        matchedWithUID = tags?.find((item) => item?.node?.id == uid) || tags[0];
      }
      return matchedWithUID?.node || {};
    } catch (error) {
      return {};
    }
  };

  const convertTags = async (text, groupId = null, postId = null, source = []) => {
    try {
      const regexp = new RegExp(`${TAG_WARP_CHAR}[^${TAG_WARP_CHAR}]+${TAG_WARP_CHAR}`, 'g');
      const matches = text.match(regexp);
      const results = [];
      for (const item of matches) {
        const [, text] = item?.split(TAG_WARP_CHAR);
        const { id, mentions_subtext: detail, name } = await findAndMatchTag(text, groupId, postId, source);

        if (MENTIONS_SET.indexOf(`@${text}@`) !== -1) {
          // Special name like everyone, highlight, ... from facebook @[name]
          if (name === `@${text}`) {
            results.push({ text: item, id, length: item?.length, detail });
          }
        } else {
          results.push({ text: item, id, length: item?.length, detail });
        }
      }

      // Convert to object include data
      let existed = results?.length !== 0;
      let formatedText = text;
      /**
       * item of payload
       * {
            entity: {
              id: "61554669931019"
            },
            offset: 0
            length: 10,
          }
      * 
      */
      const ranges = [];
      const details = {};
      while (existed) {
        for (const data of results) {
          const { text, id, length, detail } = data;
          const offset = formatedText?.indexOf(text);
          if (offset !== -1) {
            const start = formatedText?.substring(0, offset);
            const end = formatedText?.substring(offset + length);
            let removedSystax = text?.replaceAll(TAG_WARP_CHAR, '')?.split(TAG_UID_CHAR)[0] || '';
            if (id) {
              ranges.push({
                entity: {
                  id,
                },
                offset,
                length: removedSystax?.length,
              });
              details[text] = detail;
            } else {
              removedSystax = '';
            }
            formatedText = `${start}${removedSystax}${end}`;
          }
        }
        existed = results?.some((item) => formatedText?.indexOf(item?.text) !== -1);
      }

      return { ranges, formatedText, details };
    } catch (error) {
      return { ranges: [], formatedText: text, details: {} };
    }
  };

  // ============================================================================

  // Get current facebook profile
  const getProfile = useCallback(async () => {
    try {
      const FBToolInstance = getFBServiceInstance();
      const profileRequest = FBToolInstance?.getProfile();
      const response = await sendRequest('FETCH', profileRequest);
      const data = responseHelper.extractProfile(response);
      if (data) {
        const checkPageProfileRequest = FBToolInstance?.checkPageProfile();
        const checkPageResponse = await sendRequest('FETCH', checkPageProfileRequest);
        const { isPage: isPageProfile, delegatePageId } = responseHelper.extractCheckPageProfile(checkPageResponse);
        // update delegatePageId for inbox by page profile business
        delegatePageIdRef.current = delegatePageId;
        const { profileUUID, avatar, username } = data;
        const info = { uid: profileUUID, image: avatar, name: username, isPageProfile };
        return info || null;
      }
      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }, [FBData]);

  // Get current facebook profile by uid
  const getProfileByUserUID = useCallback(
    async (uid) => {
      try {
        const FBToolInstance = getFBServiceInstance();
        const profileRequest = FBToolInstance?.getProfileByUserUID(uid);
        const response = await sendRequest('FETCH', profileRequest);
        return responseHelper.extractUserProfileUID(response);
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    [FBData]
  );

  const getTagsRelateQueryInGroup = useCallback(
    async (groupId, query = '') => {
      try {
        const FBToolInstance = getFBServiceInstance();
        const profileRequest = FBToolInstance?.searchTagInGroupPayload(groupId, query);
        const response = await sendRequest('FETCH', profileRequest);
        return responseHelper.extractTagInGroup(response);
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    [FBData]
  );

  // Get current facebook profile
  const checkFacebookInternet = useCallback(async () => {
    try {
      const response = await sendRequest('CHECK_INTERNET', {}, null, null);
      const { url, redirected } = response || {};

      // Is use mobile internet
      return ['web.facebook', 'm.facebook']?.some((item) => url?.indexOf(item) !== -1 && redirected);
    } catch (error) {
      console.log(error);
      return false;
    }
  }, []);

  // Get groups joined of current account
  const getGroupsJoined = useCallback(async () => {
    try {
      const FBToolInstance = getFBServiceInstance();
      const listGroupsRequest = FBToolInstance?.getGroupsJoined();
      const response = await sendRequest('FETCH', listGroupsRequest);
      const data = responseHelper.extractGroupsJoined(response);
      if (data) {
        const { results } = data;
        const temp = results?.map((item) => {
          const node = item?.node || {};
          return {
            uid: node?.id,
            name: node?.name,
            link: node?.url,
            image: node?.profile_picture?.uri,
            customUID: getUIDOfFacebookGroupFromURL(node?.url),
          };
        });

        const uniqueList = _.uniqBy(temp, 'uid');
        return [...uniqueList];
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  }, [FBData]);

  // Get groups joined of current account
  const onLoadAccessToken = useCallback(async () => {
    try {
      const FBToolInstance = getFBServiceInstance();
      const accessTokenRequest = FBToolInstance?.getAccessToken();
      const response = await sendRequest('FETCH', accessTokenRequest);
      const accessToken = responseHelper.extractAccessToken(response);
      if (accessToken) {
        setUserAccessToken(accessToken);
        return accessToken;
      }
      // const posts = await getReactionsInPost('100031636493707_1058634595201080');
      // const posts = await getMessengerInfo();
      // console.log(posts);
      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }, [FBData]);

  // Get groups joined of current account
  const getFriends = useCallback(async () => {
    try {
      const FBToolInstance = getFBServiceInstance();
      const listFriendsRequest = FBToolInstance?.getFriends();
      const response = await sendRequest('FETCH', listFriendsRequest);
      const data = responseHelper.extractFriends(response);
      if (data) {
        const temp = data?.map((item) => {
          return {
            uid: item?.id,
            name: item?.name,
            link: `https://www.facebook.com/${item?.id}`,
            image: item?.photo?.uri,
            customUID: item?.id,
          };
        });

        const uniqueList = _.uniqBy(temp, 'uid');
        return [...uniqueList];
      }
    } catch (error) {
      console.log(error);
      return [];
    }
  }, [FBData]);

  const getUserControlBusiness = useCallback(async () => {
    try {
      const FBToolInstance = getFBServiceInstance();
      const userControlBusinessRequest = FBToolInstance?.getUserControlBusiness();
      const response = await sendRequest('FETCH', userControlBusinessRequest);
      return response?.data?.viewer?.current_user_info?.uid;
    } catch (error) {
      console.log(error);
      return null;
    }
  }, [FBData]);

  // Get groups joined of current account
  const getUsersInfoByUIDS = useCallback(
    async (uids = [], callback = () => {}) => {
      try {
        const uid = await getUserControlBusiness();
        if (!uid) {
          return [];
        }

        const results = [];
        const SIZE = 499;

        const batchedData = uids
          ?.map((_, index) => uids.slice(index * SIZE, index * SIZE + SIZE))
          ?.filter((item) => item?.length !== 0);

        for (const [index, data] of batchedData.entries()) {
          const FBToolInstance = getFBServiceInstance();
          const usersInfoByUIDSRequest = FBToolInstance?.getUsersInfoByUIDS(data, uid);
          const response = await sendRequest('FETCH', usersInfoByUIDSRequest);
          const res = responseHelper.extractUsersOnBusiness(response);
          if (res) {
            callback(res);
            results.push(...res);
          }

          const isLastTask = index === batchedData?.length - 1;
          if (!isLastTask) {
            await delayFunc(1000, signal?.current);
          }
        }

        return results;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    [FBData]
  );

  // Get followers of current page
  const getFollowers = useCallback(
    async (cursor = null, callback = () => {}) => {
      try {
        const FBToolInstance = getFBServiceInstance();
        const listFollowerRequest = FBToolInstance?.getFollowers(cursor);
        const response = await sendRequest('FETCH', listFollowerRequest);
        const { data, nextCursor } = responseHelper.extractFollowers(response);
        const results = [];

        if (data) {
          const temp = data?.map((item) => {
            return {
              uid: item?.id,
              name: item?.name,
              link: item?.url || `https://www.facebook.com/${item?.id}`,
              image: item?.profile_picture?.uri,
              customUID: item?.id,
            };
          });
          callback(temp);
          results.push(...temp);
        }

        if (nextCursor) {
          await delayFunc(200, signal?.current);
          const posts = await getFollowers(nextCursor, callback);
          results.push(...posts);
        }
        return results;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    [FBData]
  );

  const getCommentInfoInPost = useCallback(
    async (groupPostFormatId, cursor, callback = () => {}, inputIsFeedback = false) => {
      try {
        const FBToolInstance = getFBServiceInstance();
        const commentInfoRequest = FBToolInstance?.getPayloadCommentInPost(groupPostFormatId, cursor, inputIsFeedback);
        const response = await sendRequest('FETCH', commentInfoRequest);
        const data = responseHelper.extractCommentInPost(response) || {};

        let pagination = data?.pagination || {};
        const nextCursor = pagination?.end_cursor;

        const comments = [];

        if (data?.comments) {
          callback(data?.comments);
          comments.push(...data?.comments);
        }

        if (pagination?.has_next_page && nextCursor) {
          await delayFunc(2000, signal?.current);
          const { comments: otherComments, isOperationCanceled } = await getCommentInfoInPost(
            groupPostFormatId,
            nextCursor,
            callback,
            inputIsFeedback
          );
          if (isOperationCanceled) {
            return { isOperationCanceled: true };
          }
          comments.push(...(otherComments || []));
        }
        return { ...data, comments: _.unionBy(comments, 'feedbackId') };
      } catch (error) {
        console.log(error);
        if ([ABORT_MSG, CANCEL_MSG]?.some((item) => error?.message?.indexOf(item) !== -1)) {
          return { isOperationCanceled: true };
        }
        return {};
      }
    },
    [FBData]
  );

  const getPostsInProfile = useCallback(
    async (start = reduceMinutes(new Date(), 60 * 24 * 30 * 6), end = new Date(), endpoint = '') => {
      try {
        const FBToolInstance = getFBServiceInstance();
        const listPostsRequest = FBToolInstance?.getPayloadListPosts(
          endpoint,
          fDateTime(start, 'yyyy-MM-dd'),
          fDateTime(end, 'yyyy-MM-dd')
        );
        const response = await sendRequest('FETCH', listPostsRequest);

        const results = [];

        const { data, paging } = responseHelper.extractPostsInProfile(response);
        const haveNextPage = paging && paging?.next;

        if (data) {
          results.push(...data);
        }

        if (haveNextPage) {
          await delayFunc(2000, signal?.current);
          const posts = await getPostsInProfile(start, end, paging?.next);
          results.push(...posts);
        }
        return _.uniq(results);
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    [FBData]
  );

  const getReactionsInPost = useCallback(
    async (groupPostFormatId, cursor = '', callback = () => {}) => {
      try {
        const FBToolInstance = getFBServiceInstance();
        const reactionRequest = FBToolInstance?.getPayloadReactionsInPost(groupPostFormatId, cursor);
        const response = await sendRequest('FETCH', reactionRequest);
        const results = [];

        const { data, nextCursor } = responseHelper.extractReactionInPost(response);

        if (data) {
          results.push(...data);
          callback(data);
        }

        if (nextCursor) {
          await delayFunc(2000, signal?.current);
          const posts = await getReactionsInPost(groupPostFormatId, nextCursor, callback);
          if (posts?.isOperationCanceled) {
            return { isOperationCanceled: true };
          }
          results.push(...posts);
        }
        return _.uniq(results);
      } catch (error) {
        console.log(error);
        if ([ABORT_MSG, CANCEL_MSG]?.some((item) => error?.message?.indexOf(item) !== -1)) {
          return { isOperationCanceled: true };
        }
        return [];
      }
    },
    [FBData]
  );

  const getReactionsFromFeedback = useCallback(
    async (feedbackId, cursor = null, callback = () => {}) => {
      try {
        const FBToolInstance = getFBServiceInstance();
        const reactionRequest = FBToolInstance?.getPayloadReactionsFromFeedback(feedbackId, cursor);
        const response = await sendRequest('FETCH', reactionRequest);
        const { data, nextCursor } = responseHelper.extractReactionInFeedback(response);

        const results = [];

        if (data) {
          results.push(...data);
          callback(data);
        }

        if (nextCursor) {
          await delayFunc(1500, signal?.current);
          const posts = await getReactionsFromFeedback(feedbackId, nextCursor, callback);
          if (posts?.isOperationCanceled) {
            return { isOperationCanceled: true };
          }
          results.push(...posts);
        }
        return _.uniq(results);
      } catch (error) {
        console.log(error);
        if ([ABORT_MSG, CANCEL_MSG]?.some((item) => error?.message?.indexOf(item) !== -1)) {
          return { isOperationCanceled: true };
        }
        return [];
      }
    },
    [FBData]
  );

  const getMessengerInfo = useCallback(async () => {
    try {
      const FBToolInstance = getFBServiceInstance();
      const messengerRequest = FBToolInstance?.getPayloadReadMessages();
      const response = await sendRequest('FETCH', messengerRequest);
      const messageActors = responseHelper.extractMessages(response);
      return messageActors;
    } catch (error) {
      console.log(error);
      return [];
    }
  }, [FBData]);

  const uploadImages = async (files) => {
    try {
      if (!files || files?.length === 0) {
        return [];
      }
      // const timeout = Math.max(20, files?.length * 4);

      const formated = await resizeAndWatermarkImages(files);
      const { userCookie, userFBdtsg, userDyn } = FBDataRef?.current || {};

      return await sendRequest(
        'UPLOAD_IMAGES',
        { files: formated, extraData: { userCookie, userFBdtsg, userDyn } },
        null,
        null
      );
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  /**
   * Not working with livestream url
   * @param {string} url
   * @return {Promise}
   */
  const readDataWithUrl = async (url) => {
    try {
      if (!url) {
        return null;
      }
      const text = await sendRequest('READ_DATA_WITH_URL', { url }, null);
      return responseHelper.extractPostAtLinkData(text);
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  /**
   * Get page info
   * @param {string} pageId
   * @return {Promise}
   */
  const getPageInfo = async (pageId) => {
    try {
      if (!pageId) {
        return null;
      }
      const FBToolInstance = getFBServiceInstance();
      const pageInfoRequest = FBToolInstance?.getPayloadPageInfo(pageId);
      const response = await sendRequest('FETCH', pageInfoRequest);
      const res = responseHelper.extractPageInfo(response);
      const baseCategories = res?.categories;
      if (baseCategories) {
        let categories = await Promise.all(baseCategories?.map((item) => searchPageCategory(item)));
        res.categories = categories?.filter((item) => item && baseCategories?.indexOf(item?.name) !== -1);
      }
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  /**
   * Get page info
   * @param {string} pageId
   * @return {Promise}
   */
  const searchPageCategory = async (category) => {
    try {
      if (!category) {
        return null;
      }
      const FBToolInstance = getFBServiceInstance();
      const pageInfoRequest = FBToolInstance?.getPayloadPageCategory(category);
      const response = await sendRequest('FETCH', pageInfoRequest);
      return responseHelper.extractPageCategory(response);
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const uploadMultiVideo = async (composerSessionId, groupId, files) => {
    try {
      if (!files || files?.length === 0) {
        return [];
      }
      const results = [];

      for (let item of files) {
        const videoId = await uploadVideo(composerSessionId, groupId, item);
        results.push({ videoId, index: item?.index || 0 });
      }

      return results;
    } catch (error) {
      return [];
    }
  };

  const checkParticipateApproved = async (groupId) => {
    try {
      const FBToolInstance = getFBServiceInstance();
      const getParticipageApprovalRequest = FBToolInstance?.getParticipageApprovalPayload(groupId);
      const response = await sendRequest('FETCH', getParticipageApprovalRequest);
      return responseHelper.extractParticipateApproved(response);
    } catch (error) {
      return null;
    }
  };

  // Check valid group
  const isValidGroup = async (groupId, skipInfo = {}) => {
    try {
      let isValid = false;
      let reason = '';
      if (!groupId) {
        return { isValid, reason };
      }

      // Check skipInfo
      const maximumPendingPostsAllow = skipInfo?.amountPendingPostsAllow;
      if (skipInfo && typeof maximumPendingPostsAllow === 'number') {
        const remains = await getRemainsPendingPostsManual(groupId);
        console.log('[isValidGroup][maximumPendingPostsAllow]:', maximumPendingPostsAllow);
        console.log('[isValidGroup][remains]:', remains);
        if (remains > maximumPendingPostsAllow) {
          return { isValid: false, reason: ERRORS_CATEGORIZE.exceeded_pending_posts, dontExclude: true };
        }
      }

      // if (skipCheckGroupRef?.current) {
      //   console.log(`Skipped check group ${groupId}`);
      //   return { isValid: true };
      // }

      // const res = await getPendingPosts(groupId);
      // const { pendingPosts } = res || {};
      // if (res?.isRateLimit) {
      //   setSkipCheckGroup(true);
      //   return { isValid: true };
      // }

      // if (pendingPosts?.length >= MAXIMUM_PENDING_POSTS_ALLOW) {
      //   return { isValid, reason: ERRORS_CATEGORIZE.pending_posts };
      // }

      // const { isApproved, isRateLimit } = await checkParticipateApproved(groupId);
      // if (isRateLimit) {
      //   setSkipCheckGroup(true);
      //   return { isValid: true };
      // }

      // if (!isApproved) {
      //   return { isValid, reason: ERRORS_CATEGORIZE.join };
      // }

      return { isValid: true };
    } catch (error) {
      console.log(error);
      return { isValid: false };
    }
  };

  const randomMentionData = async (groupId, baseContent = '') => {
    const shuffled = shuffleArray(MENTIONS_SET);
    for (const [itemIndex, text] of shuffled.entries()) {
      const content = `${text} ${baseContent}`;
      const { formatedText, ranges, details } = await convertTags(content, groupId);
      const isExceededMention =
        !!details[text] && EXCEEDED_MENTION_TEXT?.some((item) => details[text]?.indexOf(item) !== -1);

      const realizeString = text?.replaceAll(TAG_WARP_CHAR, '');
      const shouldBeMatch = formatedText && formatedText?.indexOf(realizeString) === 0;

      // Accept last try if ranges have value
      const isLastTry = formatedText && itemIndex === shuffled?.length - 1;

      if ((shouldBeMatch || isLastTry) && ranges && ranges?.length !== 0 && !isExceededMention) {
        return { formatedText, ranges };
      }
    }
    return null;
  };

  // Post to group
  const postToGroup = useCallback(
    async (data) => {
      try {
        const { groupId, isRemovePendingPostFirst, randomMention, shuffleMedia, removeSaleFormat } = data;

        // Skip
        if (checkExceededGroupIssues(groupId)) {
          return null;
        }

        data.composerSessionId = uuidv4();

        if (isRemovePendingPostFirst) {
          const resultRemove = await removePendingPostsManual(groupId);
          console.log('[postToGroup][removePendingPostsManual][result]:', resultRemove);
          if (resultRemove) {
            const amountRemoved = resultRemove?.amount || 0;
            const remains = resultRemove?.remains || 0;
            // Skip post to group when pending posts exceeded limit allow (!!!PLEASE TURN ON FLAG NAMED ENABLED_SKIP_POST TO ALLOW CHECK THIS LOGIC)
            if (ENABLED_SKIP_POST && amountRemoved !== 0 && remains >= MAXIMUM_PENDING_POSTS_ALLOW) {
              return null;
            }
          }
        }

        const { imageFiles, videoFiles } = categorizeFiles(data?.files || [], !!shuffleMedia);

        // Upload images
        let imagesUploaded = await uploadImages(imageFiles);
        imagesUploaded = imagesUploaded?.filter((item) => item)?.map((item) => item?.photoID);
        data.images = imagesUploaded;

        if (imageFiles?.length !== 0 && imagesUploaded?.length === 0) {
          console.log(
            `[postToGroup]: Upload images failed ${imageFiles?.length - imagesUploaded?.length}/${imageFiles?.length}`
          );
          emitter.send('upload-media-failed');
          return null;
        }

        // Upload video
        let videoUploaded = await uploadMultiVideo(data.composerSessionId, groupId, videoFiles);
        videoUploaded = videoUploaded?.filter((item) => item);
        data.video = videoUploaded;

        if (videoFiles?.length !== 0 && videoUploaded?.length === 0) {
          console.log(
            `[postToGroup]: Upload video failed ${videoFiles?.length - videoUploaded?.length}/${videoFiles?.length}`
          );
          emitter.send('upload-media-failed');
          return null;
        }

        // Append tags
        if (randomMention && canUseTier(3)) {
          const shuffted = await randomMentionData(groupId, data?.content);
          console.log('shuffled:', shuffted);
          if (shuffted) {
            const { formatedText, ranges } = shuffted;
            data.content = formatedText;
            data.ranges = [...(data?.ranges || []), ...ranges];
          }
        } else if (data?.content && canUseTier(3)) {
          const { formatedText, ranges } = await convertTags(data?.content, groupId);
          data.content = formatedText;
          data.ranges = ranges;
        }

        const FBToolInstance = getFBServiceInstance();
        const postToGroupRequest = FBToolInstance?.getPayloadPostToGroup(data);
        const res = await sendRequest('FETCH', postToGroupRequest);

        // ---------------Begin detect checkpoint----------------
        if (res?.responseInfo) {
          const { status, location } = res?.responseInfo;
          if (status === 302 && location && location?.indexOf('checkpoint') !== -1) {
            stopQueue(true, 'checkpoint');
            return null;
          }
        }

        if (!res?.data?.story_create) {
          markAsGroupIssue(groupId);
          markAsPostIssue(groupId);

          // Flow check group issues don't stop when got error
          if (!checkExceededPostIssues(groupId)) {
            return null;
          }
          const isError = await checkErrors(res);
          if (isError) {
            return null;
          }
        }
        // ---------------Finished detect checkpoint----------------
        // Try remove sale format
        if (removeSaleFormat) {
          const storyId = res?.data?.story_create?.story?.id || res?.data?.story_create?.story_id;
          if (storyId) {
            const removeSaleFormatSuccess = await removeSaleFormatFunc(storyId);
            console.log(`[postToGroup][removeSaleFormat][result]: `, removeSaleFormatSuccess);
          }
        }

        return res;
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    [FBData]
  );

  // Post comment to feedback
  const postComment = useCallback(
    async (feedbackId, data, limitImages = null) => {
      try {
        if (!feedbackId) {
          return null;
        }

        let { imageFiles } = categorizeFiles(data?.files || []);

        if (typeof limitImages === 'number') {
          imageFiles = (imageFiles || [])?.slice(0, limitImages);
        }

        // Upload images
        let imagesUploaded = await uploadImages(imageFiles);
        imagesUploaded = imagesUploaded?.filter((item) => item)?.map((item) => item?.photoID);
        data.images = imagesUploaded;

        if (imageFiles?.length !== 0 && imagesUploaded?.length === 0) {
          console.log(
            `[postComment]: Upload images failed ${imageFiles?.length - imagesUploaded?.length}/${imageFiles?.length}`
          );
          return null;
        }

        // Append mentions tags
        if (data?.text && canUseTier(3)) {
          let groupId = null;
          let postId = null;
          if (data?.groupId) {
            groupId = data?.groupId;
            postId = convertFeedBackToId(feedbackId);
          }
          const { formatedText, ranges } = await convertTags(data?.text, groupId, postId, data?.mentionSource);
          data.text = formatedText;
          data.ranges = ranges;
        }

        const FBToolInstance = getFBServiceInstance();
        const postCommentRequest = FBToolInstance?.getPayloadPostComment(feedbackId, data);
        const res = await sendRequest('FETCH', postCommentRequest);

        // ---------------Begin detect checkpoint----------------
        if (res?.responseInfo) {
          const { status, location } = res?.responseInfo;
          if (status === 302 && location && location?.indexOf('checkpoint') !== -1) {
            stopQueue(true, 'checkpoint');
            return null;
          }
        }

        if (!res?.data?.comment_create) {
          const isError = await checkErrors(res);
          if (isError) {
            return null;
          }
        }
        // ---------------Finished detect checkpoint----------------
        return res?.data?.comment_create?.feedback_comment_edge?.node?.url || null;
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    [FBData]
  );

  const restartSignal = () => {
    // Reset errors counter
    onResetGroupIssues();
    onResetPostIssues();
    // Reset retry info
    setRetryInfo(RETRY_DEFAULT_VALUE);
    // Reset slowdown
    slowdown.onFalse();
    // Restart signal
    const newController = new AbortController();
    controller.current = newController;
    signal.current = newController?.signal;

    signal?.current?.addEventListener('abort', () => {
      throw new Error(ABORT_MSG);
    });
  };

  const getVideoUploadConfigs = async (groupId) => {
    try {
      const FBToolInstance = getFBServiceInstance();

      // GET VIDEO UPLOAD CONFIGS
      const videoUploadConfigsRequest = FBToolInstance?.getVideoUploadConfigs(groupId);
      const res = await sendRequest('FETCH', videoUploadConfigsRequest);
      return responseHelper.extractVideoUploadConfigs(res);
    } catch (error) {
      return null;
    }
  };

  const uploadVideo = async (composerSessionId, groupId, file) => {
    try {
      if (!file || !groupId) {
        return null;
      }

      const { size } = file;

      const splited = file?.fileName?.split('.');
      const extensionFromName = splited[splited?.length - 1];

      // GET VIDEO UPLOAD CONFIGS
      const videoUploadConfigs = await getVideoUploadConfigs();

      if (!videoUploadConfigs) {
        return null;
      }

      const waterfallId = composerSessionId;

      const hashKey = convertToMD5(waterfallId);

      const FBToolInstance = getFBServiceInstance();

      const {
        chunk_start_uri: declareVideoUri,
        chunk_receive_uri: receiveUri,
        resumable_service_name: serviceName,
        resumable_service_domain: serviceDomain,
      } = videoUploadConfigs || {};

      console.log(videoUploadConfigs);

      // DECALRE VIDEO UPLOAD
      const declareVideoUploadInfomationRequest = FBToolInstance?.declareVideoUploadInfomation(
        declareVideoUri,
        waterfallId,
        size,
        extensionFromName
      );
      const res1 = await sendRequest('FETCH', declareVideoUploadInfomationRequest);
      if (!res1) {
        throw new Error('declareVideoUploadInfomation failed');
      }
      const { startOffset, endOffset, videoId } = responseHelper.extractDeclareVideoUploadInfomation(res1);

      // OPEN CONTAINER FOR UPLOAD
      const containerServiceUri = `https://${serviceName}.${serviceDomain}`;
      const openContainerForUploadRequest = FBToolInstance?.openContainerForUpload(
        containerServiceUri,
        hashKey,
        startOffset,
        size
      );
      const res2 = await sendRequest('FETCH', openContainerForUploadRequest);
      if (!res2) {
        throw new Error('openContainerForUpload failed');
      }

      const videoPayload = {
        ...file,
        hashKey,
        startOffset,
        endOffset: size,
        videoId,
        waterfallId,
        containerServiceUri,
      };

      const { userCookie, userFBdtsg, userDyn } = FBDataRef?.current || {};
      const res3 = await sendRequest(
        'UPLOAD_VIDEO',
        {
          file: videoPayload,
          extraData: { userCookie, userFBdtsg, userDyn },
        },
        null,
        null
      );
      if (!res3) {
        throw new Error('UPLOAD_VIDEO failed');
      }

      const { h: fbuploaderVideoFileChunk } = res3 || {};

      if (!fbuploaderVideoFileChunk) {
        throw new Error('fbuploaderVideoFileChunk empty');
      }

      const markAsReceivedVideoUploadProgressRequest = FBToolInstance?.markAsReceivedVideoUploadProgress(
        receiveUri,
        waterfallId,
        size,
        videoId,
        fbuploaderVideoFileChunk
      );
      const res4 = await sendRequest('FETCH', markAsReceivedVideoUploadProgressRequest);
      if (!res4) {
        throw new Error('markAsReceivedVideoUploadProgress failed');
      }

      return videoId;
    } catch (error) {
      console.log('uploadVideo: ', error);
      return null;
    }
  };

  // Post to groups with multi contents
  const postToGroupsWithMultiContent = useCallback(
    async (data, callback, onComplete) => {
      try {
        restartSignal();

        const FBToolInstance = getFBServiceInstance();
        const { groupIds, contents, delay, postType, formatPresets, isRemovePendingPost, skipInfo, limitPosts } =
          data || {};

        const comBinationsContents = contents.map((item) => {
          const comments = FBToolInstance?.getSpinComments(item?.comments || [], groupIds);

          return {
            ...item,
            // spin: FBToolInstance?.generateCombinations(item?.spinContents || {}),
            commentSpin: comments,
          };
        });

        const selectedPostType = postType || POST_TYPES.sequence;

        // Check limit posts
        let limitAmountPosts = null;
        let postsSetup = 0;
        if (limitPosts && typeof limitPosts?.amount === 'number') {
          limitAmountPosts = limitPosts?.amount;
        }

        // Tasks
        const tasks = [];
        groupIds?.forEach((groupId, groupIndex) => {
          let combinationsData = [...comBinationsContents];
          // Sequence OR rotate
          if (selectedPostType === POST_TYPES.rotate) {
            if (groupIndex !== 0) {
              const firstData = comBinationsContents?.shift();
              comBinationsContents?.push(firstData);
            }
            combinationsData = [comBinationsContents[0]];
          }

          combinationsData.some((item, contentIndex) => {
            if (limitAmountPosts && postsSetup >= limitAmountPosts) {
              // Break the loop
              return true;
            }

            const { files, content, link, randomPresets, jobId, randomMention, shuffleMedia, removeSaleFormat } = item;
            // Check allow remove pending posts in group at the first time
            const isRemovePendingPostFirst = isRemovePendingPost && contentIndex === 0;

            // Get spin content
            let modifiedContent = content;
            if (item?.spinContents) {
              let spinData = FBToolInstance?.randomSpinData(item?.spinContents);
              if (spinData) {
                Object.keys(spinData).forEach((key) => {
                  modifiedContent = modifiedContent.replace(`{{${key}}}`, spinData[key]);
                });
              }
            }

            // Comments
            let modifyComments = [];
            if (item?.commentSpin && item?.commentSpin?.length !== 0) {
              const spinData = item?.commentSpin?.pop();
              // Update head
              item?.commentSpin?.unshift(spinData);
              modifyComments = spinData || [];
            }

            // Check group
            const checkGroupTask = async () => {
              if (excludeGroupListRef?.current?.indexOf(groupId) !== -1) {
                return false;
              }

              const { isValid, reason, dontExclude } = await isValidGroup(groupId, skipInfo);
              if (!isValid) {
                if (!dontExclude) {
                  setExcludeGroupList(_.uniq([...excludeGroupListRef?.current, groupId]));
                }

                if (reason) {
                  callback({
                    success: false,
                    groupId,
                    error: reason,
                  });
                }
              }
              return isValid;
            };

            // Create post
            const postTask = async () => {
              let textFormatPresetId = null;
              if (randomPresets && formatPresets?.length !== 0) {
                textFormatPresetId = formatPresets[randomInRange({ from: 0, to: formatPresets?.length - 1 })];
              }
              const res = await postToGroup({
                groupId,
                files,
                content: modifiedContent,
                link,
                textFormatPresetId,
                isRemovePendingPostFirst,
                randomMention,
                shuffleMedia,
                removeSaleFormat,
              });

              const storyCreate = res?.data?.story_create;

              const { id: feedbackId } = storyCreate?.group_feed_story_edge?.node?.feedback || {
                id: convertStoryIdToFeedBackId(storyCreate?.story_id),
              };
              let isWaitingForApprove = (storyCreate?.story?.url || '').indexOf('pending_posts') !== -1;
              if (res) {
                // Post content success
                callback({
                  success: true,
                  groupId,
                  result: res,
                  content: item?.baseContent,
                  isWaitingForApprove,
                  jobId,
                });
              } else {
                // Skip comment because create post failed
                isWaitingForApprove = true;
                callback({
                  success: false,
                  groupId,
                  content,
                  jobId,
                });
              }
              return { feedbackId, isCancelPostComment: isWaitingForApprove, groupId };
            };

            // Post comments
            const commentTasks = modifyComments?.map((data) => {
              return async (feedbackId) => {
                const res = await postComment(feedbackId, { ...data, groupId });
                callback({
                  success: !!res,
                  groupId,
                  content: item?.baseContent,
                  type: 'comment',
                  linkComment: res,
                });
              };
            });

            const task = { checkGroupTask, postTask, commentTasks };

            tasks.push(task);
            postsSetup += 1;
          });

          const isLastTask = groupIndex >= groupIds?.length - 1;
          const gotExceededLimit = limitAmountPosts && postsSetup >= limitAmountPosts;
          if (!isLastTask && !gotExceededLimit) {
            // Reset issues task
            const resetIssuesTask = () => {
              onResetGroupIssues();
              console.log('Reset group issues');
            };

            const task = { resetIssuesTask };
            tasks.push(task);
          }
        });

        for (const [taskIndex, task] of tasks.entries()) {
          if (signal?.current?.aborted) {
            throw new Error(CANCEL_MSG);
          }

          if (task?.resetIssuesTask) {
            task?.resetIssuesTask();
            continue;
          }

          const isLastTask = taskIndex === tasks?.length - 1;

          const valid = await task.checkGroupTask();

          if (!valid) {
            if (!isLastTask) {
              await delayFunc(randomInRange(delay) * 1000, signal?.current);
            }
            continue;
          }

          const { feedbackId, isCancelPostComment, groupId } = await task.postTask();

          if (!feedbackId || checkExceededGroupIssues(groupId)) {
            continue; // Move to the next main task
          }

          // Have feedback => post success => reset post issues
          if (feedbackId) {
            onResetPostIssues();
          }

          // Check should skip post comments(Post  just created above need approve by mod or admin of group)
          if (!isCancelPostComment) {
            for (const commentTask of task.commentTasks) {
              if (signal?.current?.aborted) {
                throw new Error(CANCEL_MSG);
              }
              await delayFunc(randomInRange(delay) * 1000, signal?.current);
              await commentTask(feedbackId);
            }
          }
          if (!isLastTask) {
            await delayFunc(randomInRange(delay) * 1000, signal?.current);
          }
        }
        return null;
      } catch (error) {
        console.error(error);
        return null;
      } finally {
        onComplete();
      }
    },
    [FBData]
  );

  // Get comments from feedback
  const getCommentsFromFeedback = useCallback(
    async (feedbackId, amount = 55, cursor = null, otherFilters = (item) => true, returnCursor = false) => {
      try {
        if (!feedbackId) {
          return null;
        }
        const FBToolInstance = getFBServiceInstance();
        const commentOfFeedbackRequest = FBToolInstance?.getCommentsFromFeedbackPayload({
          feedbackId,
          cursor,
        });
        const response = await sendRequest('FETCH', commentOfFeedbackRequest);
        const data = responseHelper.extractCommentOfFeedbackData(response);
        let comments = (data?.comments || [])?.filter((item) => item?.content?.message?.text && otherFilters(item));
        const { has_next_page: hasNextPage, end_cursor: nextCursor } = data?.pagination || {};

        const commentsSize = comments?.length;

        if (commentsSize >= amount) {
          if (returnCursor) {
            return { comments: comments.slice(0, amount), pagination: data?.pagination };
          }
          return comments.slice(0, amount);
        }

        if (hasNextPage && nextCursor) {
          const nextData = await getCommentsFromFeedback(feedbackId, amount - commentsSize, nextCursor);
          if (returnCursor) {
            comments = [...comments, ...(nextData?.comments || [])];
          } else {
            comments = [...comments, ...nextData];
          }
        }

        if (returnCursor) {
          return { comments: comments.slice(0, amount), pagination: data?.pagination };
        }
        return comments.slice(0, amount);
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    [FBData]
  );

  // Get replies from comment
  const getRepliesFromComment = useCallback(
    async (feedbackId, expansionToken, amount = 10, cursor = null) => {
      try {
        if (!feedbackId) {
          return null;
        }
        const FBToolInstance = getFBServiceInstance();
        const repliesOfCommentRequest = FBToolInstance?.getRepliesOfCommentPayload({
          feedbackId,
          expansionToken,
          cursor,
        });
        const response = await sendRequest('FETCH', repliesOfCommentRequest);
        const data = responseHelper.extractRepliesOfComment(response);
        let replies = data?.replies || [];
        const { has_next_page: hasNextPage, end_cursor: nextCursor } = data?.pagination || {};

        const replySize = replies?.length;

        if (replySize >= amount) {
          return replies.slice(0, amount);
        }

        if (hasNextPage && nextCursor) {
          const nextData = await getRepliesFromComment(feedbackId, expansionToken, amount - replySize, nextCursor);
          replies = [...replies, ...nextData];
        }

        return replies.slice(0, amount);
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    [FBData]
  );

  // Get feedbackData
  const getFeedbackData = useCallback(
    async (groupId, amount, cursor = null) => {
      try {
        const FBToolInstance = getFBServiceInstance();
        const feedbacksRequest = FBToolInstance?.getFeedbackPayload(groupId, cursor);
        const response = await sendRequest('FETCH', feedbacksRequest);
        const data = responseHelper.extractFeedbackData(response);

        let feedBackIds = data?.feedBackIds || [];
        let cursors = data?.cursors || [];

        let nextCursor = null;
        const feedBackSize = feedBackIds?.length;
        const cursorSize = cursors?.length;

        if (feedBackSize >= amount) {
          return feedBackIds.slice(0, amount);
        }

        if (feedBackSize !== 0 && cursorSize !== 0) {
          nextCursor = cursors[cursorSize - 1];
          if (nextCursor) {
            const nextData = await getFeedbackData(groupId, amount - feedBackSize, nextCursor);
            feedBackIds = [...feedBackIds, ...nextData];
          }
        }

        return feedBackIds.slice(0, amount);
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    [FBData]
  );

  // Get posts timeline of user
  /**
   *  const posts = await getTimelinePostsOfUser('100006370831091');
      console.log(posts);
   */
  const getTimelinePostsOfUser = useCallback(
    async (userId, amount, cursor = null) => {
      try {
        const FBToolInstance = getFBServiceInstance();
        const timelineRequest = FBToolInstance?.getTimelinePostsPayload(userId, cursor);
        const response = await sendRequest('FETCH', timelineRequest);
        const data = responseHelper.extractTimelinePosts(response);

        let feedBackIds = data?.feedBackIds || [];
        let cursors = data?.cursors || [];

        let nextCursor = null;
        const feedBackSize = feedBackIds?.length;
        const cursorSize = cursors?.length;

        if (feedBackSize >= amount) {
          return { posts: feedBackIds.slice(0, amount), nextCursor: cursorSize !== 0 ? cursors[cursorSize - 1] : null };
        }

        if (feedBackSize !== 0 && cursorSize !== 0) {
          nextCursor = cursors[cursorSize - 1];
          if (nextCursor) {
            const nextData = await getTimelinePostsOfUser(userId, amount - feedBackSize, nextCursor);
            feedBackIds = [...feedBackIds, ...(nextData?.posts || [])];
          }
        }

        return { posts: feedBackIds.slice(0, amount), nextCursor };
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    [FBData]
  );

  const getTimelinePostsOfPage = useCallback(
    async (from, to, amount, cursor = null) => {
      try {
        const FBToolInstance = getFBServiceInstance();
        const timelineRequest = FBToolInstance?.getTimelinePostsOfPagePayload(from, to, cursor);
        const response = await sendRequest('FETCH', timelineRequest);
        const data = responseHelper.extractTimelinePosts(response);
        let feedBackIds = data?.feedBackIds || [];
        let cursors = data?.cursors || [];

        let nextCursor = null;
        const feedBackSize = feedBackIds?.length;
        const cursorSize = cursors?.length;

        if (feedBackSize >= amount) {
          return { posts: feedBackIds.slice(0, amount), nextCursor: cursorSize !== 0 ? cursors[cursorSize - 1] : null };
        }

        if (feedBackSize !== 0 && cursorSize !== 0) {
          nextCursor = cursors[cursorSize - 1];
          if (nextCursor) {
            const nextData = await getTimelinePostsOfPage(from, to, amount - feedBackSize, nextCursor);
            feedBackIds = [...feedBackIds, ...(nextData?.posts || [])];
          }
        }

        return { posts: feedBackIds.slice(0, amount), nextCursor };
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    [FBData]
  );

  // Poke friend
  const pokeFriend = useCallback(
    async (userId) => {
      try {
        const FBToolInstance = getFBServiceInstance();
        const pokeRequest = FBToolInstance?.getPokePayload(userId);
        const response = await sendRequest('FETCH', pokeRequest);
        return responseHelper.extractPokeData(response, userId);
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    [FBData]
  );

  // Like friend
  const likePost = useCallback(
    async (feedbackId) => {
      try {
        const FBToolInstance = getFBServiceInstance();
        const likeRequest = FBToolInstance?.getLikeReactionPayload(feedbackId);
        const response = await sendRequest('FETCH', likeRequest);
        return responseHelper.extractLikeData(response);
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    [FBData]
  );

  // remove friend
  const removeFriend = useCallback(
    async (friendId) => {
      try {
        const FBToolInstance = getFBServiceInstance();
        const likeRequest = FBToolInstance?.getRemoveFriendPayload(friendId);
        const response = await sendRequest('FETCH', likeRequest);
        return responseHelper.extractRemoveFriend(response, friendId);
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    [FBData]
  );

  // remove sale format
  const removeSaleFormatFunc = useCallback(
    async (storyId) => {
      try {
        const FBToolInstance = getFBServiceInstance();
        const likeRequest = FBToolInstance?.getRemoveSaleFormatPayload(storyId);
        const response = await sendRequest('FETCH', likeRequest);
        return responseHelper.extractRemoveSaleformat(response, storyId);
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    [FBData]
  );

  // Get feedbackData
  const getPostsInGroupByKeyword = useCallback(
    async (groupId, keyword, cursor = null) => {
      try {
        const FBToolInstance = getFBServiceInstance();
        const feedbacksRequest = FBToolInstance?.getSearchPostsInGroupPayload(groupId, keyword, cursor);
        const response = await sendRequest('FETCH', feedbacksRequest);

        return responseHelper.extractPostsOfSearchInGroup(response);
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    [FBData]
  );

  // Seeding Comments
  const postSeedingComments = async (data, callback, onComplete) => {
    try {
      restartSignal();

      const { groupIds, comments, delay } = data || {};
      const FBToolInstance = getFBServiceInstance();
      const commentSpin = FBToolInstance?.getSpinComments(comments || [], groupIds);
      // Tasks
      const tasks = [];

      groupIds?.forEach((groupId) => {
        // Feedback task
        const feedbackTask = async () => {
          return await getFeedbackData(groupId, comments?.length);
        };

        // Comment task
        const commentTask = async (item, thisComment) => {
          const res = await postComment(item.id, { ...thisComment, groupId });
          if (res) {
            callback({
              success: true,
              groupId,
              feedbackData: item,
              isSeedingComment: true,
              type: 'seeding_comment',
              linkComment: res,
            });
          } else {
            callback({
              success: false,
              groupId,
              feedbackData: item,
              isSeedingComment: true,
              type: 'seeding_comment',
            });
          }
        };

        const task = { feedbackTask, commentTask };
        tasks.push(task);
      });

      for (const [taskIndex, task] of tasks.entries()) {
        if (signal?.current?.aborted) {
          throw new Error(CANCEL_MSG);
        }

        const feedbacks = await task.feedbackTask();

        if (!feedbacks || feedbacks?.length === 0) {
          continue; // Move to the next main task
        }

        let modifyComments = [];
        if (commentSpin && commentSpin?.length !== 0) {
          const spinData = commentSpin?.pop();
          // Update head
          commentSpin.unshift(spinData);
          modifyComments = spinData || [];
        }

        for (const [commentIndex, item] of feedbacks.entries()) {
          if (signal?.current?.aborted) {
            throw new Error(CANCEL_MSG);
          }
          // Comments
          const thisComment = modifyComments?.[commentIndex];
          await task.commentTask(item, thisComment);

          const isLastTask = feedbacks?.length - 1 === commentIndex && tasks?.length - 1 === taskIndex;
          if (!isLastTask) {
            await delayFunc(randomInRange(delay) * 1000, signal?.current);
          }
        }
      }

      return null;
    } catch (error) {
      console.log(error?.message);
      return null;
    } finally {
      onComplete();
    }
  };

  // ADd new friends
  const addListFriends = async (data, callback, onComplete) => {
    try {
      restartSignal();

      const { users, delay } = data || {};
      // Tasks
      const tasks = [];

      users?.forEach((userUID) => {
        // Add friend task
        const addFriendTask = async () => {
          const res = await addFriend(userUID);
          // Get profile
          const profile = await getProfileByUserUID(userUID);
          callback({
            profile,
            success: !!res,
          });
        };

        tasks.push(addFriendTask);
      });

      for (const [taskIndex, task] of tasks.entries()) {
        if (signal?.current?.aborted) {
          throw new Error(CANCEL_MSG);
        }

        await task();

        const isLastTask = tasks?.length - 1 === taskIndex;
        if (!isLastTask) {
          await delayFunc(randomInRange(delay) * 1000, signal?.current);
        }
      }

      return null;
    } catch (error) {
      console.log(error?.message);
      return null;
    } finally {
      onComplete();
    }
  };

  // Get site info
  const getSiteInfo = useCallback(
    async (url) => {
      try {
        if (!url) {
          return null;
        }
        const FBToolInstance = getFBServiceInstance();
        const siteInfoRequest = FBToolInstance?.getPayloadSiteInfo(url);
        const response = await sendRequest('FETCH', siteInfoRequest);
        return { ...(response?.data || {}), url } || null;
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    [FBData]
  );

  // Get search posts
  const getSearchPosts = useCallback(
    async (data) => {
      try {
        const FBToolInstance = getFBServiceInstance();
        const searchPostRequest = FBToolInstance?.getSearchPostsPayload(data);
        const response = await sendRequest('FETCH', searchPostRequest);

        if (response?.responseInfo) {
          const { status, location } = response?.responseInfo;
          if (status === 302 && location && location?.indexOf('checkpoint') !== -1) {
            stopQueue(true, 'checkpoint');
            return null;
          }
        }

        if (!response?.data?.serpResponse) {
          const isError = await checkErrors(response);
          if (isError) {
            return null;
          }
        }
        // ---------------Finished detect checkpoint----------------

        return responseHelper.extractSearchPosts(response);
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    [FBData]
  );

  const getGroupActivity = useCallback(
    async (groupId) => {
      const FBToolInstance = getFBServiceInstance();
      const groupActivityRequest = FBToolInstance?.getGroupActivityPayload(groupId);
      const response = await sendRequest('FETCH', groupActivityRequest);
      return responseHelper.extractGroupActivity(response);
    },
    [FBData]
  );

  // Get search posts
  const addFriend = useCallback(
    async (newFriendId) => {
      try {
        const targetId = `${newFriendId}`;
        const FBToolInstance = getFBServiceInstance();
        const addFriendRequest = FBToolInstance?.getAddFriendRequest(newFriendId);
        const res = await sendRequest('FETCH', addFriendRequest);
        const { data } = res;

        // ---------------Begin detect checkpoint----------------
        if (res?.responseInfo) {
          const { status, location } = res?.responseInfo;
          if (status === 302 && location && location?.indexOf('checkpoint') !== -1) {
            stopQueue(true, 'checkpoint');
            return false;
          }
        }

        if (!res?.data?.friend_request_send) {
          const isError = await checkErrors(res);
          if (isError) {
            return false;
          }
        }
        // ---------------Finished detect checkpoint----------------

        return data?.friend_request_send?.friend_requestees[0]?.id === targetId;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    [FBData]
  );

  // Send inbox
  const sendInbox = async (uid, text, images = [], delay = 60 + 30) => {
    try {
      let files = await uploadImages(images || []);
      files = files?.map((item) => item?.imageSrc);
      // Timeout in seconds
      return await sendRequest('SEND_INBOX', { uid, text, images: files }, signal?.current, delay);
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  // Send inbox page
  const sendInboxPage = async (uid, text, images = [], delay = 60 + 30) => {
    try {
      const FBService = getFBServiceInstance();
      const sender = delegatePageIdRef.current || FBService.cookies?.i_user || FBService.cookies?.c_user;
      // Timeout in seconds
      return await sendRequest('SEND_PAGE_INBOX', { sender, uid, text }, signal?.current, delay);
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  // Remove pending posts in group
  const removePendingPostsManual = async (groupId) => {
    try {
      // Timeout in seconds
      return await sendRequest('REMOVE_PENDING_POSTS', { groupId }, signal?.current, 180);
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  // get remains pending posts in group
  const getRemainsPendingPostsManual = async (groupId) => {
    try {
      // Timeout in seconds
      const res = await sendRequest('REMOVE_PENDING_POSTS', { groupId, onlyGetRemains: true }, signal?.current, 100);
      return res?.remains || 0;
    } catch (error) {
      console.log(error);
      return 0;
    }
  };

  /**
   * Seeding leads
   */
  const leadsSeeding = async (data, callback, markAsExceededLimit, markAsRunning) => {
    try {
      // Restart signal
      restartSignal();
      markAsRunning();

      const ACTIONS = {
        COMMENT: 'comment',
        ADD_FRIEND: 'add_friend',
        INBOX: 'inbox',
        LIKE: 'like',
      };

      const { contents, leads, options, keyword, mentionSource } = data;
      const excludesData = { ...data?.excludesData };

      const actions = options?.actions?.map((item) => item?.value) || [];

      // if (actions?.length === 0) {
      //   return {};
      // }

      // Check actions need execute
      const settings = {
        allowComment: actions?.indexOf(ACTIONS?.COMMENT) !== -1,
        allowAddFriend: actions?.indexOf(ACTIONS?.ADD_FRIEND) !== -1,
        allowInbox: actions?.indexOf(ACTIONS?.INBOX) !== -1,
        allowLike: actions?.indexOf(ACTIONS?.LIKE) !== -1,
      };

      const baseNumber = Math.floor(TOTAL_DELAY / (actions?.length || 1));
      const delay = { from: baseNumber, to: baseNumber };
      const commentIndex = options?.actions?.findIndex((item) => item?.value === 'comment');
      let isKeepOriginProduct = false;
      if (commentIndex !== -1 && options?.actions[commentIndex] && options?.actions[commentIndex]?.keepOriginProduct) {
        isKeepOriginProduct = true;
      }

      const tasks = [];

      leads?.forEach((lead) => {
        const matchProductTask = async () => {
          const { result: res, isLimitAIRequest } = await matchProduct(contents, lead?.content, lead?.parent, keyword);
          if (isLimitAIRequest) {
            markAsExceededLimit();
            throw new Error('matchProductTask: Got exceeded limit AI request');
          }
          let product = null;
          if (res && res?.length !== 0) {
            const radIndex = randomInRange({ from: 0, to: res?.length - 1 });
            product = res[radIndex];
            // Get facebook information
            const skipGetInfo = excludesData?.info?.indexOf(actorId) !== -1;

            const infoReadToday = getCurrentReadInfo();
            const isExceededReadInfoDaily = infoReadToday >= DAILY_LIMIT_READ_FACEBOOK_INFO;
            if (isExceededReadInfoDaily) {
              enqueueSnackbar(translate('limit_read_facebook_info', { amount: DAILY_LIMIT_READ_FACEBOOK_INFO }), {
                variant: 'warning',
                persist: true,
              });
            }

            if (lead?.uid && !skipGetInfo && !isExceededReadInfoDaily) {
              await getFacebookInfomation(actorId);
              callback({
                type: 'facebook_info',
                uid: lead?.uid,
              });
            }
          }
          callback({
            feedback: lead?.feedback,
            result: { product: { success: !!res, data: product } },
          });
          return product;
        };

        const otherTasks = [];

        const actorId = lead?.uid;

        if (settings?.allowComment) {
          // Comment
          const commentTask = async (data) => {
            if (!lead?.feedback) {
              callback({
                feedback: lead?.feedback,
                result: { comment: { success: false } },
              });
              return { skip: true };
            }
            console.log('comment', lead?.feedback, data);
            // Only get first image for comment
            const { imageFiles } = categorizeFiles(data?.files || []);
            const oneImage = (imageFiles || [])?.slice(0, 1);
            // Can exist comment from AI
            let text = data?.comment || data?.text;
            if (isKeepOriginProduct) {
              text = data?.comment ? `${data?.comment} \n\n ${data?.text}` : data?.text;
            }
            const res = await postComment(lead?.feedback, { ...data, text, files: oneImage, mentionSource });
            callback({
              feedback: lead?.feedback,
              result: { comment: { success: !!res } },
            });
          };
          otherTasks.push(commentTask);
        }

        if (settings?.allowAddFriend) {
          // Add friend
          const addFriendTask = async () => {
            const skipAddFriend = excludesData?.add_friend?.indexOf(actorId) !== -1;
            if (skipAddFriend || !lead?.uid) {
              callback({
                feedback: lead?.feedback,
                result: { add_friend: { success: false } },
              });
              return { skip: true };
            }
            console.log('add friend', lead?.uid);
            if (lead?.isPage) {
              callback({
                feedback: lead?.feedback,
                result: { add_friend: { success: false } },
              });
              excludesData?.add_friend?.push(actorId);
              return;
            }
            const res = await addFriend(lead?.uid);
            callback({
              feedback: lead?.feedback,
              result: { add_friend: { success: !!res } },
            });
            excludesData?.add_friend?.push(actorId);
          };
          otherTasks.push(addFriendTask);
        }

        if (settings?.allowInbox) {
          // Inbox
          const inboxTask = async (data) => {
            const skipInbox = excludesData?.inbox?.indexOf(actorId) !== -1;

            const inboxSentToday = getCurrentInboxSent();
            const isLimitInboxDaily = inboxSentToday >= DAILY_LIMIT_INBOX;

            // Only send inbox -> stop
            if (isLimitInboxDaily && actions?.length === 1) {
              enqueueSnackbar(translate('limit_inbox', { amount: DAILY_LIMIT_INBOX }), {
                variant: 'warning',
                persist: true,
              });
              stopQueue(true, 'manual');
              return;
            }

            if (skipInbox || !lead?.uid || isLimitInboxDaily) {
              callback({
                feedback: lead?.feedback,
                result: { inbox: { success: false } },
              });
              return { skip: true, isLimitInboxDaily };
            }
            console.log('inbox', lead?.uid, data?.text, data);

            // Send greeting message
            if (data?.greeting) {
              await sendInbox(lead?.uid, data?.greeting, []);
            }
            const { imageFiles } = categorizeFiles(data?.files || []);

            const res = await sendInbox(lead?.uid, data?.text, imageFiles);
            const { sent } = res || {};
            callback({
              feedback: lead?.feedback,
              result: { inbox: { success: !!sent } },
            });
            excludesData?.inbox?.push(actorId);
          };
          otherTasks.push(inboxTask);
        }

        if (settings?.allowLike) {
          if (lead?.feedback) {
            // Comment task
            const likeTask = async () => {
              console.log(`Like`, lead?.feedback);
              const res = await likePost(lead?.feedback);
              callback({
                feedback: lead?.feedback,
                result: { like: { success: !!res, data: res } },
              });
            };
            otherTasks.push(likeTask);
          }
        }

        tasks.push({ matchProductTask, otherTasks });
      });

      for (const [taskIndex, task] of tasks.entries()) {
        if (signal?.current?.aborted) {
          throw new Error(CANCEL_MSG);
        }

        const matchedContent = await task?.matchProductTask();
        if (!matchedContent) {
          continue;
        }

        const { description, images, comment, greeting } = matchedContent || {};
        const data = {
          text: description,
          files: images || [],
          comment,
          greeting,
        };

        for (const [subTaskIndex, subTask] of task?.otherTasks.entries()) {
          if (signal?.current?.aborted) {
            throw new Error(CANCEL_MSG);
          }
          const res = await subTask(data);
          const isLastTask = task?.otherTasks?.length - 1 === subTaskIndex && tasks?.length - 1 === taskIndex;
          if (!res?.skip && !isLastTask) {
            await delayFunc(randomInRange(delay) * 1000, signal?.current);
          }
        }
      }

      return {};
    } catch (error) {
      console.log('leadsSeeding', error, error?.message);
      // Detect stop
      if ([ABORT_MSG, CANCEL_MSG]?.some((item) => error?.message?.indexOf(item) !== -1)) {
        return { isOperationCanceled: true };
      }
      return {};
    }
  };

  const leadsSeedingSavedLink = async (data, callback, markAsExceededLimit, markAsRunning) => {
    try {
      // Restart signal
      restartSignal();
      markAsRunning();

      const ACTIONS = {
        COMMENT: 'comment',
        ADD_FRIEND: 'add_friend',
        INBOX: 'inbox',
        LIKE: 'like',
      };

      const { contents, leads, options, mentionSource } = data;
      const excludesData = { ...data?.excludesData };

      const actions = options?.actions?.map((item) => item?.value) || [];

      // Check actions need execute
      const settings = {
        allowComment: actions?.indexOf(ACTIONS?.COMMENT) !== -1,
        allowAddFriend: actions?.indexOf(ACTIONS?.ADD_FRIEND) !== -1,
        allowInbox: actions?.indexOf(ACTIONS?.INBOX) !== -1,
        allowLike: actions?.indexOf(ACTIONS?.LIKE) !== -1,
      };

      const baseNumber = Math.floor(TOTAL_DELAY / (actions?.length || 1));
      const delay = { from: baseNumber, to: baseNumber };

      // Keep origin data
      const commentIndex = options?.actions?.findIndex((item) => item?.value === 'comment');
      let isKeepOriginProduct = false;
      if (commentIndex !== -1 && options?.actions[commentIndex] && options?.actions[commentIndex]?.keepOriginProduct) {
        isKeepOriginProduct = true;
      }

      const tasks = [];

      leads?.forEach((lead) => {
        const facebookInfoTask = async () => {
          callback({
            uniqueId: lead?.uniqueId,
            result: { progressed: { success: true } },
          });

          // Get facebook information
          const skipGetInfo = excludesData?.info?.indexOf(actorId) !== -1;

          const infoReadToday = getCurrentReadInfo();
          const isExceededReadInfoDaily = infoReadToday >= DAILY_LIMIT_READ_FACEBOOK_INFO;
          if (isExceededReadInfoDaily) {
            enqueueSnackbar(translate('limit_read_facebook_info', { amount: DAILY_LIMIT_READ_FACEBOOK_INFO }), {
              variant: 'warning',
              persist: true,
            });
          }

          if (lead?.uid && !skipGetInfo && !isExceededReadInfoDaily) {
            await getFacebookInfomation(actorId);
            callback({
              type: 'facebook_info',
              uid: lead?.uid,
            });
          }
        };

        const otherTasks = [];

        const actorId = lead?.uid;

        if (settings?.allowComment) {
          // Comment
          const commentTask = async (data) => {
            if (!lead?.feedback) {
              callback({
                uniqueId: lead?.uniqueId,
                result: { comment: { success: false } },
              });
              return { skip: true };
            }
            console.log('comment', lead?.feedback, data);
            // Only get first image for comment
            const { imageFiles } = categorizeFiles(data?.files || []);
            const oneImage = (imageFiles || [])?.slice(0, 1);
            // Can exist comment from AI
            let text = data?.comment;
            if (isKeepOriginProduct) {
              text = data?.text;
              if (data?.greeting && data?.text !== data?.greeting) {
                text = `${data?.greeting}\n${data?.text}`;
              }
            }
            const res = await postComment(lead?.feedback, { ...data, text, files: oneImage, mentionSource });
            callback({
              uniqueId: lead?.uniqueId,
              result: { comment: { success: !!res } },
            });
          };
          otherTasks.push(commentTask);
        }

        if (settings?.allowAddFriend) {
          // Add friend
          const addFriendTask = async () => {
            const skipAddFriend = excludesData?.add_friend?.indexOf(actorId) !== -1;
            if (skipAddFriend || !lead?.uid) {
              callback({
                uniqueId: lead?.uniqueId,
                result: { add_friend: { success: false } },
              });
              return { skip: true };
            }
            console.log('add friend', lead?.uid);
            if (lead?.isPage) {
              callback({
                uniqueId: lead?.uniqueId,
                result: { add_friend: { success: false } },
              });
              excludesData?.add_friend?.push(actorId);
              return;
            }
            const res = await addFriend(lead?.uid);
            callback({
              uniqueId: lead?.uniqueId,
              result: { add_friend: { success: !!res } },
            });
            excludesData?.add_friend?.push(actorId);
          };
          otherTasks.push(addFriendTask);
        }

        if (settings?.allowInbox) {
          // Inbox
          const inboxTask = async (data) => {
            const skipInbox = excludesData?.inbox?.indexOf(actorId) !== -1;

            const inboxSentToday = getCurrentInboxSent();
            const isLimitInboxDaily = inboxSentToday >= DAILY_LIMIT_INBOX;

            // Only send inbox -> stop
            if (isLimitInboxDaily && actions?.length === 1) {
              enqueueSnackbar(translate('limit_inbox', { amount: DAILY_LIMIT_INBOX }), {
                variant: 'warning',
                persist: true,
              });
              stopQueue(true, 'manual');
              return;
            }

            if (skipInbox || !lead?.uid || isLimitInboxDaily) {
              callback({
                uniqueId: lead?.uniqueId,
                result: { inbox: { success: false } },
              });
              return { skip: true, isLimitInboxDaily };
            }
            console.log('inbox', lead?.uid, data?.text, data);

            // Send greeting message
            if (data?.greeting) {
              await sendInbox(lead?.uid, data?.greeting, []);
            }

            const { imageFiles } = categorizeFiles(data?.files || []);

            const res = await sendInbox(lead?.uid, data?.text, imageFiles);
            const { sent } = res || {};
            callback({
              uniqueId: lead?.uniqueId,
              result: { inbox: { success: !!sent } },
            });
            excludesData?.inbox?.push(actorId);
          };
          otherTasks.push(inboxTask);
        }

        if (settings?.allowLike) {
          if (lead?.feedback) {
            console.log(`Like`, lead?.feedback);
            // Comment task
            const likeTask = async () => {
              const res = await likePost(lead?.feedback);
              callback({
                uniqueId: lead?.uniqueId,
                result: { like: { success: !!res, data: res } },
              });
            };
            otherTasks.push(likeTask);
          }
        }

        const AISpinContent = async () => {
          if (!settings?.allowInbox && !settings?.allowComment) {
            return null;
          }
          const { result: res, isLimitAIRequest } = await matchProduct(contents, lead?.content, lead?.parent, '');
          if (isLimitAIRequest) {
            markAsExceededLimit();
            throw new Error('AISpinContent: Got exceeded limit AI request');
          }
          let product = null;
          if (res && res?.length !== 0) {
            const radIndex = randomInRange({ from: 0, to: res?.length - 1 });
            product = res[radIndex];
          }
          return product;
        };

        tasks.push({ facebookInfoTask, AISpinContent, otherTasks });
      });

      for (const [taskIndex, task] of tasks.entries()) {
        if (signal?.current?.aborted) {
          throw new Error(CANCEL_MSG);
        }

        await task?.facebookInfoTask();

        const content = await task?.AISpinContent();
        const { description, images, comment, greeting } = content || {};
        const data = {
          text: description,
          files: images || [],
          comment,
          greeting,
        };

        for (const [subTaskIndex, subTask] of task?.otherTasks.entries()) {
          if (signal?.current?.aborted) {
            throw new Error(CANCEL_MSG);
          }
          const res = await subTask(data);
          const isLastTask = task?.otherTasks?.length - 1 === subTaskIndex && tasks?.length - 1 === taskIndex;
          if (!res?.skip && !isLastTask) {
            await delayFunc(randomInRange(delay) * 1000, signal?.current);
          }
        }
      }

      return {};
    } catch (error) {
      console.log('leadsSeeding', error, error?.message);
      // Detect stop
      if ([ABORT_MSG, CANCEL_MSG]?.some((item) => error?.message?.indexOf(item) !== -1)) {
        return { isOperationCanceled: true };
      }
      return {};
    }
  };

  // Seeding Comments
  const commentUpPosts = async (data, callback) => {
    try {
      restartSignal();

      const { jobs, comments, delay, mentionSource } = data || {};

      // Tasks
      const tasks = [];

      jobs?.forEach((job) => {
        const { assetUUID, posts } = job;
        // Get posts list
        posts?.forEach((post) => {
          const { groupId, postedUID } = post;
          postedUID?.forEach((storyId) => {
            const feedbackId = convertIdToFeedBackId(storyId);
            if (feedbackId) {
              // Random comment
              const radIndex = randomInRange({ from: 0, to: comments?.length });
              const text = comments[radIndex];
              // Comment task
              const commentTask = async () => {
                const res = await postComment(feedbackId, { text, groupId, mentionSource });
                if (res) {
                  callback({
                    success: true,
                    assetUUID,
                    groupId,
                    link: res,
                  });
                } else {
                  callback({
                    success: false,
                    assetUUID,
                    groupId,
                  });
                }
              };

              const task = { commentTask };
              tasks.push(task);
            }
          });
        });
      });

      for (const [taskIndex, task] of tasks.entries()) {
        if (signal?.current?.aborted) {
          throw new Error(CANCEL_MSG);
        }

        await task.commentTask();

        const isLastTask = tasks?.length - 1 === taskIndex;
        if (!isLastTask) {
          await delayFunc(randomInRange(delay) * 1000, signal?.current);
        }
      }

      return {};
    } catch (error) {
      console.log(error?.message);
      if ([ABORT_MSG, CANCEL_MSG]?.some((item) => error?.message?.indexOf(item) !== -1)) {
        return { isOperationCanceled: true };
      }
      return {};
    }
  };

  const infiniteRemovePendingPosts = async (data, callback) => {
    try {
      restartSignal();

      const { groupIds, delay, isFirstLoop } = data || {};

      // Tasks
      const tasks = [];

      groupIds?.forEach((groupId) => {
        let getPendingTask = null;
        if (isFirstLoop) {
          getPendingTask = async () => {
            const remains = await getRemainsPendingPostsManual(groupId);
            callback({
              success: true,
              groupId,
              remains: remains || 0,
            });
          };
        }

        const removeTask = async () => {
          const res = await removePendingPostsManual(groupId);
          console.log('[infiniteRemovePendingPosts][removePendingPostsManual][result]:', res);
          if (res) {
            callback({
              success: true,
              groupId,
              amount: res?.amount || 0,
            });
          }
        };

        const task = { removeTask, getPendingTask };
        tasks.push(task);
      });

      for (const [taskIndex, task] of tasks.entries()) {
        if (signal?.current?.aborted) {
          throw new Error(CANCEL_MSG);
        }

        if (task?.getPendingTask) {
          await task?.getPendingTask();
        }

        await task.removeTask();

        const isLastTask = tasks?.length - 1 === taskIndex;
        if (!isLastTask) {
          await delayFunc(randomInRange(delay) * 1000, signal?.current);
        }
      }

      return {};
    } catch (error) {
      console.log(error?.message);
      if ([ABORT_MSG, CANCEL_MSG]?.some((item) => error?.message?.indexOf(item) !== -1)) {
        return { isOperationCanceled: true };
      }
      return {};
    }
  };

  const extractSpinData = (item) => {
    const { files, content } = item;
    // Get spin content
    let modifiedContent = content;
    if (item?.spin && item?.spin?.length !== 0) {
      let spinData = item?.spin?.pop();
      // Update head
      item?.spin?.unshift(spinData);
      if (spinData) {
        Object.keys(spinData).forEach((key) => {
          modifiedContent = modifiedContent.replace(`{{${key}}}`, spinData[key]);
        });
      }
    }
    return { files, content: modifiedContent };
  };

  const getRandomContent = (comBinationsContents) => {
    const item = comBinationsContents[randomInRange({ from: 0, to: comBinationsContents?.length - 1 })];
    return extractSpinData(item);
  };

  // Interact friend
  const interactFriend = async (data, callback) => {
    try {
      restartSignal();

      const FBToolInstance = getFBServiceInstance();

      const { posts, delay, actions, friendId, contents, isPageProfile, isTextedWithPageBefore, mentionSource } =
        data || {};

      const excludesData = { ...data?.excludesData };

      const comBinationsContents = contents.map((item) => ({
        ...item,
        spin: FBToolInstance?.generateCombinations(item?.spinContents || {}),
      }));

      const ACTIONS = {
        COMMENT: 'comment',
        REMOVE_FRIEND: 'remove_friend',
        INBOX: 'inbox',
        LIKE: 'like',
        POKE: 'poke',
      };

      // Check actions need execute
      const settings = {
        allowComment: actions?.indexOf(ACTIONS?.COMMENT) !== -1,
        allowRemove: actions?.indexOf(ACTIONS?.REMOVE_FRIEND) !== -1,
        allowInbox: actions?.indexOf(ACTIONS?.INBOX) !== -1,
        allowLike: actions?.indexOf(ACTIONS?.LIKE) !== -1,
        allowPoke: actions?.indexOf(ACTIONS?.POKE) !== -1,
      };

      // Tasks
      const task = {};

      if (settings.allowComment && posts?.length !== 0 && comBinationsContents) {
        const commentTasks = [];
        posts?.forEach((post, index) => {
          if (index !== 0) {
            const firstData = comBinationsContents?.shift();
            comBinationsContents?.push(firstData);
          }

          const { files, content } = extractSpinData(comBinationsContents[0]) || getRandomContent(comBinationsContents);

          if (post?.id) {
            console.log(`Comment ${index}`, files, content);
            // Comment task
            const commentTask = async () => {
              const res = await postComment(post?.id, { text: content, files, mentionSource });
              callback({
                friendId,
                result: { comment: { success: !!res, link: res } },
              });
            };
            commentTasks.push(commentTask);
          }
        });
        task.commentTasks = commentTasks;
      }

      if (settings?.allowRemove) {
        // Remove friend
        const removeFriendTask = async () => {
          console.log('remove friend', friendId);
          const res = await removeFriend(friendId);
          callback({
            friendId,
            result: { remove_friend: { success: !!res } },
          });
        };
        task.removeFriend = removeFriendTask;
      }

      // Check flow inbox
      const inboxByUserProfile = settings?.allowInbox && !isPageProfile;
      const inboxByPageProfile = settings?.allowInbox && isPageProfile && isTextedWithPageBefore;

      if (inboxByUserProfile || inboxByPageProfile) {
        // Inbox
        const { files, content } = getRandomContent(comBinationsContents);
        const inboxTask = async () => {
          const skipInbox = excludesData?.inbox?.indexOf(friendId) !== -1;

          const inboxSentToday = getCurrentInboxSent();
          const isLimitInboxDaily = inboxSentToday >= DAILY_LIMIT_INBOX;

          // Only send inbox -> stop
          if (isLimitInboxDaily && actions?.length === 1) {
            enqueueSnackbar(translate('limit_inbox', { amount: DAILY_LIMIT_INBOX }), {
              variant: 'warning',
              persist: true,
            });
            stopQueue(true, 'manual');
            return;
          }

          if (skipInbox) {
            return;
          }

          if (!friendId || isLimitInboxDaily) {
            callback({
              friendId,
              result: { inbox: { success: false } },
            });
          }

          console.log('inbox', friendId, content);

          const { imageFiles } = categorizeFiles(files || []);

          const res = isPageProfile
            ? await sendInboxPage(friendId, content, imageFiles)
            : await sendInbox(friendId, content, imageFiles);
          const { sent } = res || {};
          callback({
            friendId,
            result: { inbox: { success: !!sent } },
          });
        };
        task.inboxTask = inboxTask;
      }

      if (settings.allowLike && posts?.length !== 0) {
        // Like posts
        const likeTasks = [];
        posts?.forEach((post, index) => {
          console.log(`Like ${index}`, post.id);
          if (post?.id) {
            // Comment task
            const likeTask = async () => {
              const res = await likePost(post.id);
              callback({
                friendId,
                result: { like: { success: !!res, data: res } },
              });
            };
            likeTasks.push(likeTask);
          }
        });
        task.likeTasks = likeTasks;
      }

      if (settings?.allowPoke) {
        // poke friend
        const pokeTask = async () => {
          console.log('like post', friendId);
          const res = await pokeFriend(friendId);
          callback({
            friendId,
            result: { poke: { success: !!res } },
          });
        };
        task.pokeTask = pokeTask;
      }

      const listTask = Object.keys(task);
      for (const [taskIndex, taskKey] of listTask.entries()) {
        if (signal?.current?.aborted) {
          throw new Error(CANCEL_MSG);
        }

        if (['commentTasks', 'likeTasks']?.indexOf(taskKey) !== -1) {
          for (const [subTaskIndex, subTask] of task[taskKey].entries()) {
            if (signal?.current?.aborted) {
              throw new Error(CANCEL_MSG);
            }
            await subTask();
            const isLastTask = task[taskKey]?.length - 1 === subTaskIndex;
            if (!isLastTask) {
              await delayFunc(randomInRange(delay) * 1000, signal?.current);
            }
          }
        } else {
          await task[taskKey]();
        }

        const isLastTask = listTask?.length - 1 === taskIndex;
        if (!isLastTask) {
          await delayFunc(randomInRange(delay) * 1000, signal?.current);
        }
      }

      return {};
    } catch (error) {
      console.log(error?.message);
      if ([ABORT_MSG, CANCEL_MSG]?.some((item) => error?.message?.indexOf(item) !== -1)) {
        return { isOperationCanceled: true };
      }
      return {};
    }
  };

  const onStop = (reason) => {
    if (['checkpoint', 'retry']?.indexOf(reason) !== -1) {
      emitter.send(reason);
    }
  };

  // Stop current queue
  const stopQueue = async (sendMessage = false, reason = '') => {
    try {
      if (reason === 'checkpoint' || reason === 'manual') {
        controller?.current?.abort();
        emitter?.send('stop');
      }
      if (sendMessage) {
        onStop(reason);
      }
      return { reason };
    } catch (error) {
      console.log(error);
      return { reason: error?.message };
    } finally {
      slowdown.onFalse();
    }
  };

  const onCallbackWarningActionPopup = (result = 'skip', delayTime = null) => {
    // console.log('onCallbackWarningActionPopup', result, delayTime);
    const current = { ...retryInfoRef?.current };
    if (result === 'delay') {
      // In seconds
      if (delayTime) {
        setRetryInfo({ ...current, continueAfter: delayTime });
      }
    } else if (result === 'continue') {
      setRetryInfo({ ...current, continueAfter: 0 });
    }
  };

  const watchRetry = async () => {
    try {
      while (!retryInfoRef?.current?.isCancelled) {
        await new Promise((resolve) => {
          const current = retryInfoRef?.current;
          const isValidDelay = current?.continueAfter !== null && typeof current?.continueAfter === 'number';
          const currentDelay = isValidDelay ? current?.continueAfter : 1000;
          setTimeout(() => {
            if (isValidDelay) {
              setRetryInfo({ ...current, isCancelled: true });
            }
            resolve();
          }, currentDelay);
        });
      }
      if (retryInfoRef?.current?.isCancelled) {
        throw new Error('Operation cancelled');
      }
    } catch (error) {
      console.log('watchRetry', error);
      return;
    }
  };

  const checkErrors = async (response) => {
    try {
      if (isCheckPoint(response?.errors) || isCheckPoint(response?.data?.errors)) {
        stopQueue(true, 'checkpoint');
        return true;
      }
      if (isTooManyActions(response?.errors) || isTooManyActions(response?.data?.errors)) {
        // Auto delay
        if (signal?.current) {
          slowdown.onTrue();
          await delayFunc(DELAY_RETRY, signal?.current);
          slowdown.onFalse();
        }
        // stopQueue(true, 'retry');
        // await watchRetry();
        return false;
      }
      return false;
    } catch (error) {
      console.log('checkErrors', error);
      return false;
    }
  };

  /**
   * Check reponse have checkpoint, spam, ...
   */
  const isCheckPoint = (errors = []) => {
    try {
      const checkpointMessages = checkpointListRef?.current || [
        'bị hạn chế',
        'restrict',
        'checkpoint',
        'spam',
        'tần suất',
        'vĩnh viễn',
        'cấm',
        'not logged in',
        'đăng nhập',
        'vô hiệu hoá',
        'disabled',
        // 'tính năng',
        // 'lỗi truy vấn',
        // "can't",
      ];
      return haveErrorsInList(errors, checkpointMessages);
    } catch (error) {
      return false;
    }
  };

  /**
   * Check reponse have retry,...
   */
  const isTooManyActions = (errors = []) => {
    try {
      const retryMessages = ['thử lại sau', 'try again', 'retry'];
      return haveErrorsInList(errors, retryMessages);
    } catch (error) {
      return false;
    }
  };

  const getPendingPosts = async (groupId) => {
    try {
      const FBToolInstance = getFBServiceInstance();
      const pendingPostsRequest = FBToolInstance?.getPendingPostsInGroupPayload(groupId);
      const response = await sendRequest('FETCH', pendingPostsRequest);
      return responseHelper.extractPendingPosts(response);
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  /**
   * Remove post
   * @param {string} storyId
   * @return {boolean}
   */
  const removePost = async (storyId) => {
    try {
      const FBToolInstance = getFBServiceInstance();
      const removePendingPostRequest = FBToolInstance?.getRemovePendingPostPayload(storyId);
      const response = await sendRequest('FETCH', removePendingPostRequest);
      const storyIdRemoved = responseHelper.extractRemovePendingPost(response);
      return storyIdRemoved === storyId;
    } catch (error) {
      return false;
    }
  };

  const removePendingPosts = async (data, callback, onComplete) => {
    try {
      restartSignal();

      const { groupIds, delay } = data || {};

      // Tasks
      const tasks = [];

      groupIds?.forEach((groupId) => {
        // Feedback task
        const getListPendingTask = async () => {
          return await getPendingPosts(groupId);
        };

        // Remove pending posts task
        const removeTask = async (storyId) => {
          const res = await removePost(storyId);
          if (res) {
            callback({
              success: true,
              groupId,
              type: 'remove_post',
            });
          }
        };

        const task = { getListPendingTask, removeTask };
        tasks.push(task);
      });

      for (const [taskIndex, task] of tasks.entries()) {
        if (signal?.current?.aborted) {
          throw new Error(CANCEL_MSG);
        }

        const pendingPosts = await task.getListPendingTask();

        if (!pendingPosts || pendingPosts?.length === 0) {
          continue; // Move to the next main task
        }

        for (const [postIndex, item] of pendingPosts.entries()) {
          if (signal?.current?.aborted) {
            throw new Error(CANCEL_MSG);
          }
          // Remove
          await task.removeTask(item?.storyId);

          const isLastTask = pendingPosts?.length - 1 === postIndex && tasks?.length - 1 === taskIndex;
          if (!isLastTask) {
            await delayFunc(randomInRange(delay) * 1000, signal?.current);
          }
        }
      }

      return null;
    } catch (error) {
      return null;
    } finally {
      onComplete();
    }
  };

  const getPageLikedOrFollowed = useCallback(
    async (cursor = null) => {
      try {
        const FBToolInstance = getFBServiceInstance();
        const pagesRequest = FBToolInstance?.getPagesLikedOrFollowedPayload(cursor);
        const response = await sendRequest('FETCH', pagesRequest);
        const data = responseHelper.extractPages(response);

        let pages = data?.pages || [];

        let hasNextPage = data?.pagination?.has_next_page;
        let nextCursor = data?.pagination?.end_cursor;

        if (!hasNextPage || !nextCursor) {
          return pages;
        }

        if (hasNextPage && nextCursor) {
          const nextData = await getPageLikedOrFollowed(nextCursor);
          pages = [...pages, ...(nextData || [])];
        }

        return pages;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    [FBData]
  );

  /**
   * By default tool get 100 records
      const reactors = await getReactorsOfPost(`ZmVlZGJhY2s6MTAyMzQxNjQxNjM2NzA3ODU=`);
      console.log('reactors', reactors);
   */
  const getReactorsOfPost = useCallback(
    async (feedbackId, amount = 100, cursor = null) => {
      try {
        if (!feedbackId) {
          return null;
        }
        const FBToolInstance = getFBServiceInstance();
        const reactorsOfPostRequest = FBToolInstance?.getReactorsOfPostPayload({
          feedbackId,
          cursor,
        });
        const response = await sendRequest('FETCH', reactorsOfPostRequest);
        const data = responseHelper.extractReatorsOfPost(response);
        let reactors = data?.reactors || [];
        const { has_next_page: hasNextPage, end_cursor: nextCursor } = data?.pagination || {};

        const reactorsSize = reactors?.length;

        if (reactorsSize >= amount) {
          return { reactors: reactors.slice(0, amount), pagination: data?.pagination };
        }

        if (hasNextPage && nextCursor) {
          const nextData = await getReactorsOfPost(feedbackId, amount - reactorsSize, nextCursor);
          reactors = [...reactors, ...nextData];
        }

        return { reactors: reactors.slice(0, amount), pagination: data?.pagination };
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    [FBData]
  );

  /**
   * By default tool get 10 records
      const reshares = await getReShareData(`ZmVlZGJhY2s6MTAyMzQxNjQxNjM2NzA3ODU=`);
      console.log('reshares', reshares);
   */
  const getReShareData = useCallback(
    async (feedbackId, amount = 10, cursor = null) => {
      try {
        if (!feedbackId) {
          return null;
        }
        const FBToolInstance = getFBServiceInstance();
        const reshareOfPostRequest = FBToolInstance?.getReshareOfPostPayload({
          feedbackId,
          cursor,
        });
        const response = await sendRequest('FETCH', reshareOfPostRequest);
        const data = responseHelper.extractResharesOfPost(response);
        let reshares = data?.reshares || [];
        const { has_next_page: hasNextPage, end_cursor: nextCursor } = data?.pagination || {};

        const resharesSize = reshares?.length;

        if (resharesSize >= amount) {
          return { reshares: reshares.slice(0, amount), pagination: data?.pagination };
        }

        if (hasNextPage && nextCursor) {
          const nextData = await getReShareData(feedbackId, amount - resharesSize, nextCursor);
          reshares = [...reshares, ...(nextData?.reshares || [])];
        }

        return { reshares: reshares.slice(0, amount), pagination: data?.pagination };
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    [FBData]
  );

  const getShareLinkOfPost = useCallback(
    async (link) => {
      try {
        if (!link) {
          return null;
        }
        const FBToolInstance = getFBServiceInstance();
        const shareLinkOfPostRequest = FBToolInstance?.getShareLinkOfPostPayload(link);
        const response = await sendRequest('FETCH', shareLinkOfPostRequest);
        return responseHelper.extractShareLinkOfPost(response);
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    [FBData]
  );
  return (
    <FBFunctionContext.Provider
      value={{
        slowdown: slowdown?.value,
        onChangeFacebookPayloadConfigs,
        getProfile,
        getGroupsJoined,
        postToGroupsWithMultiContent,
        postSeedingComments,
        getSiteInfo,
        getSearchPosts,
        leadsSeeding,
        leadsSeedingSavedLink,
        stopQueue,
        getCommentsFromFeedback,
        getRepliesFromComment,
        getExtensionVersion,
        checkExtensionActive,
        updateFBData,
        getVideoUploadConfigs,
        getPostsInGroupByKeyword,
        ACTIONS_DELAY: DEFAULT_DELAY,
        updateVideoConfigs,
        getTextFormatPresets,
        commentUpPosts,
        checkFacebookInternet,
        removePendingPosts,
        infiniteRemovePendingPosts,
        getRemainsPendingPostsManual,
        getPendingPosts,
        isValidGroup,
        // Emitter
        emitter,
        onCallbackWarningActionPopup,
        onLoadAccessToken,
        getMessengerInfo,
        getReactionsInPost,
        getPostsInProfile,
        getCommentInfoInPost,
        getFriends,
        getFollowers,
        getReactionsFromFeedback,
        getTimelinePostsOfPage,
        getTimelinePostsOfUser,
        interactFriend,
        addListFriends,
        getUsersInfoByUIDS,
        getGroupActivity,
        getPageLikedOrFollowed,
        readDataWithUrl,
        getReactorsOfPost,
        getReShareData,
        getShareLinkOfPost,
        getPageInfo,
        getFBLocale,
        restartSignal,
      }}
    >
      {children}
    </FBFunctionContext.Provider>
  );
}

export { FBFunctionProvider, FBFunctionContext, MAXIMUM_PENDING_POSTS_ALLOW };
