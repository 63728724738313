import PropTypes from 'prop-types';
// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import { get } from 'lodash';
import { useMemo } from 'react';

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  name: PropTypes.string,
  isNumeric: PropTypes.bool,
  isAutoScaleWidth: PropTypes.bool,
};

export default function RHFTextField({ name, isNumeric = false, isAutoScaleWidth = false, ...other }) {
  const { control, getValues } = useFormContext();

  const values = getValues();

  const currentValue = get(values, name);

  const extraStyle = useMemo(() => {
    if (isAutoScaleWidth) {
      let BASE_FONT_SIZE = document.documentElement.style.fontSize
        ?.replace('px', '')
        ?.replace('rem', '')
        ?.replace('em', '');
      BASE_FONT_SIZE = parseInt(BASE_FONT_SIZE, 10);
      const nextWidth = currentValue ? BASE_FONT_SIZE * `${currentValue}`?.length : 100;
      return { style: { width: nextWidth <= 100 ? 100 : nextWidth } };
    }
    return {};
  }, [isAutoScaleWidth, currentValue]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => {
        if (isNumeric) {
          return (
            <NumericFormat
              onBlur={onBlur} // notify when input is touched
              inputRef={ref}
              fullWidth
              value={typeof value === 'number' && Number(value) === 0 ? '0' : Number(value)}
              customInput={TextField}
              onValueChange={({ value: v }) => {
                if (v === '') {
                  onChange(0);
                } else {
                  onChange(Number(v));
                }
              }}
              error={!!error}
              helperText={error?.message}
              {...other}
              {...extraStyle}
            />
          );
        }
        return (
          <TextField
            onBlur={onBlur} // notify when input is touched
            onChange={onChange} // send value to hook form
            inputRef={ref}
            fullWidth
            value={typeof value === 'number' && Number(value) === 0 ? '' : value}
            error={!!error}
            helperText={error?.message}
            {...other}
            {...extraStyle}
          />
        );
      }}
    />
  );
}
