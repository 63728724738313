import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import ReactGA from 'react-ga4';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Loading from '../../../components/Loading';
import useAuth from '../../../hooks/useAuth';
import useBoolean from '../../../hooks/useBoolean';
import useLocales from '../../../hooks/useLocales';
import PricingPopup from '../../../sections/whitelabel/sometool/components/PricingPopup';
import WarningPopup from '../../../sections/whitelabel/sometool/components/WarningPopup';
import axiosInstance from '../../../utils/axios';
import { parseJSON } from '../../../utils/others';
import { getPaymentLink, paymentCapture } from '../../../utils/tool/api';
import useToolContext from '../../../hooks/useToolContext';
import InternetPopup from '../../../sections/whitelabel/sometool/components/InternetPopup';
import ToolBockRedirect from '../../../components/ToolBockRedirect';
import WarningActionPopup from '../../../sections/whitelabel/sometool/components/WarningActionPopup';
import { PAYPAL_CODE_MAP, PAYPAL_CONTAINER_ID } from '../../../utils/tool/paypal';
import AutoDetectPopups from '../../../sections/whitelabel/sometool/components/popup/AutoDetectPopups';

const AFFILIATE_KEY = 'affiliate_token';

export default function ToolLayout({ children }) {
  const { translate } = useLocales('tool');

  const { user, verify, googleLogin, registerFBToolTenant } = useAuth();

  const navigate = useNavigate();

  const loading = useBoolean();

  const {
    isOpenPricingModal,
    isOpenWarningModal,
    isOpenInternetModal,
    isOpenWarningActionModal,
    openLoginModal,
    closePricingModal,
    closeInternetModal,
    closeWarningModal,
    closeWarningActionModal,
    onChangeSecretkey,
    isLoading,
  } = useToolContext();

  const [payments, setPayments] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const { enqueueSnackbar } = useSnackbar();

  const [cookies, setCookie, removeCookie] = useCookies([AFFILIATE_KEY]);

  useEffect(() => {
    if (user) {
      registerFBToolTenant();
    }
  }, [user]);

  useEffect(() => {
    (async () => {
      try {
        // Query params
        // Facebook
        const token = searchParams.get('token');
        const refresh = searchParams.get('refresh');
        const loginType = searchParams.get('login_type');
        const errorMessage = searchParams.get('message');
        // Google
        const code = searchParams.get('code');
        const status = searchParams.get('status');
        const message = searchParams.get('message');
        const ga4Event = searchParams.get('ga4_event');
        const ga4Category = searchParams.get('ga4_category');
        const ga4Value = searchParams.get('ga4_value');
        const ga4TransactionID = searchParams.get('ga4_transaction_id');
        // Secret mode
        const secretKey = searchParams.get('secret_key');
        // Redirect url
        const state = searchParams.get('state');
        const { redirectTo } = parseJSON(state, {});
        // Hidden query params on URL
        setSearchParams({});

        if (secretKey) {
          onChangeSecretkey(secretKey);
        }

        const affiliateToken = searchParams.get('affiliate_token');
        if (affiliateToken) {
          // 7 days max
          setCookie(AFFILIATE_KEY, affiliateToken, { maxAge: 60 * 60 * 24 * 7 });
        }

        if (status && message) {
          enqueueSnackbar(message, { variant: status });
        }

        if (ga4Category && ga4Event) {
          // affiliate purchase are stored in item
          const ecommerceItems = JSON.parse(localStorage.getItem('ecommerce_items'));
          const cartItem = ecommerceItems
            .filter((item) => parseInt(item.price, 10) === parseInt(ga4Value, 10))
            .find((element) => element !== undefined);
          ReactGA.event(ga4Event, {
            currency: 'VND',
            value: ga4Value || 1,
            transaction_id: ga4TransactionID || '',
            items: [cartItem],
          });
        }

        if (errorMessage) {
          enqueueSnackbar(errorMessage, { variant: 'error' });
        }

        if (token && refresh && loginType && ['google-auth2'].indexOf(loginType) > -1) {
          // Verify token
          await verify(token, refresh);
        } else if (code) {
          console.log('submit google 1st');
          const referrerToken = cookies[AFFILIATE_KEY] || null;
          const loggedSuccess = await googleLogin(code, referrerToken, redirectTo);
          if (loggedSuccess) {
            if (referrerToken) {
              removeCookie(AFFILIATE_KEY);
            }
            if (redirectTo && redirectTo !== window.location.href.replace(window.location.origin, '')) {
              navigate(redirectTo);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const onLoadPayments = async () => {
    try {
      const { data } = await axiosInstance.get('api/v2/tool/payment/');
      if (data?.message === 'OK') {
        // set payment data as Google Analytics 4 ecommerce item in redux
        const items = [];
        data?.data.forEach((payment) => {
          if (payment?.meta?.isFBTool === true) {
            items.push({
              item_id: payment?.name, // Unique identifier for the product
              item_name: payment?.alias, // Name of the product
              item_category: 'Subscription', // Category (e.g. Apparel)
              // "item_brand": "RECOMMENDED_STRING",  // Brand of the product
              // "item_variant": "OPTIONAL_STRING",  // Variant (e.g. Color, Size)
              price: payment?.amount, // Price of the product
              quantity: 1, // Number of units purchased
              currency: 'VND', // Currency code (e.g. USD)
              // "coupon": "OPTIONAL_STRING",  // Coupon code applied
              discount: payment?.saving, // Discount applied (as a number)
              list_price:
                payment?.saving_percentage > 0 ? (payment?.amount * 100) / payment?.saving_percentage : payment?.amount, // Original price before discount
              affiliation: user?.fbtool?.affiliate_token, // Affiliation (e.g. retailer)
              // Up to 27 additional custom parameters can be added here
              // "custom_parameter_1": "OPTIONAL_VALUE",
              // "custom_parameter_2": "OPTIONAL_VALUE",
            });
          }
        });
        localStorage.setItem(`ecommerce_items`, JSON.stringify(items));
        setPayments(data?.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    onLoadPayments();
  }, []);

  const handlePaymentWithSystem = async (name, source = 'momo', refcode = null) => {
    // Currently support MoMo and VNPay
    const index = payments?.findIndex((item) => item?.name === name);
    if (index !== -1) {
      const payload = {
        paymentAmount: payments[index]?.amount,
        name: payments[index]?.name,
        source,
        refcode,
      };
      const { success, link, error } = await getPaymentLink(payload);
      if (success && link) {
        window.location.replace(link);
      } else if (error) {
        enqueueSnackbar(error, { variant: 'error' });
      }
    }
  };

  const handlePaymentWithGPay = async (name) => {
    const index = payments?.findIndex((item) => item?.name === name);
    if (index !== -1) {
      const payload = {
        paymentAmount: payments[index]?.amount,
        name: payments[index]?.name,
      };
      const { success, link, error } = await getPaymentLink(payload);
      if (success && link) {
        console.log('Gpay need turn on');
      } else if (error) {
        enqueueSnackbar(error, { variant: 'error' });
      }
    }
  };

  const handlePaymentWithPaypal = async (name, refcode) => {
    try {
      const hostedButtonId = PAYPAL_CODE_MAP[name];
      if (!hostedButtonId) {
        return;
      }

      const index = payments?.findIndex((item) => item?.name === name);
      if (index !== -1) {
        const container = document.getElementById(PAYPAL_CONTAINER_ID);
        if (container) {
          container.innerHTML = '';
        }
        window.paypal
          .Buttons({
            // eslint-disable-next-line consistent-return
            async createOrder() {
              try {
                const payload = {
                  paymentAmount: payments[index]?.amount,
                  name: payments[index]?.name,
                  source: 'paypal',
                  refcode,
                };
                const { success, link } = await getPaymentLink(payload);
                if (success && link) {
                  if (link?.indexOf('http') !== -1) {
                    window.location.replace(link);
                  } else {
                    // As order id
                    return link;
                  }
                }
                throw new Error('Create order_id failed');
              } catch (error) {
                console.error(error);
                enqueueSnackbar('Có lỗi xảy ra', { variant: 'error' });
              }
            },
            async onApprove(data, actions) {
              console.info('onApprove', data, actions);
              if (data?.orderID) {
                const { success, link } = await paymentCapture(data?.orderID);
                if (success && link) {
                  window.location.replace(link);
                }
              }
            },
          })
          .render(`#${PAYPAL_CONTAINER_ID}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePaymentWithPayos = async (name, refcode, channel) => {
    try {
      const index = payments?.findIndex((item) => item?.name === name);
      if (index !== -1) {
        const payload = {
          paymentAmount: payments[index]?.amount,
          name: payments[index]?.name,
          source: channel,
          refcode,
        };
        const { success, link } = await getPaymentLink(payload);
        if (success && link) {
          if (link?.indexOf('http') !== -1) {
            window.location.replace(link);
          } else {
            // As order id
            return link;
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePaymentSubscription = async ({ cardType, name, refcode }) => {
    try {
      loading.onTrue();
      if (!user) {
        openLoginModal();
        return;
      }

      if (user?.fbtool?.is_bought_lifetime) {
        enqueueSnackbar(translate('messages.bought_lifetime'), { variant: 'warning' });
        return;
      }

      if (['momo', 'vnpay']?.indexOf(cardType) !== -1) {
        await handlePaymentWithSystem(name, cardType, refcode);
        return;
      }

      if (cardType === 'paypal') {
        await handlePaymentWithPaypal(name, refcode);
        return;
      }

      if (['payos', 'payos2'].includes(cardType)) {
        await handlePaymentWithPayos(name, refcode, cardType);
        return;
      }

      if (cardType === 'gpay') {
        await handlePaymentWithGPay(name);
        return;
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading.onFalse();
    }
  };

  return (
    <>
      {children}
      <PricingPopup
        payments={payments}
        open={isOpenPricingModal}
        onClose={closePricingModal}
        onBuySubscription={handlePaymentSubscription}
      />
      <WarningPopup open={isOpenWarningModal} onClose={closeWarningModal} />
      <InternetPopup open={isOpenInternetModal} onClose={closeInternetModal} />
      <WarningActionPopup open={isOpenWarningActionModal} onClose={closeWarningActionModal} />
      <ToolBockRedirect />
      <Loading loading={loading.value || isLoading} />
      <AutoDetectPopups />
    </>
  );
}

ToolLayout.propTypes = {
  children: PropTypes.node,
};
