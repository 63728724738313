import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Iconify from '../../../../components/Iconify';
import useLocales from '../../../../hooks/useLocales';
import useSettings from '../../../../hooks/useSettings';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

InternetPopup.propTypes = { open: PropTypes.bool, onClose: PropTypes.func };

export default function InternetPopup({ open, onClose }) {
  const { themeMode } = useSettings();

  const { translate: tButton } = useLocales('tool.btn');

  const { translate } = useLocales('tool.popup.internet');

  const onClickViewMore = () => {
    window.open(
      'https://doc.fbtool.net/cau-hoi-thuong-gap#hoi-da-dang-nhap-va-cai-tien-ich-mo-rong-nhung-tool-khong-nhan-ra-facebook-cua-toi',
      '_blank'
    );
  };

  return (
    <BootstrapDialog onClose={onClose} open={open}>
      <DialogTitle sx={{ m: 0, p: 2, textAlign: 'center' }}>
        <Stack direction="row" gap={1} alignItems="center" justifyContent={'center'}>
          <Iconify icon={'ph:warning-fill'} color="error.main" sx={{ height: 32, width: 32 }} />
          <Typography color="error.main" fontSize={'2.45rem'} fontWeight="bold">
            {translate('title')}
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack flex={1} justifyContent="center" alignItems={'center'} direction={'row'} px={{ xs: 2, sm: 10 }}>
          <Stack flex={1} justifyContent="center" alignItems={'center'} direction={'column'} gap={1.5}>
            <Typography>{translate('content')}</Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          py: '8px !important',
        }}
      >
        <Button
          sx={{ color: themeMode === 'dark' ? 'common.white' : 'common.black', textTransform: 'none' }}
          autoFocus
          onClick={onClickViewMore}
          endIcon={<Iconify icon="icon-park-outline:right" sx={{ height: 18, width: 18 }} />}
        >
          {tButton('guide')}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
