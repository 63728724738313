import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Stack, Typography } from '@mui/material';
// components
import useLocales from '../../../hooks/useLocales';
import useResponsive from '../../../hooks/useResponsive';
import useSettings from '../../../hooks/useSettings';
import Avatar from '../../../components/Avatar';
import createAvatar from '../../../utils/createAvatar';
import useTTContext from '../../../hooks/useTTContext';
import { fAbbreviate } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 1.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavbarAccount({ isCollapse }) {
  const { translate } = useLocales('tiktok.info');

  const isDesktop = useResponsive('up', 'lg');

  const { themeMode } = useSettings();

  const { TTUser } = useTTContext();

  const user = TTUser || {
    image: null,
    name: 'N/A',
    uid: 'N/A',
  };

  const stats = useMemo(() => {
    return {
      followers: fAbbreviate(TTUser?.stats?.followerCount || 0),
      following: fAbbreviate(TTUser?.stats?.followingCount || 0),
      heart: fAbbreviate(TTUser?.stats?.heartCount || 0),
    };
  }, [TTUser]);

  const nameColor = themeMode === 'light' ? 'common.black' : 'common.white';

  return (
    <Link
      width={isDesktop ? '280px' : '100%'}
      underline="none"
      color="inherit"
      bgcolor="transparent"
      component={RouterLink}
      to={'/'}
    >
      <RootStyle
        sx={{
          bgcolor: 'transparent',
        }}
      >
        <Avatar
          src={user?.image}
          alt={user?.name}
          color={createAvatar(user?.name).color}
          sx={{ width: 50, height: 50 }}
        >
          {createAvatar('T').name}
        </Avatar>

        <Box
          sx={{
            ml: 2,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Stack direction={'column'}>
            <Typography variant="subtitle1" color={nameColor} noWrap>
              {user?.name}
            </Typography>
            <Typography variant="body2" color="text.secondary" noWrap>
              {stats?.following} {translate('following')}
            </Typography>
            <Typography variant="body2" color="text.secondary" noWrap>
              {stats?.followers} {translate('followers')}
            </Typography>
            <Typography variant="body2" color="text.secondary" noWrap>
              {stats?.heart} {translate('like')}
            </Typography>
          </Stack>
        </Box>
      </RootStyle>
    </Link>
  );
}
