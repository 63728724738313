import { Button, Portal, Stack, Tooltip } from '@mui/material';
import { React } from 'react';
import Iconify from '../../../components/Iconify';
import Loading from '../../../components/Loading';
import useBoolean from '../../../hooks/useBoolean';
import useToolContext from '../../../hooks/useToolContext';

export default function ClearCaches() {
  const { onClearCache, extensionVersion } = useToolContext();

  const loading = useBoolean();

  const handleClickClearCache = () => {
    if (extensionVersion) {
      loading.onTrue();
      onClearCache();
    } else {
      window.open('https://doc.fbtool.net/#buoc-2-tai-va-cai-dat-tien-ich-mo-rong', '_blank');
    }
  };

  return (
    <>
      <Button
        sx={{
          color: 'primary.main',
          border: (theme) => `1px solid ${theme.palette.primary.main}`,
          borderRadius: '8px',
          px: 1.2,
          py: 0.5,
          fontWeight: 700,
          cursor: 'pointer',
          minWidth: 'fit-content',
        }}
        onClick={handleClickClearCache}
      >
        <Iconify icon={`mdi:cookie-refresh`} sx={{ width: 20, height: 20 }} />
      </Button>
      <Portal>
        <Loading loading={loading.value} />
      </Portal>
    </>
  );
}
