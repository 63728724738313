import { useContext } from 'react';
import { FBContext } from '../contexts/FBContext';

const useFBContext = () => {
  const context = useContext(FBContext);

  if (!context) {
    throw new Error('FBContext context must be use inside FBProvider');
  }

  return context;
};

export default useFBContext;
