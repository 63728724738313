// @mui
import { Backdrop, CircularProgress, Portal } from '@mui/material';
import PropTypes from 'prop-types';
// ---------------------- PROPS VALIDATE ---------------------
Loading.propTypes = {
  loading: PropTypes.bool,
};
// -----------------------------------------------------------

export default function Loading({ loading }) {
  return (
    <Portal>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 2 }} open={loading}>
        <CircularProgress color="info" />
      </Backdrop>
    </Portal>
  );
}
