import { useContext } from 'react';
import { FBFunctionContext } from '../contexts/FBFunctionContext';

const useToolFunction = () => {
  const context = useContext(FBFunctionContext);

  if (!context) {
    throw new Error('FBFunction context must be use inside FBFunctionProvider');
  }

  return context;
};

export default useToolFunction;
