export function removeAccentsVi(str) {
  // Gộp nhiều dấu space thành 1 space
  str = str.replace(/\s+/g, ' ');
  // loại bỏ toàn bộ dấu space (nếu có) ở 2 đầu của chuỗi
  str = str.trim();
  // bắt đầu xóa dấu tiếng việt  trong chuỗi
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
  str = str.replace(/Đ/g, 'D');
  return str;
}

export function convertHtmlToString(html) {
  // Create a temporary element to parse the HTML
  const tempElement = document.createElement('div');
  tempElement.innerHTML = html;

  // Function to recursively traverse the DOM tree and convert elements to string with newline characters
  function traverse(element, depth) {
    let result = '';

    // Add newline characters based on the depth of the element
    if (depth > 0) {
      result += '\n';
    }

    // Get the computed style of the element
    const computedStyle = window.getComputedStyle(element);
    const display = computedStyle.getPropertyValue('display');

    // Process the element based on its display style
    if (display === 'block' || display === 'table' || display === 'list-item') {
      result += '\n';
    }

    // Process child nodes
    const childrens = [...element.childNodes];
    for (let i = 0; i < childrens?.length; i += 1) {
      const node = childrens[i];
      if (node.nodeType === Node.TEXT_NODE) {
        // If it's a text node, append its text content
        result += node.textContent;
      } else if (node.nodeType === Node.ELEMENT_NODE && node.tagName !== 'BR') {
        // If it's an element node, recurse into it
        result += traverse(node, depth + 1);
      }
    }

    return result;
  }

  // Start traversing from the root element
  return traverse(tempElement, 0);
}

export function getUIDOfFacebookGroupFromURL(facebookGroupUrl) {
  try {
    // Define the regex pattern to match the group UID
    const regex = /\/groups\/([a-zA-Z0-9_-]+)\//;

    // Use the regex pattern to extract the group UID from the URL
    const match = facebookGroupUrl.match(regex);

    // Check if a match is found
    if (match && match[1]) {
      return match[1];
    }
    return '';
  } catch (error) {
    console.log(error);
    return '';
  }
}

export function obscureEmail(email) {
  const [localPart, domain] = email.split('@');
  const localPartLength = localPart.length;

  // Define how many characters to keep at the start and end of the local part
  const keepStart = 1;
  const keepEnd = 1;
  const obscuredPartLength = localPartLength - (keepStart + keepEnd);

  // Ensure the obscured part length is valid
  if (obscuredPartLength > 0) {
    const obscuredPart = '*'.repeat(obscuredPartLength);
    return `${localPart.slice(0, keepStart)}${obscuredPart}${localPart.slice(localPartLength - keepEnd)}@${domain}`;
  }
  // If the local part is too short to obscure, just return the original email
  return `***@${domain}`;
}
