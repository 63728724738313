import _ from 'lodash';
import PropTypes from 'prop-types';
import { createContext, useCallback, useMemo, useState } from 'react';
import useRefCustom from '../../../../../hooks/useRefCustom';
import { EmitterService } from '../../../../../utils/event';

const MODE = {
  ads: 'ads',
  group: 'group',
  link: 'link',
};

const initialState = {
  emitterNoty: { on: () => {}, off: () => {}, send: () => {} },
  sourceType: '',
  MODE: {
    ads: 'ads',
    group: 'group',
    link: 'link',
  },
  // Groups
  groupIdsSelected: [],
  setGroupIdsSelected: () => {},
  getGroupIdsSelected: () => {},
  // Links
  linkIdsSelected: [],
  setLinkIdsSelected: () => {},
  getLinkIdsSelected: () => {},
  // -------------------
  onChangeType: () => {},
};

const NOTY_STOP = {
  group: 'lead-group-stop',
  ads: 'lead-ads-stop',
  link: 'lead-link-stop',
};

const TargetSourceContext = createContext(initialState);

// ---------------------- PROPS VALIDATE ---------------------
TargetSourceProvider.propTypes = {
  children: PropTypes.any,
};
// -----------------------------------------------------------

function TargetSourceProvider({ children }) {
  const [sourceType, setSourceType] = useState('ads');

  const [emitterNoty, setEmitterNoty] = useState(new EmitterService());

  // ================================================================================================
  const onChangeType = useCallback(
    (value) => {
      setSourceType(value);
      Object.keys(NOTY_STOP)?.forEach((key) => {
        if (value !== key) {
          emitterNoty.send(NOTY_STOP[key]);
        }
      });
    },
    [sourceType, emitterNoty]
  );
  // ================================================================================================

  return (
    <TargetSourceContext.Provider
      value={useMemo(
        () => ({
          emitterNoty,
          // --------------------------------
          MODE,
          sourceType,
          onChangeType,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [sourceType, emitterNoty]
      )}
    >
      {children}
    </TargetSourceContext.Provider>
  );
}

export { TargetSourceProvider, TargetSourceContext };
