import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useGoogleLogin } from '@react-oauth/google';

function useGoogleLoginCustom({ redirectTo = '' }) {
  const [mountTime, setMountTime] = useState(1);

  useEffect(() => {
    setMountTime((prev) => prev + 1);
  }, [redirectTo]);

  return useGoogleLogin({
    onError: (error) => {
      console.error('useGoogleLoginCustom error: ', error);
    },
    useOneTap: true,
    auto_select: true,
    cancel_on_tap_outside: false,
    flow: 'auth-code',
    ux_mode: 'redirect',
    redirect_uri: window.location.origin,
    state: JSON.stringify({ redirectTo: redirectTo.replace(window.location.origin, '') }),
  });
}

export default useGoogleLoginCustom;
