import { IS_PRODUCTION } from '../../config';

export const PAYPAL_CONTAINER_ID = `paypal-container`;

// Hashmap paypal package options
export const PAYPAL_CODE_MAP = IS_PRODUCTION
  ? {
      // PRODUCTION
      fbtool_monthly_1: 'S5HAHZYHBGMG4',
      fbtool_annual_1: 'LV6JC4WG2ZEEJ',
      fbtool_monthly_2: '5RV392LK2SPLC',
      fbtool_annual_2: 'B5UACUTFPB7NU',
      fbtool_monthly_3: '3U9RJ6J5S9C46',
      fbtool_annual_3: '5G64B75CSL4LS',
    }
  : {
      // SANDBOX
      fbtool_monthly_1: 'GZZV2MELCR5G4',
      fbtool_annual_1: 'S32L47DDDAG5N',
      fbtool_monthly_2: 'EZPJ7G67848DW',
      fbtool_annual_2: '2B3JXZ5Q6FD8J',
      fbtool_monthly_3: '4HJPLMCVQTYX4',
      fbtool_annual_3: 'NMVFZ47BB3P7A',
    };

export const getPaypalOptions = (packageName) => PAYPAL_CODE_MAP[packageName];
