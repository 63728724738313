import { useState, useEffect, useRef } from 'react';
import { addMinutes } from '../utils/formatTime';
import useBoolean from './useBoolean';
import useRefCustom from './useRefCustom';

// ----------------------------------------------------------------------
const DEFAULT_TIMER = {
  days: '00',
  hours: '00',
  minutes: '00',
  seconds: '00',
};

export default function useCountdownCustom(ms) {
  const [countdown, setCountdown] = useState({
    days: '000',
    hours: '00',
    minutes: '00',
    seconds: '00',
  });

  const [, setEndDate, endDateRef] = useRefCustom();

  const timerInterval = useRef(null);

  const isDoned = useBoolean();

  const isRunning = useBoolean();

  useEffect(() => {
    const isExpiredCase2 = Object.keys(countdown).some((key) => `${countdown[key]}`.indexOf('-') !== -1);
    if (isExpiredCase2) {
      setCountdown({ ...DEFAULT_TIMER });
      clearInterval(timerInterval.current);
      if (isRunning?.value) {
        isDoned.onTrue();
      }
    }
  }, [countdown]);

  const onStart = () => {
    const endDate = addMinutes(new Date(), ms / (60 * 1000));
    setEndDate(endDate);
    isRunning.onTrue();
    timerInterval.current = setInterval(() => setNewTime(), 1000);
  };

  const setNewTime = () => {
    const startTime = endDateRef?.current;
    const endTime = new Date();
    const distanceToNow = startTime - endTime;

    const getDays = Math.floor(distanceToNow / (1000 * 60 * 60 * 24));
    const getHours = `0${Math.floor((distanceToNow % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))}`.slice(-2);
    const getMinutes = `0${Math.floor((distanceToNow % (1000 * 60 * 60)) / (1000 * 60))}`.slice(-2);
    const getSeconds = `0${Math.floor((distanceToNow % (1000 * 60)) / 1000)}`.slice(-2);

    setCountdown({
      days: getDays || '000',
      hours: getHours || '00',
      minutes: getMinutes || '00',
      seconds: getSeconds || '00',
    });
  };

  return { countdown, onStart, isDoned: isDoned?.value };
}
