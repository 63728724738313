import PropTypes from 'prop-types';
// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { Autocomplete, Chip, TextField } from '@mui/material';
import Iconify from '../Iconify';

// ----------------------------------------------------------------------

RHFAutoCompleteChip.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
};

export default function RHFAutoCompleteChip({ name, label = '', ...other }) {
  const { control } = useFormContext();

  const handleRenderArray = ({ field }) => {
    const currentValues = field.value || [];
    return (
      <Autocomplete
        multiple
        freeSolo
        {...field}
        onChange={(e, value) => {
          field.onChange([...value]);
        }}
        isOptionEqualToValue={(option, value) => {
          const isBasicType = ['number', 'string'].indexOf(typeof option) !== -1;

          if (isBasicType) {
            return option === value;
          }

          return Boolean(option) && Boolean(value) && option?.id === value?.id;
        }}
        renderTags={(value, getTagProps) =>
          currentValues.map((option, index) => (
            <Chip
              sx={{ mr: 1 }}
              color={option?.color || 'primary'}
              variant="filled"
              key={option?.label || option}
              label={option?.label || option}
              clickable
              deleteIcon={<Iconify icon={'typcn:delete-outline'} onMouseDown={(event) => event.stopPropagation()} />}
              onDelete={() => {
                currentValues.splice(index, 1);
                field.onChange([...currentValues]);
              }}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => <TextField {...params} label={label} />}
        fullWidth
        {...other}
      />
    );
  };

  return <Controller name={name} control={control} render={handleRenderArray} />;
}
