import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Stack, Typography } from '@mui/material';
// components
import useLocales from '../../../hooks/useLocales';
import useResponsive from '../../../hooks/useResponsive';
import useSettings from '../../../hooks/useSettings';
import Avatar from '../../../components/Avatar';
import createAvatar from '../../../utils/createAvatar';
import useFBContext from '../../../hooks/useFBContext';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 1.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavbarAccount({ isCollapse }) {
  const { translate } = useLocales('tool');

  const isDesktop = useResponsive('up', 'lg');

  const { themeMode } = useSettings();

  const { FBUser } = useFBContext();

  const user = FBUser || {
    image: null,
    name: 'N/A',
    uid: 'N/A',
    subTitle: translate('profile'),
  };

  const nameColor = themeMode === 'light' ? 'common.black' : 'common.white';

  return (
    <Link
      width={isDesktop ? '280px' : '100%'}
      underline="none"
      color="inherit"
      bgcolor="transparent"
      component={RouterLink}
      to={'/'}
    >
      <RootStyle
        sx={{
          bgcolor: 'transparent',
        }}
      >
        <Avatar
          src={user?.image}
          alt={user?.name}
          color={createAvatar(user?.name).color}
          sx={{ width: 50, height: 50 }}
        >
          {createAvatar('T').name}
        </Avatar>

        <Box
          sx={{
            ml: 2,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Stack direction={'column'}>
            <Typography variant="subtitle1" color={nameColor} noWrap>
              {user?.name}
            </Typography>
            <Typography variant="caption" color="text.secondary" noWrap>
              {user?.isPageProfile ? translate('page') : translate('profile')}
            </Typography>
            <Typography variant="caption" color="text.secondary" noWrap>
              {user?.uid}
            </Typography>
          </Stack>
        </Box>
      </RootStyle>
    </Link>
  );
}
