import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { Box, Drawer, Stack } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
// hooks
import useAuth from '../../../hooks/useAuth';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
import useLocales from '../../../hooks/useLocales';
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { NAVBAR } from '../../../config';
// components
import { NavSectionVertical } from '../../../components/nav-section';
import Scrollbar from '../../../components/Scrollbar';
//
import packageJson from '../../../../package.json';
import CollapseButton from './CollapseButton';
import NavbarAccount from './NavbarAccount';
import navConfig from './NavConfig';
import Label from '../../../components/Label';
import UserStats from './UserStats';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

const APP_VERSION = packageJson.version;

const VERSION_HEIGHT = 52;

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }) {
  const theme = useTheme();

  const { translate } = useLocales();

  const { user } = useAuth();

  const isAdminTenant = user?.package_using?.is_admin || false;

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const isMobile = useResponsive('down', 'sm');

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Stack
      sx={{
        position: 'relative',
      }}
    >
      <Scrollbar
        sx={{
          '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
          maxHeight: '100vh',
          height: `calc(100% - ${VERSION_HEIGHT}px)`,
        }}
      >
        <Stack sx={{}}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={3}
            sx={{
              pt: 1,
              pb: 1,
              px: 1,
              flexShrink: 0,
              ...(isCollapse && { alignItems: 'center' }),
            }}
          >
            <NavbarAccount isCollapse={isCollapse} />
            {isDesktop && !isCollapse && (
              <Stack sx={{ ml: '0 !important' }} flex={1}>
                <CollapseButton onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
              </Stack>
            )}
          </Stack>
          {!isCollapse && <UserStats />}
          <NavSectionVertical navConfig={navConfig(isAdminTenant)} isCollapse={isCollapse} sx={{ height: 1 }} />
        </Stack>
      </Scrollbar>
      {!isCollapse && (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 10,
            bottom: 0,
            width: 1,
            pl: 4,
            py: 2,
            height: VERSION_HEIGHT,
            ...cssStyles(theme).bgBlur(),
          }}
        >
          <Label color="info">build.{APP_VERSION}</Label>
        </Box>
      )}
    </Stack>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH } }}>
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: NAVBAR.DASHBOARD_WIDTH,
              borderRightStyle: 'dashed',
              bgcolor: 'background.default',
              transition: (theme) =>
                theme.transitions.create('width', {
                  duration: theme.transitions.duration.standard,
                }),
              ...(isCollapse && {
                width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
              }),
              ...(collapseHover && {
                ...cssStyles(theme).bgBlur(),
                boxShadow: (theme) => theme.customShadows.z24,
              }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
