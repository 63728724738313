import PropTypes from 'prop-types';
// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { Autocomplete, Chip, TextField } from '@mui/material';

// ----------------------------------------------------------------------

RHFInputChip.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
};

export default function RHFInputChip({ name, label, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          multiple
          options={[]}
          freeSolo
          {...field}
          {...other}
          onChange={(e, value) => {
            field.onChange(value);
          }}
          isOptionEqualToValue={(option, value) => option === value}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip key={index} variant="filled" label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => <TextField {...params} label={label} error={!!error} helperText={error?.message} />}
        />
      )}
    />
  );
}
