import { useContext } from 'react';
import { TTContext } from '../contexts/TTContext';

const useTTContext = () => {
  const context = useContext(TTContext);

  if (!context) {
    throw new Error('useTTContext context must be use inside TTProvider');
  }

  return context;
};

export default useTTContext;
