import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, IconButton, Stack, Tab, Tabs } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// redux
import { popupCalendarForm } from '../../../../../redux/slices/asset';
import { addNewPendingTask, markAsRead } from '../../../../../redux/slices/task';
import { dispatch, useSelector } from '../../../../../redux/store';
// hooks
import useAuth from '../../../../../hooks/useAuth';
import useLocales from '../../../../../hooks/useLocales';
import useResponsive from '../../../../../hooks/useResponsive';
import useTabs from '../../../../../hooks/useTabs';
import useTenant from '../../../../../hooks/useTenant';
// routes
import { PATH_DASHBOARD } from '../../../../../routes/paths';
// components
import { EmojiPicker } from '../../../../../components/emoji-picker';
import { FormProvider, ModalWrapper, RHFTextField, RHFUploadMultiFile } from '../../../../../components/hook-form';
import Iconify from '../../../../../components/Iconify';
import Loading from '../../../../../components/Loading';
import LoadingProgress from '../../../../../components/LoadingProgress';
import PaymentWarning from '../../../../../components/PaymentWarning';
import PreviewModal from '../../../../../components/PreviewModal';
// utils
import { asyncUploadFiles } from '../../../../../utils/fileUtils';
import { GPTHashTag, GPTSpin } from '../../../../../utils/gpt';
// helper
import {
  ENABLED_DETECT_NUDE,
  FEATURES_SCHEDULE_NAME_MAPPING,
  FEATURE_GPT,
  IMAGE_ID_PREFIX,
} from '../../../../../config';
import { removeAnotherNoty } from '../../../../../redux/slices/notification';
import { detectNudeList } from '../../../../../utils/createAvatar';
import { removeEmoji } from '../../../../../utils/others';

// ----------------------------------------------------------------------
const NOTY_AI_RESPONSE = 'noty_ai_response';

SocialWebPostForm.propTypes = {
  uuid: PropTypes.string,
  onCreatedSuccess: PropTypes.func,
  onBack: PropTypes.func,
  source: PropTypes.object,
  isEdit: PropTypes.bool,
};

export default function SocialWebPostForm({ isEdit = false, uuid, onCreatedSuccess, onBack, source = {} }) {
  const {
    configs: {
      pipeline_actions: { mxh: typesAllowClone },
      asset_image_count: assetImageCount,
    },
  } = useAuth();
  const { translate: tValidate } = useLocales('form');
  const { translate } = useLocales('dashboard.create_asset');
  const { translate: tCampaign } = useLocales('dashboard.multi_channel.steps.btn');
  const { translate: tForm } = useLocales('dashboard.create_asset.social.form');
  const { translate: t1 } = useLocales();
  const theme = useTheme();

  const { currentTab, onChangeTab } = useTabs('image');

  const isSmaller660 = useResponsive('between', '', 0, 660);

  const { enqueueSnackbar } = useSnackbar();

  const { checkFeatureDisabled } = useTenant();

  const navigate = useNavigate();

  const { tasks } = useSelector((state) => state.task);

  const { anotherNotiType } = useSelector((state) => state.noti);

  const [emoji, setEmoji] = useState('');

  const [isLoading, setLoading] = useState(false);

  const [isLoadingGPT, setLoadingGPT] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const [previousDescription, setPreviousDescription] = useState('');

  const [isLoadingProgress, setIsLoadingProgress] = useState(false);

  const [progress, setProgress] = useState(0);

  const [textNeedRender, setTextNeedRender] = useState([]);

  const [preview, setPreview] = useState({
    isOpen: false,
    assets: [],
    index: 0,
  });

  // eslint-disable-next-line no-unused-vars
  const [currentAsset, setCurrentAsset] = useState(null);

  const SocialPostSchema = Yup.object().shape({
    title: Yup.string()
      .required(tValidate('required'))
      .min(50, tValidate('min', { min: 50 }))
      .max(90, tValidate('max', { max: 90 })),
    description: Yup.string()
      .required(tValidate('required'))
      .min(50, tValidate('min', { min: 50 }))
      .max(3000, tValidate('max', { max: 3000 })),
    images: Yup.array()
      .min(1, tValidate('image.min', { min: 1 }))
      .max(assetImageCount, tForm('error.images.max', { asset_image_count: assetImageCount })),
    keyword: Yup.string().trim(),
    videoUrl: Yup.string(),
    converts: Yup.object(),
  });

  const defaultValues = {
    title: '',
    description: '',
    images: [],
    videoUrl: '',
    keyword: '',
    converts: typesAllowClone.reduce((value, curr) => {
      value[curr] = false;
      return value;
    }, {}),
  };

  const methods = useForm({
    resolver: yupResolver(SocialPostSchema),
    defaultValues,
  });

  const {
    watch,
    // control,
    setValue,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (source) {
      reset({
        description: source?.description || '',
        title: source?.fullTitle || '',
        images: source?.images || [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source]);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const images = values.images || [];
      setValue('images', [
        ...images,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
    [setValue, values.images]
  );

  const detectImageTimer = useRef(null);

  const { translate: tCommonForm } = useLocales('form');

  useEffect(() => {
    // Detect Nude Images
    (async () => {
      if (ENABLED_DETECT_NUDE) {
        if (detectImageTimer.current) {
          clearInterval(detectImageTimer.current);
        }

        detectImageTimer.current = setTimeout(async () => {
          const stringImages = values.images?.filter((item) => typeof item === 'string');
          const fileImages = values.images?.filter((item) => typeof item !== 'string');
          const nodeIds = values.images
            ?.map((image, index) => (typeof image === 'string' ? '' : `${IMAGE_ID_PREFIX}${index + 1}`))
            .filter((item) => item);

          const result = await detectNudeList(nodeIds);
          const validFile = [];
          if (result.some((item) => item)) {
            fileImages.forEach((item, index) => {
              if (!result[index]) {
                validFile.push(item);
              }
            });
            enqueueSnackbar(tCommonForm('nudity'), { variant: 'error' });
            setValue('images', [...stringImages, ...validFile]);
          }
          clearInterval(detectImageTimer.current);
        }, 500);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.images]);

  useEffect(() => {
    if (emoji) {
      setValue('description', `${values.description} ${emoji}`);
    } else if (!source) {
      setValue('description', emoji);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emoji, source]);

  useEffect(() => {
    if (textNeedRender && textNeedRender.length !== 0) {
      const temp = [...textNeedRender];
      setValue('description', `${values.description} ${temp[0]?.trim()}`);
      temp.splice(0, 1);
      setTextNeedRender([...temp]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textNeedRender]);

  useEffect(() => {
    if (!isEmpty(anotherNotiType) && anotherNotiType.type === NOTY_AI_RESPONSE) {
      const { inputName, partialContent, lastTime, gpt_type: type } = anotherNotiType;
      if (lastTime) {
        if (type === 'socialize') {
          setValue(inputName, `${previousDescription} \n${partialContent}`);
        } else {
          setValue(inputName, partialContent);
          setPreviousDescription(partialContent);
        }
        setTextNeedRender([]);
      } else {
        setTextNeedRender((prev) => [...prev, ...partialContent?.split(' ')].filter((item) => item !== ' ' || item));
      }
    }
    return () => {
      // Unmount
      if (anotherNotiType.type === NOTY_AI_RESPONSE) {
        dispatch(removeAnotherNoty());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anotherNotiType]);

  useEffect(() => {
    // Current uuid
    const currentUUID = currentAsset ? currentAsset?.uuid : uuid;
    const index = tasks?.findIndex(
      (item) =>
        item?.extra_data?.asset_uuid === currentUUID &&
        item?.result_data?.success &&
        item?.result_data?.action === 'generate'
    );
    if (index !== -1) {
      // User on current page and got new content from AI features
      const { input_name: inputName, new_content: newContent } = tasks[index]?.result_data;
      setValue(inputName, newContent);
      markAsRead(tasks[index]?.request_code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks]);

  const handleRemoveAll = () => {
    setValue('images', []);
  };

  const handleRemove = (file) => {
    const filteredItems = values.images && values.images?.filter((_file) => _file !== file);
    setValue('images', filteredItems);
  };

  // Progress Loading
  const callBackProgress = () => {
    setProgress((prev) => prev + 1);
  };

  const progressToPercent = (value) => Math.ceil((value * 100) / values.images.length);

  const handleUploadFile = async () => {
    setIsLoadingProgress(true);
    // handle async upload images
    const response = await asyncUploadFiles(values.images, callBackProgress);
    setProgress(0);
    setIsLoadingProgress(false);
    return response;
  };

  const MEDIA_TABS = [
    {
      value: 'image',
      label: tForm('field.image.title'),
      icon: <Iconify icon={'eva:image-fill'} width={20} height={20} />,
      component: (
        <RHFUploadMultiFile
          showPreview
          name="images"
          maxSize={3145728}
          onDrop={handleDrop}
          onRemove={handleRemove}
          onRemoveAll={handleRemoveAll}
          title={tForm('field.image.heading', { asset_image_count: assetImageCount })}
          text1={tForm('field.image.first_content')}
          text2={tForm('field.image.second_content')}
          text3={tForm('field.image.third_content')}
        />
      ),
    },
    {
      value: 'video',
      label: 'Video',
      icon: <Iconify icon={'eva:video-fill'} width={20} height={20} />,
      component: <RHFTextField disabled name="videoUrl" label="Đường dẫn video" multiline rows={5} />,
    },
  ];

  const triggerData = () => {
    const { description, title, converts } = values;
    // Get 70 first character in descriptions
    const shortTitle = removeEmoji(title).normalize('NFKC').substring(0, 70).trim();

    if (shortTitle.length < 50) {
      setError('title', { type: 'custom', message: tForm('error.description.invalid') });
      return { success: false, data: {} };
    }
    return { success: true, data: { shortTitle, description, converts } };
  };

  const onSubmit = async () => {
    try {
      const {
        success,
        data: { shortTitle, description, converts },
      } = triggerData();
      if (!success) {
        return;
      }

      setLoading(true);
      let images = [];
      if (values.images.length > 0) {
        images = await handleUploadFile();
      }

      // CASE ONLY GET DATA AND SEND ASSET TO PARENT COMPONENT. PLEASE USE THIS FUNCTION FOR REUSALBE
      if (onCreatedSuccess) {
        onCreatedSuccess({
          description,
          images,
          shortTitle,
          converts,
          fullTitle: values.title,
          type: 'MXH',
        });
        setLoading(false);
        return;
      }
      setLoading(false);
    } catch (error) {
      enqueueSnackbar(error?.message || error?.detail, { variant: 'error' });
      setLoading(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const onClickGPTHashTag = async () => {
    try {
      // Tenant check feature
      if (checkFeatureDisabled(FEATURE_GPT)) {
        enqueueSnackbar(t1('feature_disable'), { variant: 'error' });
        return;
      }
      setLoadingGPT(true);
      if (values.keyword) {
        const mainContent = `${values.keyword}`;
        let newUUID = currentAsset ? currentAsset?.uuid : uuid;
        newUUID = isEdit ? newUUID : '';
        const { message, task } = await GPTHashTag(mainContent, newUUID);
        enqueueSnackbar(message, { variant: 'success' });
        setPreviousDescription(values.description);
        // Check task
        if (task) {
          addNewPendingTask(task);
        }
      } else {
        enqueueSnackbar(translate('social.message.hashtag_error'), { variant: 'error' });
      }
    } catch (error) {
      const { message, reason } = error;
      if (reason && reason === 'balance') {
        setIsOpen(true);
      } else {
        enqueueSnackbar(message, { variant: 'error' });
      }
    }
    setLoadingGPT(false);
  };

  const onClickGPTSpin = async () => {
    try {
      // Tenant check feature
      if (checkFeatureDisabled(FEATURE_GPT)) {
        enqueueSnackbar(t1('feature_disable'), { variant: 'error' });
        return;
      }
      setLoadingGPT(true);
      if (values.description) {
        const mainContent = `${values.description}`;
        let newUUID = currentAsset ? currentAsset?.uuid : uuid;
        newUUID = isEdit ? newUUID : '';
        const { message, task } = await GPTSpin(mainContent, newUUID);
        enqueueSnackbar(message, { variant: 'success' });
        setValue('description', '');
        // Check task
        if (task) {
          addNewPendingTask(task);
        }
      } else {
        enqueueSnackbar(translate('social.message.hashtag_error'), { variant: 'error' });
      }
    } catch (error) {
      const { message, reason } = error;
      if (reason && reason === 'balance') {
        setIsOpen(true);
      } else {
        enqueueSnackbar(message, { variant: 'error' });
      }
    }
    setLoadingGPT(false);
  };

  // Popup modal preview asset
  const handleNext = (nextIndex) => {
    if (nextIndex > preview.assets.length - 1) {
      // Done preview
      // popup calendar form
      // Check permission schedule
      const assetAfterDisable = preview.assets?.filter(
        (asset) => !checkFeatureDisabled(FEATURES_SCHEDULE_NAME_MAPPING.schedule[asset?.type])
      );
      if (assetAfterDisable.length !== 0) {
        const baseAsset = assetAfterDisable[0];
        assetAfterDisable.splice(0, 1);
        popupCalendarForm({ ...baseAsset });
      }
      handleClosePreview();
      navigate(PATH_DASHBOARD.post.list);
      return;
    }
    setPreview({
      ...preview,
      index: nextIndex,
    });
  };

  const handleClosePreview = () => {
    setPreview({
      assets: [],
      isOpen: false,
      index: 0,
    });
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Stack spacing={3}>
              <RHFTextField name="title" label={tForm('field.title')} />
              <Stack
                direction={isSmaller660 ? 'column' : 'row'}
                alignItems="center"
                justifyContent={isSmaller660 ? 'center' : 'space-evenly'}
                flexWrap={isSmaller660 ? 'wrap' : 'nowrap'}
                gap={'20px'}
              >
                <RHFTextField name="keyword" label={tForm('field.keyword')} sx={{ flex: isSmaller660 ? 1 : 0.7 }} />
                <LoadingButton
                  sx={{
                    color: `${theme.palette.primary.main}`,
                    fontWeight: '400',
                    fontSize: '14px',
                    px: '20px',
                    py: '13px',
                    border: `1px solid ${theme.palette.primary.main}`,
                    flex: isSmaller660 ? 1 : 0.3,
                  }}
                  variant="text"
                  startIcon={<Iconify sx={{ color: `${theme.palette.primary.main}` }} icon={'simple-icons:openai'} />}
                  onClick={onClickGPTHashTag}
                >
                  Emoji &#38; Hashtag
                </LoadingButton>
              </Stack>
              <Box
                sx={{
                  position: 'relative',
                }}
              >
                <ModalWrapper>
                  <RHFTextField
                    name="description"
                    label={tForm('field.description')}
                    multiline
                    rows={5}
                    sx={{
                      '.MuiOutlinedInput-root': {
                        pb: '45px !important',
                        pr: '35px',
                        '.MuiInputBase-input': {
                          '&::-webkit-scrollbar': {
                            width: '10px',
                            borderRadius: '16px',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#f0f0f0',
                            borderRadius: '16px',
                          },
                        },
                      },
                    }}
                  />
                </ModalWrapper>

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="left"
                  spacing={2}
                  sx={{
                    ml: '1rem',
                    mb: '0.5rem',
                    position: 'absolute',
                    bottom: errors.description ? '25px' : 0,
                    left: 0,
                  }}
                >
                  <EmojiPicker value={emoji} setValue={setEmoji} />
                  <Box style={{ marginLeft: '0.5rem' }}>
                    <IconButton size="small">
                      <Iconify icon="material-symbols:tag-rounded" width={20} height={20} sx={{ color: '#919EAB' }} />
                    </IconButton>
                  </Box>
                  <LoadingButton
                    sx={{
                      color: `${theme.palette.primary.main}`,
                      fontWeight: '400',
                      py: '4px',
                      px: '10px',
                      border: `1px solid ${theme.palette.primary.main}`,
                    }}
                    variant="text"
                    startIcon={<Iconify sx={{ color: `${theme.palette.primary.main}` }} icon={'simple-icons:openai'} />}
                    onClick={onClickGPTSpin}
                  >
                    {translate('social.btn.rewrite')}
                  </LoadingButton>
                </Stack>
              </Box>

              <Tabs
                allowScrollButtonsMobile
                variant="scrollable"
                scrollButtons="auto"
                value={currentTab}
                onChange={onChangeTab}
              >
                {MEDIA_TABS.map((tab) => (
                  <Tab disableRipple key={tab.value} label={tab.label} icon={tab.icon} value={tab.value} />
                ))}
              </Tabs>

              <Box sx={{ mb: 5 }} />

              {MEDIA_TABS.map((tab) => {
                const isMatched = tab.value === currentTab;
                return isMatched && <Box key={tab.value}>{tab.component}</Box>;
              })}
            </Stack>
          </Grid>
        </Grid>
        <Stack direction="row" justifyContent={'space-between'} spacing={1.5} sx={{ mt: 3 }}>
          <Button variant={'outlined'} onClick={onBack}>
            {tCampaign('back')}
          </Button>
          <Button variant={'contained'} type="submit">
            {tCampaign('next')}
          </Button>
        </Stack>
      </FormProvider>
      <Loading loading={isLoading || isLoadingGPT} />
      <LoadingProgress loading={isLoadingProgress} value={progressToPercent(progress)} />
      <PaymentWarning handleClose={handleClose} isOpen={isOpen} />
      <PreviewModal
        assets={preview.assets}
        isOpen={preview.isOpen}
        handleClose={handleClosePreview}
        handleNext={handleNext}
        currentIndex={preview?.index}
      />
    </>
  );
}
