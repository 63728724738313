import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Divider,
  Stack,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Iconify from '../../../../components/Iconify';
import useLocales from '../../../../hooks/useLocales';
import useSettings from '../../../../hooks/useSettings';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

WarningPopup.propTypes = { open: PropTypes.bool, onClose: PropTypes.func };

export default function WarningPopup({ open, onClose }) {
  const { themeMode } = useSettings();

  const { translate: tButton } = useLocales('tool.btn');

  const { translate } = useLocales('tool.popup.warning');

  const ITEMS = translate('items', { returnObjects: true });

  const onClickViewMore = () => {
    window.open(' https://doc.fbtool.net/cau-hoi-thuong-gap#hoi-dang-dang-bai-thi-hien-thong-bao-luu-y', '_blank');
  };

  return (
    <BootstrapDialog onClose={onClose} open={open}>
      <DialogTitle sx={{ m: 0, p: 2, textAlign: 'center' }}>
        <Stack direction="row" gap={1} alignItems="center" justifyContent={'center'}>
          <Iconify icon={'ph:warning-fill'} color="error.main" sx={{ height: 32, width: 32 }} />
          <Typography color="error.main" fontSize={'2.45rem'} fontWeight="bold">
            {translate('title')}
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack flex={1} justifyContent="center" alignItems={'center'} direction={'row'} px={{ xs: 0, sm: 2 }}>
          <Stack flex={1} justifyContent="center" alignItems={'center'} direction={'column'} gap={1.5}>
            <Typography mb={1}>{translate('sub_title')}</Typography>
            <Stack direction={'column'} gap={1.5} rowGap={5.5}>
              {ITEMS?.map((item, index) => (
                <Stack direction={'column'} gap={1} key={item?.name}>
                  <Typography fontSize="1rem">
                    <Typography component="span" fontWeight={'bold'}>{`${index + 1} - ${item?.name}:`}</Typography>
                    <Typography component="span" ml={1}>
                      {item?.description}
                    </Typography>
                  </Typography>
                  <Stack direction="row" justifyContent={'start'} flex={1}>
                    <Iconify icon="tabler:arrow-right" height={20} width={20} sx={{ flexShrink: 0 }} />
                    <Typography fontSize="1rem">
                      {translate('treatment')}
                      <Typography fontSize="1rem" component="span" ml={1}>
                        {item?.solution}
                      </Typography>
                    </Typography>
                  </Stack>
                  {item?.prevent && <Typography fontSize="1rem">{item?.prevent}</Typography>}
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          py: '8px !important',
        }}
      >
        <Button
          sx={{ color: themeMode === 'dark' ? 'common.white' : 'common.black', textTransform: 'none' }}
          autoFocus
          onClick={onClickViewMore}
          endIcon={<Iconify icon="icon-park-outline:right" sx={{ height: 18, width: 18 }} />}
        >
          {tButton('more')}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
