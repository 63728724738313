import _ from 'lodash';
import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useMemo, useRef } from 'react';
// hooks
import useAuth from '../hooks/useAuth';
import useRefCustom from '../hooks/useRefCustom';
import useTenant from '../hooks/useTenant';
import axiosInstance from '../utils/axios';
import { fDate } from '../utils/formatTime';

const initialState = {
  inboxSent: 0,
  isBlockInbox: false,
  DAILY_LIMIT_INBOX: 20,
  leadsCrawled: 0,
  isBlockCrawl: false,
  DAILY_LIMIT_CRAWL: 1000,
  increaseInboxSent: () => {},
  getCurrentInboxSent: () => {},
  isBlockReadInfo: false,
  DAILY_LIMIT_READ_FACEBOOK_INFO: 1000,
  increaseLeadsReadInfo: () => {},
  getCurrentReadInfo: () => {},
  onLoadFBUID: () => {},
  canUseTier: (tier = 1) => false,
};

const STATS = {
  INBOX: 'inbox',
  CRAWL: 'crawl',
  READ_INFO: 'read_facebook_info',
};

const StatsGuardContext = createContext(initialState);

// ---------------------- PROPS VALIDATE ---------------------
StatsGuardProvider.propTypes = {
  children: PropTypes.any,
};
// -----------------------------------------------------------

function StatsGuardProvider({ children }) {
  const { user: userAuth, configs } = useAuth();

  const [user, setUser, userRef] = useRefCustom();

  const [fbUID, setFbUID, fbUIDRef] = useRefCustom(null);

  const { isTTTool, isSMT } = useTenant();

  /**
   * @type {{current:Date|null}}
   */
  const getConfigRef = useRef();

  useEffect(() => {
    if (configs) {
      const current = new Date();
      getConfigRef.current = current;
    }
  }, [configs]);

  useEffect(() => {
    if (userAuth) {
      setUser(userAuth);
    }
  }, [userAuth]);

  const DAILY_LIMIT_INBOX = useMemo(() => configs?.daily_limit_lead_inbox || 20, [configs?.daily_limit_lead_inbox]);

  const DAILY_LIMIT_CRAWL = useMemo(() => configs?.daily_limit_lead_crawl || 1000, [configs?.daily_limit_lead_crawl]);

  const DAILY_LIMIT_READ_FACEBOOK_INFO = useMemo(
    () => configs?.daily_limit_read_facebook_info || 1000,
    [configs?.daily_limit_read_facebook_info]
  );

  const [stats, setStats, statsRef] = useRefCustom({
    [STATS.INBOX]: user?.inbox_sent || 0,
    [STATS.CRAWL]: user?.leads_crawled || 0,
    [STATS.READ_INFO]: user?.read_facebook_info || 0,
  });

  const checkUserData = _.debounce(async () => {
    if (getConfigRef.current) {
      // Diff
      if (fDate(new Date()) !== fDate(getConfigRef?.current || new Date())) {
        // Fetch new data and reset
        const user = await axiosInstance.get('api/v1/users/me/');
        if (user?.data) {
          setUser(user?.data);
        }
      }
    }
  }, 5000);

  const getStatsData = (data, useTotal = false) => {
    if (useTotal) {
      return data?.total || 0;
    }
    const uid = fbUIDRef?.current;
    return data?.[uid] || 0;
  };

  const userStats = useMemo(() => {
    return {
      inbox_sent: getStatsData(user?.inbox_sent),
      leads_crawled: getStatsData(user?.leads_crawled, true),
      read_facebook_info: getStatsData(user?.read_facebook_info, true),
    };
  }, [user, fbUID]);

  useEffect(() => {
    setStats({
      ...statsRef?.current,
      [STATS.INBOX]: userStats?.inbox_sent || 0,
      [STATS.CRAWL]: userStats?.leads_crawled || 0,
      [STATS.READ_INFO]: userStats?.read_facebook_info || 0,
    });
  }, [userStats]);

  const increaseStatValue = (stat, amount = 1) => {
    checkUserData();
    const newValue = (statsRef?.current[stat] || 0) + amount;
    setStats({ ...statsRef?.current, [stat]: newValue });
  };

  const getCurrentStat = (stat) => {
    return statsRef?.current[stat];
  };

  // =========================================
  const inboxSent = stats[STATS.INBOX];
  const isBlockInbox = useMemo(
    () => !!(inboxSent && DAILY_LIMIT_INBOX && inboxSent >= DAILY_LIMIT_INBOX),
    [inboxSent, DAILY_LIMIT_INBOX]
  );

  const increaseInboxSent = (amount = 1) => {
    if (!amount) {
      return;
    }
    increaseStatValue(STATS.INBOX, amount);
  };

  // =========================================
  const leadsCrawled = stats[STATS.CRAWL];
  const isBlockCrawl = useMemo(
    () => !!(leadsCrawled && DAILY_LIMIT_CRAWL && leadsCrawled >= DAILY_LIMIT_CRAWL),
    [leadsCrawled, DAILY_LIMIT_CRAWL]
  );

  const increaseLeadsCrawled = (amount = 1) => {
    if (!amount) {
      return;
    }
    increaseStatValue(STATS.CRAWL, amount);
  };

  // =========================================
  const leadsReadInfo = stats[STATS.READ_INFO];
  const isBlockReadInfo = useMemo(
    () => !!(leadsReadInfo && DAILY_LIMIT_READ_FACEBOOK_INFO && leadsReadInfo >= DAILY_LIMIT_READ_FACEBOOK_INFO),
    [leadsReadInfo, DAILY_LIMIT_READ_FACEBOOK_INFO]
  );

  const increaseLeadsReadInfo = (amount = 1) => {
    if (!amount) {
      return;
    }
    increaseStatValue(STATS.READ_INFO, amount);
  };

  const onLoadFBUID = (uid) => {
    setFbUID(uid);
  };

  const canUseTier = useCallback(
    (tierExpected) => {
      let name = 'fbtool';
      if (isTTTool) {
        name = 'tiktoktool';
      }
      if (isSMT) {
        name = 'fbtool';
      }
      // console.log(name, userRef?.current?.[name]?.tier);
      return (userRef?.current?.[name]?.tier || 0) >= tierExpected;
    },
    [isTTTool, isSMT]
  );

  return (
    <StatsGuardContext.Provider
      value={useMemo(
        () => ({
          // Inbox
          inboxSent,
          isBlockInbox,
          increaseInboxSent,
          getCurrentInboxSent: () => getCurrentStat(STATS.INBOX),
          DAILY_LIMIT_INBOX,
          // Crawl
          leadsCrawled,
          isBlockCrawl,
          DAILY_LIMIT_CRAWL,
          increaseLeadsCrawled,
          getCurrentLeadsCrawled: () => getCurrentStat(STATS.CRAWL),
          // Read info
          leadsReadInfo,
          isBlockReadInfo,
          DAILY_LIMIT_READ_FACEBOOK_INFO,
          increaseLeadsReadInfo,
          getCurrentReadInfo: () => getCurrentStat(STATS.READ_INFO),
          onLoadFBUID,
          canUseTier,
        }),
        [
          // eslint-disable-next-line react-hooks/exhaustive-deps
          stats,
          // inbox
          inboxSent,
          isBlockInbox,
          DAILY_LIMIT_INBOX,
          // crawl
          leadsCrawled,
          isBlockCrawl,
          DAILY_LIMIT_CRAWL,
          // Read info
          leadsReadInfo,
          isBlockReadInfo,
          DAILY_LIMIT_READ_FACEBOOK_INFO,
        ]
      )}
    >
      {children}
    </StatsGuardContext.Provider>
  );
}

export { StatsGuardProvider, StatsGuardContext };
