import React from 'react';
import PropTypes from 'prop-types';
import { StatsGuardProvider } from '../../contexts/StatsGuardContext';
import { FBProvider } from '../../contexts/FBContext';
import { ToolProvider } from '../../contexts/ToolContext';
import { FBFunctionProvider } from '../../contexts/FBFunctionContext';

function CombinedContextLayout({ children }) {
  return (
    <StatsGuardProvider>
      <FBFunctionProvider>
        <FBProvider>
          <ToolProvider>{children}</ToolProvider>
        </FBProvider>
      </FBFunctionProvider>
    </StatsGuardProvider>
  );
}

CombinedContextLayout.propTypes = {
  children: PropTypes.node,
};

export default CombinedContextLayout;
