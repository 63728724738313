import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Box, Button } from '@mui/material';

// ----------------------------------------------------------------------

RHFRadioButton.propTypes = {
  name: PropTypes.string,
  item: PropTypes.object,
  isArray: PropTypes.bool,
};

export default function RHFRadioButton({ name, item, isArray = true, ...other }) {
  const { control } = useFormContext();

  const handleRenderArray = ({ field }) => {
    const currentValues = field.value || [];
    const index = currentValues.findIndex((item2) => item2 === item.value);
    return (
      <Box
        component={Button}
        onClick={() => {
          if (index !== -1) {
            currentValues.splice(index, 1);
            field.onChange([...currentValues]);
            return;
          }
          field.onChange([...currentValues, item.value]);
        }}
        variant={index !== -1 ? 'contained' : 'outlined'}
        color={index !== -1 ? 'primary' : 'GrayText'}
        sx={{ borderRadius: '50%', p: 0, width: 26, height: 26, minWidth: 0, boxShadow: 0 }}
        {...other}
      >
        {item.label}
      </Box>
    );
  };

  const handleRenderValue = ({ field }) => {
    const currentValue = field.value;
    const selected = currentValue === item.value;
    return (
      <Box
        component={Button}
        onClick={() => {
          field.onChange(item.value);
        }}
        variant={selected ? 'contained' : 'outlined'}
        color={selected ? 'primary' : 'GrayText'}
        sx={{ borderRadius: '50%', p: 0, minWidth: 0, boxShadow: 0 }}
        {...other}
      >
        {item.label}
      </Box>
    );
  };

  return <Controller name={name} control={control} render={isArray ? handleRenderArray : handleRenderValue} />;
}
