import { useEffect, useState } from 'react';
import { removeAccentsVi } from '../utils/formatString';
import useAuth from './useAuth';
import useLocales from './useLocales';

// ----------------------------------------------------------------------
const REPLACE_LIST = {
  city: ['Thành phố', 'Tỉnh'],
  district: ['Thành phố', 'Phường', 'Huyện', 'Thị xã', 'Quận'],
  ward: ['Thị trấn', 'Xã', 'Phường'],
  street: ['Đường', 'Phố', 'Tỉnh lộ', 'duong', 'Quốc Lộ', 'Đại Lộ'],
  demands: ['Cần bán', 'Cho thuê', 'Cần mua', 'Cần thuê'],
};

export default function useTranslateCatalog(listCatalog, name) {
  const { translate, currentLang } = useLocales('translate_catalog');
  const [result, setResult] = useState([]);
  const { categories } = useAuth();

  useEffect(() => {
    if (listCatalog && name) {
      if (['city', 'district', 'ward', 'street'].indexOf(name) !== -1) {
        const temp = listCatalog.map((item) => {
          const listReplace = REPLACE_LIST[name];
          if (currentLang.value === 'en') {
            const newItem = listReplace.reduce(
              (result, current, currentIndex) => {
                let newLabel = result.label;
                const index = newLabel.indexOf(current);
                // Found
                if (index !== -1) {
                  newLabel = newLabel.replace(listReplace[currentIndex], '').trim();
                  newLabel = `${newLabel} ${translate(`${name}.${currentIndex}`).toLowerCase()}`;
                }
                return {
                  ...item,
                  alias: newLabel,
                  label: newLabel,
                };
              },
              { ...item }
            );
            const newLabel = removeAccentsVi(newItem.label);
            return {
              ...newItem,
              alias: newLabel,
              label: newLabel,
            };
          }
          return {
            ...item,
          };
        });
        setResult([...temp]);
      }
      if (['categories', 'categoriesDetail'].indexOf(name) !== -1) {
        const temp = listCatalog.map((item) => {
          const parent = categories.find((cate) => cate.id === item.parentId);
          const newLabel =
            name === 'categories'
              ? translate(`categories.${item.name}.title`)
              : translate(`categories.${parent.name}.${item.name}`);
          if (item.children) {
            item.children.forEach((child, index) => {
              const newChildLabel = translate(`categories.${item.name}.${child.name}`);
              item.children[index] = { ...child, alias: newChildLabel, label: newChildLabel };
            });
          }
          return {
            ...item,
            alias: newLabel,
            label: newLabel,
          };
        });
        setResult([...temp]);
      }
      if (['demands'].indexOf(name) !== -1) {
        const temp = listCatalog.map((item) => {
          const listReplace = REPLACE_LIST[name];
          if (currentLang.value === 'en') {
            const newItem = listReplace.reduce(
              (result, current, currentIndex) => {
                let newLabel = result.label;
                const index = newLabel.indexOf(current);
                // Found
                if (index !== -1) {
                  newLabel = `${translate(`${name}.${currentIndex}`)}`;
                }
                return {
                  ...item,
                  alias: newLabel,
                  label: newLabel,
                };
              },
              { ...item }
            );
            const newLabel = removeAccentsVi(newItem.label);
            return {
              ...newItem,
              alias: newLabel,
              label: newLabel,
            };
          }
          return {
            ...item,
          };
        });
        setResult([...temp]);
      }
      if (['industries'].indexOf(name) !== -1) {
        const temp = listCatalog?.map((item) => ({
          ...item,
          label: translate(`industries.${item?.name}`),
        }));
        setResult([...temp]);
      }
      if (['genders'].indexOf(name) !== -1) {
        const temp = listCatalog?.map((item) => ({
          ...item,
          label: translate(`genders.${item?.value}`),
        }));
        setResult([...temp]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listCatalog, name, currentLang]);

  // eslint-disable-next-line consistent-return
  const handleTranslateSingle = (obj, name) => {
    if (obj) {
      if (['city', 'district', 'ward', 'street'].indexOf(name) !== -1) {
        const listReplace = REPLACE_LIST[name];
        if (currentLang.value === 'en') {
          const newItem = listReplace.reduce(
            (result, current, currentIndex) => {
              let newLabel = result.label;
              const index = newLabel.indexOf(current);
              // Found
              if (index !== -1) {
                newLabel = newLabel.replace(listReplace[currentIndex], '').trim();
                newLabel = `${newLabel} ${translate(`${name}.${currentIndex}`).toLowerCase()}`;
              }

              return {
                ...obj,
                alias: newLabel,
                label: newLabel,
              };
            },
            { ...obj }
          );
          const newLabel = removeAccentsVi(newItem.label);
          return {
            ...newItem,
            alias: newLabel,
            label: newLabel,
          };
        }
        return {
          ...obj,
        };
      }
      if (['categories', 'categoriesDetail'].indexOf(name) !== -1) {
        const parent = categories.find((cate) => cate.id === obj.parentId);
        const newLabel =
          name === 'categories'
            ? translate(`categories.${obj.name}.title`)
            : translate(`categories.${parent.name}.${obj.name}`);
        return {
          ...obj,
          alias: newLabel,
          label: newLabel,
        };
      }
      if (['demands'].indexOf(name) !== -1) {
        const listReplace = REPLACE_LIST[name];
        if (currentLang.value === 'en') {
          const newItem = listReplace.reduce(
            (result, current, currentIndex) => {
              let newLabel = result.label;
              const index = newLabel.indexOf(current);
              // Found
              if (index !== -1) {
                newLabel = `${translate(`${name}.${currentIndex}`)}`;
              }
              return {
                ...obj,
                alias: newLabel,
                label: newLabel,
              };
            },
            { ...obj }
          );
          return {
            ...newItem,
          };
        }
      }
      if (['industries'].indexOf(name) !== -1) {
        return {
          ...obj,
          label: translate(`industries.${obj?.name}`),
        };
      }
      if (['genders'].indexOf(name) !== -1) {
        return {
          ...obj,
          label: translate(`genders.${obj?.value}`),
        };
      }
    }
  };
  return { result, handleTranslateSingle };
}
