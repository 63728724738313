import { useNavigate } from 'react-router-dom';
import { Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { createContext, useCallback, useMemo, useState } from 'react';
import Label from '../components/Label';
import useAuth from '../hooks/useAuth';
import useBoolean from '../hooks/useBoolean';
import useLocales from '../hooks/useLocales';
import AdsContent from '../sections/@dashboard/campaign/ads/content';
import { AdsSchedule } from '../sections/@dashboard/campaign/ads/schedule';
import { CampaignTarget } from '../sections/@dashboard/campaign/ads/target';
import { GENDERS } from '../sections/@dashboard/campaign/common';
import { fTime } from '../utils/formatTime';
import { createAdsCampaign } from '../sections/@dashboard/campaign/ads/helper';
import { createPostSocial } from '../sections/@dashboard/post/helper';
import { PATH_DASHBOARD } from '../routes/paths';
import useTranslateCatalog from '../hooks/useTranslateCatalog';

// ----------------------------------------------------------------------

const initialState = {
  onNextStep: () => {},
  onPreviousStep: () => {},
  onGotoStep: () => {},
  flowControl: {},
  loading: {},
  // filter
  step1: {},
  // content
  step2: {},
};

const defaultValue = {
  step1: {},
  step2: {},
};

const AdsCampaignContext = createContext(initialState);

// ----------------------------------------------------------------------

AdsCampaignProvider.propTypes = {
  children: PropTypes.node,
};

function AdsCampaignProvider({ children }) {
  const [state, setState] = useState(defaultValue);

  const navigate = useNavigate();

  const loading = useBoolean();

  const { enqueueSnackbar } = useSnackbar();

  const { currentLang, translate } = useLocales('dashboard.ads_marketing');

  const STEPS = Object.freeze(
    useMemo(
      () => [
        {
          step: 1,
          label: translate('steps.target'),
          component: <CampaignTarget />,
          isCompleted: false,
        },
        {
          step: 2,
          label: translate('steps.content'),
          component: <AdsContent />,
          isCompleted: false,
        },
        {
          step: 3,
          label: translate('steps.schedule'),
          component: <AdsSchedule />,
          isCompleted: false,
        },
      ],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [currentLang]
    )
  );

  const DEFFAULT_FLOW = {
    currentStep: 1,
    data: cloneDeep(STEPS),
  };

  const [flowControl, setFlowControl] = useState(DEFFAULT_FLOW);

  // Functions
  const getPayload = (assetUuid, datetimeObj) => {
    const { datetime, excludesList } = datetimeObj;

    const {
      step1: { filter, adsIds },
    } = state;

    return {
      schedule_time: datetime,
      asset_uuid: assetUuid,
      ...filter,
      ads_ids: adsIds?.filter((item) => excludesList?.indexOf(item) === -1),
    };
  };

  const handleCreateCampaign = async (datetimeObj) => {
    loading.onTrue();
    try {
      const {
        step2: convertPayload,
        step1: { adsIds },
      } = state;

      const remainAdsId = adsIds?.filter((item) => datetimeObj?.excludesList?.indexOf(item) === -1) || [];
      if (remainAdsId?.length === 0) {
        enqueueSnackbar(translate('message.empty_adience'), { variant: 'error' });
        return;
      }

      // Send API create asset
      const response = await createPostSocial(convertPayload);

      if (response?.isSuccess) {
        const { data: asset } = response.response;
        const assetUuid = asset?.uuid;

        // Send API create ads campaign
        const payload = getPayload(assetUuid, datetimeObj);
        const { success, data, msg } = await createAdsCampaign(payload);
        if (!success) {
          enqueueSnackbar(msg, { variant: 'error' });
          return;
        }
        enqueueSnackbar(data);
        navigate(PATH_DASHBOARD.advertisement.manage);
      }
    } catch (error) {
      console.log(error);
    } finally {
      loading.onFalse();
    }
  };

  const updateFlowContent = useCallback(
    (step, label = null, content = null, nextStep = false) => {
      const cloneFlow = { ...flowControl };
      const cloneSTEPS = [...STEPS];
      const currentData = [...cloneFlow.data];
      const index = currentData?.findIndex((item) => item.step === step);
      // Index of DEFAULT VALUE
      const defaultIndex = cloneSTEPS?.findIndex((item) => item.step === step);
      if (index !== -1 && defaultIndex !== -1) {
        // Modify content
        if (content) {
          currentData[index].component = content;
        } else {
          currentData[index].component = cloneSTEPS[defaultIndex].component;
        }

        // Modify label
        if (label) {
          currentData[index].label = label;
        }

        if (nextStep) {
          currentData[index].isCompleted = true;
          cloneFlow.currentStep = step + 1;
        }
        setFlowControl({ ...cloneFlow, data: [...currentData] });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flowControl]
  );

  const onNextStep = useCallback(
    (extraData) => {
      loading.onTrue();
      try {
        const cloneFlow = { ...flowControl };
        const step = cloneFlow.currentStep;
        const currentData = [...cloneFlow.data];
        const index = currentData?.findIndex((item) => item.step === step);
        // Index of DEFAULT VALUE
        const defaultIndex = STEPS?.findIndex((item) => item.step === step);
        // Invalid
        if (index === -1 && defaultIndex === -1) {
          return;
        }
        // STEP 1
        if (step === 1) {
          const labelDisplay = (
            <Stack direction={'column'} gap={1}>
              <Typography component={'span'} variant={'body2'} fontWeight={'bold'}>
                {STEPS[defaultIndex].label}
              </Typography>
              <Typography component={'span'} variant={'body2'}>
                <LabelInfo filter={extraData?.filter} />
              </Typography>
            </Stack>
          );
          updateFlowContent(step, labelDisplay, null, true);
          setState({ ...state, step1: extraData });
        }
        // STEP 2
        if (step === 2) {
          const labelDisplay = (
            <Stack direction={'column'} gap={1}>
              <Typography component={'span'} variant={'body2'} fontWeight={'bold'}>
                {STEPS[defaultIndex].label}
              </Typography>
              <Stack direction={'row'} flexWrap={'wrap'} gap={1}>
                <Label>{`Image (${extraData?.images?.length})`}</Label>
              </Stack>
            </Stack>
          );
          updateFlowContent(step, labelDisplay, null, true);
          setState({ ...state, step2: extraData });
        }
        // STEP 3
        if (step === 3) {
          const { date, time, excludesList } = extraData;
          const payload = {
            date: format(date, 'yyyy-MM-dd'),
            datetime: `${format(date, 'yyyy-MM-dd')} ${fTime(time)}`,
            excludesList,
          };
          handleCreateCampaign(payload);
        }
      } catch (error) {
        console.log(error);
      } finally {
        loading.onFalse();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flowControl, state, loading]
  );

  const onPreviousStep = useCallback(() => {
    loading.onTrue();
    try {
      const cloneFlow = { ...flowControl };
      const step = cloneFlow.currentStep;
      const previous = step - 1;
      if (previous > 0) {
        setFlowControl({ ...cloneFlow, currentStep: step - 1 });
      }
    } catch (error) {
      console.log(error);
    } finally {
      loading.onFalse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowControl]);

  const onGotoStep = useCallback(
    (step) => {
      const cloneFlow = { ...flowControl };
      setFlowControl({ ...cloneFlow, currentStep: step });
    },
    [flowControl]
  );

  return (
    <AdsCampaignContext.Provider
      value={useMemo(
        () => ({
          flowControl,
          loading,
          step1: state.step1,
          step2: state.step2,
          onNextStep,
          onPreviousStep,
          onGotoStep,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [state, flowControl, loading]
      )}
    >
      {children}
    </AdsCampaignContext.Provider>
  );
}

export { AdsCampaignProvider, AdsCampaignContext };

// ===============================================================================

LabelInfo.propTypes = {
  filter: PropTypes.object,
};

function LabelInfo({ filter }) {
  const { industries: commonIndustries, nations: commonNations } = useAuth();

  // Industries
  const { result: translatedIndustries } = useTranslateCatalog(commonIndustries, 'industries');

  // Genders
  const { result: translatedGenders } = useTranslateCatalog(GENDERS, 'genders');

  const { currentLang, translate: tForm } = useLocales('dashboard.ads_marketing.form.field');

  const { genders, industries, locations, nations, allAge, age_max: ageMax, age_min: ageMin } = filter || {};

  let txtGender = '';
  let txtAge = '';
  let txtIndustry = '';
  let txtNation = '';
  let txtLocation = '';

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const allText = useMemo(() => tForm('all'), [currentLang]);

  // Gender
  if (genders?.indexOf('all') !== -1 || genders?.length === 0) {
    txtGender = allText;
  } else {
    txtGender = genders
      ?.map((val) => {
        const matched = translatedGenders?.find((item) => item.value === val);
        return matched?.label;
      })
      .join(', ');
  }

  // Age
  if (allAge) {
    txtAge = allText;
  } else {
    const minString = ageMin === 0 ? `${tForm('age.below')} ` : `${ageMin} -`;
    txtAge = `${minString}${ageMax} ${tForm('age.sub_title')}`;
  }

  // Industry
  if (industries?.length === 0) {
    txtIndustry = allText;
  } else {
    txtIndustry = industries
      ?.map((val) => {
        const matched = translatedIndustries.find((item) => item?.id === val);
        return matched?.label;
      })
      .join(', ');
  }

  // Nation
  if (nations?.length === 0) {
    txtNation = allText;
  } else {
    txtNation = nations
      ?.map((val) => {
        const matched = commonNations.find((item) => item?.id === val);
        return matched?.name;
      })
      .join(', ');
  }

  // Location
  if (locations?.length === 0) {
    txtLocation = allText;
  } else {
    txtLocation = locations?.join(', ');
  }

  const data = [
    { label: tForm('age.title'), content: txtAge },
    { label: tForm('gender'), content: txtGender },
    { label: tForm('industry'), content: txtIndustry },
    { label: tForm('nation'), content: txtNation },
    { label: tForm('location'), content: txtLocation },
  ];

  return (
    <Stack direction={'column'} gap={1} alignItems={'start'} justifyContent="space-between">
      {data?.map((item) => (
        <Typography key={item?.label} component={'span'} variant={'body2'}>
          <Typography key={item?.label} component={'span'} variant={'body2'} fontWeight={'bold'}>
            {item?.label}
          </Typography>
          : {item?.content}
        </Typography>
      ))}
    </Stack>
  );
}
