import React, { useEffect, useRef } from 'react';
import { isEmpty, uniqBy } from 'lodash';
import useRefCustom from '../useRefCustom';
import axiosInstance from '../../utils/axios';
import useStatsGuard from './useStatsGuard';
import useToolContext from '../useToolContext';
import useFBContext from '../useFBContext';

const FBTOOL_AI_STATS_SYNC_ENDPOINT = 'api/v2/tool/lead/stats/sync/';

const STATS_NAME = {
  CRAWLED_LEADS: 'lead_crawled',
};

function useSync() {
  const { increaseLeadsCrawled } = useStatsGuard();

  const [statsNeedSync, setStatsNeedSync, statsNeedSyncRef] = useRefCustom({});

  const [, setIsSyncing, isSyncingRef] = useRefCustom(false);

  const { FBUser } = useFBContext();

  const [, setFBUserRef, FBUserRef] = useRefCustom(null);

  useEffect(() => {
    setFBUserRef(FBUser);
  }, [FBUser]);

  const {
    // !!!!!!!! IMPORTANT: DEPEND ON DELAY MINIMUM DELAY BETWEEN TWO ACTIONS. IT SHOULD BE LESS THAN MINIMUM DELAY
    ACTIONS_DELAY: { from: SYNC_DELAY },
  } = useToolContext();

  const syncRef = useRef();

  useEffect(() => {
    startSyncProgress();
  }, [statsNeedSync]);

  const startSyncProgress = () => {
    // Data need sync not empty and not sync progress are running
    if (!isEmpty(statsNeedSyncRef?.current) && !isSyncingRef?.current) {
      if (syncRef?.current) {
        clearTimeout(syncRef?.current);
      }
      syncRef.current = setTimeout(syncStats, (SYNC_DELAY - 5) * 1000);
    }
  };

  const addToStatsNeedSync = (name, value = 0) => {
    const rename = `lead_${name}`;
    const currentValue = { ...statsNeedSyncRef?.current };
    currentValue[rename] = (currentValue[rename] || 0) + value;
    setStatsNeedSync(currentValue);
  };

  const syncStats = async () => {
    const currentData = statsNeedSyncRef?.current;
    if (isEmpty(currentData)) {
      return;
    }
    try {
      setIsSyncing(true);
      const res = await axiosInstance.post(FBTOOL_AI_STATS_SYNC_ENDPOINT, {
        data: currentData,
        fb_uid: FBUserRef?.current?.uid,
      });

      // Check cralw data
      const currentCrawlData = currentData[STATS_NAME.CRAWLED_LEADS] || 0;
      if (currentCrawlData) {
        increaseLeadsCrawled(currentCrawlData);
      }

      console.log(res?.data?.message);

      // Update new value
      const newValue = Object.keys(statsNeedSyncRef?.current)?.reduce(
        (res, key) => {
          if (res[key] && currentData[key]) {
            res[key] -= currentData[key];
            if (res[key] <= 0) {
              delete res[key];
            }
          }
          return res;
        },
        { ...statsNeedSyncRef?.current }
      );

      setStatsNeedSync(newValue);
    } catch (error) {
      console.log(error);
    } finally {
      setIsSyncing(false);
      clearTimeout(syncRef?.current);
      // If it have data continue sync
      console.log(statsNeedSyncRef?.current);
      if (!isEmpty(statsNeedSyncRef?.current)) {
        startSyncProgress();
      }
    }
  };

  return { addToStatsNeedSync };
}

export default useSync;
