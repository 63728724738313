import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';

import PropTypes from 'prop-types';
import Iconify from './Iconify';
// components
import Image from './Image';
// hooks
import useAuth from '../hooks/useAuth';
import useLocales from '../hooks/useLocales';
import PreviewSingle from './PreviewSingle';

export default function PreviewModal({ isOpen, assets, handleNext, currentIndex }) {
  const asset = assets[currentIndex] || null;
  const { translate } = useLocales('modal');
  const {
    configs: { aws_endpoint_url: S3_HOST },
  } = useAuth();

  const renderContent = (asset) => {
    if (!asset) {
      return '';
    }
    if (asset?.type === 'WEB') {
      return <Box dangerouslySetInnerHTML={{ __html: asset?.content }} />;
    }
    return asset?.description.split('\n').map((item, index) => <Box key={index}>{item}</Box>);
  };

  return (
    <div>
      <Backdrop sx={{ color: '#fff', zIndex: 9999 }} open={isOpen}>
        {asset && (
          <Grid
            container
            sx={{
              maxWidth: 'lg',
              maxHeight: '70vh',
              backgroundColor: '#D6E4FF',
              px: '30px',
              py: '40px',
              borderRadius: '16px',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '10px',
                borderRadius: '16px',
              },
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                color: '#000',
                fontSize: '14px',
              }}
            >
              <PreviewSingle title={asset?.title} image={asset?.main_image} />
              <Stack
                direction="column"
                justifyContent="center"
                width={'100%'}
                sx={{
                  '& ul': {
                    ml: '25px',
                    li: {
                      mt: 1,
                    },
                  },
                }}
              >
                <Typography variant="h4" sx={{ fontWeight: '700', mt: '20px' }}>
                  {asset?.title}
                </Typography>
                <Box sx={{ fontSize: '14px', mt: '20px' }}>{renderContent(asset)}</Box>
              </Stack>
            </Grid>

            {/* Render images */}
            {asset?.images &&
              asset?.images?.map((image, index) => {
                const imageUrl = typeof image.image === 'object' ? image?.image?.preview : `${S3_HOST}/${image.image}`;
                return (
                  <Grid key={index} item xs={4} sx={{ mt: '20px' }}>
                    <Image
                      sx={{ height: '200px', objectFit: 'cover' }}
                      visibleByDefault
                      disabledEffect
                      src={imageUrl}
                      alt="image"
                    />
                  </Grid>
                );
              })}
            <Grid item xs={12} sx={{ mt: '20px' }}>
              <Stack alignItems={'center'} justifyContent={'end'} direction={'row'}>
                <Button
                  variant="text"
                  size="large"
                  onClick={() => {
                    handleNext(currentIndex + 1);
                  }}
                  endIcon={<Iconify icon="material-symbols:arrow-forward-ios-rounded" width={20} height={20} />}
                  sx={{
                    px: '10px',
                    py: '5px',
                    height: '36px',
                    textTransform: 'none',
                    borderRadius: '8px',
                    color: '#fff',
                    backgroundColor: '#3366FF',
                    fontSize: '0.875rem',
                    boxShadow: '0px 8px 16px rgba(0, 171, 85, 0.24)',
                    '&:hover': {
                      color: '#fff',
                      backgroundColor: '#3366FF',
                    },
                  }}
                >
                  {currentIndex < assets?.length - 1 ? translate('btn.next') : translate('btn.done')}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        )}
      </Backdrop>
    </div>
  );
}

PreviewModal.propTypes = {
  isOpen: PropTypes.bool,
  assets: PropTypes.array,
  handleNext: PropTypes.func,
  currentIndex: PropTypes.number,
};
