import { useContext } from 'react';
import { AdsCampaignContext } from '../contexts/AdsCampaignContext';

// ----------------------------------------------------------------------

const useAdsCampaign = () => {
  const context = useContext(AdsCampaignContext);

  if (!context) throw new Error('Auth context must be use inside AdsCampaignProvider');

  return context;
};

export default useAdsCampaign;
