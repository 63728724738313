import PropTypes from 'prop-types';
// @mui
import { Box, Card, CardContent, Link } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
// hooks
import useAuth from '../hooks/useAuth';
import useResponsive from '../hooks/useResponsive';
// components
import { MotionContainer } from './animate';
import Image from './Image';
import TextMaxLine from './TextMaxLine';

// ----------------------------------------------------------------------

const OverlayStyle = styled('div')(({ theme }) => ({
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 8,
  position: 'absolute',
  backgroundColor: alpha(theme.palette.grey[900], 0.64),
}));

// ----------------------------------------------------------------------

PreviewSingle.propTypes = {
  image: PropTypes.any.isRequired,
  title: PropTypes.string,
};

export default function PreviewSingle({ image, title, ...other }) {
  const { configs } = useAuth();

  const imageUrl = typeof image === 'object' ? image.preview : `${configs.aws_endpoint_url}/${image}`;

  return (
    <Card {...other}>
      <CarouselItem image={imageUrl} title={title} isActive />
    </Card>
  );
}

// ----------------------------------------------------------------------

CarouselItem.propTypes = {
  isActive: PropTypes.bool,
  image: PropTypes.string,
  title: PropTypes.string,
};

function CarouselItem({ image, title, isActive }) {
  const isDesktop = useResponsive('up', 'md');
  return (
    <Box sx={{ position: 'relative' }}>
      <CardContent
        component={MotionContainer}
        animate={isActive}
        action
        sx={{
          bottom: 0,
          width: 1,
          zIndex: 9,
          textAlign: 'left',
          position: 'absolute',
          color: 'common.white',
        }}
      >
        <Link color="inherit" underline="none">
          <TextMaxLine variant={isDesktop ? 'h3' : 'subtitle1'} line={2} persistent>
            {title}
          </TextMaxLine>
        </Link>
      </CardContent>

      <OverlayStyle />

      <Image alt={title} src={image} sx={{ height: { xs: 280, xl: 320 } }} />
    </Box>
  );
}
