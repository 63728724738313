export function haveErrorsInList(errors = [], errorsList = []) {
  try {
    // Fields to check checkpoints, spam, banned
    const fields = ['summary', 'message', 'description', 'code', 'errorSummary', 'errorDescription'];
    return errors?.some((item) =>
      fields?.some((field) => {
        try {
          if (!item[field]) {
            return false;
          }
          if (typeof item.severity !== 'undefined' && item.severity.toLowerCase() === 'critical') {
            console.error(`checkpoint CRITICAL: ${item.summary}`);
            // this.sentryHandler.captureEvent({
            //   message: `checkpoint CRITICAL: ${item.summary}`,
            //   level: 'warning',
            //   extra: errors,
            // });
            // too many scenarios, can't support this check, log for further categorization
            if (typeof item.api_error_code !== 'undefined' && item.api_error_code === 368) {
              return true;
            }
          }
          // field can null or not exist
          return errorsList?.some((msg) => {
            try {
              if (item[field] === msg || item[field]?.toLowerCase().indexOf(msg) !== -1) {
                console.error(`checkpoint ${field}: ${msg}`);
                // this.sentryHandler.captureEvent({
                //   message: `checkpoint ${field}: ${msg}`,
                //   level: 'warning',
                //   extra: errors,
                // });
                return true;
              }
              return false;
            } catch (error) {
              return false;
            }
          });
        } catch (error) {
          return false;
        }
      })
    );
  } catch (error) {
    return false;
  }
}
