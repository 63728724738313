import axios from '../../../../utils/axios';

const PREFIX = '/api/v1/booking';

export const ADS_API_ENDPOINTS = {
  configs: `${PREFIX}/configs/`,
  register: `${PREFIX}/register/`,
  accounts: `${PREFIX}/accounts/`,
  disable: (adsId) => `${PREFIX}/${adsId}/disable/`,
  cost: `${PREFIX}/cost/`,
  campaign_create: `${PREFIX}/campaign/create/`,
  manage: {
    list: `${PREFIX}/manage/list/`,
    pending: `${PREFIX}/manage/list/pending/`,
    approve: (adsId) => `${PREFIX}/manage/${adsId}/approve/`,
    reject: (adsId) => `${PREFIX}/manage/${adsId}/reject/`,
    remove: (adsId) => `${PREFIX}/manage/${adsId}/remove/`,
    scheduled: (campaignId) => `${PREFIX}/manage/${campaignId}/scheduled/`,
  },
};

export const getAdsAccounts = async (payload, pageNo) => {
  try {
    let customUrl = ADS_API_ENDPOINTS.accounts;
    if (pageNo) {
      customUrl = `${ADS_API_ENDPOINTS.accounts}?page=${pageNo}`;
    }
    const res = await axios.post(customUrl, payload);
    return { success: true, data: res?.data };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.detail };
  }
};

export const registerAdsAccount = async (siteAccountId, socialId) => {
  try {
    const payload = { site_account_id: siteAccountId, social_id: socialId };
    const res = await axios.post(ADS_API_ENDPOINTS.register, payload);
    return { success: true, data: res?.data };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.detail };
  }
};

export const disableAdsAccount = async (adsId) => {
  try {
    const res = await axios.delete(ADS_API_ENDPOINTS.disable(adsId));
    return { success: true, data: res?.data?.message };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.detail };
  }
};

export const getEstimateCost = async (filter) => {
  try {
    const res = await axios.post(ADS_API_ENDPOINTS.cost, filter);
    return { success: true, data: res?.data };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.detail };
  }
};

export const createAdsCampaign = async (payload) => {
  try {
    const res = await axios.post(ADS_API_ENDPOINTS.campaign_create, payload);
    return { success: true, data: res?.data?.message };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.detail };
  }
};

export const getListAdsCampaign = async (pageNo) => {
  try {
    let customUrl = ADS_API_ENDPOINTS.manage.list;
    if (pageNo) {
      customUrl = `${ADS_API_ENDPOINTS.manage.list}?page=${pageNo}`;
    }
    const res = await axios.get(customUrl);
    return { success: true, data: res?.data };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.detail };
  }
};

export const getListPendingAdsCampaign = async (pageNo) => {
  try {
    let customUrl = ADS_API_ENDPOINTS.manage.pending;
    if (pageNo) {
      customUrl = `${ADS_API_ENDPOINTS.manage.pending}?page=${pageNo}`;
    }
    const res = await axios.get(customUrl);
    return { success: true, data: res?.data };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.detail };
  }
};

export const approveAdsCampaign = async (campaignId) => {
  try {
    const res = await axios.post(ADS_API_ENDPOINTS.manage.approve(campaignId));
    return { success: true, data: res?.data?.message };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.detail };
  }
};

export const removeAdsCampaign = async (campaignId) => {
  try {
    const res = await axios.delete(ADS_API_ENDPOINTS.manage.remove(campaignId));
    return { success: true, data: res?.data?.message };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.detail };
  }
};

export const rejectAdsCampaign = async (campaignId) => {
  try {
    const res = await axios.post(ADS_API_ENDPOINTS.manage.reject(campaignId));
    return { success: true, data: res?.data?.message };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.detail };
  }
};

export const getAdsCampaignDetail = async (campaignId, pageNo) => {
  try {
    let customUrl = ADS_API_ENDPOINTS.manage.scheduled(campaignId);
    if (pageNo) {
      customUrl = `${ADS_API_ENDPOINTS.manage.scheduled(campaignId)}?page=${pageNo}`;
    }
    const res = await axios.get(customUrl);
    return { success: true, data: res?.data };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.detail };
  }
};
