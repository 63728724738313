import React from 'react';
import PropTypes from 'prop-types';
import { StatsGuardProvider } from '../../contexts/StatsGuardContext';
import { TTProvider } from '../../contexts/TTContext';
import { CommonToolProvider } from '../../contexts/CommonToolContext';
import { TTFunctionProvider } from '../../contexts/TTFunctionContext';

function CombinedContextLayout({ children }) {
  return (
    <StatsGuardProvider>
      <TTFunctionProvider>
        <TTProvider>
          <CommonToolProvider>{children}</CommonToolProvider>
        </TTProvider>
      </TTFunctionProvider>
    </StatsGuardProvider>
  );
}

CombinedContextLayout.propTypes = {
  children: PropTypes.node,
};

export default CombinedContextLayout;
