import { Button, Grid, Stack, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import { useNavigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import Iconify from './Iconify';
// components
import useResponsive from '../hooks/useResponsive';
import Image from './Image';
// routes
import { PATH_DASHBOARD } from '../routes/paths';

export default function PaymentWarning({ isOpen, handleClose }) {
  const isMobile = useResponsive('between', null, 0, 700);
  const navigate = useNavigate();

  const handleRedirectPayment = () => {
    navigate(`${PATH_DASHBOARD.user.account}?activeTab=payment`);
  };
  return (
    <div>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isOpen}>
        <Grid
          container
          sx={{
            maxWidth: '500px',
            backgroundColor: '#D6E4FF',
            px: '30px',
            py: '40px',
            borderRadius: '16px',
          }}
        >
          <Grid
            item
            xs={isMobile ? 12 : 10}
            sx={{
              color: '#000',
              fontSize: '14px',
            }}
          >
            <Stack
              direction="column"
              justifyContent="center"
              width={'100%'}
              sx={{
                '& ul': {
                  ml: '25px',
                  li: {
                    mt: 1,
                  },
                },
              }}
            >
              <Stack justifyContent="center" alignItems="center" sx={{ mb: '8px' }}>
                <Iconify icon={'emojione:warning'} sx={{ width: '40px', height: '48px' }} />
              </Stack>
              <Typography
                variant="h4"
                sx={{ textAlign: 'center', fontWeight: '700', textTransform: 'uppercase', mt: '20px' }}
              >
                Số dư không đủ
              </Typography>
              <Typography variant="span" sx={{ textAlign: 'center', fontSize: '14px', mt: '20px' }}>
                Số dư tài khoản của bạn không đủ để thanh toán cho tính năng này.
              </Typography>
            </Stack>
            <Stack direction={'row'} mt={'20px'} justifyContent={'space-evenly'} alignItems={'center'}>
              <Button
                variant="text"
                size="large"
                onClick={() => {
                  handleClose();
                }}
                startIcon={<Iconify icon="material-symbols:arrow-back-ios-new" width={20} height={20} />}
                sx={{
                  px: '5px',
                  py: '10px',
                  height: '36px',
                  textTransform: 'none',
                  borderRadius: '8px',
                  color: '#000',
                  fontSize: '0.875rem',
                  '&:hover': {
                    color: '#000',
                  },
                }}
              >
                Quay lại
              </Button>
              {/* <Button
                variant="text"
                size="large"
                sx={{
                  px: '20px',
                  py: '5px',
                  height: '36px',
                  textTransform: 'none',
                  borderRadius: '8px',
                  color: '#fff',
                  backgroundColor: '#3366FF',
                  fontSize: '0.875rem',
                  boxShadow: '0px 8px 16px rgba(51, 102, 255, 0.24',
                  '&:hover': {
                    color: '#fff',
                    backgroundColor: '#3366FF !important',
                  },
                }}
                disabled
              >
                Cày tiền
              </Button> */}
              <Button
                variant="text"
                size="large"
                onClick={handleRedirectPayment}
                endIcon={<Iconify icon="material-symbols:arrow-forward-ios-rounded" width={20} height={20} />}
                sx={{
                  px: '5px',
                  py: '10px',
                  height: '36px',
                  textTransform: 'none',
                  borderRadius: '8px',
                  color: '#000',
                  fontSize: '0.875rem',
                  '&:hover': {
                    color: '#000',
                  },
                }}
              >
                Nạp tiền
              </Button>
            </Stack>
          </Grid>
          {isMobile ? (
            ''
          ) : (
            <Grid item xs={2}>
              <Image
                sx={{
                  width: '57px',
                }}
                visibleByDefault
                disabledEffect
                src="/assets/illustrations/illustration_annoy.svg"
                alt="login"
              />
            </Grid>
          )}
        </Grid>
      </Backdrop>
    </div>
  );
}

PaymentWarning.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
};
