import { Box, Collapse, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import useBoolean from '../../../../hooks/useBoolean';

// ===========================================================================

// ===========================================================================

function ScheduleItem({ title, icon, content, isOpen = false, disabled = false, onSendNow, color }) {
  const viewMore = useBoolean(isOpen);

  return (
    <Stack mb={2} gap={1} {...(onSendNow && { onClick: onSendNow })}>
      <Stack
        sx={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
        direction={'row'}
        alignItems={'center'}
        gap={2}
        mb={1}
        onClick={disabled ? null : viewMore.onToggle}
      >
        {icon}
        {typeof title !== 'string' ? (
          title
        ) : (
          <Typography variant="subtitle2" color={color}>
            {title}
          </Typography>
        )}
      </Stack>
      <Collapse in={viewMore.value}>
        <Box ml={'48px'}>{content}</Box>
      </Collapse>
    </Stack>
  );
}

ScheduleItem.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  content: PropTypes.node,
  isOpen: PropTypes.bool,
  disabled: PropTypes.bool,
  onSendNow: PropTypes.func,
  onSendLater: PropTypes.func,
  color: PropTypes.string,
};

export default ScheduleItem;
