import { Box, Checkbox, Grid, MenuItem, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import TableMoreMenu from '../TableMoreMenu';

CustomMobileRow.propTypes = {
  columns: PropTypes.array,
  actions: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  row: PropTypes.object,
  displayCheckBox: PropTypes.bool,
  selected: PropTypes.bool,
  onCheck: PropTypes.func,
};

export default function CustomMobileRow({ selected = false, displayCheckBox = false, columns, actions, row, onCheck }) {
  const [open, setOpen] = useState(null);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const renderNormalActions = (row) => (
    <>
      {actions?.map((action, index) => (
        <MenuItem
          key={index}
          onClick={() => {
            action.onClick(row);
            handleCloseMenu();
          }}
        >
          {action?.text}
        </MenuItem>
      ))}
    </>
  );

  const renderAdvanceActions = (row) => React.cloneElement(actions, { row });

  const isAdvanceAction = !Array.isArray(actions);

  return (
    <Stack spacing={2} direction="column" sx={{ py: 2.5, px: 3 }}>
      <Stack direction="row" className="containerMobileRow">
        <Box className="overflowTableRow">
          <Stack direction="row" alignItems={'center'} justifyContent="start" maxHeight={40}>
            {displayCheckBox && onCheck && <Checkbox checked={selected} onChange={onCheck} />}
            {actions && (
              <>
                {isAdvanceAction ? (
                  renderAdvanceActions(row)
                ) : (
                  <TableMoreMenu
                    open={open}
                    onOpen={handleOpenMenu}
                    onClose={handleCloseMenu}
                    actions={renderNormalActions(row)}
                  />
                )}
              </>
            )}
          </Stack>
          <Grid container alignItems="flex-start" rowSpacing={1}>
            {columns?.map((column) => {
              const rowContentType = typeof row[column.key];
              return (
                <Grid key={column.key} item container alignItems="center">
                  <Grid item sx={{ width: '73px' }}>
                    <p className="mobileTableHeader">{column?.label}:</p>
                  </Grid>

                  <Grid item sx={{ width: 'calc(100% - 73px)', paddingLeft: '1rem' }}>
                    {['string', 'number'].indexOf(rowContentType) !== -1 ? (
                      <p className="mobileTableContent">{row[column.key]}</p>
                    ) : (
                      row[column.key]
                    )}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Stack>
    </Stack>
  );
}
