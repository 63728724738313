import _ from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { createContext, useEffect, useMemo, useState } from 'react';
import useAuth from '../hooks/useAuth';
import useBoolean from '../hooks/useBoolean';

// hooks
import useRefCustom from '../hooks/useRefCustom';
import useTTToolFunction from '../hooks/useTTToolFunction';
import { EmitterService } from '../utils/event';
import { delayFunc } from '../utils/others';
import { ExtractResponse } from '../utils/tool';
import { saveLink } from '../utils/tool/api';

const initialState = {
  emitterNoty: { on: () => {}, off: () => {}, send: () => {} },
  TTUser: {},
  userStats: {},
  following: null,
  followers: null,
  friends: null,
  recommend: null,
  isFetching: false,
  // Main funcs
  findAuthor: () => {},
  onLoadFollowing: () => {},
  onLoadFollowers: () => {},
  onLoadFriends: () => {},
  onLoadRecommend: () => {},
  onLoadStats: () => {},
  checkExtensionActive: () => {},
  getExtensionVersion: () => {},
  stopQueue: () => {},
};

const QUEUE_NAME = {
  FOLLOWING: 'FOLLOWING',
  FOLLOWER: 'FOLLOWER',
  FRIENDS: 'FRIENDS',
  SUGGEST: 'SUGGEST',
};

const TTContext = createContext(initialState);

const responseHelper = new ExtractResponse();

// ---------------------- PROPS VALIDATE ---------------------
TTProvider.propTypes = {
  children: PropTypes.any,
};
// -----------------------------------------------------------

function TTProvider({ children }) {
  const [emitterNoty, setEmitterNoty] = useState(new EmitterService());

  const {
    getProfile,
    getStatsAnalytics,
    getFollowing,
    getFollowers,
    getFriends,
    getRecommend,
    checkExtensionActive,
    getExtensionVersion,
    getTikTokDataByLink,
    stopQueue,
  } = useTTToolFunction(
    () => {},
    () => {}
  );

  const { enqueueSnackbar } = useSnackbar();

  const { user: userAuth } = useAuth();

  const [user, setUserRef, userRef] = useRefCustom(null);

  const [userStats, setUserStats, userStatsRef] = useRefCustom(null);

  const [following, setFollowing, followingRef] = useRefCustom(null);

  const [followers, setFollowers, followersRef] = useRefCustom(null);

  const [friends, setFriends, friendsRef] = useRefCustom(null);

  const [recommend, setRecommend, recommendRef] = useRefCustom(null);

  const [isFetching, setIsFetching, isFetchingRef] = useRefCustom(false);

  const [, setFetchQueues, fetchQueuesRef] = useRefCustom([]);

  useEffect(() => {
    setUserRef(userAuth);
  }, [userAuth]);

  // Tiktok user data
  const [TTUser, setTTUser, TTUserRef] = useRefCustom(null);

  useEffect(() => {
    const handleMessage = async (event) => {
      const { payload, type } = event?.data || {};
      const link = payload?.data?.link;
      if (type === 'R_SAVE_LINK' && link) {
        console.log(link);
        const regex = /https:\/\/www\.tiktok\.com\/@[\w.-]+\/video\/\S*/;
        if (regex.test(link)) {
          console.log('Use just copy tiktok link: ', link);
          const resp = await getTikTokDataByLink(link);
          const isValid = resp?.author && resp?.videoId;
          if (isValid) {
            const { success, message } = await saveLink(link, resp, false, 'tiktok');
            if (success) {
              emitterNoty.send('link-saved');
            } else if (message) {
              enqueueSnackbar(message, { variant: 'error' });
            }
          }
        }
      }

      if (type === 'R_ADS_DATA') {
        if (payload?.data) {
          checkAdsData(payload);
        }
      }
    };
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const getLinkToVideo = (data) => {
    try {
      const {
        videoId,
        author: { uniqueId },
      } = data;
      return `https://tiktok.com/@${uniqueId}/video/${videoId}`;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const checkAdsData = async (payload) => {
    try {
      if (payload?.data) {
        const ads = responseHelper.extractTiktokAds(payload);
        console.log(ads);
        const results = (ads || [])?.filter((item) => item?.author && item?.videoId);
        let promises = results?.map(async (item) => ({ link: getLinkToVideo(item), ...item }));
        const listUri = await Promise.all(promises);

        promises = listUri?.map(async (item) => {
          const { link, ...mainData } = item;
          if (link) {
            const { success } = await saveLink(link, mainData, true, 'tiktok');
            if (success) {
              return success;
            }
          }
          return null;
        });
        const successList = (await Promise.all(promises)).filter((item) => item);
        if (successList?.length !== 0) {
          emitterNoty.send('ads-saved');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  //  ---------------------------------------------------------------------

  const addToQueues = (name) => {
    const fetchQueues = fetchQueuesRef?.current || [];
    setFetchQueues(_.uniq([...fetchQueues, name]));
  };

  const removeQueue = (name) => {
    const fetchQueues = fetchQueuesRef?.current || [];
    const excluded = fetchQueues?.filter((queue) => queue !== name);
    setFetchQueues(excluded);
  };

  const allowRunQueue = (name) => {
    const fetchQueues = fetchQueuesRef?.current || [];
    const index = fetchQueues?.indexOf(name);
    if (index === -1) {
      addToQueues(name);
    }
    return index === -1;
  };

  //  ---------------------------------------------------------------------

  const findAuthor = (uid) => {
    try {
      const data = [
        ...(followingRef?.current || []),
        ...(followersRef?.current || []),
        ...(friendsRef?.current || []),
        ...(recommendRef?.current || []),
      ];
      return data?.find((item) => item?.uid === uid);
    } catch (error) {
      return null;
    }
  };

  const onGetProfile = async () => {
    if (!isFetchingRef?.current) {
      setIsFetching(true);
      const profile = await getProfile();
      if (!TTUserRef?.current) {
        setTTUser(profile);
      }
      setIsFetching(false);
      await onLoadStats();
    }
  };

  const onLoadStats = async (days = 7) => {
    if (!isFetchingRef?.current) {
      setIsFetching(true);
      const stats = await getStatsAnalytics(days);
      setUserStats(stats);
      setIsFetching(false);
    }
  };

  const onListenUsers = (name = 'following', response = null) => {
    if (response) {
      const users = ([response] || [])?.reduce((res, item) => [...res, ...(item?.userList || [])], []);
      const data = ExtractResponse?.extractUserList(users);
      // console.log('onListenFollowing data', data);
      switch (name) {
        case 'following':
          {
            const current = followingRef?.current || [];
            const temp = _.uniqBy([...current, ...data], 'uid');
            setFollowing(temp);
          }
          break;
        case 'followers':
          {
            const current = followersRef?.current || [];
            const temp = _.uniqBy([...current, ...data], 'uid');
            setFollowers(temp);
          }
          break;
        case 'friends':
          {
            const current = friendsRef?.current || [];
            const temp = _.uniqBy([...current, ...data], 'uid');
            setFriends(temp);
          }
          break;
        case 'recommend':
          {
            const current = recommendRef?.current || [];
            const temp = _.uniqBy([...current, ...data], 'uid');
            setRecommend(temp);
          }
          break;
        default:
          break;
      }
    }
  };

  const onLoadFollowing = async (amount = null, returnData = true) => {
    if (allowRunQueue(QUEUE_NAME.FOLLOWING)) {
      setIsFetching(true);
      const uid = TTUserRef?.current?.uid;
      if (uid) {
        const data = await getFollowing(uid, amount, (res) => {
          onListenUsers('following', res);
        });
        setFollowing(data);
        if (returnData) {
          return data;
        }
      }
      setIsFetching(false);
      removeQueue(QUEUE_NAME.FOLLOWING);
    }
    if (returnData) {
      return [];
    }
  };

  const onLoadFollowers = async (amount = null, returnData = true) => {
    // xuanhiepreviewbc
    if (allowRunQueue(QUEUE_NAME.FOLLOWER)) {
      setIsFetching(true);
      const uid = TTUserRef?.current?.uid;
      if (uid) {
        const data = await getFollowers(uid, amount, (res) => {
          onListenUsers('followers', res);
        });
        setFollowers(data);
        if (returnData) {
          return data;
        }
      }
      setIsFetching(false);
      removeQueue(QUEUE_NAME.FOLLOWER);
    }
    if (returnData) {
      return [];
    }
  };

  const onLoadFriends = async (amount = null, returnData = true) => {
    // xuanhiepreviewbc
    if (allowRunQueue(QUEUE_NAME.FRIENDS)) {
      setIsFetching(true);
      const uid = TTUserRef?.current?.uid;
      if (uid) {
        const data = await getFriends(uid, amount, (res) => {
          onListenUsers('friends', res);
        });
        setFriends(data);
        if (returnData) {
          return data;
        }
      }
      setIsFetching(false);
      removeQueue(QUEUE_NAME.FRIENDS);
    }
    if (returnData) {
      return [];
    }
  };

  const onLoadRecommend = async (amount = null, returnData = true) => {
    // xuanhiepreviewbc
    if (allowRunQueue(QUEUE_NAME.SUGGEST)) {
      setIsFetching(true);
      const uid = TTUserRef?.current?.uid;
      if (uid) {
        const data = await getRecommend(uid, amount, (res) => {
          onListenUsers('recommend', res);
        });
        setFriends(data);
        if (returnData) {
          return data;
        }
      }
      setIsFetching(false);
      removeQueue(QUEUE_NAME.SUGGEST);
    }
    if (returnData) {
      return [];
    }
  };

  useEffect(() => {
    if (user) {
      onGetProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  //  ---------------------------------------------------------------------
  return (
    <TTContext.Provider
      value={useMemo(
        () => ({
          // Emmiter
          emitterNoty,
          TTUser,
          userStats,
          following,
          followers,
          friends,
          recommend,
          isFetching,
          // Main funcs
          findAuthor,
          onLoadFollowing,
          onLoadFollowers,
          onLoadFriends,
          onLoadRecommend,
          onLoadStats,
          checkExtensionActive,
          getExtensionVersion,
          stopQueue,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [emitterNoty, TTUser, userStats, following, followers, friends, recommend, isFetching]
      )}
    >
      {children}
    </TTContext.Provider>
  );
}

export { TTProvider, TTContext };
