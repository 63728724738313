import { Button, Menu } from '@mui/material';
import PropTypes from 'prop-types';
import { cloneElement, useState } from 'react';
import Iconify from '../../../../components/Iconify';

// ----------------------------------------------------------------------

DropdownWrapper.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
};

// ----------------------------------------------------------------------

export default function DropdownWrapper({ label, children, ...other }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const chidlrenModify = cloneElement(children, {
    onClose: handleClose,
  });

  return label ? (
    <>
      <Button
        variant="outlined"
        onClick={handleClick}
        endIcon={<Iconify icon="ri:arrow-down-s-fill" />}
        fullWidth
        {...other}
      >
        {label}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ maxWidth: { xs: '100%', sm: 500 } }}
        PaperProps={{
          style: {
            maxWidth: '80vw',
          },
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {chidlrenModify}
      </Menu>
    </>
  ) : (
    chidlrenModify
  );
}
