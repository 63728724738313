import PropTypes from 'prop-types';
// @mui
import { Box, Stack, Typography } from '@mui/material';
// assets
import { UploadIllustration } from '../../assets';

// ----------------------------------------------------------------------
// ---------------------- PROPS VALIDATE ---------------------
BlockContent.propTypes = {
  title: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
  imageWidth: PropTypes.number,
};
// -----------------------------------------------------------
export default function BlockContent({
  title = 'Tối thiểu 3 ảnh - Tối đa 6 ảnh',
  text1 = '- Kéo thả hoặc nhấn chọn ảnh',
  text2 = '- Dung lượng ảnh tối đa 3Mb',
  text3 = '- Ảnh đầu tiên từ trái qua sẽ là ảnh bìa',
  imageWidth = 220,
}) {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction={{ xs: 'column', md: 'row' }}
      sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
    >
      <UploadIllustration sx={{ width: imageWidth }} />

      <Box sx={{ p: { xs: 0, sm: 3 } }}>
        <Typography gutterBottom variant="h5">
          {title}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {text1}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {text2}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {text3}
        </Typography>
      </Box>
    </Stack>
  );
}
