import PropTypes from 'prop-types';
// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { Autocomplete, TextField } from '@mui/material';

// ----------------------------------------------------------------------

RHFAutoComplete.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  initValue: PropTypes.string,
  required: PropTypes.bool,
};
// eslint-disable-next-line no-unused-vars
export default function RHFAutoComplete({ name, label, initValue = '', required = false, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          onChange={(event, newValue) => field.onChange(newValue)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => <TextField {...params} label={label} error={!!error} helperText={error?.message} />}
          {...other}
        />
      )}
    />
  );
}
