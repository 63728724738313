import PropTypes from 'prop-types';
/* eslint-disable */
import { useSnackbar } from 'notistack';
import { createContext, useEffect, useRef, useState } from 'react';
import useAuth from '../hooks/useAuth';
import useBoolean from '../hooks/useBoolean';
import usePostMessageRequest from '../hooks/usePostMessageRequest';
import useRefCustom from '../hooks/useRefCustom';
import { EmitterService } from '../utils/event';
import { ExtractResponse } from '../utils/tool';
import { delayFunc, generateCombinations, randomInRange } from '../utils/others';
import { reduceMinutes } from '../utils/formatTime';
import { matchProduct } from '../utils/tool/api';

// In seconds
const CANCEL_MSG = `Canceling tasks!`;
const ABORT_MSG = 'Operation canceled';

const TOTAL_DELAY = 40;

const DEFAULT_COMMENTS = 10;

const DEFAULT_LIKE = 10;

const initialState = {
  // Emitter
  emitter: { on: () => {}, off: () => {}, send: () => {} },
  // Funcs
  getProfile: () => {},
  getFollowing: () => {},
  getFollowers: () => {},
  getFriends: () => {},
  getRecommend: () => {},
  searchVideosByKeyword: () => {},
  getVideosOfUser: (username, amount, callback) => {},
  commentVideo: (username, videoId, text) => {},
  interactVideo: (username, videoId, type) => {},
  repostVideo: (username, videoId) => {},
  followUser: (username) => {},
  stopQueue: () => {},
  getExtensionVersion: () => {},
  checkExtensionActive: () => {},
  getStatsAnalytics: () => {},
  interactFunc: () => {},
  getTikTokDataByLink: () => {},
  getCommentsOfVideo: () => {},
  leadsSeedingSavedLink: () => {},
};

const TTFunctionContext = createContext(initialState);

// ---------------------- PROPS VALIDATE ---------------------
TTFunctionProvider.propTypes = {
  children: PropTypes.any,
};
// -----------------------------------------------------------

function TTFunctionProvider({ children }) {
  const { sendRequest } = usePostMessageRequest();

  const [emitter, setEmitter] = useState(new EmitterService());

  const responseHelper = new ExtractResponse();

  const slowdown = useBoolean();

  const { enqueueSnackbar } = useSnackbar();

  const { user } = useAuth();

  const [, setUser, userRef] = useRefCustom(null);

  useEffect(() => {
    setUser(user);
  }, [user]);

  // Control flow, queue, tasks
  let controller = useRef(null);
  let signal = useRef(null);

  const getExtensionVersion = async () => {
    const payload = await sendRequest('GET_VERSION');
    return payload;
  };

  const checkExtensionActive = async () => {
    return await sendRequest('CHECK_ACTIVE_EXTENSION');
  };

  // Get current tiktok profile
  const getProfile = async () => {
    try {
      const response = await sendRequest('GET_PROFILE');
      if (response) {
        const {
          userInfo: { stats, user },
        } = response;
        return {
          image: user?.avatarMedium,
          name: `@${user?.uniqueId}`,
          uid: user?.uniqueId,
          stats,
        };
      }
      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  // Get current following
  const getFollowing = async (uid, amount, callback) => {
    try {
      if (!uid) {
        return [];
      }
      const response = await sendRequest('GET_FOLLOWING', { uid, amount, stream: true }, null, null, callback);
      const users = (response || [])?.reduce((res, item) => [...res, ...(item?.userList || [])], []);
      return ExtractResponse?.extractUserList(users);
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  // Get current followers
  const getFollowers = async (uid, amount, callback) => {
    try {
      if (!uid) {
        return [];
      }
      const response = await sendRequest('GET_FOLLOWERS', { uid, amount, stream: true }, null, null, callback);
      const users = (response || [])?.reduce((res, item) => [...res, ...(item?.userList || [])], []);
      return ExtractResponse?.extractUserList(users);
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  // Get current friends
  const getFriends = async (uid, amount, callback) => {
    try {
      if (!uid) {
        return [];
      }
      const response = await sendRequest('GET_FRIENDS', { uid, amount, stream: true }, null, null, callback);
      const users = (response || [])?.reduce((res, item) => [...res, ...(item?.userList || [])], []);
      return ExtractResponse?.extractUserList(users);
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  // Get current recommend
  const getRecommend = async (uid, amount, callback) => {
    try {
      if (!uid) {
        return [];
      }
      const response = await sendRequest('GET_RECOMMEND', { uid, amount, stream: true }, null, null, callback);
      const users = (response || [])?.reduce((res, item) => [...res, ...(item?.userList || [])], []);
      return ExtractResponse?.extractUserList(users);
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  // Get videos by keyword
  const searchVideosByKeyword = async (keyword, amount = 100, callback = () => {}) => {
    try {
      if (!keyword) {
        return [];
      }
      const response = await sendRequest(
        'SEARCH_VIDEOS',
        { keyword, amount, stream: true },
        signal?.current,
        null,
        (data) => {
          const formartedData = responseHelper.extractSearchVideos([data]);
          callback(formartedData);
        }
      );
      return responseHelper?.extractSearchVideos(response || [])?.slice(0, amount);
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const getVideosOfUser = async (username, amount = 100, callback = () => {}) => {
    try {
      if (!username) {
        return [];
      }
      const response = await sendRequest(
        'USER_VIDEOS',
        { username, amount, stream: true },
        signal?.current,
        null,
        (data) => {
          const formartedData = responseHelper.extractUserVideos([data]);
          console.log(formartedData);
          if (callback) {
            callback(formartedData);
          }
        }
      );
      return responseHelper?.extractUserVideos(response || [])?.slice(0, amount);
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const getCommentsOfVideo = async (username, videoId, amount = 10, callback = () => {}) => {
    try {
      if (!username || !videoId) {
        return [];
      }
      const response = await sendRequest(
        'COMMENTS_OF_VIDEO',
        { username, videoId, amount, stream: true },
        signal?.current,
        null,
        (data) => {
          const formartedData = responseHelper.extractCommentsOfVideo([data]);
          if (callback) {
            callback(formartedData);
          }
        }
      );
      return responseHelper?.extractCommentsOfVideo(response || [])?.slice(0, amount);
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  // comment video
  const commentVideo = async (username, videoId, text) => {
    try {
      if (!username || !videoId || !text) {
        return false;
      }
      return await sendRequest('COMMENT_VIDEO', { username, videoId, text }, signal?.current, 100);
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  // Comment video
  const commentMultiple = async (username, videoId, data) => {
    try {
      if (!username || !videoId || !data) {
        return false;
      }
      const timeout = data?.amount ? data?.amount * 60 : 60;
      return await sendRequest('COMMENT_MULTIPLE_VIDEO', { username, videoId, data }, signal?.current, timeout);
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  // Like comments in video
  const likeComments = async (username, videoId, amount) => {
    try {
      if (!username || !videoId || !amount) {
        return false;
      }
      const timeout = amount ? amount * 40 : 60;
      return await sendRequest('LIKE_COMMENTS', { username, videoId, amount }, signal?.current, timeout);
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  // Inbox user
  const inboxUser = async (userId, text) => {
    try {
      if (!userId || !text) {
        return false;
      }
      return await sendRequest('INBOX_USER', { userId, text }, signal?.current, 60);
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  // follow user
  const followUser = async (username) => {
    try {
      if (!username) {
        return false;
      }
      return await sendRequest('FOLLOW_USER', { username }, signal?.current);
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  // follow interact video
  const interactVideo = async (username, videoId, type) => {
    try {
      if (!username || !videoId || !type) {
        return false;
      }
      const VALID_TYPE = ['LIKE', 'SAVE'];
      if (VALID_TYPE?.indexOf(type) === -1) {
        console.log('[interactVideo]: Invalid type');
        return false;
      }
      return await sendRequest('INTERACT_VIDEO', { username, videoId, interactType: type }, signal?.current, 40);
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  // repost video
  const repostVideo = async (username, videoId) => {
    try {
      if (!username || !videoId) {
        return false;
      }
      return await sendRequest('REPOST', { username, videoId }, signal?.current, 40);
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  // view video
  const viewProfile = async (username) => {
    try {
      if (!username) {
        return false;
      }
      return await sendRequest('VIEW_PROFILE', { username }, signal?.current, 40);
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const getTikTokDataByLink = async (link) => {
    try {
      const response = await sendRequest('FETCH', {
        endpoint: link,
        method: 'GET',
        headers: {},
        returnRaw: true,
      });
      return responseHelper.extractTiktokDataAtLinkData(response);
    } catch (error) {
      return null;
    }
  };

  // get stats from analytics
  const getStatsAnalytics = async (days = 30) => {
    try {
      const start = reduceMinutes(new Date(), 60 * 24 * days);
      start.setHours(0, 0, 0, 0);

      const end = new Date();
      end.setHours(0, 0, 0, 0);

      const resp = await sendRequest(
        'GET_ANALYTICS_DETAIL',
        { start: start?.getTime(), end: end?.getTime() },
        signal?.current,
        40
      );

      return responseHelper.extractUserStats(resp);
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const extractSpinData = (item) => {
    const { files, content } = item;
    // Get spin content
    let modifiedContent = content;
    if (item?.spin && item?.spin?.length !== 0) {
      let spinData = item?.spin?.pop();
      // Update head
      item?.spin?.unshift(spinData);
      if (spinData) {
        Object.keys(spinData).forEach((key) => {
          modifiedContent = modifiedContent.replace(`{{${key}}}`, spinData[key]);
        });
      }
    }
    return { files, content: modifiedContent };
  };

  const getRandomContent = (comBinationsContents) => {
    const item = comBinationsContents[randomInRange({ from: 0, to: comBinationsContents?.length - 1 })];
    return extractSpinData(item);
  };

  const interactFunc = async (data, callback) => {
    try {
      restartSignal();

      const { targetId, video, delay, actions, contents, userId } = data || {};

      const excludesData = { ...data?.excludesData };

      const comBinationsContents = contents.map((item) => ({
        ...item,
        spin: generateCombinations(item?.spinContents || {}),
      }));

      const ACTIONS = {
        COMMENT: 'comment',
        FOLLOW: 'follow',
        LIKE: 'like',
        SAVE: 'save',
        REPOST: 'repost',
        PROFILE: 'profile',
        INBOX: 'inbox',
      };

      // Check actions need execute
      const settings = {
        allowComment: actions?.indexOf(ACTIONS?.COMMENT) !== -1,
        allowFollow: actions?.indexOf(ACTIONS?.FOLLOW) !== -1,
        allowLike: actions?.indexOf(ACTIONS?.LIKE) !== -1,
        allowSave: actions?.indexOf(ACTIONS?.SAVE) !== -1,
        allowRepost: actions?.indexOf(ACTIONS?.REPOST) !== -1,
        allowProfile: actions?.indexOf(ACTIONS?.PROFILE) !== -1,
        allowInbox: actions?.indexOf(ACTIONS?.INBOX) !== -1,
      };

      // Tasks
      const tasks = [];

      // Comment video
      if (settings.allowComment && video?.id) {
        const { content } = getRandomContent(comBinationsContents);
        const commentTask = async () => {
          const res = await commentVideo(targetId, video?.id, content);
          callback({
            targetId,
            target: video?.author,
            result: { comment: { success: !!res } },
          });
        };
        tasks.push(commentTask);
      }

      // Follow
      if (settings.allowFollow && targetId) {
        const followTask = async () => {
          const res = await followUser(targetId);
          callback({
            targetId,
            target: video?.author,
            result: { follow: { success: !!res } },
          });
        };
        tasks.push(followTask);
      }

      // Like
      if (settings.allowLike && video?.id) {
        const likeTask = async () => {
          const res = await interactVideo(targetId, video?.id, 'LIKE');
          callback({
            targetId,
            target: video?.author,
            result: { like: { success: !!res } },
          });
        };
        tasks.push(likeTask);
      }

      if (settings.allowProfile) {
        const viewProfileTask = async () => {
          const res = await viewProfile(targetId);
          callback({
            targetId,
            target: video?.author,
            result: { view_profile: { success: !!res } },
          });
        };
        tasks.push(viewProfileTask);
      }

      if (settings.allowRepost && video?.id) {
        const repostTask = async () => {
          const res = await repostVideo(targetId, video?.id);
          callback({
            targetId,
            target: video?.author,
            result: { repost: { success: !!res } },
          });
        };
        tasks.push(repostTask);
      }
      if (settings.allowSave && video?.id) {
        const saveTask = async () => {
          const res = await interactVideo(targetId, video?.id, 'SAVE');
          callback({
            targetId,
            target: video?.author,
            result: { save: { success: !!res } },
          });
        };
        tasks.push(saveTask);
      }

      if (settings?.allowInbox) {
        // Inbox
        const inboxTask = async () => {
          const skipInbox = excludesData?.inbox?.indexOf(userId) !== -1;

          if (skipInbox || !userId) {
            callback({
              targetId,
              target: video?.author,
              result: { inbox: { success: false } },
            });
            return;
          }

          const { content } = getRandomContent(comBinationsContents);
          console.log('Inbox', targetId, content);

          const sent = await inboxUser(userId, content);
          callback({
            targetId,
            target: video?.author,
            result: { inbox: { success: !!sent } },
          });
          excludesData?.inbox?.push(userId);
        };
        tasks.push(inboxTask);
      }

      for (const [taskIndex, task] of tasks.entries()) {
        if (signal?.current?.aborted) {
          throw new Error(CANCEL_MSG);
        }
        await task();

        const isLastTask = tasks?.length - 1 === taskIndex;
        if (!isLastTask) {
          await delayFunc(randomInRange(delay) * 1000, signal?.current);
        }
      }

      return {};
    } catch (error) {
      console.log(error?.message);
      if ([ABORT_MSG, CANCEL_MSG]?.some((item) => error?.message?.indexOf(item) !== -1)) {
        return { isOperationCanceled: true };
      }
      return {};
    }
  };

  const leadsSeedingSavedLink = async (data, callback, markAsExceededLimit, markAsRunning) => {
    try {
      // Restart signal
      restartSignal();
      markAsRunning();

      const ACTIONS = {
        FOLLOW: 'follow',
        INBOX: 'inbox',
        COMMENT: 'comment',
        HEART: 'heart',
      };

      const { contents, leads, actions, actionAmount } = data;
      const excludesData = { ...data?.excludesData };

      // Check actions need execute
      const settings = {
        allowFollow: actions?.indexOf(ACTIONS?.FOLLOW) !== -1,
        allowInbox: actions?.indexOf(ACTIONS?.INBOX) !== -1,
        allowComment: actions?.indexOf(ACTIONS?.COMMENT) !== -1,
        allowLike: actions?.indexOf(ACTIONS?.HEART) !== -1,
      };

      const baseNumber = Math.floor(TOTAL_DELAY / (actions?.length || 1));
      const delay = { from: baseNumber, to: baseNumber };

      const tasks = [];

      leads?.forEach((lead) => {
        const actorId = lead?.uid;
        const otherTasks = [];

        // Comment video
        if (settings.allowComment) {
          const item = contents[randomInRange({ from: 0, to: contents?.length - 1 })];
          const commentTask = async () => {
            const videoId = lead?.parent?.videoId;
            const skipComment = excludesData?.comment?.indexOf(videoId) !== -1;
            if (skipComment) {
              callback({
                uid: lead?.uid,
                result: { comment: { success: false } },
              });
              return;
            }
            // Amount comments we will comment "amount"
            const res = await commentMultiple(lead?.parent?.uniqueId, videoId, {
              ...item,
              amount: actionAmount || DEFAULT_COMMENTS,
            });
            callback({
              uid: lead?.uid,
              result: { comment: { success: !!res, amount: res?.commentsPosted || 0 } },
            });
            excludesData?.comment?.push(videoId);
          };
          otherTasks.push(commentTask);
        }

        if (settings?.allowFollow) {
          // Follow
          const followTask = async () => {
            const skipFollow = excludesData?.follow?.indexOf(actorId) !== -1;
            if (skipFollow || !lead?.uid) {
              callback({
                uid: lead?.uid,
                result: { follow: { success: false } },
              });
              return;
            }
            console.log('Follow', lead?.uid);
            const res = await followUser(lead?.uniqueId);
            callback({
              uid: lead?.uid,
              result: { follow: { success: !!res } },
            });
            excludesData?.follow?.push(actorId);
          };
          otherTasks.push(followTask);
        }

        // Like
        const videoId = lead?.parent?.videoId;
        if (settings.allowLike && videoId) {
          const likeTask = async () => {
            const key = `${lead?.parent?.uniqueId}_${videoId}`;
            const skipLike = excludesData?.like?.indexOf(key) !== -1;
            if (skipLike) {
              callback({
                uid: lead?.uid,
                result: { like: { success: false } },
              });
              return;
            }

            const res = await likeComments(lead?.parent?.uniqueId, videoId, actionAmount || DEFAULT_LIKE);
            callback({
              uid: lead?.uid,
              result: { like: { success: !!res, amount: res?.liked } },
            });
            excludesData?.like?.push(key);
          };
          otherTasks.push(likeTask);
        }

        if (settings?.allowInbox) {
          // Inbox
          const inboxTask = async (data) => {
            const skipInbox = excludesData?.inbox?.indexOf(actorId) !== -1;

            if (skipInbox || !lead?.authorId) {
              callback({
                uid: lead?.uid,
                result: { inbox: { success: false } },
              });
              return;
            }
            console.log('Inbox', lead?.authorId, data?.text, data);

            let text = data?.text;
            // Send greeting message
            if (data?.greeting) {
              text = `${data?.greeting}\n${text}`;
            }

            const sent = await inboxUser(lead?.authorId, text);
            callback({
              uid: lead?.uid,
              result: { inbox: { success: !!sent } },
            });
            excludesData?.inbox?.push(actorId);
          };
          otherTasks.push(inboxTask);
        }

        const AISpinContent = async () => {
          if (!settings?.allowInbox) {
            return null;
          }
          const { result: res, isLimitAIRequest } = await matchProduct(contents, lead?.content, lead?.parent, '');
          if (isLimitAIRequest) {
            markAsExceededLimit();
            throw new Error('AISpinContent: Got exceeded limit AI request');
          }
          let product = null;
          if (res && res?.length !== 0) {
            const radIndex = randomInRange({ from: 0, to: res?.length - 1 });
            product = res[radIndex];
          }
          return product;
        };

        tasks.push({ AISpinContent, otherTasks });
      });

      for (const [taskIndex, task] of tasks.entries()) {
        if (signal?.current?.aborted) {
          throw new Error(CANCEL_MSG);
        }

        const content = await task?.AISpinContent();
        const { description, baseContent, images, comment, greeting } = content || {};
        const data = {
          text: baseContent,
          files: images || [],
          comment,
          greeting,
        };

        for (const [subTaskIndex, subTask] of task?.otherTasks.entries()) {
          if (signal?.current?.aborted) {
            throw new Error(CANCEL_MSG);
          }
          const res = await subTask(data);
          const isLastTask = task?.otherTasks?.length - 1 === subTaskIndex && tasks?.length - 1 === taskIndex;
          if (!res?.skip && !isLastTask) {
            await delayFunc(randomInRange(delay) * 1000, signal?.current);
          }
        }
      }

      return {};
    } catch (error) {
      console.log('leadsSeeding', error, error?.message);
      // Detect stop
      if ([ABORT_MSG, CANCEL_MSG]?.some((item) => error?.message?.indexOf(item) !== -1)) {
        return { isOperationCanceled: true };
      }
      return {};
    }
  };

  // ===================================================================
  const restartSignal = () => {
    // Restart signal
    const newController = new AbortController();
    controller.current = newController;
    signal.current = newController?.signal;

    signal?.current?.addEventListener('abort', () => {
      throw new Error(ABORT_MSG);
    });
  };

  const onStop = (reason) => {
    if (['checkpoint', 'retry']?.indexOf(reason) !== -1) {
      emitter.send(reason);
    }
  };

  // Stop current queue
  const stopQueue = async (sendMessage = false, reason = '') => {
    try {
      if (reason === 'checkpoint' || reason === 'manual') {
        controller?.current?.abort();
        emitter?.send('stop');
      }
      if (sendMessage) {
        onStop(reason);
      }
      return { reason };
    } catch (error) {
      console.log(error);
      return { reason: error?.message };
    } finally {
      slowdown.onFalse();
    }
  };

  return (
    <TTFunctionContext.Provider
      value={{
        getProfile,
        getStatsAnalytics,
        getFollowing,
        getFollowers,
        getFriends,
        getRecommend,
        searchVideosByKeyword,
        getVideosOfUser,
        commentVideo,
        interactVideo,
        repostVideo,
        followUser,
        stopQueue,
        getExtensionVersion,
        checkExtensionActive,
        getTikTokDataByLink,
        getCommentsOfVideo,
        interactFunc,
        leadsSeedingSavedLink,
        // Emitter
        emitter,
      }}
    >
      {children}
    </TTFunctionContext.Provider>
  );
}

export { TTFunctionProvider, TTFunctionContext };
