import { Backdrop } from '@mui/material';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
// hooks
import useLocales from '../hooks/useLocales';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

// ---------------------- PROPS VALIDATE ---------------------
LoadingProgress.propTypes = {
  loading: PropTypes.bool,
  value: PropTypes.number,
};
// -----------------------------------------------------------

export default function LoadingProgress({ loading, value }) {
  const { translate } = useLocales();

  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999 }} open={loading}>
      <Box sx={{ width: '50%' }}>
        <h1>{translate('progress')}</h1>
        <LinearProgressWithLabel value={value || 0} />
      </Box>
    </Backdrop>
  );
}
