import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';

const initialState = {
  feed: null,
  result: null,
};

const slice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    updateFeedResult(state, action) {
      const { feed, result } = action.payload;
      state.feed = feed;
      state.result = result;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export async function updateFeedResult(feedSetting, result) {
  dispatch(slice.actions.updateFeedResult({ feed: feedSetting, result }));
}
