import PropTypes from 'prop-types';
// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { FormControlLabel, Switch } from '@mui/material';

// ----------------------------------------------------------------------

RHFSwitch.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onChangeValue: PropTypes.func,
};

export default function RHFSwitch({ name, disabled = false, onChangeValue = () => {}, ...other }) {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Switch
              {...field}
              checked={field.value}
              disabled={disabled}
              onChange={(e) => {
                field.onChange(e.target.checked);
                onChangeValue(name, e.target.checked);
              }}
            />
          )}
        />
      }
      {...other}
    />
  );
}
