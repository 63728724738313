import { Typography, MenuItem } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import Iconify from '../../../components/Iconify';
import useAuth from '../../../hooks/useAuth';
import axiosInstance from '../../../utils/axios';
import { numberWithCommas } from '../../../utils/formatNumber';

// ====================================================
const DEFAULT_AFFILIATE_INFO = {
  withdraw: null,
  totalCommission: null,
};
const WITHDRAWABLE = ['pending', 'unpaid'];
const WITHDRAWABLE_STATUSES = WITHDRAWABLE.join(',');
const TOTAL_COMMISSION_STATUSES = ['accepted', 'paid', ...WITHDRAWABLE].join(',');
// ====================================================

export default function AffiliateInfo() {
  const { user } = useAuth();

  const [affiliateInfo, setAffiliateInfo] = useState(DEFAULT_AFFILIATE_INFO);

  const { is_affiliate: isAffiliate } = user?.referral || {};

  const getAffiliateValueByStatuses = async (statuses = '') => {
    try {
      const { data } = await axiosInstance?.get(`api/v2/tool/affiliate/amount?statuses=${statuses}`);
      return data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  const onLoadAffiliateAmount = async () => {
    try {
      const withdrawData = await getAffiliateValueByStatuses(WITHDRAWABLE_STATUSES);
      const totalCommissionData = await getAffiliateValueByStatuses(TOTAL_COMMISSION_STATUSES);
      if (withdrawData?.success && totalCommissionData?.success) {
        setAffiliateInfo((prev) => ({
          ...prev,
          totalCommission: totalCommissionData?.result,
          withdraw: withdrawData?.result,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isAffiliate) {
      onLoadAffiliateAmount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAffiliate]);

  const getStringValue = (value) => (typeof value === 'number' ? numberWithCommas(value) : '_');

  const renderAmount = useMemo(() => {
    const { withdraw, totalCommission } = affiliateInfo;
    const withdrawDataValue = getStringValue(withdraw);
    const totalCommissionValue = getStringValue(totalCommission);
    return (
      <MenuItem
        sx={{
          justifyContent: 'start',
          m: 1,
        }}
      >
        <Iconify icon={`tabler:currency-dong`} sx={{ width: 12, height: 12, mr: 1 }} />
        <Typography>{withdrawDataValue}</Typography>
        <Typography component="span">/</Typography>
        <Typography>{totalCommissionValue}</Typography>
      </MenuItem>
    );
  }, [affiliateInfo]);

  return isAffiliate && renderAmount;
}
