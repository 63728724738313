import { alpha, Checkbox, MenuItem, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import TableMoreMenu from '../TableMoreMenu';

CustomTableRow.propTypes = {
  columns: PropTypes.array,
  actions: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  row: PropTypes.object,
  displayCheckBox: PropTypes.bool,
  selected: PropTypes.bool,
  onCheck: PropTypes.func,
};

export default function CustomTableRow({ selected = false, displayCheckBox = false, columns, actions, row, onCheck }) {
  const [open, setOpen] = useState(null);

  const theme = useTheme();

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const renderNormalActions = (row) => (
    <>
      {actions.map((action, index) => (
        <MenuItem
          key={index}
          onClick={() => {
            action.onClick(row);
            handleCloseMenu();
          }}
        >
          {action?.text}
        </MenuItem>
      ))}
    </>
  );

  const renderAdvanceActions = (row) => React.cloneElement(actions, { row });

  const isAdvanceAction = !Array.isArray(actions);

  // row?.selected can be data of row. Yo ucan custom base on it
  return (
    <TableRow selected={selected} sx={{ ...(row?.selected && { bgcolor: alpha(theme.palette.secondary.main, 0.08) }) }}>
      {displayCheckBox && onCheck && (
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onChange={onCheck} />
        </TableCell>
      )}
      {columns?.map((column) => (
        <TableCell key={column.key} align={column?.align || 'left'}>
          {row[column.key]}
        </TableCell>
      ))}
      {isAdvanceAction ? (
        <>{actions && <TableCell align="right">{renderAdvanceActions(row)}</TableCell>}</>
      ) : (
        <>
          {actions && actions?.length > 0 && (
            <TableCell align="right">
              <TableMoreMenu
                open={open}
                onOpen={handleOpenMenu}
                onClose={handleCloseMenu}
                actions={renderNormalActions(row)}
              />
            </TableCell>
          )}
        </>
      )}
    </TableRow>
  );
}
