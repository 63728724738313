import { createSlice } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  categories: [],
  entities: [],
  settings: [],
  // For run schedule as background task if have clone assets
  asset: null, // Contain info of asset + childAssets
  scheduleInfo: {
    channel: {
      settings: [],
      sites: [],
    },
    date: null,
    datetime: null,
  },
  emailSource: null,
  assetsClone: [],
};

const slice = createSlice({
  name: 'source',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET SOURCE CATEGORIES
    getSourceCategoriesSuccess(state, action) {
      state.isLoading = false;
      state.categories = uniqBy([...state.categories, ...action.payload], 'id');
    },

    // GET SOURCE ENTITIES
    getSourceEntitiesSuccess(state, action) {
      state.isLoading = false;
      state.entities = uniqBy([...state.entities, ...action.payload], 'id');
    },

    // GET CHANNEL SETTING
    getChannelSettingsSuccess(state, action) {
      state.isLoading = false;
      state.settings = uniqBy([...action.payload, ...state.settings], 'id');
    },

    // GET CLONE ASSET FROM WEBSOCKET
    getCloneAssetsSuccess(state, action) {
      const {
        result_data: { asset_uuid: cloneUUID, asset_type: cloneType },
        extra_data: { asset_uuid: parentUUID, type: actionType },
      } = action.payload;
      if (actionType === 'clone') {
        const resultClone = {
          uuid: cloneUUID,
          type: cloneType,
        };
        // Check completed clone assets
        if (parentUUID === state.asset?.uuid) {
          const temp = uniqBy([...state.assetsClone, resultClone], 'uuid');
          state.assetsClone = [...temp];
        }
      }
    },
    // DELETE SETTING
    deleteChannelSettingsSuccess(state, action) {
      state.settings = uniqBy([...state.settings.filter((item) => item?.id !== action.payload)], 'id');
    },

    // Set schedule info
    setScheduleInfo(state, action) {
      const { asset, emailSource, scheduleInfo } = action?.payload;
      state.asset = asset;
      state.scheduleInfo = scheduleInfo;
      state.emailSource = emailSource || null;
    },

    // Set schedule info
    // eslint-disable-next-line no-unused-vars
    resetScheduleData(state, action) {
      state.asset = initialState.asset;
      state.assetsClone = initialState.assetsClone;
      state.scheduleInfo = initialState.scheduleInfo;
      state.emailSource = initialState.emailSource;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

/**
 * If notification from websocket noty?.data?.request_data?.extra_data?.type === 'clone'
 * Payload is notification from websocket noty?.data?.request_data
 *  */

export async function resetScheduleData() {
  dispatch(slice.actions.resetScheduleData());
}

export async function createCloneAssetSuccess(requestData) {
  dispatch(slice.actions.getCloneAssetsSuccess(requestData));
}

export async function setScheduleInfo(channel, date, datetime, emailSource, asset) {
  dispatch(slice.actions.setScheduleInfo({ scheduleInfo: { channel, date, datetime }, emailSource, asset }));
}

export async function getSourceCategories(url, params) {
  const response = await axios.get(url, { params });
  if (response?.data) {
    const { results, next } = response.data;
    dispatch(slice.actions.getSourceCategoriesSuccess(results));

    if (next && undefined === params.page) {
      // first call
      const { count } = response.data;
      const pageSize = results.length;
      const concurrency = Math.ceil(count / pageSize);
      for (let page = 2; page <= concurrency; page += 1) {
        (async () => {
          params.page = page;
          await getSourceCategories(url, params);
        })();
      }
    }
  }
}

export async function getSourceEntities(url, params) {
  const response = await axios.get(url, { params });
  if (response?.data) {
    const { results, next } = response.data;
    dispatch(slice.actions.getSourceEntitiesSuccess(results));

    if (next && undefined === params.page) {
      // first call
      const { count } = response.data;
      const pageSize = results.length;
      const concurrency = Math.ceil(count / pageSize);
      for (let page = 2; page <= concurrency; page += 1) {
        (async () => {
          params.page = page;
          await getSourceEntities(url, params);
        })();
      }
    }
  }
}

export async function getChannelSettings(url, params) {
  const response = await axios.get(url, { params });
  if (response?.data) {
    const { results, next } = response.data;
    dispatch(slice.actions.getChannelSettingsSuccess(results));

    if (next && undefined === params.page) {
      // first call
      const { count } = response.data;
      const pageSize = results.length;
      const concurrency = Math.ceil(count / pageSize);
      for (let page = 2; page <= concurrency; page += 1) {
        (async () => {
          params.page = page;
          await getChannelSettings(url, params);
        })();
      }
    }
  }
}

export async function updateChannelSettings(newSetting) {
  dispatch(slice.actions.getChannelSettingsSuccess([newSetting]));
}

export async function removeChannelSettings(id) {
  dispatch(slice.actions.deleteChannelSettingsSuccess(id));
}
