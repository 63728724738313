import { useContext } from 'react';
import { StatsGuardContext } from '../../contexts/StatsGuardContext';

const useStatsGuard = () => {
  const context = useContext(StatsGuardContext);

  if (!context) throw new Error('StatsGuardContext context must be use inside StatsGuardProvider');

  return context;
};

export default useStatsGuard;
