import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useEffect, useState, useRef, useMemo } from 'react';
// validate lib + form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
// mui
import { LoadingButton } from '@mui/lab';
import { Grid, Stack, Typography } from '@mui/material';
// components
import { FormProvider, RHFAutoCompleteChip } from '../../../../../components/hook-form';
import Iconify from '../../../../../components/Iconify';
// hooks
import Loading from '../../../../../components/Loading';
import useAuth from '../../../../../hooks/useAuth';
import useBoolean from '../../../../../hooks/useBoolean';
import useLocales from '../../../../../hooks/useLocales';
import useTranslateCatalog from '../../../../../hooks/useTranslateCatalog';
import { DropdownWrapper } from '../../components';
import { getEstimateCost } from '../helper';
import AgeDropdown from './AgeDropdown';
import { GENDERS } from '../../common';

const ResultBlock = styled('div')((props) => ({
  color: props.color,
  backgroundColor: props.bgColor,
  filter: `drop-shadow(3px 3px 6px ${props.bgColor})`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0.2rem',
  borderRadius: '0.5rem',
  width: '50%',
  height: '3rem',
  fontSize: '1rem',
  fontWeight: '700',
}));

const DropdownWrapperSx = {
  flex: 1,
  minWidth: '49.5%',
  color: '#637381',
  fontSize: '1rem',
  textTransform: 'none',
  fontWeight: 400,
  borderColor: 'rgba(145, 158, 171, 0.32)',
  justifyContent: 'start',
  '.MuiButton-endIcon': { ml: 'auto' },
  flexShrink: 0,
};

const AGE_DEFAULT_VALUE = { range: [0, 100], all: true };

const numberWithDot = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

AudienceChoice.propTypes = {
  onChangeValue: PropTypes.func,
  currentValues: PropTypes.object,
};

export default function AudienceChoice({ onChangeValue, currentValues }) {
  const { enqueueSnackbar } = useSnackbar();

  const { translate: tForm, currentLang } = useLocales('dashboard.ads_marketing.form');

  const { translate: tButton } = useLocales('dashboard.ads_marketing.btn');

  const { industries, nations, cities } = useAuth();

  const costDebounce = useRef(null);

  const loading = useBoolean();

  const disabledSubmit = useBoolean();

  // Nationality
  const modifedNations = nations?.map((item) => ({ ...item, value: item?.id, label: item?.name }));

  // Industry
  const { result: translatedIndustries } = useTranslateCatalog(industries, 'industries');
  const modifedIndustries = translatedIndustries?.map((item) => ({ ...item, value: item?.id }));

  const { result: translatedCities } = useTranslateCatalog(cities, 'city');

  // Genders
  const { result: translatedGenders } = useTranslateCatalog(GENDERS, 'genders');

  // Vietnam Locations
  const modifiedLocations = translatedCities?.map((item) => item?.label);

  const NotificationSchema = Yup.object().shape({
    locations: Yup.array(),
    nations: Yup.array(),
    industries: Yup.array(),
    genders: Yup.array(),
  });

  const defaultValues = useMemo(
    () =>
      currentValues?.form || {
        locations: [],
        nations: [],
        industries: [],
        genders: [],
      },
    [currentValues]
  );

  const [result, setResult] = useState({
    totalFee: 0,
    totalPeople: 0,
    adsIds: [],
    filter: {},
    form: defaultValues,
  });

  const [rangeAge, setRangeAge] = useState(AGE_DEFAULT_VALUE);

  const currentAgeLabel = useMemo(() => {
    let txtAge = '';
    if (rangeAge?.all) {
      txtAge = tForm('field.all');
    } else {
      const minString = rangeAge?.range[0] === 0 ? `${tForm('field.age.below')} ` : `${rangeAge?.range[0]} -`;
      txtAge = `${minString}${rangeAge?.range[1]} ${tForm('field.age.sub_title')}`;
    }
    return `${tForm('field.age.title')}: ${txtAge}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rangeAge, currentLang]);

  const methods = useForm({
    resolver: yupResolver(NotificationSchema),
    defaultValues,
  });

  const {
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = () => {
    onChangeValue(result);
  };

  const handleResetAge = () => {
    setRangeAge(AGE_DEFAULT_VALUE);
  };

  const handleChangeAge = (newValue) => {
    setRangeAge(newValue);
  };

  const calcCost = async () => {
    try {
      loading.onTrue();
      const genderValues = values?.genders.map((item) => item?.value);
      const isAllGenders = genderValues?.length === 0 || genderValues?.indexOf('all') !== -1;

      if (isAllGenders && genderValues?.length > 0) {
        setValue('genders', []);
        return;
      }

      const filter = {
        locations: values?.locations,
        nations: values?.nations.map((item) => item?.value),
        platforms: null,
        genders: isAllGenders ? [] : genderValues,
        industries: values?.industries.map((item) => item?.value),
        age_min: null,
        age_max: null,
      };

      if (!rangeAge.all) {
        filter.age_min = rangeAge.range[0];
        filter.age_max = rangeAge.range[1];
      }

      const { success, data, msg } = await getEstimateCost(filter);

      if (!success) {
        enqueueSnackbar(msg, { variant: 'error' });
      }

      const { estimate_cost: estimateCost, ads_ids: adsIds, audience } = data || {};
      setResult({
        totalFee: estimateCost || 0,
        totalPeople: audience || 0,
        adsIds,
        form: values,
        filter: { ...filter, allAge: rangeAge?.all },
      });
      clearInterval(costDebounce.current);
    } catch (error) {
      console.log(error);
    } finally {
      loading.onFalse();
      disabledSubmit.onFalse();
    }
  };

  useEffect(() => {
    if (costDebounce) {
      clearInterval(costDebounce.current);
    }

    disabledSubmit.onTrue();

    costDebounce.current = setTimeout(() => {
      calcCost();
    }, 1000);

    return () => clearInterval(costDebounce.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.genders, values.industries, values.locations, values.nations, rangeAge]);

  useEffect(() => {
    if (!isEmpty(currentValues)) {
      const {
        filter: { allAge, age_min: ageMin, age_max: ageMax },
      } = { ...currentValues };
      if (allAge) {
        setRangeAge(AGE_DEFAULT_VALUE);
      } else {
        setRangeAge({ range: [ageMin, ageMax], all: false });
      }
    }
  }, [currentValues]);

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} sx={{ minHeight: '50vh' }}>
            <Typography variant="subtitle2" color="#637381">
              {tForm('label.demographic')}
            </Typography>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }} mt={2}>
              <RHFAutoCompleteChip
                name="nations"
                options={modifedNations}
                label={tForm('field.nation')}
                freeSolo={false}
              />
              <RHFAutoCompleteChip
                name="industries"
                options={modifedIndustries}
                label={tForm('field.industry')}
                freeSolo={false}
              />
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }} mt={2}>
              <DropdownWrapper sx={DropdownWrapperSx} label={currentAgeLabel}>
                <AgeDropdown currentvalue={rangeAge} onReset={handleResetAge} onSubmit={handleChangeAge} />
              </DropdownWrapper>
              <RHFAutoCompleteChip
                name="genders"
                options={translatedGenders}
                label={tForm('field.gender')}
                freeSolo={false}
              />
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }} mt={2}>
              <RHFAutoCompleteChip name="locations" options={modifiedLocations} label={tForm('field.location')} />
            </Stack>

            <Stack
              direction="row"
              justifyContent={{ xs: 'space-between', md: 'end' }}
              spacing={{ xs: 3, sm: 2 }}
              mt="2rem"
            >
              <Stack direction="row" sx={{ width: { xs: '100%', md: '50%' } }} spacing={2}>
                <ResultBlock color="#212B36" bgColor="#FFC107">
                  <>{result?.totalPeople}</>
                  <Iconify icon={'material-symbols:person'} width={20} height={20} />
                </ResultBlock>

                <ResultBlock color="white" bgColor="#3366FF">
                  <>{numberWithDot(result?.totalFee)}</>
                  <Iconify icon={'mdi:dollar'} width={20} height={20} />
                </ResultBlock>
              </Stack>
            </Stack>
          </Grid>

          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting || disabledSubmit.value}
            sx={{
              ml: 'auto',
              mt: 2,
            }}
            disabled={disabledSubmit.value}
          >
            {tButton('next')}
          </LoadingButton>
        </Grid>
      </FormProvider>
      <Loading loading={loading.value} />
    </>
  );
}
