import { useMemo } from 'react';
import useAdsCampaign from '../../../../../hooks/useAdsCampaign';
import SkipForm from '../../multi-channel/content/SkipForm';

// ============================================================
// ============================================================
AdsContent.propTypes = {};

export default function AdsContent() {
  const { onNextStep, step2, onPreviousStep } = useAdsCampaign();

  const source = useMemo(() => step2 || null, [step2]);

  const handleGetSourceSocial = (socialAsset) => {
    onNextStep(socialAsset);
  };

  return <SkipForm onBack={onPreviousStep} onCreatedSuccess={handleGetSourceSocial} source={source} />;
}
