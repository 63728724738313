import _ from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Tooltip,
  Typography,
  useTheme,
  Link,
} from '@mui/material';
// utils
import cssStyles from '../../../utils/cssStyles';
import { fDateTime } from '../../../utils/formatTime';
// components
import { IconButtonAnimate } from '../../../components/animate';
import Avatar from '../../../components/Avatar';
import Iconify from '../../../components/Iconify';
import MenuPopover from '../../../components/MenuPopover';
import { addNewNoty, getNotifications, getPopupList, markNotiAsRead } from '../../../redux/slices/notification';
import { useDispatch, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';

// hooks
import useAuth from '../../../hooks/useAuth';
import useLocales from '../../../hooks/useLocales';
import useWebSocket from '../../../hooks/useWS';
import createAvatar from '../../../utils/createAvatar';
import useToolFunction from '../../../hooks/useToolFunction';
import useTenant from '../../../hooks/useTenant';
// ----------------------------------------------------------------------

const LIMIT_NOTY_NUMBER = 10;
const NOTY_ADVANCE_LOGOUT = 'noty_advance_logout';

// ----------------------------------------------------------------------

const colors = {
  green: '#00AB55',
  red: '#FF4842',
};
const keywords = {
  'thất bại': colors.red,
  'xóa lịch': colors.red,
  'không thành công': colors.red,
  'lên lịch': colors.green,
  'chỉnh sửa': colors.green,
  'cập nhật': colors.green,
  'thành công': colors.green,
  'thay đổi nội dung': colors.green,
  'tạo bài viết': colors.green,
  'tạo tin': colors.green,
  'tích hợp': colors.green,
  'sử dụng chức năng': colors.green,
};

const WHITELABEL = 'fbtool';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const { translate } = useLocales('header.notification');

  const { translate: tGlobal } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const { stopQueue } = useToolFunction();

  const [newNoty, setNewNoty] = useState(false);

  const { wsClient, advanceLogout } = useWebSocket();

  const { bellNotiList, totalUnread } = useSelector((state) => state.noti);

  const navigate = useNavigate();

  const [open, setOpen] = useState(null);

  useEffect(() => {
    dispatch(getNotifications(WHITELABEL));
    dispatch(getPopupList(WHITELABEL));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!_.isEmpty(newNoty)) {
      const isExactDomain = !!newNoty?.origin && newNoty?.origin?.indexOf(window.location.hostname) !== -1;

      if (newNoty?.snackbar && isExactDomain) {
        const link = newNoty?.link;
        const options = { variant: newNoty.variant, autoHideDuration: 5000 };
        if (link) {
          options.action = (
            <Link target="_blank" href={link} color="secondary" size="small">
              {tGlobal('tool.btn.more')}
            </Link>
          );
        }
        enqueueSnackbar(newNoty.description, options);
      }
      if (newNoty?.type === NOTY_ADVANCE_LOGOUT && isExactDomain) {
        advanceLogout();
        stopQueue();
        // Reload force => data go away
        window.location.reload();
      } else {
        dispatch(addNewNoty(newNoty));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newNoty]);

  const handleOpen = (event) => {
    if (totalUnread > 0) {
      // Mark as read all notifications
      dispatch(markNotiAsRead([]));
    }
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleViewNotifications = () => {
    handleClose();
    navigate(PATH_DASHBOARD.user.notification);
  };

  useEffect(() => {
    if (wsClient) {
      const handleMessage = (message) => {
        if (message) {
          setNewNoty(message);
        }
      };

      wsClient?.on('message', handleMessage);

      return () => {
        wsClient?.off('message', handleMessage);
      };
    }
  }, [wsClient]);

  return (
    <>
      <IconButtonAnimate color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={totalUnread} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          width: 360,
          p: 0,
          mt: 1.5,
          ml: 0.75,
          overflowY: 'auto',
          height: bellNotiList.length > LIMIT_NOTY_NUMBER / 2 ? '73%' : 'fit-content',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">{translate('notification')}</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {totalUnread > 0 && translate('unread').replace('#', totalUnread)}
            </Typography>
          </Box>

          {/* <Tooltip title={translate('all_notification')}>
            <IconButton color="default" onClick={handleViewNotifications}>
              <Iconify icon="basil:eye-solid" sx={{ color: '#000' }} />
            </IconButton>
          </Tooltip> */}
        </Box>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <List disablePadding>
          {bellNotiList.map((notification) => (
            <NotificationItem key={notification?.id} notification={notification} handleClose={handleClose} />
          ))}
        </List>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.any,
};

function NotificationItem({ notification }) {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { translate } = useLocales('dashboard.notification');
  const { currentTenant } = useSelector((state) => state.tenant);
  const { currentLogo } = useTenant();

  const colorKeywordText = (verb) => {
    if (!verb) {
      return verb;
    }

    const keys = Object.keys(keywords);
    const getObject = keys.reduce(
      (result, curr, index) => {
        const indexKey = result.textReplaced.indexOf(curr);
        if (indexKey !== -1) {
          const colorText = (
            <Typography
              className="colored"
              key={`${curr}${index}`}
              display="inline"
              variant="subtitle2"
              color={keywords[curr]}
            >
              {curr}
              &nbsp;
            </Typography>
          );
          result.textReplaced = result.textReplaced.replaceAll(curr, `|${index}|`);
          result.results.push({
            index,
            component: colorText,
          });
        }
        return { ...result };
      },
      { textReplaced: verb, results: [] }
    );

    const { textReplaced, results } = getObject;
    const result = textReplaced.split(' ');
    return result.map((item) => {
      if (/^\|\d+\|$/g.test(item)) {
        const index = +item.split('|')[1];
        const instance = results.find((item) => item?.index === index);
        return instance.component;
      }
      return `${item} `;
    });
  };

  const renderContent = (notification, user) => {
    const areYou = notification?.actor_name === user?.name || notification?.actor_name === user?.email;

    const getUserInfo = () => {
      const description = areYou ? (
        <Typography key={notification?.id} display="inline" variant="string">
          {colorKeywordText(notification?.verb)}
          <Typography display="inline" variant="string" fontWeight={'bold'}>
            {notification?.target_name || notification?.description}
          </Typography>
        </Typography>
      ) : (
        <Typography key={notification?.id} display="inline" variant="string" fontWeight={'bold'}>
          {notification?.target_name || notification?.description}
        </Typography>
      );

      return {
        name: areYou ? translate('you') : _.get(notification?.actor_name, ''),
        avatar: areYou ? user?.image : currentTenant?.config?.logo || currentLogo,
        description,
      };
    };

    const title = (
      <Typography variant="subtitle2">
        {getUserInfo().name}
        <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
          &nbsp;{getUserInfo().description}
        </Typography>
      </Typography>
    );

    return {
      avatar: areYou ? user?.image : currentTenant?.config?.logo || currentLogo,
      title,
    };
  };

  const { avatar, title } = renderContent(notification, user);

  const onClickNoty = async (event, notyId) => {
    const link = notification?.data?.link;
    if (link) {
      window.open(link);
    }

    if (notification.data.is_read || Object.prototype.hasOwnProperty.call(notification.data, 'expiration')) {
      return;
    }

    dispatch(markNotiAsRead([notyId]));
  };

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...((!notification.data.is_read || Object.prototype.hasOwnProperty.call(notification.data, 'expiration')) && {
          bgcolor: 'action.selected',
        }),
      }}
      onClick={(event) => onClickNoty(event, notification.id)}
    >
      <ListItemAvatar>
        <Avatar
          src={avatar}
          alt={notification?.actor_name}
          color={notification?.actor_name ? createAvatar(notification?.actor_name).color : 'default'}
        >
          {createAvatar(notification?.actor_name).name}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fDateTime(new Date(notification.timestamp))}
          </Typography>
        }
      />
    </ListItemButton>
  );
}
