import _ from 'lodash';
import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useBoolean from '../../../../../hooks/useBoolean';
import useFBContext from '../../../../../hooks/useFBContext';
import useToolContext from '../../../../../hooks/useToolContext';
import useToolFunction from '../../../../../hooks/useToolFunction';
import { convertStoryIdToId } from '../../../../../utils/others';
import { createSocialContent, syncUserJobs } from '../../../../../utils/tool/api';
import useRefCustom from '../../../../../hooks/useRefCustom';
import useAuth from '../../../../../hooks/useAuth';
import { categorizeFiles } from '../../../../../utils/fileUtils';
import useLocales from '../../../../../hooks/useLocales';
import { useStatsGuard } from '../../../../../hooks/lead';

const LIMIT_POSTS = 25;

const initialState = {
  LIMIT_POSTS,
  comments: [],
  isRunning: false,
  postQueues: [],
  isRemovePendingPost: {
    value: true,
    onTrue: () => {},
    onFalse: () => {},
    onToggle: () => {},
  },
  isLimitPosts: {
    value: false,
    onTrue: () => {},
    onFalse: () => {},
    onToggle: () => {},
  },
  amountPendingPostsAllow: '',
  onCreateComment: () => {},
  onAppendComments: () => {},
  onRemoveCommentsByCollection: () => {},
  onChangeValue: () => {},
  onSelectComment: () => {},
  onStartUpPost: () => {},
  onCancel: () => {},
  onRemovePendingPosts: () => {},
  onChangeAmountPendingPostsAllow: (value) => {},
};

const REMOVE_PENDING_CYCLE_DELAY = 15 * 60 * 1000;

const PostCommentContext = createContext(initialState);

// ---------------------- PROPS VALIDATE ---------------------
PostCommentProvider.propTypes = {
  children: PropTypes.any,
};
// -----------------------------------------------------------

function PostCommentProvider({ children }) {
  const [postQueues, setPostQueues] = useState([]);

  const { translate } = useLocales('tool');

  const isRunning = useBoolean();

  const { user } = useAuth();

  const [, setUserRef, userRef] = useRefCustom(null);

  const { canUseTier } = useStatsGuard();

  useEffect(() => {
    setUserRef(user);
  }, [user]);

  const [removePendingPost, setRemovePendingPost, removePendingPostRef] = useRefCustom(false);

  const isRemovePendingPost = useMemo(
    () => ({
      value: removePendingPostRef?.current,
      onTrue: () => {
        setRemovePendingPost(true);
      },
      onFalse: () => {
        setRemovePendingPost(false);
      },
      onToggle: () => {
        setRemovePendingPost(!removePendingPostRef?.current);
      },
    }),
    [removePendingPost]
  );

  const [limitPosts, setLimitPosts, limitPostsRef] = useRefCustom(true);

  const isLimitPosts = useMemo(
    () => ({
      value: limitPostsRef?.current,
      onTrue: () => {
        setLimitPosts(true);
      },
      onFalse: () => {
        setLimitPosts(false);
      },
      onToggle: () => {
        setLimitPosts(!limitPostsRef?.current);
      },
    }),
    [limitPosts]
  );

  const [amountPendingPostsAllow, setAmountPendingPostsAllow, amountPendingPostsAllowRef] = useRefCustom('');

  const { formatPresets, FBUser } = useFBContext();

  const [, setFBUserRef, FBUserRef] = useRefCustom('');

  const { checkCommonData, updateTaskRunningStatus, onLoadLatestAssets } = useToolContext();

  const { emitter, postToGroupsWithMultiContent, postSeedingComments, infiniteRemovePendingPosts, stopQueue } =
    useToolFunction();

  const [, setCurrentJob, currentJobRef] = useRefCustom(null);

  const [, setBannerList, bannerListRef] = useRefCustom([]);

  useEffect(() => {
    if (emitter) {
      const handleMessage = () => {
        isRunning.onFalse();
      };

      emitter?.on('stop', handleMessage);
      emitter?.on('checkpoint', handleMessage);

      return () => {
        emitter?.off('stop', handleMessage);
        emitter?.off('checkpoint', handleMessage);
      };
    }
  }, [emitter]);

  useEffect(() => {
    if (formatPresets?.length !== 0) {
      setBannerList(formatPresets);
    }
  }, [formatPresets]);

  useEffect(() => {
    setFBUserRef(FBUser);
  }, [FBUser]);

  const onChangeAmountPendingPostsAllow = (value) => {
    setAmountPendingPostsAllow(value);
  };

  const sendUpdateJobAPI = async () => {
    const currentValue = currentJobRef?.current || {};
    const isValidData = Object?.keys(currentValue)?.some(
      (jobId) => currentValue[jobId] && Object.keys(currentValue[jobId])?.length !== 0
    );
    if (isValidData) {
      const payload = {
        facebook_uid: FBUserRef?.current?.uid || null,
        data: currentValue,
      };
      await syncUserJobs(payload);
    }
    setCurrentJob(null);
  };

  const onComplete = () => {
    isRunning.onFalse();
    sendUpdateJobAPI();
  };

  const syncJobs = (jobId, groupId, postId) => {
    try {
      console.log('sync', jobId, groupId, postId);
      if (currentJobRef?.current) {
        const temp = { ...currentJobRef?.current };
        const currentValue = temp[jobId][groupId] || [];
        temp[jobId][groupId] = _.uniq([...currentValue, postId]);
        setCurrentJob(temp);
      }
    } catch (error) {
      console.log('syncJobs', error);
    }
  };

  const storageAssets = (assetUUIDs) => {
    setCurrentJob(assetUUIDs?.reduce((res, uuid) => ({ ...res, [uuid]: {} }), {}));
  };

  // Multi contents + comment callback
  const multiContentCallback = (payload) => {
    const { result, groupId, success, content, type, isWaitingForApprove, linkComment, jobId, error } = payload || {};
    // Detect group have problem
    if (error) {
      setPostQueues((prevState) => {
        const newArray = [...prevState];
        const index = newArray.findIndex((item) => item?.groupId === groupId);
        if (index !== -1) {
          newArray[index] = { ...newArray[index], warning: true, error };
        }
        return [...newArray];
      });
      return;
    }

    let link = linkComment || null;
    let isSuccess = success;
    if (success && type !== 'comment') {
      const { legacy_story_hideable_id: postId } = result?.data?.story_create?.story || {
        legacy_story_hideable_id: convertStoryIdToId(result?.data?.story_create?.story_id),
      };
      if (!postId) {
        isSuccess = false;
      } else {
        link = `https://www.facebook.com/groups/${groupId}/permalink/${postId}/`;
        if (jobId) {
          syncJobs(jobId, groupId, postId);
        }
      }
    }
    setPostQueues((prevState) => {
      const newArray = [...prevState];
      const index = newArray.findIndex((item) => item?.groupId === groupId);
      if (index !== -1) {
        newArray[index].results?.push({ success: isSuccess, link, content, type, isWaitingForApprove });
      }
      return [...newArray];
    });
  };

  const autoGenerateContent = (content) => {
    const { files, baseLink } = content || {};
    if (baseLink) {
      return translate('post_link', { link: baseLink });
    }

    if (files && files.length !== 0) {
      const { imageFiles, videoFiles } = categorizeFiles(files);
      return translate('post_content', { images: imageFiles?.length, video: videoFiles?.length });
    }

    return `${translate('post_unkown')} ${Date.now()}`;
  };

  const createAssets = async (contents) => {
    try {
      const formatedContents = [];
      // Create assets
      for (const content of contents) {
        const res = await createSocialContent(content?.baseContent || autoGenerateContent(content));
        formatedContents.push({ ...content, jobId: res?.uuid || null });
      }
      return formatedContents;
    } catch (error) {
      console.log('Something wrong while create assets');
      return contents;
    }
  };

  const onPostWithMultiContents = useCallback(
    async (data) => {
      try {
        if (!checkCommonData()) {
          return;
        }

        if (canUseTier(2)) {
          data.skipInfo = {
            amountPendingPostsAllow: amountPendingPostsAllowRef?.current,
          };
          data.isRemovePendingPost = removePendingPostRef?.current;
        }

        // Check limt posts
        if (limitPostsRef?.current) {
          // LIMIT_POSTS
          data.limitPosts = {
            amount: LIMIT_POSTS,
          };
        }

        const { groupIds, contents } = data;
        data.formatPresets = bannerListRef?.current?.map((item) => item?.preset_id) || [];

        isRunning.onTrue();
        setPostQueues([...groupIds?.map((groupId) => ({ groupId, results: [] }))]);

        // Reset current jobs
        setCurrentJob(null);

        // Modify content
        data.contents = await createAssets(contents);
        const jobIds = data.contents?.filter((item) => item?.jobId)?.map((item) => item?.jobId);

        // Check amount jobIds contents
        if (contents?.length !== jobIds?.length) {
          // Build component
          const skip = await window?.showAlert();
          if (!skip) {
            onCancelPost();
            return;
          }
        }

        storageAssets(jobIds);
        postToGroupsWithMultiContent(data, multiContentCallback, onComplete);
        onLoadLatestAssets();
      } catch (error) {
        console.log(error);
      }
    },
    [isRemovePendingPost]
  );

  const seedingCommentCallback = (payload) => {
    const {
      groupId,
      success,
      type,
      feedbackData: { link, content },
      linkComment,
    } = payload || {};
    setPostQueues((prevState) => {
      const newArray = [...prevState];
      const index = newArray.findIndex((item) => item?.groupId === groupId);
      if (index !== -1) {
        newArray[index].results?.push({ success, link: linkComment || link, content, type });
      }
      return [...newArray];
    });
  };

  const onSeedingComments = async (data) => {
    try {
      if (!checkCommonData()) {
        return;
      }
      const { groupIds } = data;
      isRunning.onTrue();
      setPostQueues([...groupIds?.map((groupId) => ({ groupId, results: [] }))]);
      postSeedingComments(data, seedingCommentCallback, isRunning.onFalse);
    } catch (error) {
      console.log(error);
    }
  };

  // ================================== REMOVE PENDING POSTS CYCLE ================================
  const taskRef = useRef();

  const onReset = () => {
    isRunning.onFalse();
    if (taskRef?.current) {
      clearTimeout(taskRef?.current);
    }
  };

  const removePendingPostCallback = (payload) => {
    const { groupId, success, amount, remains } = payload || {};

    console.log(payload);
    setPostQueues((prevState) => {
      const newArray = [...prevState];
      const index = newArray.findIndex((item) => item?.groupId === groupId);
      if (index !== -1) {
        newArray[index].remains = newArray[index]?.remains || remains;
        if (typeof amount === 'number') {
          newArray[index].results?.push({ success, amount });
        }
      }
      checkRemovePendingCompleted(newArray);
      return [...newArray];
    });
  };

  const checkRemovePendingCompleted = (data) => {
    const isCompleted = data?.every((item) => {
      const removed = item?.results
        ?.filter((result) => result?.success)
        ?.reduce((sum, result) => sum + (result?.amount || 0), 0);
      const remains = item?.remains;
      return typeof remains === 'number' && removed >= remains;
    });
    if (isCompleted) {
      onCancelPost();
    }
  };

  const loopTask = async (data, isFirstLoop = false) => {
    const { isOperationCanceled } = await infiniteRemovePendingPosts(
      { ...data, isFirstLoop },
      removePendingPostCallback
    );
    if (isOperationCanceled) {
      console.log('Break cycle!! STOPPED.');
      onReset();
      return;
    }
    console.log('Run next cycle!');
    if (taskRef?.current) {
      clearTimeout(taskRef?.current);
    }

    taskRef.current = setTimeout(() => {
      loopTask(data);
    }, REMOVE_PENDING_CYCLE_DELAY);
  };

  const onRemovePendingPosts = async (data) => {
    try {
      if (!checkCommonData()) {
        return;
      }
      const { groupIds } = data;
      isRunning.onTrue();
      setPostQueues([...groupIds?.map((groupId) => ({ groupId, results: [], type: 'remove_pending_posts' }))]);
      loopTask(data, true);
    } catch (error) {
      console.log(error);
    }
  };

  // ==================================================================

  // CONTROL SUBMIT BUTTON
  const onCancelPost = () => {
    onReset();
    stopQueue(false, 'manual');
    isRunning.onFalse();
    const temp = [...postQueues]?.filter((item) => item?.results && item?.results?.length !== 0);
    setPostQueues([...temp]);
  };

  useEffect(() => {
    updateTaskRunningStatus(isRunning?.value);
  }, [isRunning?.value]);

  return (
    <PostCommentContext.Provider
      value={useMemo(
        () => ({
          LIMIT_POSTS,
          postQueues,
          isRunning: isRunning.value,
          isRemovePendingPost,
          isLimitPosts,
          amountPendingPostsAllow,
          onChangeAmountPendingPostsAllow,
          onCancelPost,
          onSeedingComments,
          onPostWithMultiContents,
          onRemovePendingPosts,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isRunning?.value, isRemovePendingPost?.value, isLimitPosts?.value, amountPendingPostsAllow, postQueues]
      )}
    >
      {children}
    </PostCommentContext.Provider>
  );
}

export { PostCommentProvider, PostCommentContext };
