import { format, getTime, formatDistanceToNow, startOfWeek, endOfWeek } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date, seperator = '/') {
  if (!isValidDate(date)) {
    return '';
  }
  return format(new Date(date), `dd${seperator}MM${seperator}yyyy`);
}

export function fTime(date, timeFormat = 'HH:mm:ss') {
  if (!isValidDate(date)) {
    return '';
  }
  return format(new Date(date), timeFormat);
}

export function fDateTime(date, dateFormat = 'dd/MM/yyyy HH:mm') {
  return format(new Date(date), dateFormat);
}

export function fTimestamp(date) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

function isValidDate(date) {
  return date instanceof Date && !Number.isNaN(date);
}

export const convertDateString = (dateString, timeString) => {
  if (typeof dateString === 'string') {
    const [year, month, date] = dateString.split('/').reverse();
    const [hour, minute, second] = timeString.split(':');

    return new Date(year, month - 1, date, hour, minute, second);
  }
  return false;
};

export const getStartEndOfWeek = (date) => ({ start: startOfWeek(date), end: endOfWeek(date) });

export const isFutureDateTime = (date, time) => {
  const cloneDate = new Date(date.getTime());
  const currentDate = new Date();
  // Compare date
  cloneDate.setHours(0, 0, 0, 0);
  currentDate.setHours(0, 0, 0, 0);
  const diff = cloneDate.getTime() - currentDate.getTime();
  if (diff >= 0) {
    // Compare by time
    const cloneTime = new Date(time.getTime());

    // Same day
    if (diff === 0) {
      return fTime(new Date(), 'HH:mm') < fTime(cloneTime, 'HH:mm');
    }

    return true;
  }
  return false;
};

export function addMinutes(date, minutes) {
  return new Date(date.getTime() + minutes * 60000);
}

export function reduceMinutes(date, minutes) {
  return new Date(date.getTime() - minutes * 60000);
}

export function getNextWeekDay() {
  const today = new Date();
  today.setDate(today.getDate() + 30);
  return today;
}

export function getCurrentTime() {
  const currentTime = new Date();
  const futureTime = new Date(currentTime.getTime() + 30 * 60000); // 30 minutes in milliseconds

  return futureTime;
}

export function getDateInFuture(days) {
  const today = new Date();
  const futureDate = new Date(today);
  futureDate.setDate(today.getDate() + days);
  return futureDate;
}

export function modifyTime(date) {
  if (date) {
    const expiredTime = new Date(date);
    expiredTime.setHours(0);
    expiredTime.setMinutes(0);
    expiredTime.setSeconds(0);
    return expiredTime;
  }
  return new Date();
}

export function getDaysRemain(date) {
  if (!date) {
    return '000';
  }
  const startTime = date;
  const distanceToNow = startTime - new Date();

  const getDays = Math.floor(distanceToNow / (1000 * 60 * 60 * 24));
  return getDays < 0 ? 0 : getDays;
}

function timeString(value, text, appendS = false) {
  const extraString = value > 1 && appendS ? 's' : '';
  return value > 0 ? `${value} ${text}${extraString} ` : '';
}

export function msToTimeString(
  milliseconds,
  dayText = 'day',
  hourText = 'hours',
  minuteText = 'minute',
  secondText = 'second',
  appendS = true
) {
  const seconds = Math.floor((milliseconds / 1000) % 60);
  const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
  const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);
  const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));

  const daysStr = timeString(days, dayText, appendS);
  const hoursStr = timeString(hours, hourText, appendS);
  const minutesStr = timeString(minutes, minuteText, appendS);
  const secondsStr = timeString(seconds, secondText, appendS);

  return `${daysStr}${hoursStr}${minutesStr}${secondsStr}`.trim();
}

/**
 *
 * @param {number} hours
 * @return {Array}
 */
export function getLastXHoursArray(hours) {
  const now = new Date();
  const hoursArray = [];

  for (let i = 0; i <= hours; i += 1) {
    const pastHour = new Date(now?.getTime() - i * 60 * 60 * 1000);
    pastHour.setMinutes(0, 0, 0);
    hoursArray?.push(pastHour);
  }

  return hoursArray?.reverse();
}

/**
 * Compare two dates with modify sub time
 * @param {Date} date1
 * @param {Date} date2
 * @param {number} hours
 * @param {number} minutes
 * @param {number} seconds
 * @return {boolean}
 */
export function isSameDateWith(date1, date2, hours = null, minutes = null, seconds = null) {
  try {
    const objModify = { hours, minutes, seconds };

    const funcs = { hours: 'setHours', minutes: 'setMinutes', seconds: 'setSeconds' };

    Object.keys(objModify)?.forEach((key) => {
      if (objModify[key] !== null && funcs[key]) {
        date1[funcs[key]](objModify[key]);
        date2[funcs[key]](objModify[key]);
      }
    });
    return date1 === date2;
  } catch (error) {
    return false;
  }
}

export function compareTwoDate(date1, date2) {
  try {
    if (date1?.getTime() === date2?.getTime()) {
      return 0;
    }
    return date1?.getTime() > date2?.getTime() ? 1 : -1;
  } catch (error) {
    console.log(error);
    return 'NaN';
  }
}
