import { useRef, useState } from 'react';

export default function useRefCustom(initialValue) {
  const [, setMountNumber] = useState(0);
  const ref = useRef(initialValue);

  const setRefValue = (value) => {
    ref.current = value;
    setMountNumber((prev) => prev + 1);
  };

  return [ref?.current, setRefValue, ref];
}
