// Random emoji
export function getRandomEmoji(amount = 5) {
  const emoji = [
    '🤚',
    '🖐',
    '✋',
    '🖖',
    '👍',
    '🤲',
    '✋🏻',
    '🖐🏻',
    '🤚🏻',
    '👍🏻',
    '👍🏼',
    '👋🏼',
    '👌🏼',
    '🤏🏼',
    '🤟🏼',
    '🤙🏼',
    '👏🏼',
    '💪🏼',
    '🙏🏼',
    '👐🏼',
    '✊🏽',
    '👊🏽',
    '👏🏽',
    '👍🏾',
    '👏🏾',
    '🙏🏾',
    '👏🏿',
  ];
  let result = '';
  for (let index = 0; index < amount; index += 1) {
    result += emoji[Math.floor(Math.random() * emoji.length)];
  }
  return result;
}

// Function to calculate dynamic font size based on image dimensions
function calculateFontSize(canvasWidth, canvasHeight, ratio = 80) {
  const largestDimension = Math.max(canvasWidth, canvasHeight);
  return Math.floor(largestDimension / ratio);
}

// Function to measure the text width for positioning
function measureTextWidth(context, text) {
  return context.measureText(text).width;
}

// Function to randomly select a position for the watermark
function getRandomPosition() {
  // const positions = ['top-left', 'top-right', 'bottom-left', 'bottom-right'];
  const positions = ['center-left', 'center-right'];
  return positions[Math.floor(Math.random() * positions.length)];
}

// Function to get text coordinates based on the selected position
function getTextCoordinates(position, canvasWidth, canvasHeight, textWidth, textHeight) {
  let x;
  let y;
  switch (position) {
    case 'top-left':
      x = 20;
      y = textHeight;
      break;
    case 'top-right':
      x = canvasWidth - textWidth - 20;
      y = textHeight;
      break;
    case 'bottom-left':
      x = 20;
      y = canvasHeight - 20;
      break;
    case 'bottom-right':
      x = canvasWidth - textWidth - 20;
      y = canvasHeight - 20;
      break;
    case 'center-left':
      x = 20;
      y = canvasHeight / 2 - textHeight / 4;
      break;
    case 'center-right':
      x = canvasWidth - textWidth - 20;
      y = canvasHeight / 2 - textHeight / 4;
      break;
    default:
      x = canvasWidth - textWidth - 20;
      y = canvasHeight - 20;
      break;
  }
  return { x, y };
}

// Main function to watermark the image with text
export function watermarkImageWithText(imageFile, watermarkText, opacity = 0.5) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (event) {
      const img = new Image();
      img.src = event.target.result;

      img.onload = function () {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        const canvasWidth = img.width;
        const canvasHeight = img.height;

        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        // Draw the original image on the canvas
        context.drawImage(img, 0, 0, canvasWidth, canvasHeight);

        // Calculate the font size
        const fontSize = calculateFontSize(canvasWidth, canvasHeight);

        // Set the font style
        context.fillStyle = `rgba(0, 0, 255, ${opacity})`; // blue color with opacity
        context.textBaseline = 'middle';
        context.font = `bold ${fontSize}px serif`;

        // Measure text width and height
        const textWidth = measureTextWidth(context, watermarkText);
        const textHeight = fontSize; // Approximate height as the font size

        // Get random position for the text
        const position = getRandomPosition();

        // Get coordinates for the text based on the selected position
        const { x, y } = getTextCoordinates(position, canvasWidth, canvasHeight, textWidth, textHeight);

        // Place the watermark text at the random position
        context.fillText(watermarkText, x, y);

        // Convert the canvas to a blob (File object)
        canvas.toBlob((blob) => {
          if (blob) {
            const watermarkedFile = new File([blob], imageFile.name, { type: imageFile.type });
            resolve(watermarkedFile);
          } else {
            reject(new Error('Failed to create blob from canvas.'));
          }
        }, imageFile.type);
      };

      img.onerror = function () {
        reject(new Error('Failed to load the image.'));
      };
    };

    reader.onerror = function () {
      reject(new Error('Failed to read the file.'));
    };

    reader.readAsDataURL(imageFile);
  });
}
