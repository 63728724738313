import { useState, cloneElement, useMemo } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Iconify from '../Iconify';

// ----------------------------------------------------------------------

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '80vw',
  height: '80vh',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    width: '10px',
    borderRadius: '16px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#f0f0f0',
    borderRadius: '16px',
  },
};

ModalWrapper.propTypes = {
  children: PropTypes.any,
  fitContent: PropTypes.bool,
};

/**
 *
 * @param {Element} children RHFTextField | RHFEditor component
 * @returns Wrapper Popup
 */
export default function ModalWrapper({ children, fitContent = false }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  // 67vh to display input
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pixelNeed = useMemo(() => Math.round(window.innerHeight * 0.67), [window.innerHeight]);

  // Mapping style for RHF component
  const flexStyleByRHFName = {
    RHFEditor: {
      '.ql-editor': {
        maxHeight: pixelNeed,
        height: pixelNeed,
        '&::-webkit-scrollbar': {
          width: '10px',
          borderRadius: '16px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#f0f0f0',
          borderRadius: '16px',
        },
      },
    },
    RHFTextField: {
      '.MuiOutlinedInput-root': {
        pr: open ? 0 : '35px',
        '.MuiInputBase-input': {
          '&::-webkit-scrollbar': {
            width: '10px',
            borderRadius: '16px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#f0f0f0',
            borderRadius: '16px',
          },
        },
      },
    },
  };

  const renderStyle = (children, open) => {
    const name = children?.type?.name;
    const sx = children?.props?.sx;
    if (open) {
      return flexStyleByRHFName[name];
    }
    return sx && name !== 'RHFEditor' ? {} : flexStyleByRHFName[name];
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{ position: 'absolute', top: '12px', right: '12px', cursor: 'pointer', zIndex: 10 }}
        onClick={handleOpen}
      >
        <Iconify width={20} height={20} icon={'icons8:resize-four-directions'} />
      </Box>
      {open ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={{ ...style, ...(fitContent && { height: 'fit-content' }) }}>
              {cloneElement(children, {
                ...children.props,
                rows: Math.round(pixelNeed / 23),
                autoFocus: true,
                sx: { ...children.props?.sx, ...renderStyle(children, open) },
                style: { ...children.props.style },
              })}
            </Box>
          </Fade>
        </Modal>
      ) : (
        cloneElement(children, {
          ...children.props,
          sx: { ...children.props?.sx, ...renderStyle(children, open) },
        })
      )}
    </Box>
  );
}
