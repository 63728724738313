// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

import tiktok from './tiktok/vn';

const vn = {
  months: {
    1: 'Tháng 1',
    2: 'Tháng 2',
    3: 'Tháng 3',
    4: 'Tháng 4',
    5: 'Tháng 5',
    6: 'Tháng 6',
    7: 'Tháng 7',
    8: 'Tháng 8',
    9: 'Tháng 9',
    10: 'Tháng 10',
    11: 'Tháng 11',
    12: 'Tháng 12',
  },
  not_show_today: 'Không hiển thị lại ngày hôm nay',
  not_show_again: 'Không hiển thị',
  server_error: 'Có lỗi xảy ra',
  feature_not_available: 'Tính năng không khả dụng',
  referral_link: 'Link giới thiệu',
  usage_error: 'Vui lòng gia hạn/mua gói để tiếp tục sử dụng',
  update_extension: 'Vui lòng cập nhật phiên bản mới nhất của tiện ích mở rộng',
  duplicate_extension: 'Phát hiện nhiều phiên bản trong tiện ích mở rộng. Cần tải lại để tiếp tục sử dụng',
  upload_media_error: 'Có lỗi xảy ra trong quá trình tải hình ảnh/ video',
  empty: 'Không có dữ liệu',
  complete_infomation: 'Hãy hoàn tất thông tin của bạn để có trải nghiệm tốt nhất.',
  btn: {
    fix: 'Sửa lỗi',
    reload: 'Tải lại',
  },
  error: {
    required: 'Đây là trường bắt buộc',
    format: {
      email: 'Phải nhập đúng định dạng email',
      phone: 'Phải nhập đúng định dạng số điện thoại',
    },
  },
  demo: {
    title: `Vietnamese`,
    introduction: `Lorem Ipsum chỉ đơn giản là văn bản giả của ngành in ấn và sắp chữ. Lorem Ipsum đã trở thành văn bản giả tiêu chuẩn của ngành kể từ những năm 1500, khi một nhà in không xác định lấy một dãy loại và xáo trộn nó để tạo thành một cuốn sách mẫu. Nó đã tồn tại không chỉ năm thế kỷ, mà còn là bước nhảy vọt trong lĩnh vực sắp chữ điện tử, về cơ bản vẫn không thay đổi. Nó được phổ biến vào những năm 1960 với việc phát hành các tờ Letraset chứa các đoạn Lorem Ipsum, và gần đây hơn là với phần mềm xuất bản trên máy tính để bàn như Aldus PageMaker bao gồm các phiên bản của Lorem Ipsum.`,
  },
  docs: {
    hi: `Chào`,
    description: `Cần giúp đỡ？\n Vui lòng xem tài liệu của chúng tôi.`,
    documentation: `tài liệu`,
  },
  commons: {
    day: 'd',
    hour: 'h',
    minute: 'm',
    second: 's',
    package: 'Gói',
    expired: '{{name}} đã hết hạn',
  },
  modal: {
    btn: {
      back: 'Quay lại',
      close: 'Đóng',
      done: 'Hoàn tất',
      next: 'Tiếp theo',
    },
    popup: {
      linking_again: 'Liên kết lại',
    },
    title: {
      error_linking: 'Lỗi tài khoản liên kết',
    },
  },
  image_upload: {
    upload: 'Tải ảnh lên',
    delete: 'Xóa hết',
    update: 'Cập nhật ảnh',
  },
  pwa: {
    title: 'Tính năng nhận thông báo',
    step_one: `1. Bấm vào nút 'Chia sẻ'`,
    step_two: `2. Chọn 'Thêm vào màn hình chính'`,
    content: `
      1.Truy cập trang web của bạn trên trình duyệt Safari của bạn trên thiết bị di động Apple trên 16.4 trở lên.
      2.Nhấp vào nút Chia sẻ của trình duyệt Safari.
      3.Nhấp vào tùy chọn Thêm vào màn hình chính.
      4.Lưu ứng dụng trên thiết bị của bạn.
      5.Mở ứng dụng từ màn hình chính.
      6.Đăng ký nhận thông báo để sử dụng tính năng mới của chúng tôi.
    `,
  },
  form: {
    invalid_format: 'Vui lòng nhập đúng định dạng',
    min: 'Nhập ít nhất {{min}} ký tự',
    max: 'Nhập tối đa {{max}} ký tự',
    min_number: 'Số phải lớn hơn {{min}}',
    required: 'Đây là trường bắt buộc',
    array: {
      min: 'Phải có ít nhất {{min}}',
      max: 'Tối đa {{max}}',
    },
    image: {
      min: 'Tải lên ít nhất {{min}} ảnh',
      max: 'tải lên tối đa {{max}} ảnh',
    },
    nudity: 'Hình ảnh có chứa ảnh khoả thân',
  },
  source_filter: {
    private: 'Cá nhân',
    organization: 'Nội bộ',
    public: 'Cộng đồng',
    legit: 'Chính chủ',
    exclusive: 'Độc quyền',
    all: 'Tất cả',
    btn: {
      apply: 'Áp dụng',
      reset: 'Reset',
    },
  },
  partnership: {
    title: 'Đối tác',
    content: 'Tận hưởng giá trị, hiệu quả, và ưu đãi với tài khoản đối tác trong hệ sinh thái Uptin. ',
  },
  progress: 'Đang trong tiến trình, xin đợi vài giây!',
  feature_disable: 'Bạn không thể sử dụng tính năng này',
  asset_required: 'Bạn phải lưu nội dung trước khi sử dụng tính năng này',
  task_running: 'Tiến trình tương tự đang chạy vui lòng chờ!',
  login: {
    main_title: 'Nền tảng Marketing toàn diện tăng hiệu quả tiếp thị và đột phá doanh thu',
    heading: 'Đăng nhập - Đăng ký Uptin',
    heading_tenant: 'Đăng nhập {{name}}',
    sub_heading: 'Đăng nhập hoặc đăng ký nhanh với',
    by_email: 'Đăng nhập bằng email',
    remember: 'Nhớ  tài khoản',
    forgot: 'Quên mật khẩu',
    test_account: 'Tài khoản dùng thử : {{email}} / Mật khẩu : {{password}}',
    or: 'HOẶC',
    btn: {
      login: 'Đăng nhập',
    },
    form: {
      password: 'Mật khẩu',
    },
    message: {
      invalid: 'Email hoặc mật khẩu không đúng',
      suggest: 'Bạn có thể đăng nhập bằng Google thay cho Email và Mật Khẩu',
      failure: 'Đăng nhập thất bại',
    },
  },
  forgot_password: {
    heading: 'Quên mật khẩu?',
    description:
      'Vui lòng nhập địa chỉ email được liên kết với tài khoản của bạn, chúng tôi sẽ gửi link hướng dẫn đặt lại mật khẩu.',
    form: {
      error: {
        email: {
          required: 'Email là bắt buộc',
          format: 'Phải nhập đúng định dạng email',
        },
      },
    },
    btn: {
      reset: 'Đặt lại mật khẩu',
      back: 'Quay lại',
    },
  },
  reset_password: {
    title: 'Yêu cầu đã gửi thành công!',
    form: {
      field: {
        password: 'Mật khẩu',
        re_password: 'Nhập lại mật khẩu',
      },
      error: {
        password: {
          required: 'Mật khẩu là bắt buộc',
        },
        re_password: {
          required: 'Nhập lại mật khẩu là bắt buộc',
          match: 'Mật khẩu nhập lại chưa khớp',
        },
      },
    },
    btn: {
      change: 'Đổi Mật Khẩu',
    },
  },
  confirm_staff: {
    title: 'Đang tiến hành kiểm tra...',
  },
  nav: {
    general: `Tổng quan`,
    asset: `Tin`,
    calendar: `Lịch`,
    analytic: `Thị trường`,
    push_notification: `Báo`,
    email_marketing: `Email marketing`,
    blog: `Hóng`,
    create_asset: `Soạn tin`,
    real_estate: `Bất động sản`,
    social: `Mạng xã hội`,
    web: 'Website',
    account: `Tài khoản`,
    setting: `Cài đặt`,
    payment: `Thanh toán`,
    change_password: `Đổi mật khẩu`,
    logout: `Đăng xuất`,
    staff: 'Nhân sự',
    campaign: 'Chiến dịch',
    feed: 'Nuôi',
    multi_channel: 'Tiếp thị',
    advertisement: 'Quảng cáo',
    advertisement_new: 'Tạo bài',
    advertisement_manage: 'Quản lý',
    post: 'Đăng bài & Bình luận',
    up_post: 'Úp bài',
    lead: 'Tìm kiếm khách hàng',
    earn: 'Kiếm tiền',
    usage: 'Hướng dẫn sử dụng',
    interactive: 'Tương tác',
    faqs: 'Câu hỏi thường gặp',
    support: 'Hổ trợ Online',
  },
  header: {
    notification: {
      notification: 'Thông báo',
      unread: `Bạn có # thông báo chưa đọc`,
      all_notification: 'Xem tất cả thông báo',
    },
    task: {
      view_result: 'Nhấp vào để xem kết quả',
      empty: 'Chưa có kết quả',
      task_completed: 'Một số tiến trình đã hoàn thành!',
    },
  },
  component: {
    delete_account: {
      content: `ơi, điều gì xảy ra khi bạn "<span style="font-weight: bold">Xóa tài khoản</span>" ?`,
      note: {
        first: 'Uptin chỉ xóa tài khoản đăng nhập',
        second: 'Các tài khoản liên kết với các nền tảng vẫn giữ lại',
      },
      btn: {
        back: 'Quay lại',
        confirm: 'Xác nhận',
      },
    },
    trial: {
      group: 'Bạn làm việc nhóm?',
      enterprise: 'Bạn là chủ doanh nghiệp?',
      try_package: 'Dùng thử gói tháng',
      try_seven_days: 'Dùng thử {{days}} ngày',
      content: 'Số dư tài khoản của bạn có thể được dùng để thanh toán sau.',
      btn: {
        accept: 'Đồng ý',
        cancel: 'Hủy',
      },
    },
  },
  dashboard: {
    app: {
      welcome: 'Chào mừng bạn!',
      content: 'Chọn đăng tin hoặc làm nhiệm vụ để nhận tiền FREE nhé.',
      btn: {
        post: 'Đăng tin ngay',
        linking: 'Liên kết ngay',
        quest: 'Nhiệm vụ',
        subscribe: 'Đăng kí ngay',
        zalo: 'Zalo tư vấn',
      },
      service_mkt: {
        title: 'Trọn gói dịch vụ phòng Marketing thuê ngoài chỉ 2tr500k/tháng.',
        content: `
        <ol>
        <li>Xây dựng <b> 10 cộng đồng khách hàng </b>trung thành xoay quanh sản phẩm/dịch vụ của bạn</li>
        <li>
          Nuôi dưỡng:
          <ul style="padding-left: 24px">
            <li>Viết tới <b> 300 bài content </b> chất lượng chuẩn SEO hàng tháng.</li>
            <li>Chăm sóc Blog trên Website của bạn mỗi ngày 1 bài viết.</li>
            <li> <b>Seeding</b> vào các cộng đồng kéo khách thật tham gia.</li>
          </ul>
        </li>
        <li>Hỗ trợ đăng tin Zalo, Group, Marketplace miễn phí.</li>
        <li>Báo cáo mỗi ngày.</li>
      </ol>
        `,
      },
      tab: {
        day: 'Ngày',
        month: 'Tháng',
      },
      chart: {
        filter: {
          by: 'Theo',
          week: 'Tuần',
          month: 'Tháng',
          year: 'Năm',
        },
        total_post: 'Tổng tin',
        view: 'Lượt xem',
        display: 'Lượt hiển thị',
        statistics: 'Thống kê tháng',
        statistics_posts: 'Thống kê hàng tháng số bài đăng',
        statistics_audience: 'Thống kê hàng tháng số người tiếp cận',
        statistics_sub: 'so với tháng trước',
        spend: 'Thống kê chi tiêu',
        schedule_success: 'Tin đăng thành công',
        schedule_fail: 'Tin lỗi',
        balance: 'Số dư hiện tại',
        payment: 'Nạp',
        disburse: 'Chi tiêu',
        sell: 'Bán',
        rent: 'Thuê',
      },
    },
    account: {
      save: 'Lưu thay đổi',
      send_request: 'Gửi yêu cầu',
      breadcrumb: {
        home: 'Trang chủ',
        user: 'Người dùng',
        setting: 'Cài đặt',
      },
      tab: {
        general: 'Thông tin chung',
        payment: 'Thanh toán',
        sme: 'Gói doanh nghiệp',
        notification: 'Thông báo',
        social: 'Mạng xã hội',
        password: 'Mật khẩu',
      },
      general: {
        avatar: {
          upload: 'Tải ảnh lên',
          update_type: 'Cho phép ảnh đuôi *.jpeg, *.jpg, *.png',
          update_size: 'Dung lượng tối đa 1Mb',
        },
        public: 'Công khai hồ sơ',
        delete: 'Xóa tài khoản',
        form: {
          first_name: 'Tên',
          last_name: 'Họ',
          phone: 'Điện thoại di động',
          city: 'Tỉnh/Thành phố',
          district: 'Quận/Huyện',
          company: 'Công ty',
          position: 'Chức vụ',
          persona: 'Tính cách',
          business_profile: 'Hồ sơ doanh nghiệp',
        },
        required: 'Vui lòng điền vào đây',
        success: 'Cập nhật thành công!',
        error: 'Cập nhật không thành công!',
        delete_account: 'Xóa tài khoản thành công! Hệ thống sẽ chuyển về trang đăng nhập trong vài giây!',
      },
      payment: {
        recharge: 'Nạp',
        payment: 'Thanh toán',
        method: 'Hình thức thanh toán',
        domestic_wired: 'Chuyển khoản',
        wallet: 'Ví',
        history: 'Lịch sử mua dịch vụ',
        empty: 'Không có',
        add_method: 'Thêm cách thanh toán',
        topup: 'Nạp',
        proceed: 'Thanh toán',
        package: 'Gói',
        sme: {
          member: {
            exact: '{{numb}} người dùng',
            range: '{{from}} - {{to}} người dùng',
            smaller: 'Dưới {{numb}} người dùng',
            larger: '{{numb}}+ người dùng',
          },
          target: {
            group: 'Dành cho nhóm',
            sme: 'Dành cho doanh nghiệp SME',
            enterprise: 'Dành cho doanh nghiệp lớn',
          },
          month: 'Tháng',
          year: 'Năm',
          discount: ' (Giảm {{percent}}%)',
        },
      },
      notifications: {
        activity: {
          comment: 'Gửi email cho tôi khi ai đó nhận xét bài viết của tôi',
          feeling: 'Gửi email cho tôi khi ai đó bày tỏ cảm xúc bài viết của tôi',
          follow: 'Gửi email cho tôi khi có người theo dõi tôi',
        },
        application: {
          update: 'Tin tức và thông báo hệ thống',
          blog: 'Các bản cập nhật phần mềm hàng tuần và bài viết blog mới',
        },
        success: 'Cập nhật thành công!',
      },
      change_password: {
        old_password: 'Mật khẩu cũ',
        new_password: 'Mật khẩu mới',
        re_new_password: 'Nhập lại mật khẩu mới',
        error: {
          old_password_required: 'Mật khẩu cũ là bắt buộc',
          new_password_required: 'Mật khẩu mới là bắt buộc',
          new_password_length: 'Mật khẩu mới phải ít nhất 6 ký tự',
          re_new_password: 'Mật khẩu nhập lại chưa khớp',
        },
      },
    },
    analytic: {
      location: 'Vị trí',
      breadcrumb: {
        market: 'Thị trường',
        overview: 'Tổng quan',
      },
      form: {
        city: 'Tỉnh/Thành phố',
        district: 'Quận/Huyện',
        ward: 'Phường/Xã',
        error: {
          required: 'Vui lòng điền vào đây',
        },
      },
      btn: {
        search: 'Tra cứu thông tin',
      },
      tab: {
        sell: 'Bán',
        rent: 'Thuê',
        facade: 'Mặt tiền',
        alley: 'Trong hẻm',
      },
      chart: {
        compare: 'so với tháng trước',
        title: 'Thị trường trong 3 năm',
        average_price: 'Giá trung bình',
        number_posts: 'Lượng tin',
      },
      average_price: 'Giá trung bình',
      number_posts: 'Số lượng tin',
      compare: 'so với quý trước',
      helps: 'Phân tích giúp bạn',
      develop: 'Tính năng này đang trong quá trình phát triển',
    },
    asset: {
      breadcrumb: {
        asset: 'Tin',
        overview: 'Tổng quan',
      },
      stat: {
        success: 'Thành công',
        scheduled: 'Lên lịch',
        total: 'Tổng số tin',
        month: 'tin trong tháng',
        sum: 'tổng số tin',
        post_total: 'Tổng số bài viết',
        post_month: 'bài viết trong tháng',
        post_sum: 'tổng số bài viết',
      },
      btn: {
        add: 'Thêm',
        remove_filter: 'Bỏ lọc',
        real_estate: 'BĐS',
        social: 'MXH',
        edit: 'Chỉnh sửa',
        schedule: 'Đăng tin',
        detail: 'Chi tiết',
        web: 'Website',
        converts: {
          mxh: 'AI tạo tin MXH',
          web: 'AI tạo bài WEB',
          pnt: 'AI tạo tin PNT',
        },
      },
      filter: {
        type: 'Loại tin',
        start_day: 'Ngày bắt đầu',
        end_day: 'Ngày kết thúc',
        search: 'Tìm theo tiêu đề',
      },
      table: {
        head: {
          type: 'Loại tin',
          avatar: 'Hình đại diện',
          title: 'Tiêu đề',
          city: 'Tỉnh thành phố',
          created_day: 'Ngày tạo',
          updated_day: 'Cập nhật',
        },
      },
      detail: {
        title: 'Chi tiết tin',
        table: {
          head: {
            platform: 'Nền tảng',
            title: 'Tiêu đề',
            date: 'Ngày',
            display: 'Hiển thị',
            status: 'Tình trạng',
            view: 'Lượt xem',
            impression: 'Lượt tiếp cận',
          },
        },
        tab: {
          all: 'Tổng',
          active: 'Đang hiển thị',
          inactive: 'Chưa thanh toán',
          hidden: 'Đang ẩn tin',
          remove: 'Đã xóa',
        },
        btn: {
          back: 'Quay lại',
          link: 'Xem tin',
        },
        dialog: 'Lọc tin',
        notification: 'Thông báo',
        collapse: 'Thu gọn',
      },
      message: {
        cloned: 'Bài viết đã được clone',
      },
    },
    calendar: {
      form: {
        table: {
          head: {
            platform: 'Nền tảng',
            id_balance: 'ID/Số dư',
            select_package: 'Chọn gói',
            title_balance: 'Tiêu đề/Số dư',
            note: 'Ghi chú',
            package: 'Gói',
            date_post: 'Ngày đăng',
            day_post: 'Chọn ngày',
            time_post: 'Chọn giờ',
            name_avatar: 'Tên/Ảnh đại diện',
          },
          body: {
            your_page: 'Trang của bạn',
            your_group: 'Nhóm của bạn',
            other_group: 'Nhóm của người khác',
            personal: 'Cá nhân',
            organization: 'Tổ chức',
            instagram_page: 'Trang Instagram',
          },
        },
        btn: {
          add: 'Thêm',
          edit: 'Chỉnh sửa',
          back: 'Quay lại',
          done: 'Hoàn thành',
          link: 'Xem bài đăng',
        },
        tab: {
          real_estate: 'Bất động sản',
          social: 'Mạng xã hội',
          web: 'Website',
        },
        current_asset: 'Tin đang chọn',
        spam: 'Chúng tôi thêm 5 phút độ trễ giữa các tin để bạn không bị đánh dấu spam',
        collapse: 'Thu gọn',
        type: 'Loại tin',
      },
      breadcrumb: {
        home: 'Trang chủ',
        calendar: 'Lịch',
      },
      btn: {
        schedule: 'Lên lịch',
      },
      title: {
        dialog: 'Lọc tin',
        notification: 'Thông báo',
      },
      message: {
        selects: 'Chọn ít nhất 1 nền tảng để đăng tin',
        invalid_time: 'Vui lòng chọn đúng ngày giờ lên lịch',
      },
    },
    site: {
      action: {
        link_account: 'liên kết tài khoản BĐS',
      },
      breadcrumb: {
        home: 'Trang chủ',
        account: 'Tài khoản',
      },
      dialog: {
        cms_title: 'Liên kết nền tảng CMS',
      },
      popup: {
        title: 'Công bố từ GOOGLE API',
        content: `Ứng dụng này tuân thủ Chính sách Dữ liệu Người dùng của Google API Services, bao gồm các yêu cầu Sử dụng Giới hạn. Điều này có nghĩa là chúng tôi sẽ chỉ sử dụng dữ liệu bạn cung cấp cho chúng tôi để cung cấp các dịch vụ bạn đã yêu cầu và chúng tôi sẽ không chia sẻ dữ liệu của bạn với bất kỳ bên thứ ba nào mà không có sự đồng ý của bạn.</br></br>Bạn có thể tìm hiểu thêm về Chính sách Dữ liệu Người dùng của Google API Services tại đây: <a href='https://developers.google.com/terms/api-services-user-data-policy' target='_blank'>https://developers.google.com/terms/api-services-user-data-policy</a>.</br></br>Nếu bạn có bất kỳ câu hỏi hoặc thắc mắc nào về các thực tiễn bảo mật của chúng tôi, vui lòng liên lạc với chúng tôi qua: admin@uptin.vn</br></br>Cảm ơn bạn đã sử dụng ứng dụng của chúng tôi!`,
      },
      popup_remove: {
        title: 'Xác nhận xóa tài khoản',
        content: `Bạn có chắc chắn muốn xóa tài khoản này hay không?`,
      },
      btn: {
        add: 'Thêm',
        real_estate: 'BĐS',
        social: 'MXH',
        collapse: 'Thu gọn',
        view: 'Xem',
        edit: 'Chỉnh sửa',
        connect: 'Kết nối',
        close: 'Đóng',
        save: 'Kiểm tra và lưu',
        analytic: 'Google Analytics (GA4)',
        confirm: 'Xác nhận',
        cancel: 'Hủy',
        remove: 'Xóa',
      },
      tab: {
        all: 'Tổng',
        real_estate: 'Website BĐS',
        social: 'Mạng xã hội',
        website: 'CMS',
      },
      form: {
        table: {
          head: {
            platform: 'Nền tảng',
            balance_account_url: 'Số dư/Tên tài khoản/Đường Dẫn',
            name: 'Tên tài khoản',
            status: 'Tình trạng',
            name_avatar: 'Tên/Ảnh đại diện',
          },
          body: {
            connected: 'Đã kết nối',
            disconnect: 'Mất kết nối',
          },
        },
        field: {
          password: 'Mật khẩu',
          app_password: 'Mật khẩu ứng dụng',
          access_token: 'Token của ứng dụng',
          website: 'Địa chỉ website',
          blog_url: 'Đường dẫn blog',
          username: 'Tài khoản',
          is_branch: 'Tài khoản phụ/Doanh nghiệp',
          allow_create: 'Tự động tạo nếu chưa tồn tại',
          phone: 'Số điện thoại',
          name: 'Tên hiển thị',
        },
        error: {
          required: 'Vui lòng nhập vào đây',
          url_invalid: 'Vui lòng nhập đúng định dạng',
        },
      },
      message: {
        site_inactive: '{{name}} đang tạm ngưng hoạt động',
        analytic_success: 'Tích hợp google analytic thành công',
        remove_success: 'Xoá tài khoản thành công',
      },
    },
    social: {
      popup_facebook: {
        title: 'Công bố từ FACEBOOK API',
        content: `<ol><li>Xác thực và Ủy quyền: Uptin sẽ tích hợp các cơ chế xác thực của Meta để cho phép người dùng đăng nhập hoặc kết nối tài khoản Meta của họ một cách an toàn. Điều này liên quan đến việc thu thập các quyền cần thiết từ người dùng để truy cập vào hồ sơ của họ và quản lý các nhóm và trang của họ.</li>
  <li>Tích hợp API Nhóm: Uptin sẽ sử dụng API Nhóm để lấy danh sách các nhóm và trang mà người dùng là thành viên hoặc là quản trị viên.</li>
  <li>Sử dụng Điểm cuối publish_to_groups: Uptin có thể tích hợp điểm cuối publish_to_groups để cho phép người dùng đăng nội dung vào các nhóm hoặc trang cụ thể thông qua nền tảng của họ. Điều này bao gồm: 
  </li>
    </ol>
    <ul>
    <li>Cho phép người dùng soạn và lên lịch đăng bài hoặc phương tiện truyền thông.</li>
    <li>Đảm bảo rằng nội dung được tạo ra tuân thủ các tiêu chuẩn cộng đồng và chính sách của Meta trước khi bắt đầu quá trình xuất bản.</li>
    <li>Hỗ trợ việc lựa chọn những nhóm nơi nội dung sẽ được đăng.</li>
    <li>Nhận địa chỉ email của bạn</li>
    <li>Truy cập hồ sơ và bài viết từ tài khoản</li>
    <li>Instagram được kết nối với Trang của bạn</li>
    <li>Tải phương tiện lên và tạo bài đăng cho tài khoản Instagram được kết nối với Trang của bạn</li>
    <li>Đăng nội dung vào các nhóm thay mặt bạn</li>
    <li>Tạo và quản lý nội dung trên Trang của bạn</li>
    <li>Đọc nội dung đăng trên Trang đọc nội dung người dùng trên Trang của bạn</li>
    <li>Hiển thị danh sách các Trang bạn quản lý</li>
    </ul>

`,
      },
      platform: 'Chọn nền tảng liên kết',
      message: {
        empty_page: 'Bạn chưa tham gia bất kỳ trang nào',
        empty_group: 'Bạn chưa tham gia bất kỳ nhóm nào',
        empty_installed: `Không tìm thấy thông tin về các nhóm đã thêm app Uptin`,
        empty_personal: 'Không tìm thấy thông tin về cá nhân',
        empty_organization: 'Không tìm thấy thông tin về các tổ chức',
      },
      tab: {
        fanpage: 'Trang',
        own_group: 'Nhóm của bạn',
        installed_group: 'Nhóm đã kết nối với Uptin',
        personal: 'Cá nhân',
        organization: 'Tổ chức',
      },
      btn: {
        prev: 'Quay lại',
        next: 'Tiếp theo',
        back: 'Quay lại',
        copy_link: 'Copy LINK nhóm',
        auto_linking: 'Tự liên kết',
        confirm: 'Xác nhận',
        cancel: 'Hủy',
      },
      form: {
        search: 'Tìm theo tên nhóm hoặc nhãn...',
      },
      snackbar: {
        connected: 'Kết nối thành công',
        copied: 'Đã copy đường dẫn!',
        removed: 'Xóa kết nối thành công',
      },
      popup: {
        content: `Để đăng được lên nhóm “<span style="font-weight: bold">#</span>”, bạn cần liên lạc với Quản trị viên nhóm và yêu cầu họ <span style="font-weight: bold">thêm ứng dụng Uptin</span> trong phần App của nhóm. Hãy tìm nhóm trên facebook hoặc copy link này và paste trên trình duyệt.`,
        remove_content: `Thực hiện thao tác xoá bỏ liên kết mạng xã hội khỏi Uptin?`,
      },
    },
    create_asset: {
      breadcrumb: {
        add: 'Thêm',
        home: 'Trang chủ',
        asset: 'Tin',
        create_asset: 'Soạn tin',
        edit_asset: 'Chỉnh sửa',
      },
      message: {
        form_invalid: 'Vui lòng điền đẩy đủ thông tin',
      },
      real_estate: {
        title_create: 'Soạn tin bất động sản',
        title_edit: 'Chỉnh sửa tin bất động sản',
        tab: {
          general: 'Thông tin cơ bản',
          detail: 'Thông tin mô tả',
        },
        btn: {
          next_step: 'Tiếp tục',
          next: 'Tiếp theo',
          save: 'Lưu',
          complete: '{{action}} và lên lịch',
          edit: 'Cập nhật',
          create: 'Tạo',
          amenitie: 'Tiện ích xung quanh',
          convince: 'Thuyết phục',
          back: 'Quay lại',
          close: 'Đóng',
          add_amenitie: 'Thêm vào nội dung',
        },
        message: {
          create_success: 'Tạo tin thành công',
          edit_success: 'Cập nhật tin thành công',
          check_error: 'Vui lòng kiểm tra lại thông tin: ',
        },
        form: {
          field: {
            asset_type: 'Loại hình bất động sản',
            asset_detail: 'Bất động sản chi tiết',
            city: 'Tỉnh/Thành phố',
            district: 'Quận/Huyện',
            ward: 'Phường/Xã',
            street: 'Đường/Phố',
            price: 'Giá(VNĐ)',
            roi_percentage: 'Tỷ suất lợi nhuận %',
            area: 'Diện tích (m2)',
            actual_area: 'Diện tích sử dụng (m2)',
            url: 'Đường dẫn',
            share_source: {
              title: 'Chia sẻ nguồn hàng',
              private: 'Cá nhân',
              organization: 'Nội bộ',
              public: 'Cộng đồng',
            },
            demand: {
              title: 'Nhu cầu*',
              sell: 'Cần bán',
              rent: 'Cho thuê',
              buy: 'Cần mua',
              tenant: 'Cần thuê',
              assign: 'Sang nhượng',
            },
            who: {
              title: 'Bạn là*',
              broker: 'Môi giới',
              owner: 'Chính chủ',
              developer: 'Nhà phát triển',
              investor: 'Nhà đầu tư',
            },
            bedroom: 'Phòng ngủ',
            toilet: 'Toilet',
            legal_document: 'Giấy tờ pháp lý',
            contact_info: 'Thông tin liên lạc',
            address: 'Số nhà',
            hand_overs: {
              project: 'Dự án',
              under_construction: 'Đang xây dựng',
              slacking: 'Chưa xong',
              handing_over: 'Đang bàn giao',
              complete: 'Hoàn thành',
              red_book: 'Sổ đỏ',
              house_ownership: 'Sổ hồng',
              sale_contract: 'Hợp đồng mua bán',
              valid_documents: 'Giấy tờ hợp lệ',
            },
            title: 'Tiêu đề',
            short_title: 'Ngắn (50-70 ký tự)',
            description: 'Mô tả chi tiết (tối đa 3000 ký tự)...',
            image: {
              title: 'Hình ảnh',
              heading: 'Tối thiểu 3 ảnh - Tối đa {{asset_image_count}} ảnh',
              first_content: '- Kéo thả hoặc nhấn chọn ảnh',
              second_content: '- Dung lượng ảnh tối đa 3Mb',
              third_content: '- Ảnh đầu tiên từ trái qua phải sẽ là ảnh bìa',
            },
            hidden: 'Ẩn',
            house_number_hidden: 'Ẩn số nhà khi đăng tin',
            shown: 'Hiển thị',
            house_number_shown: 'Hiển thị số nhà khi đăng tin',
            converts: {
              mxh: 'Tự động tạo bài viết mạng xã hội',
              web: 'Tự động tạo bài viết website',
              pnt: 'Tự động tạo bài "BÁO"',
            },
          },
          error: {
            select: 'Vui lòng chọn',
            required: 'Vui lòng điền vào đây',
            price: 'Vui lòng nhập giá đúng',
            area: 'Vui lòng nhập diện tích đúng',
            toilet: 'Vui lòng nhập toilet đúng',
            bedroom: 'Vui lòng nhập phòng ngủ đúng',
            url_invalid: 'Vui lòng điền đường dẫn hợp lệ',
            roi_percentage: {
              min: 'Tỷ suất lợi nhuận tối thiểu 0%',
              max: 'Tỷ suất lợi nhuận cao nhất là 30%',
            },
            short_title: {
              min: 'Tiêu đề tối thiểu 50 ký tự',
              max: 'Tiêu đề tối đa 70 ký tự',
            },
            description: {
              min: 'Mô tả chi tiết tối thiểu 200 ký tự',
              max: 'Mô tả chi tiết tối đa 3000 ký tự',
            },
            images: {
              min: 'Vui lòng tải lên tối thiểu 3 ảnh',
              max: 'Vui lòng tải lên tối đa {{asset_image_count}} ảnh',
            },
          },
        },
      },
      social: {
        title_create: 'Soạn tin mạng xã hội',
        title_edit: 'Chỉnh sửa tin mạng xã hội',
        btn: {
          rewrite: 'Viết lại',
          save: 'Lưu',
          complete: '{{action}} và lên lịch',
          edit: 'Cập nhật',
          create: 'Tạo',
          google_format: 'Định dạng bài Google',
          instagram_format: 'Định dạng bài Instagram',
        },
        message: {
          create_success: 'Tạo tin thành công',
          edit_success: 'Cập nhật tin thành công',
          check_error: 'Vui lòng kiểm tra lại thông tin: ',
          hashtag_error: 'Thêm một số từ khóa liên quan đến vấn đề của bạn',
        },
        form: {
          field: {
            keyword: 'Từ khóa, cách nhau bởi dấu phẩy',
            title: 'Tiêu đề',
            description: 'Hãy điền gì đó thật thú vị...',
            image: {
              title: 'Hình ảnh',
              heading: 'Tải lên tối đa {{asset_image_count}} ảnh',
              first_content: '-Kéo thả hoặc nhấn chọn ảnh',
              second_content: '-Dung lượng ảnh tối đa 3Mb',
              third_content: '-Ảnh đầu tiên từ trái qua phải sẽ là ảnh bìa',
            },
            converts: {
              mxh: 'Tự động tạo bài viết mạng xã hội',
              web: 'Tự động tạo bài viết website',
              pnt: 'Tự động tạo bài "BÁO"',
            },
          },
          error: {
            required: 'Vui lòng điền vào đây',
            description: {
              min: 'Điền ít nhất 50 ký tự',
              invalid: 'Nội dung phải nhiều hơn 50 ký tự (không tính biểu tượng cảm xúc)',
            },
            images: {
              max: 'Vui lòng tải lên tối đa {{asset_image_count}} ảnh',
            },
          },
        },
      },
      web: {
        title_create: 'Soạn tin website',
        title_edit: 'Chỉnh sửa tin website',
        btn: {
          preview: 'Xem trước',
          complete: 'Hoàn thành',
        },
        message: {
          create_success: 'Tạo tin thành công',
          edit_success: 'Cập nhật tin thành công',
          check_error: 'Vui lòng kiểm tra lại thông tin: ',
        },
        form: {
          field: {
            title: 'Tiêu đề',
            description: 'Mô tả',
            content: 'Nhập nội dung',
            tags: 'Tags',
            meta_title: 'Tiêu đề meta',
            meta_description: 'Mô tả meta',
            meta_keywords: 'Từ khóa',
            template: 'Template',
            image: {
              title: 'Ảnh bìa',
              heading: 'Ảnh bìa',
              first_content: '- Kéo thả hoặc nhấn chọn ảnh',
              second_content: '- Dung lượng ảnh tối đa 5Mb',
            },
            converts: {
              mxh: 'Tự động tạo bài viết mạng xã hội',
              web: 'Tự động tạo bài viết website',
              pnt: 'Tự động tạo bài "BÁO"',
            },
          },
          error: {
            required: 'Vui lòng điền vào đây',
            title: {
              min: 'Điền ít nhất 30 ký tự',
              max: 'Điền tối đa 50 ký tự',
            },
            description: {
              min: 'Điền ít nhất 50 ký tự',
              invalid: 'Nội dung phải nhiều hơn 50 ký tự (không tính biểu tượng cảm xúc)',
            },
            images: {
              max: 'Vui lòng tải lên tối đa 1 ảnh',
              min: 'Vui lòng tải lên tối thiểu 1 ảnh',
            },
            meta_keywords: {
              min: 'Phải có ít nhất 1 từ khóa',
            },
            tags: {
              limit: 'Số lượng tag đã vượt quá giới hạn cho phép',
              min: 'Phải có ít nhất 1 tag',
            },
            template: {
              max: 'Template tối đa 200 ký tự',
            },
          },
        },
      },
    },
    notification: {
      you: 'Bạn',
      breadcrumb: {
        general: 'Tổng quan',
        notification: 'Thông báo',
        all_notification: 'Tất cả thông báo',
      },
      menu: {
        setting: 'Cài đặt thông báo',
        mark_read: 'Đánh dấu tất cả là đã đọc',
      },
      btn: {
        collapse: 'Thu gọn',
      },
    },
    quest: {
      breadcrumb: {
        quest: 'Nhiệm vụ',
        general: 'Tổng quan',
        user: 'Người dùng',
      },
      detail: 'Chi tiết nhiệm vụ:',
      multi: 'số tiền',
      vnd: 'đ',
      members: 'T.viên',
      title: {
        reward_login_daily: 'Đăng nhập hằng ngày',
        reward_daily_scheduled_bds: 'Đăng 1 tin BĐS',
        reward_daily_scheduled_mxh: 'Đăng 1 tin MXH',
        reward_daily_scheduled_web: 'Đăng 1 tin WEB',
        reward_linking_account: 'Liên kết thành công 1 tài khoản',
        promo_linking_facebook_group: 'Thêm Uptin vào nhóm Facebook',
        promo_daily_topup: 'Nạp lần đầu trong ngày',
        promo_first_topup: 'Nạp lần đầu',
      },
      description: {
        reward_login_daily:
          '- Phần thưởng: {{moneyReceive}} đ\n- Yêu cầu: Đăng nhập vào Uptin thông qua Google hoặc Facebook\n- Loại nhiệm vụ: Mỗi ngày',
        reward_daily_scheduled_bds:
          '- Phần thưởng:  {{moneyReceive}} đ\n- Yêu cầu: Lên lịch cho 01 tin BĐS thành công trong ngày\n- Loại nhiệm vụ: Mỗi ngày',
        reward_daily_scheduled_mxh:
          '- Phần thưởng:  {{moneyReceive}} đ\n- Yêu cầu: Lên lịch cho 01 tin MXH thành công trong ngày\n- Loại nhiệm vụ: Mỗi ngày',
        reward_daily_scheduled_web:
          '- Phần thưởng:  {{moneyReceive}} đ\n- Yêu cầu: Lên lịch cho 01 tin WEB thành công trong ngày\n- Loại nhiệm vụ: Mỗi ngày',
        reward_linking_account:
          '- Phần thưởng: {{moneyReceive}} đ\n- Yêu cầu: Kết nối 1 tài khoản BĐS/MXH lần đầu tiên vào Uptin và thành công.\n- Loại nhiệm vụ: Một lần duy nhất',
        promo_linking_facebook_group:
          '- Phần thưởng: {{moneyReceive}} đ x Số lượng thành viên nhóm\n- Yêu cầu: Là người kết nối đầu tiên với nhóm đã cài đặt ứng dụng Uptin trong hệ thống. Nhóm phải có ít nhất 100 thành viên.\n- Loại nhiệm vụ: Một lần duy nhất',
        promo_daily_topup:
          '- Phần thưởng:  x{{moneyReceive}} số tiền nạp (Thưởng tối đa 50.000 đ)\n- Yêu cầu: Thanh toán qua ví Momo, VNPay hoặc chuyển khoản qua Ngân hàng\n- Loại nhiệm vụ: Mỗi ngày',
        promo_first_topup:
          '- Phần thưởng: x{{moneyReceive}} số tiền nạp (không giới hạn)\n- Yêu cầu: Thanh toán qua ví Momo, VNPay hoặc chuyển khoản qua Ngân hàng\n- Loại nhiệm vụ: Một lần duy nhất',
      },
      status: {
        completed: 'Hoàn thành',
      },
    },
    staff: {
      breadcrumb: {
        staff: 'Nhân sự',
        general: 'Tổng quan',
        list: 'Danh sách',
      },
      table: {
        head: {
          avatar: 'Ảnh',
          name: 'Họ tên',
          status: 'Trạng thái',
          date_join: 'Ngày tham gia',
          date_end: 'Ngày kết thúc',
        },
        body: {
          pending: 'Chờ duyệt',
          confirmed: 'Đang sử dụng',
          quit: 'Ngưng sủ dụng',
          refuse: 'Từ chối',
        },
      },
      form: {
        error: {
          required: 'Vui lòng điền vào đây',
          type_invalid: 'Vui lòng điền đúng định dạng',
        },
      },
      btn: {
        request: 'Gửi yêu cầu',
        add: 'Thêm nhân sự',
        accept: 'Đồng ý',
        collapse: 'Thu gọn',
        delete: 'Loại bỏ',
        resend: 'Mời lại',
      },
      delete: {
        title: 'Xoá nhân sự {{email}} khỏi gói dịch vụ?',
        content:
          'Người dùng trên sẽ không dùng chung gói của bạn nữa. Tuy nhiên, bạn sẽ chỉ có thể thêm nhân sự mới sau 7 ngày.',
      },
    },
    push_notification: {
      breadcrumb: {
        notification: 'Thông báo',
        overview: 'Tổng quan',
        create_notification: 'Tạo thông báo',
      },
      tab: {
        list: 'Danh sách',
        approve: 'Phê duyệt',
        audience: 'Đối tượng',
        content: 'Nội dung',
      },
      table: {
        head: {
          image: 'Ảnh',
          title: 'Tiêu đề',
          status: 'Tình trạng',
          budget: 'Ngân sách <br /> người nhận',
          date_send: 'Ngày gửi',
          content: 'Nội dung',
        },
      },
      form: {
        label: {
          demographic: 'Nhân khẩu học',
          date_picker: 'Chọn ngày',
        },
        field: {
          area: {
            title: 'Khu vực',
            northern: 'Miền Bắc',
            central: 'Miền Trung',
            southern: 'Miền Nam',
          },
          city: 'Tỉnh/Thành phố',
          career: {
            title: 'Nghề nghiệp',
            broker: 'Môi giới',
            owner: 'Chính chủ',
            developer: 'Nhà phát triển',
            investor: 'Nhà đầu tư',
          },
          asset_type: 'Loại hình bất động sản',
          asset_detail: 'Bất động sản chi tiết',
          demand: {
            title: 'Chuyên',
            sell: 'Cần bán',
            rent: 'Cho thuê',
            buy: 'Cần mua',
            tenant: 'Cần thuê',
            assign: 'Sang nhượng',
          },
          all: 'Tất cả',
          image: {
            heading: 'Tải ảnh lên',
            first_content: '- Kéo thả hoặc nhấn chọn ảnh',
            second_content: '- Dung lượng ảnh tối đa 5Mb',
            third_content: '- Ảnh có kích thước 256x256 px',
          },
          title: 'Tiêu đề(6-80 ký tự)',
          content: 'Nội dung(10-150 ký tự)',
          link: 'Đường link thông báo',
        },
        error: {
          required: 'Vui lòng điền vào đây',
          image: {
            required: 'Vui lòng tải ảnh lên',
            only_one: 'Vui lòng tải lên 1 ảnh',
            format: 'Ảnh phải có kích thước 256x256 px',
          },
          title: {
            min: 'Nhập ít nhất 6 ký tự',
            max: 'Nhập tối đa 80 ký tự',
          },
          content: {
            min: 'Nhập ít nhất 10 ký tự',
            max: 'Nhập tối đa 150 ký tự',
          },
          link: {
            format: 'Vui lòng nhập đúng định dạng link',
          },
        },
      },
      preview: 'Xem trước',
      btn: {
        delete: 'Xóa',
        approve: 'Duyệt',
        reject: 'Từ chối',
        collapse: 'Thu gọn',
        create_notification: 'Tạo thông báo',
        next: 'Tiếp tục',
        back: 'Quay lại',
        complete: 'Hoàn thành',
      },
      status: {
        deliveried: 'Đã phân phối',
        canceled: 'Từ chối',
        pending: 'Chờ duyệt',
        scheduled: 'Lên lịch',
      },
      message: {
        create_success: 'Tạo thông báo thành công',
        deleted_success: 'Xóa thông báo thành công',
        deleted_fail: 'Xóa thông báo thất bại',
        approved_success: 'Phê duyệt thông báo thành công',
        approved_fail: 'Phê duyệt thông báo thất bại',
        denied_success: 'Từ chối thông báo thành công',
        denied_fail: 'Từ chối thông báo thất bại',
      },
    },
    multi_channel: {
      steps: {
        source: 'Nguồn hàng',
        content: 'Nội dung',
        channels: 'Kênh phân phối',
        schedule: 'Lên lịch',
        btn: {
          next: 'Tiếp theo',
          back: 'Quay lại',
          save: 'Lưu',
          skip: 'Bỏ qua',
        },
        step_1: {
          social_web: 'Mạng xã hội/Web',
          industry: 'Ngành',
          exclusive: 'Độc quyền',
          continue: 'Tiếp theo',
          industry_list: {
            real_estate: 'Bất động sản',
            ecommerce: 'Thương mại điện tử',
          },
          level: 'Chế độ',
          source_name: 'Tên nguồn hàng',
          data_from: 'Lấy dữ liệu từ tin',
          form: {
            images: {
              title: 'Tải lên 1 ảnh',
              text1: '- Kéo thả hoặc nhấn chọn ảnh',
              text2: '- Dung lượng ảnh tối đa 3Mb',
              text3: '- Ảnh đại diện của nguồn hàng',
            },
            errors: {
              required: 'Đây là trường bắt buộc',
              images: {
                min: 'Chọn ít nhất 1 ảnh',
                max: 'Chọn tối đa 1 ảnh',
              },
            },
          },
          message: {
            select_source: 'Vui lòng chọn nguồn hàng',
            create_source_success: 'Thêm nguồn hàng thành công',
          },
          btn: {
            add: 'Thêm nguồn',
          },
        },
        step_3: {
          cost: 'Chi phí:',
          setting_name: 'Tên cài đặt',
          selected: 'Đã chọn',
          quota: 'Hạn mức sử dụng',
          package: 'Gói',
          setting: {
            delete: 'Xác nhận xóa cài đặt!',
          },
          types: {
            bds: 'Bất động sản',
            mxh: 'Mạng xã hội',
            email: 'Email marketing',
            web: 'Trang web/Landing pages',
          },
          form: {
            errors: {
              required: 'Đây là trường bắt buộc',
              keywords: 'Vui lòng nhập từ khóa',
            },
          },
          btn: {
            create_setting: 'Tạo cài đặt',
            add_setting: 'Thêm cài đặt',
            add_email: 'Thêm email',
          },
          message: {
            select_channel: 'Vui lòng chọn kênh phân phối',
            select_platform: 'Vui lòng chọn ít nhất 1 nền tảng',
            select_package: 'Vui lòng chọn gói phù hợp',
            create_setting_success: 'Tạo tùy chỉnh thành công',
            deleted_success: 'Xóa cài đặt thành công',
          },
          filter_tag: {
            all: 'Tất cả',
            page: 'Trang',
            group: 'Nhóm',
            other: 'Khác',
            your_group: 'Nhóm của bạn',
            other_group: 'Nhóm của người khác',
          },
        },
        step_4: {
          right_away: 'Ngay bây giờ',
          later: 'Hẹn giờ',
          on: 'Vào lúc',
          confirm: 'Xác nhận gửi',
          message: {
            select_time: 'Vui lòng chọn thời gian phân phối',
          },
        },
      },
    },
    feed: {
      btn: {
        back: 'Quay lại',
        next: 'Tiếp tục',
        save: 'Hoàn thành',
        add: 'Thêm đối tượng',
        test: 'Chạy thử nghiệm',
        url: 'Lấy đường dẫn liên quan',
        preview: 'Xem Trước',
        now: 'Đăng ngay',
        scheduled: 'Bài đã lên lịch',
        disable: 'Tắt nuôi',
        active: 'Bật nuôi',
        import_data: 'Import data group',
        general_article: 'Tạo tiêu đề',
        preview_seeding: 'Xem trước bài seeding',
        update: 'Cập nhật',
      },
      step_1: {
        title: 'Đối tượng',
        filter: {
          type: 'Loại',
          keywords: 'Tìm theo tên hoặc UID',
          all: 'Tất cả',
          page: 'Trang',
          group: 'Nhóm',
          your_group: 'Nhóm của bạn',
          other_group: 'Nhóm của người khác',
          website: 'Website',
          your_config: 'Cài đặt của bạn',
        },
        add_affiliate_account: 'Thêm tài khoản liên kết',
        linking_social_media: 'Liên kết nền tảng mạng xã hội',
        linking_cms: 'Liên kết nền tảng CMS',
      },
      step_2: {
        title: 'Cài đặt',
        messages: {
          select_sources: 'Vui lòng chọn ít nhất 1 nguồn',
          double_check: 'Vui lòng kiểm tra lại thông tin',
          limit_keyword: 'Từ khóa tối đa 1500 ký tự',
          select_days: 'Vui lòng chọn ít nhất 1 ngày trong tuần',
          select_tones: 'Vui lòng chọn ít nhất 1 giọng văn',
          select_images: 'Vui lòng chọn ít nhất 1 nguồn tạo hình ảnh',
          created_success: 'Đã kích hoạt chức năng nuôi thành công',
          server_error: 'Có lỗi xảy ra',
          run_feed: 'Đã tiến hành lên lịch',
          empty_posts_today: 'Không có bài đăng nào ngày hôm nay',
          select_page: 'Vui lòng chọn ít nhất 1 tài khoản để ủy quyền đăng',
          update_persona_success: 'Cập nhật persona thành công',
        },
        edit_persona: {
          title: 'Chỉnh sửa persona cho trang',
        },
        modal: {
          title: 'Danh sách đường dẫn liên quan',
          title_import: 'Nhập file CSV của bạn',
          upload: 'Tải lên',
          error: 'Không có file nào được chọn',
        },
        source: {
          title: 'Chọn nguồn:',
        },
        keyword: {
          title: 'Từ khóa:',
          extra_link: 'Dữ liệu sẽ được lấy tại:',
          select_link: 'Chọn nội dung này',
        },
        recurrence: {
          title: 'Vòng lặp:',
        },
        form: {
          fields: {
            days: 'Ngày',
            keyword: 'Từ khóa',
            time: 'Vào',
            end: 'Kết thúc',
            source: 'Nguồn',
            use_current_account: 'Đăng bằng tài khoản hiện tại',
            mode: {
              seo: 'SEO',
              seeding: 'Seeding',
            },
            news_option: 'Dạng bài',
            ai: {
              image: 'Hình ảnh',
              tone: 'Giọng văn AI',
              random: 'Ngẫu nhiên',
              seeding: 'Seeding',
              meme: 'MEME',
              nothing: 'Không hình ảnh',
              add_hashtag: 'Thêm Hashtag',
              add_emoji: 'Thêm Emoji',
              standard: 'Tiêu chuẩn',
              funny: 'Vui vẻ',
              creative: 'Sáng tạo',
              professional: 'Chuyên nghiệp',
              generate: 'Tạo bởi AI',
              search: 'Tìm kiếm',
              banner: 'Banner',
              block_advertisement: 'Chặn quảng cáo',
              sharing: 'Chia sẻ',
              target_info: 'Thông tin liên hệ',
              express_opinion: 'Quan điểm cá nhân',
              none: 'Không sử dụng',
              intent: 'Ý định',
            },
            intent: {
              review: 'Review',
              buy: 'Mua',
              sell: 'Bán',
              rate: 'Xin review',
              complain: 'Than phiền',
              ask: 'Hỏi',
              misc: 'Linh tinh',
            },
            seeding_mode: {
              no_text: 'No text',
              fake_user: 'Fake user',
              no_img: 'No Image',
              collection_1: 'Collection 1',
              collection_n: 'Collection N',
              text: 'Văn bản',
              image: 'Hình ảnh',
            },
            label_target: 'Thông tin cá nhân',
            days_in_week: {
              monday: '2',
              tuesday: '3',
              wednesday: '4',
              thursday: '5',
              friday: '6',
              saturday: '7',
              sunday: 'CN',
            },
            languages: {
              title: 'Ngôn ngữ',
              vietnam: 'Việt Nam',
              english: 'Tiếng Anh',
              espanol: 'Tây Ban Nha',
              french: 'Pháp',
              chinese: 'Trung Quốc',
              korean: 'Hàn Quốc',
              japan: 'Nhật Bản',
            },
            article_label: {
              title: 'Tiêu đề',
              short_keywords: 'Từ khóa ngắn',
              type: 'Kiểu',
              search_volume: 'Khối lượng tìm kiếm',
              difficulty: 'Độ khó',
            },
          },
          errors: {
            required: 'Đây là trường bắt buộc',
            days: {
              min: 'Chọn ít nhất 1 ngày',
            },
            tone: {
              min: 'Chọn ít nhất 1 giọng văn',
            },
            image: {
              min: 'Chọn ít nhất 1 tùy chọn',
            },
            keywords: {
              errors: 'Vui lòng nhập từ khóa',
              max: 'Nhập tối đa 1500 ký tự',
              commas: 'Không được chứa dấu phẩy trong trường từ khóa',
            },
            text: 'Chọn ít nhất 1 văn bản',
            seo_options: 'Chọn ít nhất 1  tuỳ chọn news hoặc business',
            business_keywords: 'Vui lòng chọn chế độ business',
            sharing: 'Vui lòng chọn chế độ chia sẽ',
            target_info: 'Vui lòng điền thông tin liên hệ',
            misc: 'Vui lòng chọn ít nhất 1 collection',
            opinion: 'Vui lòng điền tính cách cá nhân ở phần cài đặt tài khoản',
            article: 'Thông tin cá nhân hoặc hồ sơ doanh nghiệp ở phần cài đặt không được để trống',
          },
        },
      },
      coming_soon: {
        title: 'SẮP RA MẮT',
        subtitle: 'Chúng tôi đang nỗ lực phát triển tính năng này!',
      },
    },
    ads_marketing: {
      breadcrumb: {
        ads_marketing: 'Quảng cáo',
        overview: 'Tổng quan',
        create_notification: 'Tạo quảng cáo',
      },
      steps: {
        target: '1. Đối tượng khách',
        content: '2. Nội dung',
        schedule: '3. Lên lịch',
      },
      tab: {
        list: 'Danh sách',
        approve: 'Phê duyệt',
        audience: 'Đối tượng',
        content: 'Nội dung',
      },
      table: {
        head: {
          image: 'Ảnh',
          title: 'Tiêu đề',
          status: 'Tình trạng',
          budget: 'Chi phí/người nhận',
          date_send: 'Ngày đăng',
          content: 'Nội dung',
          platform: 'Nền tảng',
          name: 'Tên',
          audience: 'Người quan tâm',
          detail: 'Chi tiết',
          persona: 'Tính cách',
        },
        body: {
          status: {
            approved: 'Đã duyệt',
            rejected: 'Từ chối',
            scheduled: 'Lên lịch',
            pending: 'Chờ duyệt',
            canceled: 'Hủy bỏ',
            deliveried: 'Đã phân phối',
          },
        },
      },
      form: {
        label: {
          demographic: 'Nhân khẩu học',
          date_picker: 'Chọn ngày',
        },
        field: {
          gender: 'Giới tính',
          age: {
            title: 'Độ tuổi',
            below: 'Dưới',
            sub_title: 'tuổi',
            from: 'Từ',
            to: 'Đến',
            all: 'Tất cả độ tuổi',
            child: 'Dưới 6 tuổi',
            between: '{{min}}-{{max}} tuổi',
            middle: 'Trung niên (45-65 tuổi)',
            elder: 'Người lớn tuổi',
          },
          industry: 'Ngành/nghề',
          nation: 'Quốc gia',
          location: 'Vị trí',
          all: 'Tất cả',
        },
      },
      schedule: {
        post_time: 'Thời gian đăng',
        list: 'Danh sách đăng',
      },
      preview: 'Xem trước',
      list_scheduled: 'Danh sách đăng tin',
      btn: {
        edit: 'Chỉnh sửa',
        detail: 'Chi tiết',
        delete: 'Xóa',
        approve: 'Duyệt',
        reject: 'Từ chối',
        collapse: 'Thu gọn',
        create_notification: 'Tạo quảng cáo',
        next: 'Tiếp tục',
        back: 'Quay lại',
        complete: 'Hoàn thành',
        reset: 'Đặt lại',
        apply: 'Áp dụng',
      },
      status: {
        deliveried: 'Đã phân phối',
        canceled: 'Từ chối',
        pending: 'Chờ duyệt',
        scheduled: 'Lên lịch',
      },
      message: {
        create_success: 'Tạo bài quảng cáo thành công',
        deleted_success: 'Xóa bài quảng cáo thành công',
        deleted_fail: 'Xóa bài quảng cáo thất bại',
        approved_success: 'Phê duyệt bài quảng cáo thành công',
        approved_fail: 'Phê duyệt bài quảng cáo thất bại',
        denied_success: 'Từ chối bài quảng cáo thành công',
        denied_fail: 'Từ chối bài quảng cáo thất bại',
        empty_adience: 'Người nhận không được phép trống',
      },
    },
  },
  email: {
    sidebar: {
      compose: 'Soạn',
      contact: 'Liên lạc',
      template: 'Template',
      campaigns: 'Chiến dịch',
      settings: 'Cài đặt',
      quota: 'Mức sử dụng',
    },
    btn: {
      create_campaign: 'Tạo tin email',
      create_group: 'Thêm nhóm liên lạc',
      create_contact: 'Thêm thông tin liên lạc',
      create_template: 'Tạo template',
      update: 'Cập nhật',
      create: 'Tạo',
      cancel: 'Hủy bỏ',
      check_verify: 'Kiểm tra',
      add_verify: 'Thêm người gửi',
      delete: 'Xoá',
      resend: 'Gửi lại',
      edit: 'Chỉnh sửa',
      schedule: 'Lên lịch',
      send_now: 'Gửi ngay',
      save: 'Lưu',
    },
    tabs: {
      domain_verify: 'Xác minh tên miền',
      email_verify: 'Xác minh email',
    },
    table: {
      head: {
        domain: 'Tên miền',
        verified: 'Tình trạng',
        bounce_domain: 'Tên miền trả lại',
        email: 'Email',
      },
      body: {
        verified: 'Đã xác minh',
        unverified: 'Chưa xác minh',
      },
    },
    message: {
      error: 'Có lỗi xảy ra',
      remove_success: 'Xóa thành công',
      resend_success: 'Gửi lại mail thành công',
    },
    verify: {
      btn: {
        continue: 'Tiếp tục',
        completed: 'Hoàn thành',
        back: 'Quay lại',
        verify: 'Xác minh',
        value: 'Value',
        copy: 'Copy',
      },
      email: {
        btn: {
          resend: 'Gửi lại',
        },
        form: {
          errors: {
            email_format: 'Vui lòng nhập đúng định dạng email',
            required: 'Đây là trường bắt buộc',
          },
        },
        content: {
          step1: {
            title: 'Nhập địa chỉ email',
            content: 'Nhập địa chỉ email bạn muốn sử dụng làm email gửi của mình.',
          },
          step2: {
            title: 'Kiểm tra hộp thư',
            content:
              'Chúng tôi đã gửi cho bạn một email xác minh. Kiểm tra hộp thư của bạn và nhấp vào liên kết xác nhận. Nếu bạn không nhận được nó, hãy kiểm tra các thư mục hộp thư khác hoặc nhấp vào nút gửi lại.',
            resend: 'Gửi lại email',
          },
        },
      },
      domain: {
        form: {
          errors: {
            email_format: 'Vui lòng nhập đúng định dạng email',
            domain_format: 'Vui lòng nhập đúng định dạng tên miền',
            required: 'Đây là trường bắt buộc',
          },
        },
        content: {
          dns: {
            title: 'Được rồi! Hãy làm theo hướng dẫn được mô tả bên dưới.',
            content: {
              spf: {
                existed: {
                  item1: `Chỉnh sửa bản ghi <span style="font-weight:bold">{{bold_text1}}</span> và hợp nhất bản ghi hiện có của bạn
              ghi lại và hợp nhất <span style="font-weight:bold">{{bold_text2}}</span> hiện có của bạn với hiển thị biến bên dưới`,
                  item2: `Ví dụ: nếu miền của bạn đã có bản ghi:`,
                  item3: `Vì vậy, biến được tạo sẽ trông như thế này:`,
                },
                no_content: {
                  item1: `Tuyệt vời! Hãy làm theo hướng dẫn được mô tả bên dưới.`,
                  item2: `Tạo bản ghi <span style="font-weight:bold">{{bold_text1}}</span> mới`,
                  item3: `Điền vào trường <span style="font-weight:bold">{{bold_text1}}</span>. Tùy thuộc vào nhà cung cấp, bạn có thể phải làm theo một trong các tùy chọn sau:`,
                  item4: `Để trống`,
                  item5: 'Điền nó bằng @',
                  item6: 'Nhập tên miền của bạn ({{domainName}})',
                  item7:
                    'Điền vào <span style="font-weight:bold">{{bold_text1}}</span> của bản ghi này với biến được hiển thị bên dưới',
                },
                mainContent: {
                  item1: ` Kiểm tra xem bạn đã có bản ghi <span style="font-weight:bold">{{bold_text1}}</span> trong DNS của mình chưa. Nó phải là một bản ghi loại <span style="font-weight:bold">{{bold_text2}}</span> với các biến SPF (bắt đầu bằng "v=spf1"). Việc kiểm tra này sẽ xác định các bước tiếp theo của bạn. ghi`,
                  item2: 'Tôi không tìm thấy bản ghi được mô tả ở trên',
                  item3: 'Tôi đã tìm thấy bản ghi được mô tả ở trên',
                },
              },
              other: {
                keyword: `Tạo bản ghi <span style="font-weight:bold">{{bold_text}}</span> mới`,
                host: `Điền vào trường <span style="font-weight:bold">Tên (Máy chủ)</span> của bản ghi này với biến được hiển thị bên dưới`,
                value: `Điền vào trường <span style="font-weight:bold">Value</span> của bản ghi này với biến được hiển thị bên dưới`,
              },
            },
          },
          step1: {
            title: 'Nhập tên miền bạn muốn xác minh',
          },
          step2: {
            title: 'Đăng nhập vào nhà cung cấp tên miền của bạn',
            content: `Để xác minh miền của bạn, bạn cần đặt bản ghi DNS cho miền của mình.`,
            list: {
              item1: `Mở tab mới và điều hướng và <span style="font-weight:bold">{{bold_text}}</span>
                  tới nền tảng nơi tên miền của bạn được lưu trữ`,
              item2: `Tìm màn hình nơi bạn có thể quản lý miền của mình. Sẽ có một tùy chọn để điều chỉnh <span style="font-weight:bold">{{bold_text}}</span>. của bạn`,
              item3: `Khi bạn tìm thấy nó, bạn đã sẵn sàng cho bước tiếp theo!`,
            },
          },
          step3: {
            title: 'Thêm mục vào cài đặt DNS của bạn',
            content: ` Trong cài đặt miền của bạn, bạn cần thêm bản ghi cho {{domain_name}}.`,
          },
          step4: {
            title: 'Xác nhận',
            content1: `Tuyệt vời! Tên miền của bạn đã được xác minh! Bây giờ bạn có thể tận hưởng nền tảng này bằng cách gửi email của mình từ bất kỳ địa chỉ nào được liên kết với miền này.`,
            content2: `Ví dụ: bạn có thể gửi email từ company@ hoặc info@. Tùy bạn!`,
          },
          step5: {
            title: 'Đặt người gửi mặc định',
            content: `Người gửi mặc định là địa chỉ email được sử dụng trong email của bạn dưới dạng địa chỉ 'Từ'. Chúng tôi đặc biệt khuyên bạn nên đặt người gửi mặc định để giúp tăng hiệu suất gửi thư của mình.`,
          },
          step6: {
            title: 'Thành công!',
            content: `Tuyệt vời! Miền của bạn hiện đã được xác minh và người gửi mặc định đã được đặt.`,
          },
        },
      },
    },
    template: {
      messages: {
        update_success: 'Cập nhật template thành công',
        create_success: 'Tạo template thành công',
      },
    },
    contact: {
      tabs: {
        overview: 'Tổng quan',
        groups: 'Nhóm',
        all_contacts: 'Tất cả liên lạc',
      },
      form: {
        create_group: 'Tạo nhóm',
        group_name: 'Tên nhóm',
        group: 'Nhóm',
        last_name: 'Họ',
        first_name: 'Tên',
        step1: 'Thêm thông tin chi tiết',
        step2: 'Chọn nhóm liên hệ',
        each_one: 'Thêm từng liên hệ',
        import: 'Nhập danh sách liên hệ. Tải file mẫu tại đây',
        csv: {
          heading: 'Chọn CSV file',
          title: 'Gồm 3 cột (last_name, first_name, email)',
          example: 'File mẫu',
          start: 'Bắt đầu',
        },
        errors: {
          required: 'Đây là trường bắt buộc',
          select_file: 'Vui lòng chọn file',
          select_group: 'Vui lòng chọn nhóm liên hệ cần thêm vào',
        },
        messages: {
          group_success: 'Tạo nhóm thành công',
          contact_success: 'Tạo liên lạc thành công',
          import_success: 'Thêm danh sách liên hệ thành công',
        },
      },
      table: {
        head: {
          recipient: 'Người nhận',
          status: 'Tình trạng',
          name: 'Tên',
          email: 'Email',
        },
        body: {
          active: 'Hoạt động',
          subscribe: 'Đã đăng ký',
          unsubscribe: 'Hủy đăng ký',
          deactive: 'Ngưng hoạt động',
          deleted: 'Đã xóa',
        },
      },
      messages: {
        remove_contact_success: 'Xóa contact thành công',
        remove_group_success: 'Xoá nhóm thành công',
      },
      overview: {
        summary: 'Bảng tóm tắt',
        all_contacts: 'Tất cả liên lạc',
        new_contacts: 'Liên lạc mới',
        subscribed: 'Đã đăng ký',
        contact_today: 'Liên lạc mới trong ngày',
        unsubscribed: 'Hủy đăng ký',
        open_rate: 'Tỉ lệ mở',
        click_rate: 'Tỉ lệ click',
        recent_growth: 'Tăng trưởng gần đây',
        change: `Những thay đổi về đối tượng trong {{range}} ngày qua`,
        performance: 'Hiệu suất của người nhận',
        growth: 'Liên hệ tăng trưởng',
        form: 'Xây dựng mối quan hệ. Phát triển danh sách của bạn với các tính năng của chúng tôi và tạo biểu mẫu hoặc trang đích để thu thập địa chỉ liên hệ mới',
        data: 'Số liên hệ của ngày',
        dropdown: '{{range}} ngày trước',
      },
    },
    campaign: {
      table: {
        head: {
          name: 'Tên',
          delivered: 'Lượt nhận',
          opened: 'Lượt mở',
          clicked: 'Lượt click',
        },
      },
      form: {
        send_to: 'Gửi đến',
        summary: 'Mục tiêu',
        summary_content_b2c:
          'Chiến dịch của bạn sẽ được gửi đến <span style="font-weight:bold;font-size:18px">{{recipients}}</span> người nhận. Số tiền chi trả cho chiến dịch là <span style="font-weight:bold;font-size:18px">{{money}}</span>',
        summary_content_b2b:
          'Chiến dịch của bạn sẽ được gửi đến <span style="font-weight:bold;font-size:18px">{{recipients}}</span> người nhận.',
        subject_content: 'Tiêu đề và nội dung',
        fromEmail: 'Email gửi',
        content: 'Nội dung',
        template: 'Chọn từ các mẫu được lưu trên tài khoản của bạn',
        setting: 'Cài đặt và theo dõi',
        tracking: 'Theo dõi',
        campaignName: 'Tên chiến dịch',
        tracks: {
          open: {
            title: 'Tìm hiểu chính xác số lần người nhận chú ý đến việc mở email liên lạc mà bạn đã gửi.',
            content: 'Theo dõi số lần mở',
          },
          click: {
            title: 'Theo dõi số lần nhấp chuột',
            content:
              'Tìm hiểu xem người nhận có đủ quan tâm không chỉ để mở chiến dịch của bạn mà còn tương tác với họ hay không.',
          },
          stats: {
            title: 'Nhận số liệu thống kê chiến dịch vào hộp thư email của bạn',
            content:
              'Bạn sẽ nhận được email có số liệu thống kê tổng thể về chiến dịch như tỷ lệ mở, tỷ lệ nhấp và tỷ lệ thoát trong vòng 48 giờ sau khi gửi.',
          },
        },
        errors: {
          required: 'Đây là trường bắt buộc',
          space: 'Không bao gồm khoảng trống',
          group: 'Vui lòng chọn nhóm cần gửi',
          format: 'Vui lòng nhập đúng định dạng',
          template: 'Vui lòng chọn template',
        },
      },
      messages: {
        update_success: 'Cập nhật chiến dịch thành công',
        create_success: 'Tạo chiến dịch thành công',
      },
      schedule: {
        schedule_campaign: 'Lên lịch chiến dịch',
        time_at: 'Thời gian',
        times: 'Số lần gửi',
        future_datetime: 'Vui lòng chọn ngày giờ tương lai',
        select_datetime: 'Vui lòng chọn ngày giờ',
        send_time: 'Gửi 1 lần',
      },
    },
  },

  translate_catalog: {
    city: ['Thành phố', 'Tỉnh'],
    district: ['Thành phố', 'Phường', 'Huyện', 'Thị xã', 'Quận'],
    ward: ['Thị trấn', 'Xã', 'Phường'],
    street: ['Đường', 'Phố', 'Tỉnh lộ', 'duong', 'Quốc Lộ', 'Đại Lộ'],
    demands: ['Cần bán', 'Cho thuê', 'Cần mua', 'Cần thuê'],
    industries: {
      real_estate: 'Bất động sản',
      spa_beauty: 'Spa/Làm đẹp',
      cosmetics: 'Mỹ phẩm',
      mother_and_baby: 'Mẹ và Bé',
      electronics_technology: 'Đồ điện tử/Công nghệ',
      interior_exterior_furniture: 'Nội/Ngoại thất',
      travel: 'Du lịch',
      cars_motorcycles: 'Ô tô/Xe máy',
      food_beverages: 'Thực phẩm/Đồ ăn/Đồ uống',
      fashion: 'Thời trang',
      home_appliances: 'Đồ gia dụng',
      education: 'Giáo dục',
      pets: 'Thú cưng',
      jobs_recruitment: 'Việc làm/Tuyển dụng',
      finance_banking: 'Tài chính/Ngân hàng',
    },
    genders: {
      male: 'Nam',
      female: 'Nữ',
      neutral: 'Trung tính',
      non_disclosed: 'Không đề cập',
      all: 'Tất cả',
    },
    categories: {
      apartment: {
        title: 'Căn hộ/Chung cư',
        apartment: 'Chung cư',
        duplex: 'Duplex',
        penthouse: 'Penthouse',
        serviced_apartment: 'Căn hộ dịch vụ, mini',
        housing_project: 'Tập thể, cư xá',
        officetel: 'Officetel',
      },
      house: {
        title: 'Nhà ở',
        town_house: 'Nhà mặt phố, mặt tiền',
        alley_house: 'Nhà ngõ, hẻm',
        villa: 'Nhà biệt thự',
        terrace_house: 'Nhà phố liền kề',
      },
      land: {
        title: 'Đất',
        industrial_land: 'Đất công nghiệp',
        agricultural_land: 'Đất nông nghiệp',
        residential_land: 'Đất thổ cư',
        ground_project: 'Đất nền dự án',
      },
      commercial: {
        title: 'Văn phòng, Mặt bằng kinh doanh',
        business_premises: 'Mặt bằng kinh doanh',
        office: 'Văn phòng',
        shophouse: 'Shophouse',
        officetel: 'Officetel',
      },
      rooming_house: {
        title: 'Phòng trọ',
        rooming_house: 'Phòng trọ',
      },
    },
  },
  tool: {
    posted: 'Đã đăng',
    collection: 'Bộ sưu tập',
    search: 'Tìm kiếm',
    profile: 'Trang cá nhân',
    page: 'Trang',
    lifetime: 'Vĩnh viễn',
    purchase: 'Mua gói',
    post_success: 'Đăng thành công tin',
    post_failed: 'Đăng thất bại tin',
    target_not_found: {
      group: 'Không tìm thấy nhóm',
      friend: 'Không tìm thấy bạn bè',
      common: 'Không có dữ liệu',
    },
    mention: {
      friend: 'Bạn bè',
      group: 'Nhóm',
      members: 'Thành viên',
      page: 'Trang',
    },
    remove_pending: 'Xóa bài chờ duyệt:',
    skip_group_have_more_than: `Bỏ qua nhóm có trên {{input}} bài “Chờ Duyệt”`,
    content: 'Đăng bài',
    comment: 'Bình luận',
    estimated_time: '{{posts}} bài trong {{time}}',
    post_content: 'Bài chứa {{images}} hình, {{video}} video',
    post_link: 'Chia sẻ link: {{link}}',
    post_unkown: 'Không xác định',
    table: {
      name: 'Tên nhóm',
      members: 'Số TV',
      daily_posts: 'Bài/ngày',
      new_members: 'TV mới',
    },
    upload: {
      title: 'Hình ảnh/Video',
      first_content: '- Kéo thả hoặc nhấn chọn ảnh/video',
      second_content: '- Dung lượng ảnh/video tối đa 500mb',
    },
    time: {
      days: 'ngày',
      hours: 'giờ',
      minutes: 'phút',
      seconds: 'giây',
    },
    status: {
      success: 'Hoàn thành',
      pending: 'Chờ duyệt',
      error: 'Lỗi',
    },
    ai: {
      rewrite: 'AI viết lại',
      hashtag: 'Hashtag thông minh',
    },
    form: {
      fields: {
        delay: {
          label: 'Cách nhau',
          from: 'Từ (giây)',
          to: 'Đến (giây)',
        },
        up_times: 'Số lần up',
        assets: 'Ảnh',
        sequence: {
          label: 'Lần lượt',
          description: 'Đăng lần lượt (bài 1->10 vào từng nhóm)',
        },
        rotate: {
          label: 'Song song',
          description: `Đăng song song (bài 1 vào nhóm 1, bài 2 vào nhóm 2)`,
        },
      },
      errors: {
        delay: {
          min: 'Giá trị tối thiểu là {{number}}',
          max: 'Giá trị tối đa là {{number}}',
        },
      },
    },
    btn: {
      post: 'Bắt đầu',
      save: 'Lưu',
      view: 'Xem bài',
      install: 'Cài đặt tiện ích mở rộng',
      stop: 'Dừng',
      pending: 'Chờ duyệt',
      success: 'Thành công',
      more: 'Xem thêm',
      export: 'Xuất file',
      start: 'Bắt đầu',
      canceling: 'Đang dừng',
      skip: 'Bỏ qua',
      guide: 'Xem HDSD',
      confirm: 'Xác nhận',
      cancel: 'Hủy',
      retry: 'Thử lại sau {{time}}',
      continue: 'Tiếp tục',
      remain: 'Bắt đầu sau {{time}}',
      slowdown: 'Chậm lại...',
      payment: 'Thanh toán',
      receive_account: 'Tài khoản nhận $',
      withdraw: 'Rút $',
      edit: 'Chỉnh sửa',
      prev: 'Quay lại',
      next: 'Tiếp theo',
      export_groups: 'Xuất file nhóm',
    },
    affiliate: {
      statuses: {
        draft: 'Chưa thanh toán',
        pending: 'Chờ duyệt',
        accepted: 'Đã duyệt',
        rejected: 'Bị từ chối',
        unpaid: 'Có thể nhận',
        paid: 'Đã nhận',
      },
      chart: {
        earn: 'Thu nhập',
        package: 'Gói',
      },
      table: {
        date: 'Ngày',
        name: 'Người dùng',
        online: 'Online',
        payment_value: 'Đơn giá',
        package: 'Gói',
        discount: 'Giảm giá %',
        payment: 'Thanh toán',
        status: 'Tình trạng',
        commission: 'Hoa hồng',
      },
      commission: {
        title: 'Cập nhật thông tin nhận hoa hồng',
        front: 'Mặt trước',
        back: 'Mặt sau',
        cic: 'Căn cước công dân',
        bankInfo: 'Thông tin ngân hàng',
        bank: 'Ngân hàng',
        bankNumber: 'Số tài khoản',
        owner: 'Tên tài khoản',
        email: 'Email nhận kết quả',
        form: {
          error: {
            required: 'Đây là trường bắt buộc',
            invalid_format: 'Định dạng không hợp lệ',
            invalid_format_alphabet: 'Chỉ chứa ký tự in hoa không dấu',
            maximum: 'Tối đa {{amount}}',
            minimum: 'Tối thiểu {{amount}}',
            refcode_length: 'Tối đa {{amount}} ký tự',
            refcode_format: 'Bao chữ và số không chứa ký tự đặc biệt, khoảng trống',
          },
        },
      },
      online: {
        recent: 'Mới đây',
        yesterday: 'Ngày hôm qua',
        specific_day: '{{day}} ngày trước',
        previous_month: 'Tháng trước',
        specific_month: '{{month}} tháng trước',
      },
      package: 'Gói',
      previous_month: 'vs tháng trước',
      current_month: 'Tháng này',
      next_month: 'Tháng sau(dự kiến)',
      bait: 'Hoa hồng toàn hệ thống tháng này',
      withdraw: 'Có thể rút',
      total: 'Tổng thu nhập',
      refcode: 'Mã giới thiệu (Ref code)',
      monthly_package: 'gói tháng',
      annual_package: 'gói năm',
      contract: 'Chi tiết điều khoản',
      list_commission: 'Danh sách hoa hồng',
      status: 'Tình trạng',
    },
    export: {
      remove_post: 'Xóa bài',
      group_uid: 'UID nhóm',
      group_name: 'Tên nhóm',
      removed: 'Đã xóa',
      remains: 'Còn lại',
      type: 'Loại',
      success: 'Thành công',
      fail: 'Thất bại',
      post: 'Bài đăng',
      post_uid: 'UID bài đăng',
      status: 'Tình trạng',
      comment: 'Bình luận',
      pending: 'Chờ duyệt',
      actor: 'Người đăng',
      actor_uid: 'UID người đăng',
      categorize: 'Phân loại',
      match_product: 'Ráp SP',
      phone_number: 'SĐT',
      email: 'Email',
      location: 'Địa chỉ',
      gender: 'Giới tính',
      inbox: 'Nhắn tin',
      add_friend: 'Kết bạn',
      like: 'Thích',
      poke: 'Chọc lét',
      name: 'Tên',
      remove_friend: 'Hủy kết bạn',
      source: {
        comment: 'Bình luận',
        post: 'Bài đăng',
        reshare: 'Người chia sẻ',
        commenter: 'Người bình luận',
        reactor: 'Người tương tác',
      },
    },
    popup: {
      internet: {
        title: 'Lưu ý',
        content: `Thẻ Facebook của bạn đang có dạng web.facebook hoặc m.facebook, phần mềm không thể hoạt động. Hãy dùng wifi được phát ra từ Modem (ko phát từ thiết bị 3G 4G)`,
      },
      warning: {
        title: 'Lưu ý',
        sub_title: 'Bạn thấy thông báo này do tool KHÔNG ĐĂNG ĐƯỢC 5 nhóm hoặc 5 bài liên tục',
        treatment: 'Lý do:',
        items: [
          {
            name: 'Lỗi nhóm',
            description: 'Nhóm deactive, Chưa duyệt tham gia, Admin cấm bạn đăng bài, Giới hạn bài viết chờ duyệt...',
            solution: 'Thoát các nhóm này và dùng tool lại bình thường.',
          },
          {
            name: 'Dính spam',
            description: 'Đăng thử bằng tay vào 1 nhóm, nếu hiện cảnh báo là đã dính.',
            solution: 'Cho tài khoản này nghỉ đến khi đăng tay được, có thể dùng tài khoản khác trong khi chờ.',
            prevent: `Cách đăng hạn chế spam: Thời gian cách nhau mặc định (hoặc lâu hơn hơn), Đăng nhiều dạng nội dung theo chế độ song song hoặc lần lượt, Đăng bài kèm bình luận, Dùng AI Spin`,
          },
        ],
      },
      warning_action: {
        title: 'Chậm lại',
        sub_title: 'Bạn đã đăng quá nhiều trong khoảng thời gian ngắn !',
      },
      payment: {
        title: 'Công cụ đăng bài & bình luận vào ',
        sub: 'hội nhóm Facebook',
        release: 'RA MẮT TRONG...',
        time: {
          days: 'Ngày',
          hours: 'Giờ',
          minutes: 'Phút',
          seconds: 'Giây',
        },
        access: {
          main_content: 'Đăng ký sớm',
          secondary_content: '<span style="font-weight: bold; font-size:1.25rem; color:{{color}}">1 tháng FREE</span>',
          subtitle: 'Sau đó 50.000đ/tháng',
        },
        deal: {
          main_content: 'DEAL Trọn đời',
          secondary_content: '500.000đ',
          subtitle: 'Dùng vĩnh viễn',
        },
      },
      collection: {
        title: 'Tên bộ sưu tập',
      },
      openai: {
        title: 'Cập nhật OpenAI API Key',
      },
      early_access: {
        title: 'Đăng ký sớm Thành Công !!!',
        release:
          'Bạn đã nhận được <span style="font-weight: bold; color:{{color}}">1 Tháng FREE</span> bắt đầu tính sau...',
        purchase: 'MUA ƯU ĐÃI NÀY TRƯỚC KHI NÓ BIẾN MẤT',
        time: {
          days: 'Ngày',
          hours: 'Giờ',
          minutes: 'Phút',
          seconds: 'Giây',
        },
        deal: {
          main_content: 'DEAL Trọn đời',
          secondary_content: '500.000đ',
          subtitle: 'Dùng vĩnh viễn',
        },
      },
      extension: {
        title: 'Bạn phải cài đặt tiện ích mở rộng',
        content:
          'Để sử dụng các công cụ, trước tiên bạn cần cài đặt tiện ích mở rộng chrome của chúng tôi. Chúng tôi xin lỗi vì sự bất tiện này.',
      },
      pricing: {
        title: 'Công cụ đăng & bình luận vào ',
        sub: 'hội nhóm Facebook',
        heading: 'BẢNG GIÁ',
        features: [
          'Không cần đăng nhập ID và mật khẩu FB 🔒',
          'Dùng được cho Trang cá nhân và Trang',
          'Hạn chế tối đa Spam và CP',
          'AI viết lại nội dung miễn phí',
          'Lưu nhóm yêu thích',
        ],
        tier: 'Bậc',
        tiers: [
          {
            tier: 1,
            name: 'BASIC',
            cost: {
              month: 100000,
              year: 50000,
            },
            features: [
              {
                text: 'Đăng bài (2 chế độ)',
                info: 'Đăng 1 bài vào nhiều nhóm / Đăng lần lượt nhiều bài vào nhiều nhóm.',
              },
              {
                text: 'AI spin',
                info: 'Mỗi lần đăng bài, tool tự động dùng AI viết lại nội dung để né Spam.',
              },
              {
                text: 'Né Checkpoint',
                info: '(Tính năng ẩn) Tool tự động phát hiện và né Checkpoint.',
              },
              {
                text: 'Phát hiện Spam ',
                info: '(Tính năng ẩn) Tool tự động phát hiện Spam và dừng bảo vệ tài khoản.',
              },
              {
                text: 'Đăng chậm né Spam',
                info: '(Tính năng ẩn) Tool tự động phát hiện và đăng chậm né Spam.',
              },
              {
                text: 'Thời gian đăng ngẫu nhiên né Spam',
                info: 'Tool chọn thời gian ngẫu nhiên giữa 2 lần đăng để né Spam.',
              },
              {
                text: 'Lưu nhóm yêu thích, bài đã đăng',
                info: 'Bạn có thể chọn và lưu các nhóm yêu thích, bài đã đăng và chọn nhanh vào lần sau.',
              },
              {
                text: 'Dùng với nhiều tài khoản Facebook',
                info: 'Một tài khoản tool có thể dùng cho bao nhiêu tài khoản Facebook cũng được.',
              },
              {
                text: 'Dùng với Trang và Trang cá nhân',
                info: 'Tool có thể dùng được với Trang và Trang cá nhân.',
              },
            ],
          },
          {
            tier: 2,
            name: 'PLUS',
            cost: {
              month: 200000,
              year: 100000,
            },
            features: [
              {
                text: 'Tất cả tính năng của gói BASIC, và:',
                info: 'Gói PLUS sẽ có toàn bộ tính năng của gói BASIC.',
              },
              {
                text: 'Đăng bài (4 chế độ)',
                info: 'Đăng bài kèm bình luận ngay sau đó / Đăng bài song song né Spam.',
              },
              {
                text: 'Đăng bài có phông nền',
                info: 'Đăng bài với phông nền ngẫu nhiên tăng tương tác.',
              },
              {
                text: 'Đăng video / clip',
                info: 'Đăng video clip dưới <500 Mb.',
              },
              {
                text: 'Xoá bài chờ duyệt',
                info: 'Xoá các bài chờ duyệt (pending, admin không duyệt) trong các nhóm.',
              },
              {
                text: 'Bình luận dạo',
                info: 'Bình luận vào các bài viết mới nhất trong các nhóm.',
              },
              {
                text: 'Chia sẻ link',
                info: 'Đăng dạng chia sẻ link vào các nhóm.',
              },
              {
                text: 'Úp bài đã đăng',
                info: 'Úp lại các bài đã đăng trong các nhóm.',
              },
              {
                text: 'Xuất báo cáo',
                info: 'Xuất báo cáo đầy đủ thông tin.',
              },
            ],
          },
          {
            tier: 3,
            name: 'PRO',
            cost: {
              month: 400000,
              year: 200000,
            },
            features: [
              {
                text: 'Tất cả tính năng của gói PLUS, và:',
                info: 'Gói PRO sẽ có toàn bộ tính năng của gói PLUS.',
              },
              {
                text: 'Trang cá nhân nhắn tin, bình luận, like, lọc tương tác, chọc, huỷ kết bạn danh sách bạn bè',
                info: 'Tool tự động thả like, bình luận, chọc lét, nhắn tin, hoặc huỷ kết bạn, có sử dụng AI spin để né Spam; lọc tương tác bạn bè theo thời gian.',
              },
              {
                text: 'Trang cá nhân tự động gửi kết bạn với danh sách UID',
                info: 'Gửi kết bạn hàng loạt theo UID.',
              },
              {
                text: 'Trang nhắn tin cho khách đã từng inbox',
                info: 'Tool tự động nhắn tin cho khách đã từng inbox, có sử dụng AI spin để né Spam',
              },
              {
                text: 'Trang like, bình luận, lọc tương tác khách đã follow',
                info: 'Tool tự động thả like, bình luận, nhắn tin, có sử dụng AI spin để né Spam; lọc tương tác người theo dõi theo thời gian.',
              },
              {
                text: 'Đăng tin có tag (nêu bật, tất cả)',
                info: 'Tăng tương tác và lượng reach của tin',
              },
            ],
          },
          {
            tier: 4,
            name: 'MAX',
            cost: {
              month: 1000000,
              year: 500000,
            },
            features: [
              {
                text: 'Tất cả tính năng của gói PRO, và:',
                info: 'Gói MAX sẽ có toàn bộ tính năng của gói PRO.',
              },
              {
                text: 'Tự động tìm kiếm khách hàng (toàn bộ hoặc trong các nhóm chỉ định)',
                info: 'Dựa vào từ khoá bạn đưa vào, tool sẽ tìm bài viết có từ khoá trên toàn bộ Facebook hoặc chỉ tìm trong các nhóm bạn đã chọn.',
              },
              {
                text: 'Tự động tìm thông tin khách hàng',
                info: 'Tool tự động tìm ra Số điện thoại, Email, Nơi ở, Ngày sinh (nếu có) khách hàng.',
              },
              {
                text: 'AI tự động phân loại khách hàng',
                info: 'Tool sử dụng AI để lọc và phân loại dạng khách hàng.',
              },
              {
                text: 'AI tự động ráp sản phẩm phù hợp',
                info: 'Tool sử dụng AI để đọc nội dung bài đăng / bình luận, các sản phẩm của bạn và tự động gửi sản phẩm phù hợp với nhu cầu của khách hàng nhất.',
              },
              {
                text: 'AI tự động bình luận, gửi tin nhắn được cá nhân hóa, tự động kết bạn với khách hàng',
                info: 'Tool sử dụng AI để tự động bình luận, gửi tin nhắn inbox, kết bạn với khách hàng.',
              },
            ],
          },
        ],
        monthly: 'Hàng tháng',
        semi_annual: 'Nửa năm',
        annual: 'Hàng năm',
        month_value: '100K',
        annual_value: '50K',
        discount: 'Tiết kiệm tới {{percent}}%',
        bill_annual: 'Năm',
        bill_monthly: 'Tháng',
      },
      spin: {
        title: 'Lưu ý',
        content: `<p>Nên dùng “<a href="{{link}}" target="_blank">AI viết lại</a>” khi đăng trên {{amount}} nhóm để hạn chế dính spam</p>`,
      },
      confirm_job: {
        title: 'Lưu ý',
        content: `Lưu chiến dịch xảy lỗi, nó sẽ ảnh hưởng đến quá trình úp bài, vẫn tiếp tục?`,
      },
    },
    lead: {
      btn: {
        select: 'Chọn',
      },
      ad_categories: {
        title: 'Danh mục',
        technology_electronics: 'Công nghệ & Điện tử',
        fashion_apparel: 'Thời trang & Quần áo',
        health_wellness: 'Sức khỏe & Thể chất',
        beauty_cosmetics: 'Làm đẹp & Mỹ phẩm',
        food_beverage: 'Thực phẩm & Đồ uống',
        travel_tourism: 'Du lịch & Lữ hành',
        automotive: 'Ô tô',
        real_estate_property: 'Bất động sản & Nhà đất',
        financial_services: 'Dịch vụ tài chính',
        home_garden: 'Nhà cửa & Vườn',
        education_training: 'Giáo dục & Đào tạo',
        sports_fitness: 'Thể thao & Thể hình',
        entertainment_media: 'Giải trí & Truyền thông',
        ecommerce_retail: 'Thương mại điện tử & Bán lẻ',
        events_occasions: 'Sự kiện & Dịp đặc biệt',
        gaming: 'Trò chơi',
        personal_development_coaching: 'Phát triển cá nhân & Tư vấn',
        non_profit_causes: 'Phi lợi nhuận & Từ thiện',
        pet_care_services: 'Chăm sóc thú cưng & Dịch vụ',
        lifestyle_hobbies: 'Phong cách sống & Sở thích',
      },
      time_range: {
        title: 'Thời gian',
        days_ago: '{{days}} ngày trước',
      },
      mode: {
        saved_link: 'Link đã lưu',
        group: 'Nhóm',
        ads: 'Quảng cáo',
      },
      stat: 'Phễu',
      keyword: 'Từ khoá tìm kiếm',
      product_label: 'Sản phẩm hoặc Dịch vụ',
      product_placeholder:
        'Nội dung sản phẩm, đối tượng khách hàng, thông tin liên lạc để AI phân tích và phân loại đối tượng chính xác',
      saved_link: {
        select: 'Chọn',
        search_with_place: 'Nơi tìm',
      },
      categorize: {
        title: 'Phân loại',
        seller: 'Người bán',
        buyer: 'Người mua',
        unknown: 'Không xác định',
        reactor: 'Người tương tác',
        commenter: 'Người bình luận',
        reshare: 'Người chia sẻ',
      },
      action: {
        title: 'Hành động',
        comment: {
          title: 'Bình luận',
          level_1: 'AI trả lời khách hàng',
          level_2: 'Giữ nguyên nội dung sản phẩm',
        },
        add_friend: 'Kết bạn',
        inbox: 'Nhắn tin (Beta)',
        like: 'Thích',
      },
      chart: {
        all: 'Tất cả',
        categorize: 'Phân loại',
        total_post: 'Tổng bài',
        product: 'Ráp sản phẩm',
        comment: 'Bình luận',
        add_friend: 'Kết bạn',
        like: 'Thích',
        inbox: 'Nhắn tin',
      },
      table: {
        head: {
          post: 'Bài đăng',
          name: 'Tên người đăng',
          uid: 'UID',
          comment: 'Bình luận',
          product: 'Ráp sản phẩm',
          add_friend: 'Kết bạn',
          like: 'Thích',
          phone: 'SĐT',
          email: 'Email',
          location: 'Địa chỉ',
          gender: 'Giới tính',
          type: 'Loại',
          inbox: 'Nhắn tin',
          categorize: 'Phân loại',
        },
      },
      form: {
        errors: {
          max_content: 'Số lượng ký tự tối đa là {{max}}',
        },
      },
    },
    interact: {
      actions: {
        poke: 'Chọc lét',
        like: 'Like',
        comment: 'Bình luận',
        inbox: 'Nhắn tin',
        remove_friend: 'Xóa bạn',
        add_friend: 'Kết bạn',
      },
      form: {
        filter: 'Lọc tương tác',
        from: 'Từ',
        to: 'Đến',
        post_amount: 'Số bài',
        min: `Ngày kết thúc nhỏ nhất từ {{date}}`,
        add_friend_content: 'Danh sách UID',
      },
      table: {
        name: 'Tên bạn bè',
        comment: 'Bình luận',
        reaction: 'Tương tác',
        messages: 'Nhắn tin',
      },
    },
    up_post: {
      campaign: 'Chiến dịch đã đăng',
    },
    messages: {
      amount_pending_posts: 'Có hơn {{amount}} bài viết đang chờ duyệt trong nhóm',
      exceeded_pending_posts: 'Số bài chờ duyệt trong nhóm nhiều hơn cài đặt của bạn',
      participate_approval: 'Chờ phê duyệt tham gia nhóm',
      check_group: 'Nhóm có vấn đề: {{warning_message}}',
      error_group: 'Nhóm có vấn đề',
      redirect_confirm: 'Xác nhận chuyển hướng',
      bought_lifetime: 'Bạn đã mua gói vĩnh viễn',
      empty_content: 'Nội dung không được bỏ trống',
      select_content: 'Hãy in đậm phần nội dung cần chỉnh sửa',
      empty_post_content: 'Chọn ảnh và nội dung cần đăng',
      select_group: 'Vui lòng chọn nhóm để đăng',
      select_friend: 'Vui lòng chọn bạn bè để tương tác',
      invalid_uid: 'UID không hợp lệ',
      select_action: 'Vui lòng chọn hành động',
      delay_time: 'Vui lòng xem lại thời gian delay',
      maximum_words: `{{Đoạn văn}} không được quá {{maximum}} từ`,
      maximum_part: `Tối đa {{maximum}} {{đoạn văn}}`,
      newline_spin: `Không được xuống dòng trong {{đoạn văn}}`,
      maximum_image: 'Tối đa {{maximum}} ảnh',
      spin_error: `Có lỗi xảy ra trong quá trình “AI Viết Lại” nội dung. Vui lòng kiểm tra!`,
      spin_tab_error: `Đã xảy ra lỗi trong quá trình “AI Viết Lại” nội dung ở tab {{tabs}}. Ấn xác nhận để tiếp tục đăng nội dung ở các tab còn lại!`,
      required_keyword: 'Bắt buộc điền từ khóa',
      required_tier3: 'Tính năng dành cho người dùng đã mua gói MAX',
      required_action: 'Vui lòng chọn ít nhất 1 hành động',
      required_categorize: 'Vui lòng chọn ít nhất 1 đối tượng khách hàng',
      categorize_failed: 'Có lỗi xảy ra trong quá trình phân loại khách hàng',
      support_max: 'Tính năng dành cho người dùng đã mua gói MAX',
      not_found_lead: 'Không tìm thấy bài viết liên quan',
      limit_inbox: 'Đã đạt giới hạn số lượng tin nhắn ({{amount}} tin nhắn/ngày)',
      limit_read_facebook_info: 'Đã đạt giới hạn số lượng lấy thông tin ({{amount}} người/ngày)',
      limit_crawl: 'Đã đạt giới hạn cào dữ liệu ({{amount}} bài/ngày)',
      select_comments: 'Chọn ít nhất 1 bình luận',
      select_campaign: 'Chọn ít nhất 1 chiến dịch',
      update_bank_success: 'Cập nhật thông tin thành công',
      update_bank_fail: 'Cập nhật thông tin thất bại',
      sent_withdraw_request: 'Đã gửi yêu cầu',
      failed_send_withdraw_request: 'Xảy ra lỗi trong quá trình gửi yêu cầu',
      dont_enough_commission_withdraw: 'Hoa hồng tối thiểu {{amount}} mới có thể gửi yêu cầu',
      remove_link_success: 'Xóa link thành công',
      remove_link_fail: 'Xoá link thất bại',
      select_least_at_one_saved_link: 'Vui lòng chọn ít nhất 1 bài đã lưu',
      select_least_at_one_ads: 'Vui lòng chọn ít nhất 1 bài quảng cáo',
    },
    tooltip: {
      ai_rewrite:
        'Chọn tối đa {{maximum_parts}} đoạn văn bản. Mỗi đoạn không quá {{maximum_words}} từ. Không xuống dòng.',
      banner: 'Chế độ phông nền (tối đa 130 ký tự)',
      mention: 'Tự động tag mọi người/ nêu bật',
      shuffle: 'Tự động xáo trộn thứ tự ảnh/video',
      sale_format: 'Tự động tắt định dạng bài viết mua bán',
      pending_post: 'Số bài chờ duyệt',
      pending_post_removed: 'Đã xóa',
      limit_posts: `Đăng tối đa {{amount}} bài`,
    },
    footer: {
      is: 'là công cụ hỗ trợ kinh doanh online trên Facebook sử dụng AI để giúp các cá nhân và công ty tìm kiếm khách hàng tiềm năng hiệu quả và tiết kiệm thời gian nhất.',
      navs: [
        {
          title: '',
          items: [
            { text: 'Kiếm tiền', link: '#', disable: true, icon: 'tabler:currency-dollar' },
            { text: 'Hướng dẫn sử dụng', link: 'https://doc.fbtool.net', icon: 'streamline:manual-book' },
            { text: 'Câu hỏi thường gặp', link: 'https://doc.fbtool.net/cau-hoi-thuong-gap', icon: 'mdi:faq' },
            { text: 'Hỗ trợ Online', link: 'https://zalo.me/4152692317049608912', icon: 'bx:support' },
          ],
        },
      ],
    },
  },
  activity: {
    audience: 'Người quan tâm',
    click: 'Click',
    comment: 'Comment',
    group_comment: 'Comment nhóm',
    group_post: 'Bài đăng nhóm',
    group_reaction: 'Reaction nhóm',
    group_view: 'Lượt xem nhóm',
    impression: 'Hiển thị',
    member_count: 'Thành viên',
    reaction: 'Reaction',
    sentiment_negative: 'Tiêu cực',
    sentiment_neutral: 'Trung tính',
    sentiment_positive: 'Tích cực',
    share: 'Chia sẻ',
    ads_content: 'Bài quảng cáo',
    seo_content: 'Bài SEO',
    seeding_content: 'Bài seeding',
  },
  today: 'Hôm nay',
  empty_data: 'Không có dữ liệu',
  version: 'build',
  app: `ứng dụng`,
  user: `người dùng`,
  list: `danh sách`,
  edit: `chỉnh sửa`,
  shop: `cửa hàng`,
  blog: `blog`,
  post: `tin`,
  mail: `thư`,
  chat: `trò chuyện`,
  cards: `thẻ`,
  posts: `danh sách tin`,
  create: `tạo`,
  kanban: `bảng`,
  general: `chung`,
  banking: `ngân hàng`,
  booking: `đặt phòng`,
  profile: `hồ sơ`,
  account: `tải khoản`,
  product: `sản phẩm`,
  invoice: `hóa đơn`,
  details: `chi tiết`,
  checkout: `thanh toán`,
  calendar: `lịch`,
  analytics: `phân tích`,
  ecommerce: `thương mại điện tử`,
  management: `quản lý`,
  menu_level_1: `menu cấp 1`,
  menu_level_2a: `menu cấp 2a`,
  menu_level_2b: `menu cấp 2b`,
  menu_level_3a: `menu cấp 3a`,
  menu_level_3b: `menu cấp 3b`,
  menu_level_4a: `menu cấp 4a`,
  menu_level_4b: `menu cấp 4b`,
  item_disabled: `mục vô hiệu`,
  item_label: `mục nhãn`,
  item_caption: `mục chú thích`,
  item_external_link: `mục liên kết ngoài`,
  description: `mô tả`,
  other_cases: `Trường hợp khác`,
  item_by_roles: `mục theo vai trò`,
  only_admin_can_see_this_item: `Chỉ có quản trị viên mới có thể nhìn thấy mục này`,
  banks: [
    {
      name: 'Ngân hàng NN&PT Nông thôn Việt Nam',
      brandName: 'Agribank',
    },
    {
      name: 'Ngân hàng TNHH MTV Dầu khí toàn cầu',
      brandName: 'OceanBank',
    },
    {
      name: 'Ngân hàng TNHH MTV Đại Dương',
      brandName: 'GPBank',
    },
    {
      name: 'Ngân hàng TNHH MTV Xây dựng',
      brandName: 'CB',
    },
    {
      name: 'Ngân hàng TMCP Công thương Việt Nam',
      brandName: 'VietinBank',
    },
    {
      name: 'Ngân hàng TMCP Đầu tư và Phát triển Việt Nam',
      brandName: 'BIDV',
    },
    {
      name: 'Ngân hàng TMCP Ngoại Thương Việt Nam',
      brandName: 'Vietcombank',
    },
    {
      name: 'Ngân hàng TMCP Á Châu',
      brandName: 'ACB',
    },
    {
      name: 'Ngân hàng TMCP An Bình',
      brandName: 'ABBANK',
    },
    {
      name: 'Ngân hàng TMCP Bản Việt',
      brandName: 'Viet Capital Bank',
    },
    {
      name: 'Ngân hàng TMCP Bảo Việt',
      brandName: 'BAOVIET Bank',
    },
    {
      name: 'Ngân hàng TMCP Bắc Á',
      brandName: 'Bac A Bank',
    },
    {
      name: 'Ngân hàng TMCP Bưu điện Liên Việt',
      brandName: 'LienVietPostBank',
    },
    {
      name: 'Ngân hàng TMCP Đại Chúng Việt Nam',
      brandName: 'PVcomBank',
    },
    {
      name: 'Ngân hàng TMCP Đông Á',
      brandName: 'DongA Bank',
    },
    {
      name: 'Ngân hàng TMCP Đông Nam Á',
      brandName: 'SeABank',
    },
    {
      name: 'Ngân hàng TMCP Hàng Hải',
      brandName: 'MSB',
    },
    {
      name: 'Ngân hàng TMCP Kiên Long',
      brandName: 'Kienlongbank',
    },
    {
      name: 'Ngân hàng TMCP Kỹ Thương',
      brandName: 'Techcombank',
    },
    {
      name: 'Ngân hàng TMCP Nam Á',
      brandName: 'Nam A Bank',
    },
    {
      name: 'Ngân hàng TMCP Phương Đông',
      brandName: 'OCB',
    },
    {
      name: 'Ngân hàng TMCP Quân Đội',
      brandName: 'MB',
    },
    {
      name: 'Ngân hàng TMCP Quốc Tế',
      brandName: 'VIB',
    },
    {
      name: 'Ngân hàng TMCP Quốc dân',
      brandName: 'NCB',
    },
    {
      name: 'Ngân hàng TMCP Sài Gòn',
      brandName: 'SCB',
    },
    {
      name: 'Ngân hàng TMCP Sài Gòn Công Thương',
      brandName: 'SAIGONBANK',
    },
    {
      name: 'Ngân hàng TMCP Sài Gòn – Hà Nội',
      brandName: 'SHB',
    },
    {
      name: 'Ngân hàng TMCP Sài Gòn Thương Tín',
      brandName: 'Sacombank',
    },
    {
      name: 'Ngân hàng TMCP Tiên Phong',
      brandName: 'TPBank',
    },
    {
      name: 'Ngân hàng TMCP Việt Á',
      brandName: 'VietABank',
    },
    {
      name: 'Ngân hàng TMCP Việt Nam Thịnh Vượng',
      brandName: 'VPBank',
    },
    {
      name: 'Ngân hàng TMCP Việt Nam Thương Tín',
      brandName: 'Vietbank',
    },
    {
      name: 'Ngân hàng TMCP Xăng dầu Petrolimex',
      brandName: 'PG Bank',
    },
    {
      name: 'Ngân hàng TMCP Xuất Nhập Khẩu',
      brandName: 'Eximbank',
    },
    {
      name: 'Ngân hàng TMCP Phát triển Thành phố Hồ Chí Minh',
      brandName: 'HDBank',
    },
    {
      name: 'Ngân hàng TNHH Indovina',
      brandName: 'IVB',
    },
    {
      name: 'Ngân hàng Liên doanh Việt Nga',
      brandName: 'VRB',
    },
    {
      name: 'Ngân hàng TNHH MTV ANZ Việt Nam',
      brandName: 'ANZVL',
    },
    {
      name: 'Ngân hàng TNHH MTV Hong Leong Việt Nam',
      brandName: 'HLBVN',
    },
    {
      name: 'Ngân hàng TNHH MTV HSBC Việt Nam',
      brandName: 'HSBC',
    },
    {
      name: 'Ngân hàng TNHH MTV Shinhan Việt Nam',
      brandName: 'SHBVN',
    },
    {
      name: 'Ngân hàng TNHH MTV Standard Chartered Việt Nam',
      brandName: 'SCBVL',
    },
    {
      name: 'Ngân hàng TNHH MTV Public Bank Việt Nam',
      brandName: 'PBVN',
    },
    {
      name: 'Ngân hàng TNHH MTV CIMB Việt Nam',
      brandName: 'CIMB',
    },
    {
      name: 'Ngân hàng TNHH MTV Woori Việt Nam',
      brandName: 'Woori',
    },
    {
      name: 'Ngân hàng TNHH MTV UOB Việt Nam',
      brandName: 'UOB',
    },
    {
      name: 'Ngân hàng Chính sách xã hội Việt Nam',
      brandName: 'VBSP',
    },
    {
      name: 'Ngân hàng Phát triển Việt Nam',
      brandName: 'VDB',
    },
    {
      name: 'Ngân hàng Hợp tác xã Việt Nam',
      brandName: 'Co-opBank',
    },
  ],
  tiktok,
};

export default vn;
