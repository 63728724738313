import axios from 'axios';
import Resizer from 'react-image-file-resizer';
// config
import { HOST_API } from '../config';
import { randomInRange, shuffleArray } from './others';
import { getRandomEmoji, watermarkImageWithText } from './watermark';

export const handleUploadFiles = async (formData, callback) => {
  const accessToken = window.localStorage.getItem('accessToken');
  let fileUrl;
  try {
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    const fileUpload = await axios({
      method: 'post',
      url: `${HOST_API}api/v1/assets/upload_image/`,
      config: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
      data: formData,
    });
    if (fileUpload?.data?.message === 'OK') {
      fileUrl = fileUpload?.data?.url;
    }
    if (callback) {
      callback();
    }
  } catch (error) {
    console.error(error);
  }
  return fileUrl;
};

export const uploadFile = async (files) => {
  const images = [];
  /* eslint-disable no-await-in-loop */
  files.forEach(async (file) => {
    if (typeof file === 'string') {
      images.push(file);
    } else {
      const formData = new FormData();
      formData.append('image', file);
      const data = await handleUploadFiles(formData);
      if (data) {
        images.push(data);
      }
    }
  });
  return images;
};

// Upload async files
export const asyncUploadFiles = async (files, callback) => {
  const listPromises = files.map((file) => {
    if (typeof file === 'string') {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        resolve(file);
        // Done
        callback();
      });
    }
    const formData = new FormData();
    formData.append('image', file);
    return handleUploadFiles(formData, callback);
  });

  const result = await Promise.all([...listPromises]);

  return result.filter((uri) => uri);
};

export const isFile = (input) => 'File' in window && input instanceof File;

export const isBlob = (input) => 'Blob' in window && input instanceof Blob;

export const getVideoThumbnail = (file) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(file);
  });

export const isVideo = (file) => {
  try {
    const extension = file?.fileExtension;
    const splited = file?.fileName?.split('.');
    const extensionFromName = splited[splited?.length - 1];
    const videoExtensions = [
      'mp4',
      'mov',
      'avi',
      'mkv',
      'flv',
      'wmv',
      'webm',
      'm4v',
      'mpg',
      'mpeg',
      '3gp',
      '3g2',
      'm2ts',
      'ts',
      'vob',
      'ogv',
      'f4v',
      'rm',
      'rmvb',
      'divx',
      'asf',
    ];
    return [extensionFromName, extension]?.some((ext) => videoExtensions?.indexOf(ext) !== -1);
  } catch (error) {
    return false;
  }
};

export const categorizeFiles = (files = [], shuffle = false) => {
  const data = shuffle ? shuffleArray(files) : files;
  return data?.reduce(
    (res, file, index) => {
      const isVideoType = isVideo(file);
      if (isVideoType) {
        res.videoFiles.push({ ...file, index });
      } else {
        res.imageFiles.push(file);
      }
      return res;
    },
    { imageFiles: [], videoFiles: [] }
  );
};

const getImageDimensions = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // Load the file as a Data URL
    reader.onload = function (event) {
      const img = new Image();

      // When the image has loaded, access its width and height
      img.onload = function () {
        const width = img.width;
        const height = img.height;
        resolve({ width, height });
      };

      // Handle error in loading image
      img.onerror = function () {
        reject(new Error('Error loading image'));
      };

      // Set the src of the image to the result from FileReader
      img.src = event.target.result;
    };

    // Handle error in reading file
    reader.onerror = function () {
      reject(new Error('Error reading file'));
    };

    // Read the file as a Data URL
    reader.readAsDataURL(file);
  });
};

const resizeImage = async (file) => {
  return new Promise((resolve) => {
    try {
      getImageDimensions(file).then((data) => {
        const { width, height } = data;
        const scaleNumber = randomInRange({ from: 80, to: 120 }) / 100;
        Resizer.imageFileResizer(
          file,
          width * scaleNumber,
          height * scaleNumber,
          'JPEG',
          90,
          0,
          (blob) => {
            resolve({
              preview: URL.createObjectURL(blob),
              name: file?.name,
              size: blob?.size,
              type: blob?.type,
            });
          },
          'blob',
          width * scaleNumber,
          height * scaleNumber
        );
      });
    } catch (error) {
      console.log(error);
      resolve(file);
    }
  });
};

export const convertFileToUint8Array = async (file) => {
  const fileExtension = (file?.type || 'unknown/unknown')?.split('/')[1];
  return {
    objectURL: file?.preview,
    fileName: file?.name,
    size: file?.size,
    fileExtension,
    type: file?.type || 'unknown/unknown',
  };
};

export function getMimeTypeFromUrl(url) {
  const imageMimeTypes = {
    bmp: 'image/bmp',
    gif: 'image/gif',
    jpeg: 'image/jpeg',
    jpg: 'image/jpeg',
    png: 'image/png',
    svg: 'image/svg+xml',
    tif: 'image/tiff',
    tiff: 'image/tiff',
    webp: 'image/webp',
  };
  const parts = url.split('.');
  const extension = parts[parts.length - 1];
  return imageMimeTypes[extension];
}

export async function resizeAndWatermarkImages(files) {
  try {
    return await Promise.all(files?.map(async (file) => await resizeAndRandomWatermarkImage(file)));
  } catch (error) {
    console.log('resizeAndWatermark', error);
    return files;
  }
}

async function resizeAndRandomWatermarkImage(image) {
  try {
    const fileResponse = await fetch(image?.objectURL);
    const blob = await fileResponse.blob();

    const file = new File([blob], image?.fileName, { type: image?.type });

    let waterMarkedFile = await watermarkImageWithText(file, getRandomEmoji(), 0.2);
    waterMarkedFile = Object.assign(waterMarkedFile, {
      preview: URL.createObjectURL(waterMarkedFile),
    });

    const resized = await resizeImage(waterMarkedFile);
    const fileExtension = (resized?.type || 'unknown/unknown')?.split('/')[1];

    return {
      objectURL: resized?.preview,
      fileName: resized?.name,
      size: resized?.size,
      fileExtension,
    };
  } catch (error) {
    console.log(error);
    return image;
  }
}
