import { Paper, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import Avatar from '../../../../../components/Avatar';
import Iconify from '../../../../../components/Iconify';
import { CustomTable } from '../../../../../components/table/custom';
import useAdsCampaign from '../../../../../hooks/useAdsCampaign';
import useAuth from '../../../../../hooks/useAuth';
import useLocales from '../../../../../hooks/useLocales';
import createAvatar from '../../../../../utils/createAvatar';
import { getAdsAccounts } from '../helper';

TableTarget.propTypes = {
  excludesList: PropTypes.array,
  onExcludeAdsAccounts: PropTypes.func,
};

export default function TableTarget({ excludesList, onExcludeAdsAccounts }) {
  const [tableData, setTableData] = useState([]);

  const { translate: tTable } = useLocales('dashboard.ads_marketing.table.head');

  const { step1, loading } = useAdsCampaign();

  const { enqueueSnackbar } = useSnackbar();

  const tableRef = useRef();

  const [totalRows, setTotalRows] = useState(0);

  const [pagination, setPagination] = useState({ next: null, prev: null });

  const {
    configs: { aws_endpoint_url: S3_HOST },
  } = useAuth();

  const renderAvatar = (row) => {
    if (row?.site_identity_type !== 'mxh') {
      return (
        <Avatar
          alt={row?.username}
          src={row?.avatar}
          color={row?.avatar ? 'default' : createAvatar(row?.username).color}
          sx={{ mr: 2 }}
        >
          {createAvatar(row?.user).name}
        </Avatar>
      );
    }

    return (
      <Avatar
        alt={row?.username}
        src={`${S3_HOST}/${row?.avatar}`}
        color={row?.avatar ? 'default' : createAvatar(row?.username).color}
        sx={{ mr: 2 }}
      >
        {createAvatar(row?.username).name}
      </Avatar>
    );
  };

  const renderPlatForm = (row) => {
    if (row?.site_identity_type === 'mxh') {
      return (
        <Avatar
          variant={row?.site_identity_name === 'linkedin' ? 'square' : 'rounded'}
          alt={row?.username}
          src={`/assets/socials/${row?.meta?.type}.png`}
        />
      );
    }
    return (
      <Avatar
        alt={row?.site_identity_name}
        color={createAvatar(row?.site_identity_name).color}
        sx={{ mr: 2 }}
        src={row?.logo || ''}
      >
        {createAvatar(row?.site_identity_name).name}
      </Avatar>
    );
  };

  const columns = [
    { key: 'platformContent', label: tTable('platform'), align: 'left' },
    { key: 'avatarContent', label: tTable('image'), align: 'left' },
    { key: 'nameContent', label: tTable('name'), align: 'left' },
    { key: 'audienceContent', label: tTable('audience'), align: 'center' },
  ];

  const getRowContent = useCallback((row) => {
    const audience = row?.audience || 0;
    return {
      platformContent: renderPlatForm(row),
      avatarContent: renderAvatar(row),
      nameContent: row?.username,
      audienceContent: audience,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSubListAdsAccounts = useCallback(
    async (pageNo) => {
      try {
        loading.onTrue();
        const adsIds = step1?.adsIds || [];
        const { success, data } = await getAdsAccounts({ adsIds }, pageNo);
        if (!success) {
          enqueueSnackbar('Có lỗi xảy ra', { variant: 'error' });
          return;
        }
        const { results, count, next, previous } = data;
        const temp = results?.map((item) => ({ ...item, ...getRowContent(item) }));
        setTableData(temp);
        setTotalRows(count);
        setPagination({
          next,
          prev: previous,
        });
      } catch (error) {
        console.log(error);
      } finally {
        loading.onFalse();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [step1]
  );

  useEffect(() => {
    getSubListAdsAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step1]);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', mt: 3 }}>
      <CustomTable
        ref={tableRef}
        fetchData={getSubListAdsAccounts}
        data={tableData}
        columns={columns}
        actions={<AdvanceAtions onExcludeAdsAccounts={onExcludeAdsAccounts} excludesList={excludesList} />}
        totalRows={totalRows}
        next={pagination?.next}
        prev={pagination?.prev}
        showEmptyRow={false}
      />
    </Paper>
  );
}

// ======================================================================

AdvanceAtions.propTypes = {
  row: PropTypes.object,
  excludesList: PropTypes.array,
  onExcludeAdsAccounts: PropTypes.func,
};

function AdvanceAtions({ row, onExcludeAdsAccounts, excludesList }) {
  const isExcluded = excludesList?.indexOf(row?.ads_id) !== -1;
  return (
    <Typography
      component="span"
      sx={{
        cursor: 'pointer',
      }}
      onClick={() => {
        onExcludeAdsAccounts(row?.ads_id);
      }}
    >
      <Iconify
        width={20}
        height={20}
        sx={{
          color: isExcluded ? 'primary.main' : 'error.main',
        }}
        icon={isExcluded ? 'ic:baseline-restore' : 'material-symbols-light:delete'}
      />
    </Typography>
  );
}
