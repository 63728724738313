import EventEmitter from 'events';
import { w3cwebsocket as W3CWebSocket } from 'websocket';

const DEFAULT_OPTIONS = {
  onOpen: () => {},
  onClose: () => {},
};

export default class WebSocketService {
  constructor(url, options = DEFAULT_OPTIONS) {
    this.url = url;
    this.eventEmitter = new EventEmitter();
    this.onOpen = options?.onOpen;
    this.onClose = options?.onClose;
    this.connect();
  }

  connect() {
    this.ws = new W3CWebSocket(this.url);

    this.ws.onopen = () => {
      console.log('WebSocket Connected at %o', Date());
      this.onOpen();
    };

    this.ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message?.feature) {
        this.eventEmitter.emit(message?.feature, message);
      } else {
        console.log('Message from websocket service', message);
        this.eventEmitter.emit('message', message);
      }
    };

    this.ws.onclose = () => {
      console.log('WebSocket has closed at %o!', Date());
      this.onClose();
    };
  }

  close() {
    console.log('Closing WebSocket!');
    this.ws.close();
  }

  send(data) {
    this.ws.send(data);
  }

  on(event, listener) {
    this.eventEmitter.on(event, listener);
  }

  off(event, listener) {
    this.eventEmitter.off(event, listener);
  }
}
