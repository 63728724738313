import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/system';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';
// redux
import { useSelector } from '../../../redux/store';
// hooks
import useAuth from '../../../hooks/useAuth';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';
// utils
import useCountdown from '../../../hooks/useCountdown';
import useLocales from '../../../hooks/useLocales';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { modifyTime } from '../../../utils/formatTime';

PackageTimer.propTypes = {
  sx: PropTypes.any,
};

export default function PackageTimer({ sx, ...other }) {
  const { user } = useAuth();

  const { translate } = useLocales('commons');

  const currentPackage = user?.package_using;

  const navigate = useNavigate();

  const { countdown: countDown, isExpired } = useCountdown(modifyTime(currentPackage?.expire_at));

  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();

  const { currentTenant } = useSelector((state) => state.tenant);

  useEffect(() => {
    if (currentPackage && isExpired) {
      enqueueSnackbar(`${translate('expired', { name: currentPackage?.package_info?.alias })}`, {
        variant: 'warning',
        persist: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpired, currentPackage]);

  const handlePaymentRedirect = () => {
    navigate(PATH_DASHBOARD.user.sme);
  };

  return (
    <>
      {currentPackage && currentTenant?.payment_method !== 'subscription' ? (
        <Tooltip title={currentPackage?.package_info?.alias.replace('Gói', translate('package'))}>
          <Stack direction={'row'} alignItems={'center'} columnGap={'12px'} sx={{ ...sx }} {...other}>
            <Stack
              direction={'row'}
              alignItems={'center'}
              columnGap={'8px'}
              sx={{
                fontSize: '13px',
                color: '#fff',
                background: `${theme.palette.primary.main}`,
                borderRadius: '8px',
                px: '10px',
                py: '4px',
                fontWeight: '700',
                cursor: 'pointer',
              }}
              onClick={handlePaymentRedirect}
            >
              <SvgIconStyle src={`/assets/icons/navbar/ic_staff.svg`} sx={{ width: 20, height: 20 }} />
              {`${countDown.days !== '000' ? `${countDown.days}${translate('day')}` : ''} ${countDown.hours}${translate(
                'hour'
              )}`}
            </Stack>
          </Stack>
        </Tooltip>
      ) : (
        ''
      )}
    </>
  );
}
