import { Button, Divider, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import Iconify from '../../../../components/Iconify';
import useLocales from '../../../../hooks/useLocales';

ControlButton.propTypes = {
  onClickSubmit: PropTypes.func,
  onClickReset: PropTypes.func,
};

export default function ControlButton({ onClickReset, onClickSubmit }) {
  const { translate } = useLocales('dashboard.ads_marketing.btn');

  return (
    <>
      <Divider />
      <Stack direction="row" alignItems="center" justifyContent="space-between" p={1}>
        <Button variant="text" startIcon={<Iconify icon="ci:arrow-reload-02" />} onClick={onClickReset}>
          {translate('reset')}
        </Button>
        <Button variant="contained" onClick={onClickSubmit}>
          {translate('apply')}
        </Button>
      </Stack>
    </>
  );
}
