import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import Iconify from '../../../../components/Iconify';
import useBoolean from '../../../../hooks/useBoolean';
import useCountdownCustom from '../../../../hooks/useCountdownCustom';
import useLocales from '../../../../hooks/useLocales';
import useSettings from '../../../../hooks/useSettings';
import useToolFunction from '../../../../hooks/useToolFunction';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

// In seconds
const COUNTDOWN_TIME = 60 * 60 * 1000;

WarningActionPopup.propTypes = { open: PropTypes.bool, onClose: PropTypes.func };

export default function WarningActionPopup({ open, onClose }) {
  const { themeMode } = useSettings();

  const { onCallbackWarningActionPopup, stopQueue } = useToolFunction();

  const { countdown, onStart, isDoned } = useCountdownCustom(COUNTDOWN_TIME);

  const displayCountdown = useBoolean();

  const { translate: tButton } = useLocales('tool.btn');

  const { translate } = useLocales('tool.popup.warning_action');

  useEffect(() => {
    if (isDoned) {
      onClose();
    }
  }, [isDoned]);

  const onClickViewMore = () => {
    window.open(' https://doc.fbtool.net/cau-hoi-thuong-gap#hoi-dang-dang-bai-thi-hien-thong-bao-luu-y', '_blank');
  };

  const handleRetryAfter = () => {
    onCallbackWarningActionPopup('delay', COUNTDOWN_TIME);
    onStart();
    displayCountdown.onTrue();
  };

  const handleContinue = () => {
    onCallbackWarningActionPopup('continue');
    onClose();
  };

  const onReset = () => {
    stopQueue(true, 'manual');
    displayCountdown.onFalse();
    onClose();
  };

  return (
    <BootstrapDialog onClose={onReset} open={open}>
      <DialogTitle sx={{ m: 0, p: 2, textAlign: 'center' }}>
        <Stack direction="row" gap={1} alignItems="center" justifyContent={'center'}>
          <Iconify icon={'ph:warning-fill'} color="error.main" sx={{ height: 32, width: 32 }} />
          <Typography color="error.main" fontSize={'2.45rem'} fontWeight="bold">
            {translate('title')}
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack flex={1} justifyContent="center" alignItems={'center'} direction={'row'} px={{ xs: 2, sm: 10 }}>
          <Stack flex={1} justifyContent="center" alignItems={'center'} direction={'column'} gap={1.5}>
            <Typography>{translate('sub_title')}</Typography>
            <Stack direction={'row'} gap={1.5} mt={1}>
              {displayCountdown?.value ? (
                <Button variant="outlined" sx={{ textTransform: 'none' }}>
                  {tButton('remain', { time: `${countdown?.minutes}:${countdown.seconds}` })}
                </Button>
              ) : (
                <>
                  <Button
                    onClick={handleRetryAfter}
                    variant="outlined"
                    endIcon={<Iconify icon="ion:reload" sx={{ height: 18, width: 18 }} />}
                  >
                    {tButton('retry', { time: '1h' })}
                  </Button>
                  <Button
                    onClick={handleContinue}
                    variant="outlined"
                    endIcon={<Iconify icon="ph:play-fill" sx={{ height: 18, width: 18 }} />}
                  >
                    {tButton('continue')}
                  </Button>
                </>
              )}
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          py: '8px !important',
        }}
      >
        <Button
          sx={{ color: themeMode === 'dark' ? 'common.white' : 'common.black', textTransform: 'none' }}
          autoFocus
          onClick={onClickViewMore}
          endIcon={<Iconify icon="icon-park-outline:right" sx={{ height: 18, width: 18 }} />}
        >
          {tButton('more')}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
