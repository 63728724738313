import axios from './axios';

const url = `api/v1/gpt/`;

// Spin
export async function GPTSpin(mainContent, assetUUID) {
  const {
    data: { message, task },
  } = await axios.post(url, {
    type: 'spin',
    question: mainContent,
    spin: true,
    asset_uuid: assetUUID,
  });
  return { message, task };
}
// HashTag, emoji
export async function GPTHashTag(mainContent, assetUUID) {
  const {
    data: { message, task },
  } = await axios.post(url, {
    type: 'socialize',
    question: mainContent,
    spin: true,
    asset_uuid: assetUUID,
  });
  return { message, task };
}
// Thuyết phục
export async function GPTPersuadeRealEstate(mainContent, assetUUID) {
  const {
    data: { message, task },
  } = await axios.post(url, {
    type: 'persuade',
    question: mainContent,
    asset_uuid: assetUUID,
  });
  return { message, task };
}
