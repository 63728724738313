import React from 'react';
import PropTypes from 'prop-types';
import { alpha, Stack, Typography } from '@mui/material';

function StatItem({ title, subItems }) {
  return (
    <Stack
      direction="column"
      py={1}
      pl={2}
      pr={1}
      sx={{
        borderRadius: 2,
        backgroundColor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      }}
    >
      <Typography
        variant="body2"
        fontSize={'0.875rem'}
        fontWeight="bold"
        mb={0.5}
        sx={{
          color: (theme) => theme.palette.grey[600],
        }}
      >
        {title}
      </Typography>
      <Stack direction="column" gap={0.25}>
        {subItems?.map((item) => (
          <Typography key={item} variant="body2" color="text.secondary" noWrap>
            {item}
          </Typography>
        ))}
      </Stack>
    </Stack>
  );
}

StatItem.propTypes = {
  title: PropTypes.string,
  subItems: PropTypes.array,
};

export default StatItem;
