import { Button, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import useCallbackPrompt from '../hooks/useCallbackPrompt';
import useLocales from '../hooks/useLocales';
import useToolContext from '../hooks/useToolContext';
import useToolFunction from '../hooks/useToolFunction';

function ToolBockRedirect() {
  // Confirm redirect
  const { isTaskRunning, resetTaskRunning } = useToolContext();

  const { stopQueue } = useToolFunction();

  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const { translate } = useLocales('tool');

  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(isTaskRunning);

  const onConfirm = (snackbarId) => {
    closeSnackbar(snackbarId);
    confirmNavigation();
    resetTaskRunning();
    stopQueue(true, 'manual');
  };

  const onCancel = (snackbarId) => {
    closeSnackbar(snackbarId);
    cancelNavigation();
  };

  const action = (snackbarId) => (
    <Stack direction="row">
      <Button color="primary" onClick={() => onConfirm(snackbarId)}>
        {translate('btn.confirm')}
      </Button>
      <Button color="error" onClick={() => onCancel(snackbarId)}>
        {translate('btn.cancel')}
      </Button>
    </Stack>
  );

  useEffect(() => {
    if (showPrompt) {
      enqueueSnackbar(translate('messages.redirect_confirm'), { variant: 'warning', action, persist: true });
    }
  }, [showPrompt]);

  return <></>;
}

export default ToolBockRedirect;
