import { useContext } from 'react';
import { ToolContext } from '../contexts/ToolContext';

const useToolContext = () => {
  const context = useContext(ToolContext);

  if (!context) {
    throw new Error('ToolContext context must be use inside ToolProvider');
  }

  return context;
};

export default useToolContext;
