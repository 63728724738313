import { Dialog, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

function WatchVideo({ url, onClose }) {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    setOpen(true);
  }, [url]);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <Stack
        sx={{
          p: 1.5,
        }}
      >
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video width="100%" controls autoPlay>
          <source src={url} />
          Your browser does not support the video tag.
        </video>
      </Stack>
    </Dialog>
  );
}

WatchVideo.propTypes = {
  url: PropTypes.string,
  onClose: PropTypes.func,
};

export default WatchVideo;
