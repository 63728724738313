/* eslint-disable */

import { fDateTime, reduceMinutes } from '../formatTime';
import { convertIdToFeedBackId, convertToMD5, randomInRange } from '../others';
import uuidv4 from '../uuidv4';

/**
 * Helper class to call api to facebook.
 */
export default class FBTool {
  FACEBOOK_API = 'https://www.facebook.com/api/graphql/';

  FUNCTION_NAME = {
    POST_TO_GROUP: 'POST_TO_GROUP',
    PROFILE: 'PROFILE',
    PROFILE_BY_UID: 'PROFILE_BY_UID',
    SEARCH_TAG: 'SEARCH_TAG',
    TEXT_FORMAT_PRESETS: 'TEXT_FORMAT_PRESETS',
    PARTICIPAGE_APPROVAL: 'PARTICIPAGE_APPROVAL',
    GROUPS_JOINED: 'GROUPS_JOINED',
    FRIENDS: 'FRIENDS',
    ACCESS_TOKEN: 'ACCESS_TOKEN',
    USER_CONTROL_BUSINESS: 'USER_CONTROL_BUSINESS',
    REACTIONS_FROM_FEEDBACK: 'REACTIONS_FROM_FEEDBACK',
    POST_COMMENT: 'POST_COMMENT',
    SITE_INFO: 'SITE_INFO',
    SEARCH_FEEDBACK: 'SEARCH_FEEDBACK',
    SEARCH_POSTS_IN_GROUP: 'SEARCH_POSTS_IN_GROUP',
    SEARCH_POSTS: 'SEARCH_POSTS',
    GET_COMMENTS_FROM_FEEDBACK: 'GET_COMMENTS_FROM_FEEDBACK',
    GET_REPLIES_FROM_COMMENT: 'GET_REPLIES_FROM_COMMENT',
    ADD_FRIEND: 'ADD_FRIEND',
    CHECK_PAGE: 'CHECK_PAGE',
    UPLOAD_VIDEO: 'UPLOAD_VIDEO',
    REMOVE_PENDING_POSTS: 'REMOVE_PENDING_POSTS',
    TIMELINE_POSTS: 'TIMELINE_POSTS',
    TIMELINE_POSTS_OF_PAGE: 'TIMELINE_POSTS_OF_PAGE',
    GET_FOLLOWERS: 'GET_FOLLOWERS',
    POKE: 'POKE',
    LIKE: 'LIKE',
    UNFRIEND: 'UNFRIEND',
    REMOVE_SALE_FORMAT: 'REMOVE_SALE_FORMAT',
    GROUP_ACTIVITY: 'GROUP_ACTIVITY',
    PAGE_LIKED_OR_FOLLOWED: 'PAGE_LIKED_OR_FOLLOWED',
    GET_REACTORS_OF_POST: 'GET_REACTORS_OF_POST',
    GET_RESHARE_OF_POST: 'GET_RESHARE_OF_POST',
    GET_SHARE_LINK_OF_POST: 'GET_SHARE_LINK_OF_POST',
    UNKNOWN: 'UNKNOWN',
  };

  ACTIONS = {
    POST_TO_GROUP: 'POST_TO_GROUP',
    POST_COMMENT: 'POST_COMMENT',
  };

  PATH_AFTER_UPLOADED = {
    POST_TO_GROUP: ['variables', 'input', 'attachments'],
    POST_COMMENT: ['variables', 'input', 'attachments'],
  };

  ITEM_STRUCTURE = {
    POST_TO_GROUP: {
      photo: {
        id: 'imageId',
      },
    },
    POST_COMMENT: {
      media: {
        id: 'imageId',
      },
    },
  };

  /**
   * constructor of class
   * @param {array} cookies
   * @param {string} fbDtsg
   * @param {string} dyn
   */
  constructor(cookies = [], fbDtsg = '', dyn = '', otherData = {}, accessToken = null, functionData = {}) {
    this.cookies = cookies?.reduce((res, item) => {
      res[item.name] = item?.value;
      return res;
    }, {});

    this.cookies.dbln = `%7B%2261554669931019%22%3A%222ZnumwUX%22%2C%22100031636493707%22%3A%22piBf69wj%22%7D`;
    this.cookiesOrigin = cookies;
    this.fbDtsg = fbDtsg;
    this.dyn = dyn;
    this.otherData = otherData || {};
    this.accessToken = accessToken;
    this.functionData = functionData || {};
  }

  getFilesUploadStructure(files, action = this.ACTIONS.POST_TO_GROUP) {
    try {
      const itemStructure = JSON.stringify(this.ITEM_STRUCTURE[action]);
      return { files, pathAfterUploaded: this.PATH_AFTER_UPLOADED[action], structure: itemStructure };
    } catch (error) {
      return {
        files,
        pathAfterUploaded: this.PATH_AFTER_UPLOADED[this.ACTIONS.POST_TO_GROUP],
        structure: JSON.stringify(this.ITEM_STRUCTURE[this.ACTIONS.POST_TO_GROUP]),
      };
    }
  }

  /**
   * Get profile request data.
   * @return {object}
   */
  getProfile() {
    try {
      const headers = this.getHeaders();
      const payload = this.getPayload(this?.FUNCTION_NAME?.PROFILE, {
        av: this.cookies?.i_user || this.cookies?.c_user,
        __aaid: 0,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        __a: 1,
        __comet_req: 15,
        fb_dtsg: this.fbDtsg,
        variables: { scale: 1.5 },
        server_timestamps: true,
        doc_id: 6434758246628736,
      });

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  getProfileByUserUID(uid) {
    try {
      const headers = this.getHeaders();
      const payload = this.getPayload(this?.FUNCTION_NAME?.PROFILE_BY_UID, {
        av: this.cookies?.i_user || this.cookies?.c_user,
        __aaid: 0,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        __a: 1,
        __comet_req: 15,
        fb_api_caller_class: 'RelayModern',
        fb_api_req_friendly_name: 'ProfileCometHeaderQuery',
        variables: {
          scale: 1,
          selectedID: uid,
          selectedSpaceType: 'community',
          shouldUseFXIMProfilePicEditor: false,
          userID: uid,
        },
        server_timestamps: true,
        doc_id: '8662754200424653',
      });

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  searchTagInGroupPayload(groupID, query = '', postID = null) {
    try {
      const headers = this.getHeaders();
      const payload = this.getPayload(this?.FUNCTION_NAME?.SEARCH_TAG, {
        av: this.cookies?.i_user || this.cookies?.c_user,
        __aaid: 0,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        __a: 1,
        __comet_req: 15,
        fb_api_caller_class: 'RelayModern',
        fb_api_req_friendly_name: 'useGroupsCometTypeaheadNetworkDataSourceQuery',
        variables: {
          query,
          scale: 1,
          groupID,
          canViewerMessage: null,
          isPhotoTag: null,
          postID,
          firstDegreeFilters: ['USER', 'WORKROOMS_USER', 'EVENT', 'GROUP', 'PAGE'],
          canUseSections: null,
        },
        server_timestamps: true,
        doc_id: '6493643600719877',
      });

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Get text format presets
   * @return {object}
   */
  getTextFormatPresetsPayload() {
    try {
      const headers = this.getHeaders();
      const payload = this.getPayload(this?.FUNCTION_NAME?.TEXT_FORMAT_PRESETS, {
        av: this.cookies?.i_user || this.cookies?.c_user,
        __aaid: 0,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        __a: 1,
        fb_dtsg: this.fbDtsg,
        __dyn: this.dyn,
        variables: { containsCustomSwatchCategory: false, scale: 1 },
        server_timestamps: true,
        doc_id: 9847007025340314,
      });

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Get group pending participate approval
   * @return {object}
   */
  getParticipageApprovalPayload(groupId) {
    try {
      const headers = this.getHeaders();
      const payload = this.getPayload(this?.FUNCTION_NAME?.PARTICIPAGE_APPROVAL, {
        av: this.cookies?.i_user || this.cookies?.c_user,
        __aaid: 0,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        __a: 1,
        fb_dtsg: this.fbDtsg,
        __dyn: this.dyn,
        variables: { groupID: groupId },
        server_timestamps: true,
        doc_id: 7120999354638317,
      });

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Get groups joined request data.
   * @return {object}
   */
  getGroupsJoined() {
    try {
      const headers = this.getHeaders();
      const payload = this.getPayload(this?.FUNCTION_NAME?.GROUPS_JOINED, {
        doc_id: 3884641628300421,
        variables: {
          adminGroupsCount: 5000,
          memberGroupsCount: 5000,
          scale: 1.5,
          count: 10,
          cursor: null,
        },
      });

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Payload get list friends
   * @return {object}
   */
  getFriends() {
    try {
      const headers = this.getHeaders();
      const payload = this.getPayload(this?.FUNCTION_NAME?.FRIENDS, {
        doc_id: 4936483286421335,
        av: this.cookies?.i_user || this.cookies?.c_user,
        dpr: 1,
        __a: 1,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        server_timestamps: true,
        variables: { id: this.cookies?.i_user || this.cookies?.c_user, query: '', scale: 1 },
      });

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Payload get list friends
   * @return {object}
   */
  getAccessToken() {
    try {
      const headers = this.getHeaders();
      const payload = this.getPayload(this?.FUNCTION_NAME?.ACCESS_TOKEN, {
        app_id: 124024574287414,
        redirect_uri: `fbconnect://success`,
        display: `page`,
        from_post: 1,
        return_format: `access_token`,
        _CONFIRM: 1,
      });

      return {
        endpoint: `https://www.facebook.com/v1.0/dialog/oauth/confirm`,
        method: 'POST',
        headers,
        payload,
        returnRaw: true,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Payload get help page to find FBDtsg
   * @return {object}
   */
  getHelpPageToFindFBDtsg() {
    try {
      const headers = this.getHeaders();

      return {
        endpoint: `https://www.facebook.com/help/search/?is_typeahead=0`,
        method: 'GET',
        headers,
        payload: {},
        returnRaw: true,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Payload get locale
   * @return {object}
   */
  getLocale() {
    try {
      const headers = this.getHeaders();

      return {
        endpoint: `https://www.facebook.com//settings/?tab=language`,
        method: 'GET',
        headers,
        payload: {},
        returnRaw: true,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Payload get current user control business
   * @return {object}
   */
  getUserControlBusiness() {
    try {
      const headers = this.getHeaders();
      const payload = this.getPayload(this?.FUNCTION_NAME?.USER_CONTROL_BUSINESS, {
        // av: this.cookies?.i_user || this.cookies?.c_user,
        dpr: 1,
        __a: 1,
        // __user: this.cookies?.i_user || this.cookies?.c_user,
        server_timestamps: true,
        fb_api_caller_class: 'RelayModern',
        fb_api_req_friendly_name: 'BusinessCometAccountSwitcherPrefetcherQuery',
        variables: {},
        doc_id: '8162974337058199',
      });

      return {
        endpoint: `https://business.facebook.com/api/graphql/?_callFlowletID=0&_triggerFlowletID=1`,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Payload get list friends
   * @return {object}
   */
  getUsersInfoByUIDS(uids = [], currentUserUID) {
    try {
      const headers = this.getHeaders({
        'Content-Type': 'text/plain',
      });

      let formated = `${uids?.map((id) => `"${id}"`).join(',')}`;
      formated = `${new URLSearchParams(formated)}`.replace('=', '');
      let payload = {
        batch_name: 'MessengerParticipantsFetcher',
        __aaid: 0,
        __user: currentUserUID,
        __a: 1,
        __dyn: this.dyn,
        __comet_req: 11,
        fb_dtsg: this.fbDtsg,
      };

      payload = new URLSearchParams(payload);
      const queries = `queries=%7B%22o0%22%3A%7B%22doc_id%22%3A%227225428284192887%22%2C%22query_params%22%3A%7B%22ids%22%3A[${formated}]%2C%22source%22%3Anull%7D%7D%7D`;
      payload = `${payload}&${queries}`;
      console.log(payload);
      return {
        endpoint: `https://business.facebook.com/api/graphqlbatch/?_callFlowletID=0`,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Payload get reactions in post
   * @return {object}
   */
  getPayloadReactionsInPost(groupPostFormatId, cursor = '') {
    try {
      const headers = this.getHeaders();
      const paramsObj = {
        limit: 1000,
        fields: 'id',
        access_token: this.accessToken,
        after: cursor,
      };

      const searchParams = new URLSearchParams(paramsObj);

      return {
        endpoint: `https://graph.facebook.com/v2.6/${groupPostFormatId}/reactions?${searchParams}`,
        method: 'GET',
        headers,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Payload get reactions from feedback
   * @return {object}
   */
  getPayloadReactionsFromFeedback(feedbackId, cursor = null) {
    try {
      const headers = this.getHeaders();
      const pageId = this.cookies?.i_user || this.cookies?.c_user;

      const extraPayload = {
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        __aaid: 0,
        av: pageId,
        __user: pageId,
        __a: 1,
        dpr: 1,
        __req: 15,
        __comet_req: 15,
        fb_api_caller_class: 'RelayModern',
        fb_api_req_friendly_name: 'CometUFIReactionsDialogTabContentRefetchQuery',
        variables: {
          count: 10,
          cursor,
          feedbackTargetID: feedbackId,
          reactionID: null,
          scale: 1,
          id: feedbackId,
        },
        server_timestamps: true,
        doc_id: '7443538865752313',
      };
      const payload = this.getPayload(this?.FUNCTION_NAME?.REACTIONS_FROM_FEEDBACK, extraPayload);

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Payload get comments info in post
   * @return {object}
   */
  getPayloadListPosts(endpoint, start, end) {
    try {
      const headers = this.getHeaders();
      const paramsObj = {
        limit: 100,
        fields: 'id',
        access_token: this.accessToken,
        ...(start && { since: start }),
        ...(end && { until: end }),
      };

      const searchParams = new URLSearchParams(paramsObj);

      return {
        endpoint:
          endpoint ||
          `https://graph.facebook.com/v2.6/${this.cookies?.i_user || this.cookies?.c_user}/posts?${searchParams}`,
        method: 'GET',
        headers,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Payload get comments info in post
   * @return {object}
   */
  getPayloadCommentInPost(groupPostFormatId, afterComments = '', inputIsFeedback = false) {
    try {
      const feedbackId = inputIsFeedback ? groupPostFormatId : convertIdToFeedBackId(groupPostFormatId);
      const headers = this.getHeaders();
      const payload = {
        doc_id: 3874089129331862,
        av: this.cookies?.i_user || this.cookies?.c_user,
        fb_api_caller_class: 'RelayModern',
        dpr: 1,
        __a: 1,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        server_timestamps: true,
        variables: {
          max_comments: 50,
          after_comments: afterComments,
          feedback_id: feedbackId,
          scale: 1,
        },
        fb_dtsg: this.fbDtsg,
      };

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Payload get page info
   * @return {object}
   */
  getPayloadPageInfo(pageId) {
    try {
      const userId = this.cookies?.i_user || this.cookies?.c_user;

      const headers = this.getHeaders();
      const payload = {
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        __aaid: 0,
        av: userId,
        __user: userId,
        __a: 1,
        dpr: 1,
        __req: 15,
        __comet_req: 15,
        fb_api_caller_class: 'RelayModern',
        fb_api_req_friendly_name: 'ProfileTransparencyDialogQuery',
        variables: { pageID: pageId, scale: 1 },
        server_timestamps: true,
        doc_id: '7975481309218162',
      };

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Payload get page category
   * @return {object}
   */
  getPayloadPageCategory(category) {
    try {
      const userId = this.cookies?.i_user || this.cookies?.c_user;

      const headers = this.getHeaders();
      const payload = {
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        __aaid: 0,
        av: userId,
        __user: userId,
        __a: 1,
        dpr: 1,
        __req: 15,
        __comet_req: 15,
        fb_api_caller_class: 'RelayModern',
        fb_api_req_friendly_name: 'usePagesCometAdminEditingCategoryDataSourceQuery',
        variables: { params: { search_string: category } },
        server_timestamps: true,
        doc_id: '6219626854831519',
      };

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Payload get list chat messengers
   * @return {object}
   */
  getPayloadReadMessages() {
    try {
      const headers = this.getHeaders();
      const payload = {
        q: `viewer(){message_threads{nodes{thread_key{thread_fbid,other_user_id},messages_count,thread_type,updated_time_precise}}}`,
        fb_dtsg: this.fbDtsg,
      };

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Post to groups
   * @param {object} data
   */
  getPayloadPostToGroup(data) {
    try {
      const { groupId, images, video, content, link, textFormatPresetId, composerSessionId, ranges } = data;
      const actorId = this.cookies?.i_user || this.cookies?.c_user;
      const headers = {
        accept: '*/*',
        'accept-language': `en-US,en;q=0.9`,
        'cache-control': `no-cache`,
        'content-type': `application/x-www-form-urlencoded`,
        cookie: this.getCookieString(),
        origin: `https://www.facebook.com`,
        pragma: `no-cache`,
        priority: `u=1, i`,
        referer: `https://www.facebook.com`,
        'x-fb-friendly-name': `ComposerStoryCreateMutation`,
      };
      const payload = {
        fb_dtsg: this.fbDtsg,
        variables: {
          input: {
            source: 'WWW',
            attachments: [],
            message: { ranges: ranges || [], text: content },
            inline_activities: [],
            explicit_place_id: '0',
            tracking: [null],
            audience: { to_id: groupId },
            actor_id: actorId,
            client_mutation_id: '2',
          },
        },
        doc_id: '3559434960802556',
      };

      // Images
      if (images) {
        const photoPayload = images
          ?.filter((item) => item)
          ?.map((imageId) => ({
            photo: {
              id: imageId,
            },
          }));
        payload.variables.input.attachments = photoPayload;
      }

      // Video
      if (video) {
        const validVideos = video?.filter((item) => item && item?.videoId);

        payload.variables.input.attachments = validVideos?.reduce((res, item) => {
          const temp = { video: { id: item?.videoId, notify_when_processed: true } };
          res.splice(item?.index || 0, 0, temp);
          return res;
        }, payload?.variables?.input?.attachments || []);
      }

      // Link
      if (link) {
        payload.variables.input.attachments.push({ link });
      }

      // Preset id (banner)
      if (textFormatPresetId) {
        payload.variables.input.text_format_preset_id = textFormatPresetId;
      }

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
        // options: {
        //   redirect: 'manual',
        // },
        extraData: { userCookie: this.cookiesOrigin, userFBdtsg: this.fbDtsg, userDyn: this.dyn },
        // ...(!isOldVersion && { uploadImages: this.getFilesUploadStructure(files) }),
      };
    } catch (error) {
      console.log('content', error, error?.message);
      return null;
    }
  }

  /**
   *
   * @param {*} matrix
   * @return {Array}
   */
  generateCombinations(matrix) {
    const combinations = [];

    /**
     *
     * @param {Object} currentCombination
     * @param {Array} keys
     */
    function generate(currentCombination, keys) {
      if (keys.length === 0) {
        combinations.push(currentCombination);
        return;
      }

      const currentKey = keys[0];
      const values = matrix[currentKey];

      for (const value of values) {
        const newCombination = { ...currentCombination, [currentKey]: value };
        generate(newCombination, keys.slice(1));
      }
    }

    generate({}, Object.keys(matrix));
    return combinations;
  }

  /**
   * Post comment to feedback
   * @param {string} feedbackId
   * @param {object} data
   */
  getPayloadPostComment(feedbackId, data) {
    try {
      console.log('postComment', new Date());
      const { text, images, ranges } = data || {};

      const headers = this.getHeaders();
      const actorId = this.cookies?.i_user || this.cookies?.c_user;
      const extraPayload = {
        doc_id: 3988300734617853,
        variables: {
          displayCommentsFeedbackContext: null,
          displayCommentsContextEnableComment: null,
          displayCommentsContextIsAdPreview: null,
          displayCommentsContextIsAggregatedShare: null,
          displayCommentsContextIsStorySet: null,
          feedLocation: 'GROUP',
          feedbackSource: 0,
          focusCommentID: null,
          groupID: null,
          includeNestedComments: false,
          input: {
            attachments: null,
            feedback_id: feedbackId,
            formatting_style: null,
            message: { ranges: ranges || [], text },
            reply_target_clicked: false,
            feedback_source: 'PROFILE',
            actor_id: actorId,
            client_mutation_id: '18',
          },
          inviteShortLinkKey: null,
          renderLocation: null,
          scale: 1,
          useDefaultActor: false,
          UFI2CommentsProvider_commentsKey: 'CometGroupDiscussionRootSuccessQuery',
          __relay_internal__pv__GroupsCometDelayCheckBlockedUsersrelayprovider: false,
        },
        av: actorId,
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
      };

      // Files attachments
      if (images) {
        const mediaPayload = images
          ?.filter((item) => item)
          ?.map((mediaId) => ({
            media: {
              id: mediaId,
            },
          }));
        extraPayload.variables.input.attachments = mediaPayload;
      }

      const payload = this.getPayload(this?.FUNCTION_NAME?.POST_COMMENT, extraPayload);

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
        options: {
          redirect: 'manual',
        },
        extraData: { userCookie: this.cookiesOrigin, userFBdtsg: this.fbDtsg, userDyn: this.dyn },
        // ...(!isOldVersion && { uploadImages: this.getFilesUploadStructure(files, this.ACTIONS.POST_COMMENT) }),
      };
    } catch (error) {
      console.log('comment', error, error?.message);
      return null;
    }
  }

  randomSpinData(spinContents) {
    try {
      return Object.keys(spinContents)?.reduce((res, key) => {
        const len = spinContents[key]?.length;
        const rad = randomInRange({ from: 0, to: len - 1 });
        if (spinContents[key][rad]) {
          res[key] = spinContents[key][rad] || '';
        }
        return res;
      }, {});
    } catch (error) {
      console.log(error);
      return {};
    }
  }

  /**
   * Get list spin comments
   * @param {Array} comments
   * @param {Array} groupIds
   * @return {Array}
   */
  getSpinComments(comments, groupIds) {
    try {
      const sourceSpinned = comments?.map((item) => ({
        ...item,
        spin: this.generateCombinations(item?.data?.spinContents || {}),
      }));

      const results = groupIds?.reduce((res) => {
        const temp = [];
        sourceSpinned.forEach((item) => {
          let modifiedContent = item?.data?.description;
          if (item?.data?.spinContents) {
            const spinData = this.randomSpinData(item?.data?.spinContents);
            Object.keys(spinData).forEach((key) => {
              modifiedContent = modifiedContent.replace(`{{${key}}}`, spinData[key]);
            });
          }
          temp.push({ text: modifiedContent, files: item?.files });
        });
        res.push(temp);
        return res;
      }, []);

      return results;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  /**
   * Get site info with link
   * @param {string} url
   */
  getPayloadSiteInfo(url) {
    try {
      const headers = this.getHeaders();
      const actorId = this.cookies?.i_user || this.cookies?.c_user;
      const extraPayload = {
        av: actorId,
        __aaid: 0,
        __user: actorId,
        __a: 1,
        __req: 17,
        dpr: 1,
        __ccg: 'GOOD',
        __dyn: this.dyn,
        __comet_req: 15,
        fb_dtsg: this.fbDtsg,
        fb_api_caller_class: 'RelayModern',
        fb_api_req_friendly_name: 'ComposerLinkAttachmentPreviewQuery',
        variables: {
          feedLocation: 'FEED_COMPOSER',
          focusCommentID: null,
          goodwillCampaignId: '',
          goodwillCampaignMediaIds: [],
          goodwillContentType: null,
          params: { url },
          privacySelectorRenderLocation: 'COMET_COMPOSER',
          renderLocation: 'composer_preview',
          parentStoryID: null,
          scale: 1,
          useDefaultActor: false,
          shouldIncludeStoryAttachment: false,
          __relay_internal__pv__CometImmersivePhotoCanUserDisable3DMotionrelayprovider: false,
          __relay_internal__pv__IsWorkUserrelayprovider: false,
          __relay_internal__pv__IsMergQAPollsrelayprovider: false,
        },
        server_timestamps: true,
        doc_id: '26725143413800598',
      };

      const payload = this.getPayload(this?.FUNCTION_NAME?.SITE_INFO, extraPayload);

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      console.error('getSiteInfo', error);
      return null;
    }
  }

  /**
   * split JSON from FB
   * @param {*} text
   * @return {object}
   */
  splitJSON(text) {
    try {
      const json = JSON.parse(text);
      return json;
    } catch (error) {
      console.log('FB sometimes return multiple JSON merge with space between');
      const whitespaces = [
        '} {',
        '}\n{',
        `}
        {`,
      ];
      // for each whitespace item, loop to replace text string
      whitespaces.forEach((item) => {
        text = text.replace(item, '}{');
      });
      const split = text.split('}{');
      const newText = split[0] + '}';
      // console.log('newText', newText);
      const repaired = jsonrepair(newText);
      // console.log('repaired', repaired);
      const json = JSON.parse(repaired);
      // console.log('json', json);
      return json[0];
    }
  }

  /**
   * Get feedBackId
   * @param {string} groupId
   * @param {Number} amount
   * @param {string} cursor
   */
  getFeedbackPayload(groupId, cursor = null) {
    try {
      const headers = this.getHeaders();
      const extraPayload = {
        doc_id: 7591309714238821,
        av: this.cookies?.i_user || this.cookies?.c_user,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        __comet_req: 15,
        variables: {
          count: 3,
          ...(cursor && { cursor }),
          feedLocation: 'GROUP',
          feedType: 'DISCUSSION',
          feedbackSource: 0,
          focusCommentID: null,
          privacySelectorRenderLocation: 'COMET_STREAM',
          renderLocation: 'group',
          scale: 1,
          sortingSetting: 'CHRONOLOGICAL',
          stream_initial_count: 10,
          useDefaultActor: false,
          id: groupId,
          __relay_internal__pv__StoriesRingrelayprovider: false,
          __relay_internal__pv__EventCometCardImage_prefetchEventImagerelayprovider: false,
        },
      };
      const payload = this.getPayload(this?.FUNCTION_NAME?.SEARCH_FEEDBACK, extraPayload);

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Get search posts in group payload
   * @param {string} groupId
   * @param {string} keyword
   * @param {string} cursor
   */
  getSearchPostsInGroupPayload(groupId, keyword, cursor = null) {
    try {
      const headers = this.getHeaders();
      const extraPayload = {
        doc_id: 7165653280200780,
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        variables: {
          allow_streaming: false,
          args: {
            callsite: 'comet:scoped:group',
            config: {
              exact_match: false,
              high_confidence_config: null,
              intercept_config: null,
              sts_disambiguation: null,
              watch_config: null,
            },
            experience: {
              encoded_server_defined_params: null,
              fbid: groupId,
              type: 'GROUPS_SCOPED',
            },
            filters: ['{"name":"chronosort","args":""}'],
            text: keyword,
          },
          count: 5,
          cursor,
          feedLocation: 'SEARCH',
          feedbackSource: 23,
          fetch_filters: true,
          focusCommentID: null,
          locale: null,
          privacySelectorRenderLocation: 'COMET_STREAM',
          renderLocation: 'group_serp',
          scale: 1,
          stream_initial_count: 0,
          useDefaultActor: false,
          __relay_internal__pv__StoriesRingrelayprovider: false,
          __relay_internal__pv__EventCometCardImage_prefetchEventImagerelayprovider: false,
        },
      };
      const payload = this.getPayload(this?.FUNCTION_NAME?.SEARCH_POSTS_IN_GROUP, extraPayload);

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Get posts on facebook search engine
   */
  getSearchPostsPayload(data) {
    try {
      const { options, keyword, cursor } = data;
      const headers = this.getHeaders();
      const extraPayload = {
        av: this.cookies?.i_user || this.cookies?.c_user,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        __comet_req: 15,
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        server_timestamps: true,
        doc_id: 7677380942309792,
        variables: {
          count: 5,
          ...(cursor && { cursor }),
          allow_streaming: false,
          args: {
            callsite: 'COMET_GLOBAL_SEARCH',
            config: {
              exact_match: false,
              high_confidence_config: null,
              intercept_config: null,
              sts_disambiguation: null,
              watch_config: null,
            },
            experience: {
              client_defined_experiences: [],
              encoded_server_defined_params: null,
              fbid: null,
              type: 'POSTS_TAB',
            },
            filters: [],
            text: keyword,
          },
          feedbackSource: 23,
          fetch_filters: true,
          renderLocation: 'search_results_page',
          scale: 1,
          stream_initial_count: 0,
          useDefaultActor: false,
        },
      };

      const { recentPost } = options || {};

      const filters = [];

      // Is recent posts
      if (recentPost) {
        filters.push('{"name":"recent_posts","args":""}');
      }

      if (filters?.length !== 0) {
        const formated = filters?.join(`","`);
        extraPayload.variables.args.filters.push(formated);
      }

      const payload = this.getPayload(this?.FUNCTION_NAME?.SEARCH_POSTS, extraPayload);

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      console.log(error, error?.message);
      return null;
    }
  }

  /**
   * Get comments from feedback
   */
  getCommentsFromFeedbackPayload(data) {
    try {
      const { feedbackId, cursor } = data;
      const headers = this.getHeaders();
      const extraPayload = {
        av: this.cookies?.i_user || this.cookies?.c_user,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        __comet_req: 15,
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        server_timestamps: true,
        doc_id: 7784410118290185,
        variables: {
          commentsAfterCount: -1,
          commentsAfterCursor: null,
          commentsBeforeCount: null,
          commentsBeforeCursor: null,
          ...(cursor && { commentsAfterCursor: cursor }),
          commentsIntentToken: 'CHRONOLOGICAL_UNFILTERED_INTENT_V1',
          feedLocation: 'DEDICATED_COMMENTING_SURFACE',
          focusCommentID: null,
          scale: 1,
          useDefaultActor: false,
          id: feedbackId,
        },
      };

      const payload = this.getPayload(this?.FUNCTION_NAME?.GET_COMMENTS_FROM_FEEDBACK, extraPayload);

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      console.log(error, error?.message);
      return null;
    }
  }

  /**
   * Get comments from feedback
   */
  getRepliesOfCommentPayload(data) {
    try {
      const { feedbackId, expansionToken, cursor } = data;
      const headers = this.getHeaders();
      const extraPayload = {
        av: this.cookies?.i_user || this.cookies?.c_user,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        __comet_req: 15,
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        server_timestamps: true,
        doc_id: 7362257087232994,
        variables: {
          clientKey: null,
          expansionToken,
          feedLocation: 'GROUP_PERMALINK',
          focusCommentID: null,
          repliesAfterCount: null,
          repliesAfterCursor: null,
          repliesBeforeCount: null,
          repliesBeforeCursor: null,
          ...(cursor && { repliesAfterCursor: cursor }),
          scale: 1,
          useDefaultActor: false,
          id: feedbackId,
        },
      };

      const payload = this.getPayload(this?.FUNCTION_NAME?.GET_REPLIES_FROM_COMMENT, extraPayload);

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      console.log(error, error?.message);
      return null;
    }
  }

  /**
   * Send add friend request
   */
  getAddFriendRequest(newFriendId) {
    try {
      if (!newFriendId) {
        return null;
      }

      const targetId = `${newFriendId}`;

      const headers = this.getHeaders();
      const currentUserId = `${this.cookies?.i_user || this.cookies?.c_user}`;
      const extraPayload = {
        doc_id: 7707897945967331,
        av: this.cookies?.i_user || this.cookies?.c_user,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        variables: {
          // input: {
          //   attribution_id_v2:
          //     'ProfileCometTimelineListViewRoot.react,comet.profile.timeline.list,via_cold_start,',
          //   friend_requestee_ids: [targetId],
          //   friending_channel: 'PROFILE_BUTTON',
          //   warn_ack_for_ids: [],
          //   actor_id: currentUserId,
          //   client_mutation_id: '1',
          // },
          // scale: 1,
          input: {
            attribution_id_v2: 'ProfileCometTimelineListViewRoot.react,comet.profile.timeline.list,unexpected',
            friend_requestee_ids: [targetId],
            friending_channel: 'PROFILE_BUTTON',
            warn_ack_for_ids: [targetId],
            actor_id: currentUserId,
            client_mutation_id: '6',
          },
          scale: 1,
        },
      };
      const payload = this.getPayload(this?.FUNCTION_NAME?.ADD_FRIEND, extraPayload);

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Get list spin comments
   * @param {Array} comments
   * @param {Array} groupIds
   * @return {Array}
   */
  checkPageProfile(targetId = null) {
    try {
      const headers = this.getHeaders();
      const payload = this.getPayload(this?.FUNCTION_NAME?.CHECK_PAGE, {
        av: this.cookies?.i_user || this.cookies?.c_user,
        __aaid: 0,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        __a: 1,
        __comet_req: 15,
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        variables: { scale: 1, userID: `${targetId || this.cookies?.i_user || this.cookies?.c_user}` },
        server_timestamps: true,
        doc_id: 7113883452072861,
      });

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * get Video Upload Configs
   * @param {number | string} actorID
   * @return {object}
   */
  getVideoUploadConfigs(targetID = null) {
    try {
      const headers = this.getHeaders();
      const payload = this.getPayload(this?.FUNCTION_NAME?.UPLOAD_VIDEO, {
        __user: this.cookies?.i_user || this.cookies?.c_user,
        __a: 1,
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        doc_id: 5083329358376602,
        variables: {
          actorID: `${this.cookies?.i_user || this.cookies?.c_user}`,
          entryPoint: 'group',
          targetID: targetID || '',
        },
      });

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * declare Video Upload Infomation
   * @param {*} declareVideoUri
   * @param {*} waterfallId
   * @param {*} fileSize
   * @param {*} fileExtension
   * @param {*} actorID
   * @return {object}
   */
  declareVideoUploadInfomation(declareVideoUri, waterfallId, fileSize, fileExtension, actorID = null) {
    try {
      const headers = {
        accept: '*/*',
        'accept-language': 'en-US,en;q=0.9',
        'cache-control': 'no-cache',
        'content-type': 'application/x-www-form-urlencoded',
        cookie: this.getCookieString(),
        origin: 'https://www.facebook.com',
        pragma: 'no-cache',
        priority: 'u=1, i',
        referer: 'https://www.facebook.com',
        'sec-ch-ua': `"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"`,
        'sec-ch-ua-mobile': `?0`,
        'sec-ch-ua-platform': 'Windows',
        'sec-fetch-dest': `empty`,
        'sec-fetch-mode': `cors`,
        'sec-fetch-site': `same-site`,
        'user-agent': `Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36`,
        x_fb_video_waterfall_id: waterfallId,
      };

      const payload = {
        file_size: fileSize,
        file_extension: fileExtension,
        target_id: actorID || this.cookies?.i_user || this.cookies?.c_user,
        source: 'composer',
        composer_dialog_version: '',
        waterfall_id: waterfallId,
        composer_session_id: waterfallId,
        composer_entry_point_ref: 'group',
        composer_work_shared_draft_mode: '',
        has_file_been_replaced: false,
        supports_chunking: true,
        supports_file_api: true,
        partition_start_offset: 0,
        partition_end_offset: fileSize,
        creator_product: 2,
        spherical: false,
        video_publisher_action_source: '',
        __aaid: 0,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        __a: 1,
        __comet_req: 15,
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
      };

      return {
        endpoint: `${declareVideoUri}?__a=1`,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * open Container For Upload
   * @param {number} start_offset
   * @param {number} end_offset
   * @param {number | string} actorID
   * @return {object}
   */
  openContainerForUpload(containerServiceUri, hashKey, start_offset, end_offset, actorID = null) {
    try {
      const targetId = actorID || this.cookies?.i_user || this.cookies?.c_user;
      const headers = {
        accept: '*/*',
        'accept-language': `en-US,en;q=0.9`,
        'cache-control': `no-cache`,
        cookie: this.getCookieString(),
        origin: `https:////www.facebook.com`,
        pragma: `no-cache`,
        priority: `u=1, i`,
        referer: `https://www.facebook.com`,
        'sec-ch-ua': `"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"`,
        'sec-ch-ua-mobile': `?0`,
        'sec-ch-ua-platform': 'Windows',
        'sec-fetch-dest': `empty`,
        'sec-fetch-mode': `cors`,
        'sec-fetch-site': `same-site`,
        'user-agent': `Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36`,
      };
      const payload = {};

      const paramsObj = {
        __aaid: 0,
        __user: targetId,
        __a: 1,
        __dyn: this.dyn,
        __comet_req: 15,
        fb_dtsg_ag: this.fbDtsg,
      };

      // do not supply headers so that browser can set it automatically
      const searchParams = new URLSearchParams(paramsObj);

      return {
        endpoint: `${containerServiceUri}/fb_video/${hashKey}-${start_offset}-${end_offset}?${searchParams}`,
        method: 'GET',
        headers,
        payload,
      };
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  /**
   * append Video Data
   * @param {number} start_offset
   * @param {number} end_offset
   * @param {number | string} videoId
   * @param {number | string} actorID
   * @return {object}
   */
  appendVideoData(start_offset, end_offset, videoId, actorID = null) {
    try {
      const targetId = actorID || this.cookies?.i_user || this.cookies?.c_user;
      const headers = this.getHeaders({ 'X-Entity-Name': 'undefined' });
      const payload = {};

      const hashKey = convertToMD5(targetId);

      return {
        endpoint: `https://rupload-hkg1-2.up.facebook.com/fb_video/${hashKey}-${start_offset}-${end_offset}?__user=${targetId}&__a=1&fb_dtsg=${this.fbDtsg}&reel_video=${videoId}`,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * mark As Received Video Upload Progress
   * @param {number | string} groupId
   * @param {string} waterfallId
   * @param {number} videoSize
   * @param {string} videoId
   * @param {string} fbuploaderVideoFileChunk
   * @param {number | string} actorID
   * @return {object}
   */
  markAsReceivedVideoUploadProgress(receiveUri, waterfallId, videoSize, videoId, fbuploaderVideoFileChunk) {
    try {
      const targetId = this.cookies?.i_user || this.cookies?.c_user;
      const headers = {
        accept: '*/*',
        'accept-language': `en-US,en;q=0.9`,
        'cache-control': `no-cache`,
        cookie: this.getCookieString(),
        origin: `https://www.facebook.com`,
        pragma: `no-cache`,
        priority: `u=1, i`,
        referer: `https://www.facebook.com`,
        'sec-ch-ua': `"Not/A)Brand";v="8", "Chromium";v="126", "Google Chrome";v="126"`,
        'sec-ch-ua-mobile': `?0`,
        'sec-ch-ua-platform': 'Windows',
        'sec-fetch-dest': `empty`,
        'sec-fetch-mode': `cors`,
        'sec-fetch-site': `same-site`,
        'user-agent': `Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36`,
        x_fb_video_waterfall_id: waterfallId,
      };
      const payload = {
        fbuploader_video_file_chunk: fbuploaderVideoFileChunk,
      };

      const paramsObj = {
        composer_session_id: waterfallId,
        video_id: videoId,
        start_offset: 0,
        end_offset: videoSize,
        source: 'composer',
        target_id: targetId,
        waterfall_id: waterfallId,
        composer_entry_point_ref: 'group',
        has_file_been_replaced: false,
        supports_chunking: true,
        partition_start_offset: 0,
        partition_end_offset: videoSize,
        __aaid: 0,
        __user: targetId,
        __a: 1,
        __dyn: this.dyn,
        __comet_req: 15,
        fb_dtsg: this.fbDtsg,
      };

      const searchParams = new URLSearchParams(paramsObj);

      return {
        endpoint: `${receiveUri}?${searchParams}&composer_work_shared_draft_mode&composer_dialog_version&upload_speed`,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Get payload for list pending posts in the group
   * @param {number | string} groupId
   * @param {null | string} cursor
   * @return {object}
   */
  getPendingPostsInGroupPayload(groupId, cursor = null, amount = 10) {
    try {
      const headers = this.getHeaders();
      const payload = this.getPayload(this?.FUNCTION_NAME?.GET_PENDING_POSTS_IN_GROUP, {
        doc_id: 8052539111451857,
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        variables: {
          content_type: 'PENDING',
          count: amount,
          cursor,
          feedLocation: 'GROUPS_VIEWER_CONTENT_PENDING_POSTS',
          feedbackSource: null,
          focusCommentID: null,
          ordering: 'NEWEST_FIRST',
          privacySelectorRenderLocation: 'COMET_STREAM',
          scale: 1,
          useDefaultActor: false,
          id: groupId,
        },
      });

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * get payload for remove peding post
   * @param {string} storyId
   * @return {object}
   */
  getRemovePendingPostPayload(storyId) {
    try {
      const headers = this.getHeaders();
      const payload = this.getPayload(this?.FUNCTION_NAME?.REMOVE_PENDING_POSTS, {
        doc_id: 7380390182090193,
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        variables: {
          input: {
            story_id: storyId,
            story_location: 'GROUP',
            actor_id: this.cookies?.i_user || this.cookies?.c_user,
            client_mutation_id: '2',
          },
          groupID: null,
          inviteShortLinkKey: null,
          renderLocation: null,
          scale: 1,
        },
      });

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Get timeline posts of friend
   * @param {string} userId
   * @param {string} cursor
   */
  getTimelinePostsPayload(userId, cursor = null) {
    try {
      const headers = this.getHeaders();
      const extraPayload = {
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        __aaid: 0,
        av: this.cookies?.i_user || this.cookies?.c_user,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        __a: 1,
        __req: 'p',
        dpr: 1,
        fb_api_caller_class: 'RelayModern',
        fb_api_req_friendly_name: 'ProfileCometTimelineFeedRefetchQuery',
        variables: {
          afterTime: null,
          beforeTime: null,
          count: 3,
          ...(cursor && { cursor }),
          feedLocation: 'TIMELINE',
          feedbackSource: 0,
          focusCommentID: null,
          memorializedSplitTimeFilter: null,
          omitPinnedPost: true,
          postedBy: null,
          privacy: null,
          privacySelectorRenderLocation: 'COMET_STREAM',
          renderLocation: 'timeline',
          scale: 1,
          stream_count: 10,
          taggedInOnly: null,
          trackingCode: null,
          useDefaultActor: false,
          id: userId,
          __relay_internal__pv__CometImmersivePhotoCanUserDisable3DMotionrelayprovider: false,
          __relay_internal__pv__IsWorkUserrelayprovider: false,
          __relay_internal__pv__IsMergQAPollsrelayprovider: false,
          __relay_internal__pv__CometUFIReactionsEnableShortNamerelayprovider: false,
          __relay_internal__pv__CometUFIShareActionMigrationrelayprovider: true,
          __relay_internal__pv__IncludeCommentWithAttachmentrelayprovider: true,
          __relay_internal__pv__StoriesArmadilloReplyEnabledrelayprovider: true,
          __relay_internal__pv__EventCometCardImage_prefetchEventImagerelayprovider: false,
        },
        server_timestamps: true,
        doc_id: '26837519385846475',
      };
      const payload = this.getPayload(this?.FUNCTION_NAME?.TIMELINE_POSTS, extraPayload);

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Get timeline posts of page
   * @param {string} cursor
   */
  getTimelinePostsOfPagePayload(from = reduceMinutes(new Date(), 60 * 24 * 30 * 4), to = new Date(), cursor = null) {
    try {
      const headers = this.getHeaders();
      const pageId = this.cookies?.i_user || this.cookies?.c_user;
      const extraPayload = {
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        __aaid: 0,
        av: pageId,
        __user: pageId,
        __a: 1,
        __req: 'p',
        dpr: 1,
        fb_api_caller_class: 'RelayModern',
        fb_api_req_friendly_name: 'ProfileCometTimelineFeedRefetchQuery',
        variables: {
          beforeTime: parseInt(to.getTime() / 1000),
          afterTime: parseInt(from.getTime() / 1000),
          count: 3,
          cursor,
          feedLocation: 'TIMELINE',
          feedbackSource: 0,
          focusCommentID: null,
          memorializedSplitTimeFilter: null,
          omitPinnedPost: true,
          postedBy: { group: 'OWNER' },
          privacy: null,
          privacySelectorRenderLocation: 'COMET_STREAM',
          renderLocation: 'timeline',
          scale: 1,
          stream_count: 1,
          taggedInOnly: null,
          trackingCode: null,
          useDefaultActor: false,
          id: pageId,
          __relay_internal__pv__CometImmersivePhotoCanUserDisable3DMotionrelayprovider: false,
          __relay_internal__pv__IsWorkUserrelayprovider: false,
          __relay_internal__pv__IsMergQAPollsrelayprovider: false,
          __relay_internal__pv__CometUFIReactionsEnableShortNamerelayprovider: false,
          __relay_internal__pv__CometUFIShareActionMigrationrelayprovider: true,
          __relay_internal__pv__IncludeCommentWithAttachmentrelayprovider: true,
          __relay_internal__pv__StoriesArmadilloReplyEnabledrelayprovider: false,
          __relay_internal__pv__EventCometCardImage_prefetchEventImagerelayprovider: false,
        },
        server_timestamps: true,
        doc_id: '27642524632013717',
      };
      const payload = this.getPayload(this?.FUNCTION_NAME?.TIMELINE_POSTS_OF_PAGE, extraPayload);

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  getPagesLikedOrFollowedPayload(cursor = null) {
    try {
      const headers = this.getHeaders();
      const userId = this.cookies?.i_user || this.cookies?.c_user;
      const extraPayload = {
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        __aaid: 0,
        av: userId,
        __user: userId,
        __a: 1,
        __req: 'p',
        dpr: 1,
        fb_api_caller_class: 'RelayModern',
        fb_api_req_friendly_name: 'PagesCometAllLikedPagesSectionPaginationQuery',
        variables: {
          count: 20,
          cursor,
          ranking_model: 'INTEGRITY_SIGNALS',
          scale: 1,
          id: userId,
          __relay_internal__pv__StoriesRingrelayprovider: false,
        },
        server_timestamps: true,
        doc_id: '8407760875943054',
      };
      const payload = this.getPayload(this?.FUNCTION_NAME?.PAGE_LIKED_OR_FOLLOWED, extraPayload);

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Get followers
   */
  getFollowers(cursor = null) {
    try {
      const headers = this.getHeaders();
      const pageId = this.cookies?.i_user || this.cookies?.c_user;
      const stringToEncode = `app_collection:${pageId}:2356318349:32`;
      const encodedString = btoa(stringToEncode);

      const extraPayload = {
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        __aaid: 0,
        av: pageId,
        __user: pageId,
        __a: 1,
        dpr: 1,
        __req: 15,
        __comet_req: 15,
        fb_api_caller_class: 'RelayModern',
        fb_api_req_friendly_name: 'ProfileCometAppCollectionListRendererPaginationQuery',
        variables: {
          count: 8,
          cursor,
          scale: 1.5,
          search: null,
          id: encodedString,
        },
        server_timestamps: true,
        doc_id: '26791051487175741',
      };
      const payload = this.getPayload(this?.FUNCTION_NAME?.GET_FOLLOWERS, extraPayload);

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Get poke friend
   * @param {string} userId
   */
  getPokePayload(userId) {
    try {
      const headers = this.getHeaders();
      const extraPayload = {
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        __aaid: 0,
        av: this.cookies?.i_user || this.cookies?.c_user,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        __a: 1,
        __req: '3f',
        dpr: 1,
        fb_api_caller_class: 'RelayModern',
        fb_api_req_friendly_name: 'PokesMutatorPokeMutation',
        variables: {
          input: { client_mutation_id: '1', actor_id: this.cookies?.i_user || this.cookies?.c_user, user_id: userId },
        },
        server_timestamps: true,
        doc_id: '5028133957233114',
      };
      const payload = this.getPayload(this?.FUNCTION_NAME?.POKE, extraPayload);

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Get like reaction
   * @param {string} feedbackId
   */
  getLikeReactionPayload(feedbackId) {
    try {
      const headers = this.getHeaders();
      const extraPayload = {
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        __aaid: 0,
        av: this.cookies?.i_user || this.cookies?.c_user,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        __a: 1,
        __req: '3f',
        dpr: 1,
        fb_api_caller_class: 'RelayModern',
        fb_api_req_friendly_name: 'CometUFIFeedbackReactMutation',
        variables: {
          input: {
            feedback_id: feedbackId,
            feedback_reaction_id: '1635855486666999',
            feedback_source: 'PROFILE',
            actor_id: this.cookies?.i_user || this.cookies?.c_user,
            client_mutation_id: '1',
          },
          useDefaultActor: false,
          __relay_internal__pv__CometUFIReactionsEnableShortNamerelayprovider: false,
        },
        server_timestamps: true,
        doc_id: '8030707243693006',
      };
      const payload = this.getPayload(this?.FUNCTION_NAME?.LIKE, extraPayload);

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Remove friend
   * @param {string} friendId
   */
  getRemoveFriendPayload(friendId) {
    try {
      const headers = this.getHeaders();
      const extraPayload = {
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        __aaid: 0,
        av: this.cookies?.i_user || this.cookies?.c_user,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        __a: 1,
        __req: '3f',
        dpr: 1,
        fb_api_caller_class: 'RelayModern',
        fb_api_req_friendly_name: 'FriendingCometUnfriendMutation',
        variables: {
          input: {
            source: 'bd_profile_button',
            unfriended_user_id: friendId,
            actor_id: this.cookies?.i_user || this.cookies?.c_user,
            client_mutation_id: '4',
          },
          scale: 1,
        },
        server_timestamps: true,
        doc_id: '8752443744796374',
      };
      const payload = this.getPayload(this?.FUNCTION_NAME?.UNFRIEND, extraPayload);

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Remove sale format
   * @param {string} storyId
   */
  getRemoveSaleFormatPayload(storyId) {
    try {
      const actorId = this.cookies?.i_user || this.cookies?.c_user;
      const headers = this.getHeaders();
      const extraPayload = {
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        __aaid: 0,
        av: actorId,
        __user: actorId,
        __a: 1,
        __req: '3f',
        dpr: 1,
        fb_api_caller_class: 'RelayModern',
        fb_api_req_friendly_name: 'CometMarketplaceRemoveForSaleFormatMutation',
        variables: {
          input: {
            story_id: storyId,
            surface: 'GROUP_POST_CHEVRON',
            actor_id: actorId,
            client_mutation_id: '1',
          },
        },
        server_timestamps: true,
        doc_id: '5368163559868668',
      };
      const payload = this.getPayload(this?.FUNCTION_NAME?.REMOVE_SALE_FORMAT, extraPayload);

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * view activity of group
   * @param {string} groupID
   */
  getGroupActivityPayload(groupID) {
    try {
      const actorId = this.cookies?.i_user || this.cookies?.c_user;
      const headers = this.getHeaders();
      const extraPayload = {
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        __aaid: 0,
        av: actorId,
        __user: actorId,
        __a: 1,
        __req: '3f',
        dpr: 1,
        fb_api_req_friendly_name: 'CometGroupAboutRootQuery',
        variables: { groupID, scale: 1 },
        server_timestamps: true,
        doc_id: '27455719007375490',
      };
      const payload = this.getPayload(this?.FUNCTION_NAME?.GROUP_ACTIVITY, extraPayload);

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
        returnRaw: true,
      };
    } catch (error) {
      return null;
    }
  }

  /**
   * Get reactors from feedback
   */
  getReactorsOfPostPayload(data) {
    try {
      const { feedbackId, cursor, reactionID } = data;
      const headers = this.getHeaders();
      const extraPayload = {
        av: this.cookies?.i_user || this.cookies?.c_user,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        __comet_req: 15,
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        fb_api_caller_class: 'RelayModern',
        fb_api_req_friendly_name: 'CometUFIReactionsDialogTabContentRefetchQuery',
        variables: {
          count: 10,
          cursor,
          feedbackTargetID: feedbackId,
          reactionID: reactionID || null,
          scale: 1,
          id: feedbackId,
        },
        server_timestamps: true,
        doc_id: '7612912415479079',
      };

      const payload = this.getPayload(this?.FUNCTION_NAME?.GET_REACTORS_OF_POST, extraPayload);

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      console.log(error, error?.message);
      return null;
    }
  }

  /**
   * Get reshare from feedback
   */
  getReshareOfPostPayload(data) {
    try {
      const { feedbackId, cursor } = data;
      const headers = this.getHeaders();
      const extraPayload = {
        av: this.cookies?.i_user || this.cookies?.c_user,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        __comet_req: 15,
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        fb_api_caller_class: 'RelayModern',
        fb_api_req_friendly_name: 'CometResharesFeedPaginationQuery',
        variables: {
          count: 1,
          cursor,
          feedLocation: 'SHARE_OVERLAY',
          feedbackSource: 1,
          focusCommentID: null,
          privacySelectorRenderLocation: 'COMET_STREAM',
          renderLocation: 'reshares_dialog',
          scale: 1,
          useDefaultActor: false,
          id: feedbackId,
          __relay_internal__pv__GHLShouldChangeAdIdFieldNamerelayprovider: false,
          __relay_internal__pv__GHLShouldChangeSponsoredDataFieldNamerelayprovider: true,
          __relay_internal__pv__CometImmersivePhotoCanUserDisable3DMotionrelayprovider: false,
          __relay_internal__pv__IsWorkUserrelayprovider: false,
          __relay_internal__pv__IsMergQAPollsrelayprovider: false,
          __relay_internal__pv__FBReelsMediaFooter_comet_enable_reels_ads_gkrelayprovider: false,
          __relay_internal__pv__CometUFIReactionsEnableShortNamerelayprovider: false,
          __relay_internal__pv__CometUFIShareActionMigrationrelayprovider: true,
          __relay_internal__pv__IncludeCommentWithAttachmentrelayprovider: true,
          __relay_internal__pv__StoriesArmadilloReplyEnabledrelayprovider: true,
        },
        server_timestamps: true,
        doc_id: '8569788379803523',
      };

      const payload = this.getPayload(this?.FUNCTION_NAME?.GET_RESHARE_OF_POST, extraPayload);

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      console.log(error, error?.message);
      return null;
    }
  }

  /**
   * Get share link for post
   */
  getShareLinkOfPostPayload(link) {
    try {
      const headers = this.getHeaders();
      const extraPayload = {
        av: this.cookies?.i_user || this.cookies?.c_user,
        __user: this.cookies?.i_user || this.cookies?.c_user,
        __comet_req: 15,
        __dyn: this.dyn,
        fb_dtsg: this.fbDtsg,
        fb_api_caller_class: 'RelayModern',
        fb_api_req_friendly_name: 'useLinkSharingCreateWrappedUrlMutation',
        variables: {
          input: {
            client_mutation_id: '5',
            actor_id: this.cookies?.i_user || this.cookies?.c_user,
            original_content_url: link,
            product_type: 'UNKNOWN_FROM_DEEP_LINK',
          },
        },
        server_timestamps: true,
        doc_id: '8809105015789696',
      };

      const payload = this.getPayload(this?.FUNCTION_NAME?.GET_SHARE_LINK_OF_POST, extraPayload);

      return {
        endpoint: this.FACEBOOK_API,
        method: 'POST',
        headers,
        payload,
      };
    } catch (error) {
      console.log(error, error?.message);
      return null;
    }
  }

  /**
   * Get cookie string.
   * @return {string}
   */
  getCookieString() {
    return Object.keys(this.cookies)
      ?.map((key) => `${key}=${this.cookies[key]}`)
      .join('; ');
  }

  /**
   * Get headers.
   * @param {object}extraHeaders
   * @return {object}
   */
  getHeaders(extraHeaders = {}) {
    const cookieString = this.getCookieString();
    return {
      accept: `*/*`,
      'accept-language': 'en-US,en;q=0.9',
      'content-type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
      cookie: cookieString,
      dpr: 1,
      origin: 'https://www.facebook.com',
      referer: 'https://www.facebook.com',
      'sec-ch-prefers-color-scheme': 'light',
      'sec-ch-ua': `"Google Chrome";v="123", "Not:A-Brand";v="8", "Chromium";v="123"`,
      'sec-ch-ua-full-version-list': `"Google Chrome";v="123.0.6312.106", "Not:A-Brand";v="8.0.0.0", "Chromium";v="123.0.6312.106"`,
      'sec-ch-ua-mobile': '?0',
      'sec-ch-ua-model': '',
      'sec-ch-ua-platform': 'Windows',
      'sec-ch-ua-platform-version': '10.0.0',
      'sec-fetch-dest': 'empty',
      'sec-fetch-mode': 'cors',
      'sec-fetch-site': 'same-origin',
      'user-agent':
        'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/123.0.0.0 Safari/537.36',
      'viewport-width': 1920,
      ...extraHeaders,
    };
  }

  extractData(funcName) {
    try {
      if (this.FUNCTION_NAME[funcName] && typeof this.functionData[funcName] === 'object') {
        return this.functionData[funcName] || {};
      }
      return {};
    } catch (error) {
      return {};
    }
  }

  /**
   * Get payload.
   * @param {object} extraPayload
   * @return {object}
   */
  getPayload(funcName = this?.FUNCTION_NAME?.UNKNOWN, extraPayload = {}, excludeUnnecessaryData = true) {
    /**
     * Must have: fb_dtsg
     */
    const payload = {
      fb_dtsg: this.fbDtsg,
      server_timestamps: true,
      ...(this.otherData || {}),
      ...extraPayload,
      ...(this.extractData(funcName) || {}),
    };

    const UNNECESSARY_DATA = ['__hs', '__csr'];
    if (excludeUnnecessaryData) {
      return Object.keys(payload).reduce((res, key) => {
        if (UNNECESSARY_DATA?.indexOf(key) !== -1) {
          return res;
        }
        return { ...res, [key]: payload[key] };
      }, {});
    }
    return payload;
  }
}
