export const DEFAULT_FILTER_TARGETS = {
  siteSelected: 'all',
  keyword: '',
  type: [],
};

// Gender
export const GENDERS = [
  {
    value: 'male',
    label: 'Nam',
    id: 'male',
  },
  {
    value: 'female',
    label: 'Nữ',
    id: 'female',
  },
  {
    value: 'neutral',
    label: 'Trung tính',
    id: 'neutral',
  },
  {
    value: 'non_disclosed',
    label: 'Không đề cập',
    id: 'non_disclosed',
  },
  {
    value: 'all',
    label: 'Tất cả',
    id: 'all',
  },
];
