import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import calendarReducer from './slices/calendar';
import assetReducer from './slices/asset';
import siteReducer from './slices/site';
import swReducer from './slices/sw';
import notiReducer from './slices/notification';
import socialReducer from './slices/social';
import questReducer from './slices/quest';
import tenantReducer from './slices/tenant';
import taskReducer from './slices/task';
import formReducer from './slices/form';
import sourceReducer from './slices/source';
import dataExpirationReducer from './slices/dataExpiration';
import feedReducer from './slices/feed';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const formPersistConfig = {
  key: 'form',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['unCompletedForms'],
};

const rootReducer = combineReducers({
  calendar: calendarReducer,
  asset: assetReducer,
  site: siteReducer,
  sw: swReducer,
  noti: notiReducer,
  social: socialReducer,
  quest: questReducer,
  tenant: tenantReducer,
  task: taskReducer,
  form: persistReducer(formPersistConfig, formReducer),
  source: sourceReducer,
  dataExpiration: dataExpirationReducer,
  feed: feedReducer,
});

export { rootPersistConfig, rootReducer };
