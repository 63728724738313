import { GoogleOAuthProvider } from '@react-oauth/google';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { ChartStyle } from './components/chart';
import NotistackProvider from './components/NotistackProvider';
import { ProgressBarStyle } from './components/ProgressBar';
import ScrollToTop from './components/ScrollToTop';
import ThemeSettings from './components/settings';
// configs
import { UpdatePwaModal } from './components/UpdatePwaModal';
// hooks
import useAuth from './hooks/useAuth';
import useSettings from './hooks/useSettings';
import useWebSocket from './hooks/useWS';
// redux
import { getTenants, updateCurrentTenant } from './redux/slices/tenant';
import { useDispatch, useSelector } from './redux/store';

// utils
import { getSiteAccounts } from './redux/slices/site';
import axios from './utils/axios';
import { getChannelSettings, getSourceCategories, getSourceEntities } from './redux/slices/source';
import useTenant from './hooks/useTenant';

// ----------------------------------------------------------------------

export default function App() {
  const {
    user,
    verify,
    updateExtraData,
    configs: { sso_client_id: ssoClientId },
  } = useAuth();

  const { advanceLogout } = useWebSocket();

  const { changeColor } = useSettings();

  const dispatch = useDispatch();

  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const { tenants, currentTenant } = useSelector((state) => state.tenant);

  const { updatewhiteLabelInfo, isSMT } = useTenant();

  const authGoogleKey =
    ssoClientId?.google || '423923046811-687s6qnbg3talmdesuriooqi2jvcefdm.apps.googleusercontent.com';

  // Get list tenants
  useEffect(() => {
    (async () => {
      await getTenants('api/v1/b2b/tenant/get/', {});
    })();
  }, []);

  // Call async api commons
  useEffect(() => {
    (async () => {
      if (user) {
        getSourceEntities('api/v1/source/entity/', {});
        getSourceCategories('api/v1/source/category/', {});
        getChannelSettings('api/v1/users/setting_action/', {});
        dispatch(getSiteAccounts());
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // Control tenant login and UI
  useEffect(() => {
    (async () => {
      if (tenants.length > 0) {
        // Common param
        // Referrer tenant
        const tenantName = searchParams.get('tenant');
        // ############ Detect by login token ###########
        // Token for login
        const loginToken = searchParams.get('login_token');
        // Tenant of user
        const selectedTenantName = searchParams.get('selected_tenant');

        if (tenantName && loginToken && selectedTenantName) {
          await detectOneTimeLoginB2B(tenantName, loginToken, selectedTenantName);
        } else {
          // ############ Detect by query parameter ###########
          await detectQueryParamsB2B(tenantName);
        }

        // ############ Detect by host for whitelabel domain ###########
        await detectDomainB2B();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, tenants]);

  // Check is valid tenant user
  useEffect(() => {
    //   if (currentTenant && user && !isSMT) {
    //     if (user?.tenant_id && typeof user?.tenant_id === 'number') {
    //       // Find tenant of user
    //       const index = tenants.findIndex((item) => item.id === user?.tenant_id);
    //       // Check tenant
    //       if (index !== -1 && tenants[index].id !== currentTenant?.id && currentTenant?.id !== tenants[index].referrer) {
    //         localStorage.removeItem('tenant');
    //         advanceLogout();
    //       }
    //     } else {
    //       localStorage.removeItem('tenant');
    //       advanceLogout();
    //     }
    //   }
    updatewhiteLabelInfo();
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTenant, user]);

  // Detect just
  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      sessionStorage.removeItem('isReloaded');
    };
  }, []);

  // Call async api quests, categories, cms
  useEffect(() => {
    // Optimize get quests, socials, categories cms
    if (user) {
      updateExtraData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleBeforeUnload = () => {
    sessionStorage.setItem('isReloaded', true);
  };

  // TENANT logic ================================== start
  const detectQueryParamsB2B = async (tenantName) => {
    const tenantFromLocalStorage = JSON.parse(localStorage.getItem('tenant'));
    if (tenantName || tenantFromLocalStorage) {
      const index = tenants.findIndex(
        (item) => (item.name === tenantName || item.name === tenantFromLocalStorage?.name) && item.is_referrer
      );
      if (index !== -1) {
        await storageTenant(tenants[index]);
      } else if (tenantFromLocalStorage) {
        localStorage.removeItem('tenant');
      }
    }
  };

  const detectDomainB2B = async () => {
    const currentHost = window.location.host;
    const indexWhitelabelDomain = tenants.findIndex((item) => currentHost?.indexOf(item?.whitelabel_domain) !== -1);
    if (currentHost && indexWhitelabelDomain !== -1) {
      const selectedTenant = tenants[indexWhitelabelDomain];
      await storageTenant(selectedTenant);
    }
  };

  const detectOneTimeLoginB2B = async (tenantName, loginToken, selectedTenantName) => {
    const index = tenants.findIndex((item) => item?.name === tenantName && item?.is_referrer);
    if (index !== -1) {
      await storageTenant(tenants[index]);
      const res = await axios.post(`api/v1/users/login_by_link/`, {
        login_token: loginToken,
        tenant_name: selectedTenantName,
      });
      const { refresh, token } = res?.data;
      await verify(token, refresh);
    }
  };

  const storageTenant = async (selectedTenant) => {
    if (!selectedTenant) {
      return;
    }
    await updateCurrentTenant(selectedTenant);
    localStorage.setItem('tenant', JSON.stringify(selectedTenant));
    if (selectedTenant?.config?.colors) {
      const {
        primary_color: primaryColor,
        secondary_color: secondaryColor,
        text_color: textColor,
      } = selectedTenant?.config?.colors;

      const themeColor = {
        contrastText: '#ffffff',
        lighter: `${secondaryColor}30`,
        light: `${secondaryColor}70`,
        main: primaryColor,
        dark: primaryColor,
        darker: primaryColor,
        text: textColor,
        name: 'custom',
      };
      changeColor(themeColor);
    }
  };
  // TENANT logic ================================== end

  return (
    <GoogleOAuthProvider clientId={authGoogleKey}>
      <MotionLazyContainer>
        <ThemeProvider>
          <ThemeSettings>
            <NotistackProvider>
              <ProgressBarStyle />
              <ChartStyle />
              <ScrollToTop />
              <Router deactivateRoutes={user?.tenant_config?.deactivate_routes || []} />
            </NotistackProvider>
          </ThemeSettings>
        </ThemeProvider>
        <UpdatePwaModal />
      </MotionLazyContainer>
    </GoogleOAuthProvider>
  );
}
