import { useState } from 'react';
import { Button, Grid, Stack, TextField, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import PropTypes from 'prop-types';
import Iconify from './Iconify';
// components
// hooks
import useLocales from '../hooks/useLocales';

export default function ConfirmText({ isOpen, handleConfirm, handleClose, content, subtitle }) {
  const { translate } = useLocales('component.delete_account');
  const [text, setText] = useState('');

  const handleTextChange = (value) => {
    setText(value);
  };

  const handleSubmit = () => {
    handleConfirm(text);
    setText('');
  };

  return (
    <div>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isOpen}>
        <Grid
          container
          sx={{
            maxWidth: 350,
            backgroundColor: '#fff',
            px: '30px',
            py: '20px',
            borderRadius: '16px',
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              color: '#000',
              fontSize: '14px',
            }}
          >
            <Stack
              direction="column"
              justifyContent="center"
              width={'100%'}
              sx={{
                '& ul': {
                  ml: '25px',
                  li: {
                    mt: 1,
                  },
                },
              }}
            >
              <Typography variant="h4" textAlign={'center'} mb={5}>
                {content}
              </Typography>

              {subtitle && (
                <Typography variant="subtitle2" textAlign={'center'} mb={5}>
                  {subtitle}
                </Typography>
              )}

              <TextField value={text} fullWidth onChange={(event) => handleTextChange(event.target.value)} />

              <Stack direction={'row'} mt={'40px'} justifyContent={'space-between'}>
                <Button
                  variant="text"
                  onClick={() => {
                    handleClose();
                  }}
                  startIcon={<Iconify icon="material-symbols:arrow-back-ios-new" width={20} height={20} />}
                >
                  {translate('btn.back')}
                </Button>
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                  {translate('btn.confirm')}
                </Button>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Backdrop>
    </div>
  );
}

ConfirmText.propTypes = {
  isOpen: PropTypes.bool,
  handleConfirm: PropTypes.func,
  handleClose: PropTypes.func,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  subtitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};
