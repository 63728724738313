// utils
import axios from '../../../utils/axios';
import { SOURCE_API_ENDPOINTS } from '../campaign/helper';

const TYPES_MAPPING = {
  bds: 'BĐS',
  mxh: 'MXH',
  web: 'WEB',
  pnt: 'PNT',
};

export async function createPost(params) {
  const {
    shortTitle,
    description,
    legalDocument,
    who,
    street,
    address,
    area,
    price,
    bedroom,
    toilet,
    assetDetail,
    ward,
    demand,
    images,
    city,
    district,
    houseNumberShown,
    contactInfo,
    url,
    converts,
    shareSource,
    roiPercentage,
  } = params;
  let isSuccess = false;
  let err;
  let res;

  await axios
    .post(`api/v1/assets/create/`, {
      images: images.map((img) => ({ image: img })),
      main_image: images[0],
      title: shortTitle,
      description,
      contact: contactInfo,
      url,
      bds_details: {
        hand_over: legalDocument?.id || '',
        seller: who,
        street: street || '',
        house_number: address,
        floor: 0,
        area,
        price,
        roi_percentage: roiPercentage,
        rooms: bedroom,
        toilets: toilet,
        category: assetDetail?.id,
        ward: ward?.id,
        type_status: demand,
        city: city.id,
        district: district.id,
        house_number_shown: houseNumberShown,
      },
      type: 'BĐS',
    })
    .then((response) => {
      isSuccess = true;
      res = response;
    })
    .catch((error) => {
      isSuccess = false;
      err = error;
    });
  const clonedAssets = await clone(isSuccess, converts, res?.data);
  return { isSuccess, response: res, error: err, clonedAssets, shareSource };
}

export async function updatePost(params, uuid) {
  const {
    shortTitle,
    description,
    legalDocument,
    who,
    street,
    address,
    area,
    price,
    roiPercentage,
    bedroom,
    toilet,
    assetDetail,
    ward,
    demand,
    images,
    city,
    district,
    houseNumberShown,
    contactInfo,
    url,
  } = params;
  let isSuccess = false;
  let err;
  let res;
  await axios
    .put(`api/v1/assets/${uuid}/`, {
      images: images.map((img) => ({ image: img })),
      main_image: images[0],
      title: shortTitle,
      description,
      contact: contactInfo,
      url,
      bds_details: {
        hand_over: legalDocument?.id || '',
        seller: who,
        street: street || '',
        house_number: address,
        floor: 0,
        area,
        price,
        roi_percentage: roiPercentage,
        rooms: bedroom,
        toilets: toilet,
        category: assetDetail?.id,
        ward: ward?.id,
        type_status: demand,
        city: city.id,
        district: district.id,
        house_number_shown: houseNumberShown,
      },
      type: 'BĐS',
    })
    .then((response) => {
      isSuccess = true;
      res = response;
    })
    .catch((error) => {
      isSuccess = false;
      err = error;
    });
  return { isSuccess, response: res, error: err };
}

export async function createPostSocial(params, type = 'facebook') {
  const { description, images, shortTitle, converts, shortContent } = params;
  let isSuccess = false;
  let err;
  let res;
  await axios
    .post(`api/v1/assets/create/`, {
      images: images.map((img) => ({ image: img })),
      main_image: images[0],
      title: shortTitle,
      description,
      mxh_details: {
        social: type,
        meta: {
          full: description,
          short: shortContent || description?.substring(0, 800),
        },
      },
      type: 'MXH',
    })
    .then((response) => {
      isSuccess = true;
      res = response;
    })
    .catch((error) => {
      isSuccess = false;
      err = error;
    });
  // Check clone types
  const clonedAssets = await clone(isSuccess, converts, res?.data);
  return { isSuccess, response: res, error: err, clonedAssets };
}

export async function updatePostSocial(params, uuid, type) {
  const { description, images, shortTitle } = params;
  let isSuccess = false;
  let err;
  let res;
  await axios
    .put(`api/v1/assets/${uuid}/`, {
      images: images.map((img) => ({ image: img })),
      main_image: images[0],
      title: shortTitle,
      description,
      mxh_details: {
        social: type,
      },
      type: 'MXH',
    })
    .then((response) => {
      isSuccess = true;
      res = response;
    })
    .catch((error) => {
      isSuccess = false;
      err = error;
    });
  return { isSuccess, response: res, error: err };
}

export async function createPostWebsite(params) {
  const { shortTitle, description, content, tags, meta, images, converts, template } = params;
  let isSuccess = false;
  let err;
  let res;
  await axios
    .post(`api/v1/assets/create/`, {
      images: images.map((img) => ({ image: img })),
      main_image: images[0],
      title: shortTitle,
      description,
      web_details: {
        template,
        content,
        tags: tags?.join(','),
        meta,
      },
      type: 'WEB',
    })
    .then((response) => {
      isSuccess = true;
      res = response;
    })
    .catch((error) => {
      isSuccess = false;
      err = error;
    });
  // Check clone types
  const clonedAssets = await clone(isSuccess, converts, res?.data);
  return { isSuccess, response: res, error: err, clonedAssets };
}

export async function updatePostWebsite(params, uuid) {
  const { shortTitle, description, content, tags, meta, images, template } = params;

  let isSuccess = false;
  let err;
  let res;
  await axios
    .put(`api/v1/assets/${uuid}/`, {
      images: images.map((img) => ({ image: img })),
      main_image: images[0],
      title: shortTitle,
      description,
      web_details: {
        template,
        content,
        tags: tags?.join(','),
        meta,
      },
      type: 'WEB',
    })
    .then((response) => {
      isSuccess = true;
      res = response;
    })
    .catch((error) => {
      isSuccess = false;
      err = error;
    });
  return { isSuccess, response: res, error: err };
}

const clone = async (isSuccess, converts, parentAsset) => {
  // Check clone types
  const typesNeedClone = Object.keys(converts || {}).reduce((val, key) => {
    if (converts[key]) {
      val.push(key);
    }
    return val;
  }, []);

  if (!isSuccess || typesNeedClone.length === 0) {
    return [];
  }
  const resultsClone = await cloneAsset(parentAsset?.uuid, typesNeedClone);
  return resultsClone;
};
// eslint-disable-next-line consistent-return
const cloneAssetSingleType = async (uuid, convertTo) => {
  try {
    const res = await axios.post(`api/v1/assets/convert/`, {
      uuid,
      convert_to: TYPES_MAPPING[convertTo],
    });
    const { message, task } = res.data;
    return { success: true, message, task };
  } catch (error) {
    return { success: false, message: error?.message };
  }
};

// eslint-disable-next-line consistent-return
const cloneAsset = async (uuid, listTypes) => {
  try {
    if (uuid && listTypes) {
      const allResponse = listTypes?.map(async (item) => cloneAssetSingleType(uuid, item));
      return await Promise.all([...allResponse]);
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * Using with preview modal
 * setPreview({
        isOpen: true,
        assets,
        index:0
      });
 * 
 * @param {string} listUUID 
 * @returns assets
 */
// eslint-disable-next-line consistent-return
export const getInfoClonedAssets = async (listUUID) => {
  try {
    if (listUUID) {
      const promises = listUUID?.map(async (item) => {
        const { data } = await axios.get(`api/v1/assets/${item}`);
        return data;
      });
      let assets = await Promise.all(promises);
      assets = assets.map((data) => ({
        ...data,
        ...data?.bds_details,
        ...data?.mxh_details,
        ...data?.pnt_details,
        ...data?.web_details,
        id: data?.uuid,
        city: data?.city?.name,
      }));
      return assets;
    }
  } catch (error) {
    console.log(error);
  }
};

export const formatData = (params, type) => {
  try {
    const { converts } = params;
    // BĐS
    if (type === 'BĐS') {
      const {
        shortTitle,
        description,
        legalDocument,
        who,
        street,
        address,
        area,
        price,
        bedroom,
        toilet,
        assetDetail,
        ward,
        demand,
        images,
        city,
        district,
        houseNumberShown,
        contactInfo,
        url,
      } = params;

      return {
        images: images.map((img) => ({ image: img })),
        main_image: images[0],
        title: shortTitle,
        description,
        contact: contactInfo,
        url,
        bds_details: {
          hand_over: legalDocument?.id || '',
          seller: who,
          street: street || '',
          house_number: address,
          floor: 0,
          area,
          price,
          rooms: bedroom,
          toilets: toilet,
          category: assetDetail?.id,
          ward: ward?.id,
          type_status: demand,
          city: city.id,
          district: district.id,
          house_number_shown: houseNumberShown,
        },
        type: 'BĐS',
        converts,
      };
    }
    // SOCIAL
    if (type === 'MXH') {
      const { description, images, shortTitle } = params;
      return {
        images: images.map((img) => ({ image: img })),
        main_image: images[0],
        title: shortTitle,
        description,
        mxh_details: {
          social: type,
        },
        type: 'MXH',
        converts,
      };
    }
    // WEB
    const { shortTitle, description, content, tags, meta, images, template } = params;

    return {
      images: images.map((img) => ({ image: img })),
      main_image: images[0],
      title: shortTitle,
      description,
      web_details: {
        template,
        content,
        tags: tags?.join(','),
        meta,
      },
      type: 'WEB',
      converts,
    };
  } catch (error) {
    console.log(error);
    return {};
  }
};

export async function createPostAnyType(payload) {
  const { converts } = payload;
  let isSuccess = false;
  let err;
  let res;

  await axios
    .post(`api/v1/assets/create/`, payload)
    .then((response) => {
      isSuccess = true;
      res = response;
    })
    .catch((error) => {
      isSuccess = false;
      err = error;
    });
  // Check clone types
  const clonedAssets = await clone(isSuccess, converts, res?.data);
  return { isSuccess, response: res, error: err, clonedAssets };
}

export const autoGenerateSource = async (assetId, category, image, level) => {
  try {
    const name = `Source-${Date.now()}`;
    const payload = new FormData();
    payload.append('category', category?.id);
    payload.append('product_entity_id', assetId);
    payload.append('name', name);
    payload.append('media', image);
    payload.append('level', level);

    const config = {
      method: 'post',
      url: SOURCE_API_ENDPOINTS.source.create,
      headers: { 'Content-Type': 'application/json' },
      data: payload,
    };
    const { data: result } = await axios(config);
    return { success: true, data: result, msg: 'success' };
  } catch (error) {
    return { success: false, data: error?.message || error?.detail, msg: error?.message || error?.detail };
  }
};
