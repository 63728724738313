import { useEffect } from 'react';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import useBoolean from '../../../../../hooks/useBoolean';
import Iconify from '../../../../../components/Iconify';
import useLocales from '../../../../../hooks/useLocales';
import useSettings from '../../../../../hooks/useSettings';
import useUserPreference from '../../../../../hooks/useUserPreference';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

SinglePopup.propTypes = { data: PropTypes.object, next: PropTypes.func, zIndex: PropTypes.number };

export default function SinglePopup({ data, next, zIndex = 0 }) {
  const { themeMode } = useSettings();

  const { id: popupId, content, isPersistent } = data || {};

  const checkbox = useBoolean();

  const { translate: tButton } = useLocales('tool.btn');

  const { translate } = useLocales();

  const { setPreference, setPreferenceToday } = useUserPreference();

  useEffect(() => {
    if (data) {
      checkbox.onFalse();
    }
  }, [data]);

  const onClickViewMore = () => {
    if (checkbox?.value) {
      if (isPersistent) {
        setPreferenceToday(popupId);
      } else {
        setPreference(popupId);
      }
    }
    next(popupId);
  };

  return (
    <BootstrapDialog open sx={{ zIndex: (theme) => theme.zIndex.drawer + zIndex }}>
      {/* <DialogContent> */}
      <Box dangerouslySetInnerHTML={{ __html: content }} />
      {/* </DialogContent> */}
      <DialogActions
        sx={{
          py: '8px !important',
        }}
      >
        <Stack alignItems={'center'} direction={'row'} justifyContent={{ xs: 'space-between' }} flex={1}>
          <Stack alignItems={'center'} direction={'row'}>
            <Checkbox checked={checkbox?.value} onClick={checkbox.onToggle} />
            {isPersistent ? (
              <Typography>{translate('not_show_today')}</Typography>
            ) : (
              <Typography>{translate('not_show_again')}</Typography>
            )}
          </Stack>
          <Button
            sx={{ color: themeMode === 'dark' ? 'common.white' : 'common.black', textTransform: 'none' }}
            autoFocus
            onClick={onClickViewMore}
            endIcon={<Iconify icon="icon-park-outline:right" sx={{ height: 18, width: 18 }} />}
          >
            {tButton('confirm')}
          </Button>
        </Stack>
      </DialogActions>
    </BootstrapDialog>
  );
}
