import { useContext } from 'react';
import { TTFunctionContext } from '../contexts/TTFunctionContext';

const useTTToolFunction = () => {
  const context = useContext(TTFunctionContext);

  if (!context) {
    throw new Error('useTTToolFunction context must be use inside TTFunctionProvider');
  }

  return context;
};

export default useTTToolFunction;
