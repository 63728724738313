import { Button, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';
import Iconify from '../../../components/Iconify';
import useResponsive from '../../../hooks/useResponsive';
import useToolContext from '../../../hooks/useToolContext';
import { compareVersions } from '../../../utils/others';

export default function InstallExtension() {
  const isMobile = useResponsive('down', 'sm');

  const theme = useTheme();

  const { tenantExtensionVersion, extensionVersion } = useToolContext();

  const handleInstall = () => {
    window.open('https://doc.fbtool.net/', '_blank');
  };

  const color = useMemo(() => {
    if (extensionVersion && tenantExtensionVersion) {
      const diff = compareVersions(extensionVersion, tenantExtensionVersion);
      return diff < 0 ? theme.palette.error.main : theme.palette.primary.main;
    }
    return theme.palette.error.main;
  }, [extensionVersion, tenantExtensionVersion]);

  const versionText = useMemo(() => {
    if (isMobile) {
      return '';
    }
    if (extensionVersion && tenantExtensionVersion) {
      const diff = compareVersions(extensionVersion, tenantExtensionVersion);
      return diff > 0 ? `v${extensionVersion}` : `v${tenantExtensionVersion}`;
    }
    return `v${tenantExtensionVersion}`;
  }, [isMobile, extensionVersion, tenantExtensionVersion]);

  return (
    <Button
      sx={{
        border: `1px solid ${color}`,
        color,
        borderRadius: '8px',
        px: 1.2,
        py: 0.5,
        fontWeight: 700,
        fontSize: '1rem',
        cursor: 'pointer',
        minWidth: 'fit-content',
      }}
      onClick={handleInstall}
    >
      <Stack direction={'row'} alignItems={'center'} columnGap={0.5} sx={{ textTransform: 'lowercase' }}>
        <Iconify icon={`material-symbols:download`} sx={{ width: 20, height: 20 }} />
        {tenantExtensionVersion ? versionText : ''}
      </Stack>
    </Button>
  );
}
