import { Box, Button, Divider, Slider, Stack, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import Iconify from '../../../../../components/Iconify';
import useLocales from '../../../../../hooks/useLocales';
import { ControlButton } from '../../components';

// ----------------------------------------------------------------------
AgeDropdown.propTypes = {
  currentvalue: PropTypes.object,
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
  onClose: PropTypes.func,
};

const DEFAULT_VALUE = { range: [0, 100], all: true };

export default function AgeDropdown({ currentvalue, onSubmit, onReset, onClose }) {
  const [rangeAge, setRangeAge] = useState(DEFAULT_VALUE);

  const { translate } = useLocales('dashboard.ads_marketing.form.field.age');

  const AGE_OPTIONS = [
    {
      value: 'all',
      label: translate('all'),
    },
    {
      value: '0-6',
      label: translate('child'),
    },
    {
      value: '6-16',
      label: translate('between', { min: 6, max: 16 }),
    },
    {
      value: '6-18',
      label: translate('between', { min: 6, max: 18 }),
    },
    {
      value: '18-24',
      label: translate('between', { min: 18, max: 24 }),
    },
    {
      value: '24-45',
      label: translate('between', { min: 24, max: 45 }),
    },
    {
      value: '45-65',
      label: translate('middle'),
    },
    {
      value: '65-100',
      label: translate('elder'),
    },
  ];

  useEffect(() => {
    setRangeAge(currentvalue);
  }, [currentvalue]);

  const handleChange = (event, newValue) => {
    setRangeAge({ all: false, range: newValue });
  };

  const handleClick = useCallback(
    (item) => {
      try {
        const { value } = item;

        const [start, end] = value.split('-');

        const newValue = [parseInt(start, 10), parseInt(end, 10)];

        if (newValue.some((item2) => !item2 && item2 !== 0)) {
          setRangeAge(DEFAULT_VALUE);
          return;
        }

        setRangeAge({ all: false, range: newValue });
      } catch (error) {
        console.log(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <Stack direction="column" p={2} width={{ xs: '80vw', md: '20vw' }} gap={2}>
        <Stack direction="column" gap={1}>
          <Stack direction="column" gap={1}>
            <Stack direction="row" alignItems="center" justifyContent="center">
              <TextField
                type="number"
                value={rangeAge.range[0]}
                InputProps={{
                  inputProps: {
                    max: 100,
                    min: 0,
                  },
                }}
                label={translate('from')}
              />
              <Iconify icon="solar:arrow-right-linear" sx={{ flex: 1 }} />
              <TextField
                type="number"
                value={rangeAge.range[1]}
                InputProps={{
                  inputProps: {
                    max: 100,
                    min: 0,
                  },
                }}
                label={translate('to')}
              />
            </Stack>
            <Slider value={rangeAge.range} onChange={handleChange} step={1} min={0} max={100} />
          </Stack>
          <Divider />
          <Box maxHeight={300} className="scroll-bar-custom">
            <Stack direction="column" gap={1}>
              {AGE_OPTIONS?.map((item) => (
                <Button
                  variant="text"
                  key={item.value}
                  sx={{ justifyContent: 'start' }}
                  onClick={() => {
                    handleClick(item);
                  }}
                >
                  {item.label}
                </Button>
              ))}
            </Stack>
          </Box>
        </Stack>
      </Stack>
      <ControlButton
        onClickReset={() => {
          if (onReset) {
            onClose();
            onReset();
          }
        }}
        onClickSubmit={() => {
          onClose();
          onSubmit(rangeAge);
        }}
      />
    </>
  );
}
