import { createSlice } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const BASE_SOCIAL_URL = 'api/v1/social/';

const initialState = {
  isLoading: false,
  error: null,
  disabledSocials: [],
  facebook: {
    groups: [],
    pages: [],
    connected: [],
  },
  linkedin: {
    connected: [],
  },
  pinterest: {
    connected: [],
  },
  all_connected: [],
};

const slice = createSlice({
  name: 'social',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET SOCIAL INFO BY SOCICAL NAME
    getSocialSuccess(state, action) {
      const { socialName, data } = action.payload;
      state[socialName] = { ...data };
    },
    // GET PAGES INFO BY SOCICAL NAME
    getPagesSuccess(state, action) {
      const { socialName, data } = action.payload;
      // Remove duplicate
      const stateSocial = { ...state[socialName] };
      stateSocial.pages = uniqBy([...stateSocial.pages, ...data], 'id');
      state[socialName] = { ...stateSocial };
    },
    // GET GROUPS INFO BY SOCICAL NAME
    getGroupsSuccess(state, action) {
      const { socialName, data } = action.payload;
      const stateSocial = { ...state[socialName] };
      stateSocial.groups = uniqBy([...stateSocial.groups, ...data], 'id');
      state[socialName] = { ...stateSocial };
    },
    // GET CONNECTED INFO BY SOCICAL NAME
    getConnectedsSuccess(state, action) {
      const { socialName, data } = action.payload;
      const stateSocial = { ...state[socialName] };
      stateSocial.connected = uniqBy([...stateSocial.connected, ...data], 'id');
      state[socialName] = { ...stateSocial };

      // Global storage
      state.all_connected = uniqBy([...state.all_connected, ...data], 'id');
    },
    loadAllSocialInfo(state, action) {
      const { socialName, data } = action.payload;
      const stateSocial = { ...state[socialName] };
      stateSocial.connected = uniqBy([...stateSocial.connected, ...data], 'social_id');
      state[socialName] = { ...stateSocial };
    },
    // Call after connect page or group success
    newConnectedSuccess(state, action) {
      const { socialName, data } = action.payload;
      const stateSocial = { ...state[socialName] };
      stateSocial.connected = uniqBy([...stateSocial.connected, { ...data }], 'social_id');
      state[socialName] = { ...stateSocial };
      // Global storage
      state.all_connected = uniqBy([...state.all_connected, { ...data }], 'social_id');
    },
    // Call after remove connection
    removeConnectedSuccess(state, action) {
      const { socialName, id } = action.payload;
      const stateSocial = { ...state[socialName] };
      stateSocial.connected = uniqBy(
        [...stateSocial.connected].filter((item) => item.social_id !== id),
        'social_id'
      );
      state[socialName] = { ...stateSocial };
      // Global storage
      state.all_connected = uniqBy(
        [...state.all_connected].filter((item) => item.social_id !== id),
        'social_id'
      );
    },
    disableSocial(state, action) {
      const { socialName } = action.payload;
      const disabledSocials = uniqBy([...state.disabledSocials, socialName]);
      state.disabledSocials = [...disabledSocials];
    },
    resetState() {
      const temp = { ...initialState };
      return { ...temp };
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getSocialInfo(socialName) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const {
        data: { result: pages },
      } = await axios.get(`${BASE_SOCIAL_URL}${socialName}/pages/new/`);

      const {
        data: { result: groups },
      } = await axios.get(`${BASE_SOCIAL_URL}${socialName}/groups/new/`);

      const {
        data: { results: connected },
      } = await axios.get(`${BASE_SOCIAL_URL}${socialName}/connected/?schedule=1`);

      dispatch(
        slice.actions.getSocialSuccess({
          socialName,
          data: {
            pages,
            groups,
            connected,
          },
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function disableSocialName(socialName) {
  dispatch(
    slice.actions.disableSocial({
      socialName,
    })
  );
}

export async function getPagesBySocial(socialName, params) {
  const response = await axios.get(`${BASE_SOCIAL_URL}${socialName}/pages/new/`, { params });
  if (response?.data) {
    const { result, next } = response.data;
    dispatch(slice.actions.getPagesSuccess({ socialName, data: result }));

    if (next) {
      params.next = next;
      await getPagesBySocial(socialName, params);
    }
  }
}

export async function getGroupsBySocial(socialName, params) {
  const response = await axios.get(`${BASE_SOCIAL_URL}${socialName}/groups/new/`, { params });
  if (response?.data) {
    const { result, next } = response.data;
    dispatch(slice.actions.getGroupsSuccess({ socialName, data: result }));

    if (next) {
      params.next = next;
      await getGroupsBySocial(socialName, params);
    }
  }
}
// Add new connected page or groups into social slice
export async function storageNewConnected(socialName, newConnected) {
  dispatch(slice.actions.newConnectedSuccess({ socialName, data: newConnected }));
}

// Remove connected page or groups into social slice
export async function removeConnected(socialName, id) {
  dispatch(slice.actions.removeConnectedSuccess({ socialName, id }));
}

export async function getConnectedsBySocial(params) {
  const response = await axios.get(`${BASE_SOCIAL_URL}connected/?schedule=1`, { params });
  if (response?.data) {
    const { results, next } = response.data;
    // Get list connected of socials
    ['facebook', 'linkedin', 'pinterest'].forEach((socialName) => {
      dispatch(
        slice.actions.getConnectedsSuccess({ socialName, data: results.filter((item) => item.platform === socialName) })
      );
    });

    if (next && undefined === params.page) {
      // first call
      const { count } = response.data;
      const pageSize = results.length;
      const concurrency = Math.ceil(count / pageSize);
      for (let page = 2; page <= concurrency; page += 1) {
        (async () => {
          params.page = page;
          await getConnectedsBySocial(params);
        })();
      }
    }
  }
}

// eslint-disable-next-line consistent-return
export async function getConnectedsDetailById(socialName, id) {
  // Get detail info of each event
  try {
    const response = await axios.get(`${BASE_SOCIAL_URL}${socialName}/detail/${id}/`);
    const results = response.data;
    dispatch(slice.actions.loadAllSocialInfo({ socialName, data: [results] }));
    return results;
  } catch (error) {
    console.log(error);
  }
}

// eslint-disable-next-line consistent-return
export async function getSocialConnected(socialName) {
  try {
    const {
      data: { results: connected, next, count, previous },
    } = await axios.get(`${BASE_SOCIAL_URL}${socialName}/connected/?schedule=1`);

    return { connected, next, count, previous };
  } catch (error) {
    console.log(error);
  }
}

// reset state for clear social pages, groups after logout
export function resetSocialState() {
  dispatch(slice.actions.resetState());
}
