import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Select } from '@mui/material';

// ----------------------------------------------------------------------

RHFSelectCustom.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
};

export default function RHFSelectCustom({ name, children, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Select {...field} {...other}>
          {children}
        </Select>
      )}
    />
  );
}
