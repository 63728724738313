import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import SocialWebPostForm from './SocialWebPostForm';

// ----------------------------------------------------------------------

SkipForm.propTypes = {
  onCreatedSuccess: PropTypes.func,
  onBack: PropTypes.func,
  source: PropTypes.object,
};

export default function SkipForm({ onCreatedSuccess, onBack, source = {} }) {
  return (
    <Box sx={{ py: 2.5, px: 3 }}>
      <SocialWebPostForm onBack={onBack} onCreatedSuccess={onCreatedSuccess} source={source} />
    </Box>
  );
}
