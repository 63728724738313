import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, Stack, Tab, Tabs, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { CalendarPicker, ClockPicker } from '@mui/x-date-pickers';
import { useEffect, useRef, useState } from 'react';
import Iconify from '../../../../../components/Iconify';

import useAdsCampaign from '../../../../../hooks/useAdsCampaign';
import useLocales from '../../../../../hooks/useLocales';
import useTabs from '../../../../../hooks/useTabs';
import { fDate, fTime, getDateInFuture } from '../../../../../utils/formatTime';
import TableTarget from './TableTarget';
import { getEstimateCost } from '../helper';
import useAuth from '../../../../../hooks/useAuth';
import Loading from '../../../../../components/Loading';

// ============================================================
const ResultBlock = styled('div')((props) => ({
  color: props.color,
  backgroundColor: props.bgColor,
  filter: `drop-shadow(3px 3px 6px ${props.bgColor})`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '0.2rem',
  borderRadius: '0.5rem',
  width: '50%',
  height: '3rem',
  fontSize: '1rem',
  fontWeight: '700',
}));

// ============================================================

AdsSchedule.propTypes = {};
// eslint-disable-next-line no-unused-vars
export default function AdsSchedule() {
  const { translate: tSchedule } = useLocales('dashboard.ads_marketing.schedule');

  const { translate: tButton } = useLocales('dashboard.ads_marketing.btn');

  const { enqueueSnackbar } = useSnackbar();

  const {
    configs: { ads_time_range: ADS_TIME_RANGE },
  } = useAuth();

  const { onNextStep, onPreviousStep, loading, step1 } = useAdsCampaign();

  const { currentTab, onChangeTab } = useTabs('date');

  const [textColor, setTextColor] = useState({
    calendar: true,
    clock: false,
  });

  const costDebounce = useRef(null);

  const [result, setResult] = useState({
    totalFee: 0,
    totalPeople: 0,
  });

  const [excludesList, setExcludesList] = useState([]);

  const [date, setDate] = useState(new Date());

  const [time, setTime] = useState(new Date());

  useEffect(() => {
    if (fDate(date) === fDate(setTimeMin())) {
      setTime(new Date());
    }
  }, [date]);

  const onChangeDate = (newDate) => {
    setDate(newDate);
    setTextColor({
      calendar: true,
      clock: false,
    });
  };

  const onChangeTime = (newTime) => {
    setTime(newTime);
    setTextColor({
      calendar: false,
      clock: true,
    });
  };

  const calcCost = async () => {
    try {
      loading.onTrue();

      const filter = { ads_ids: step1?.adsIds?.filter((item) => excludesList.indexOf(item) === -1) };

      const { success, data, msg } = await getEstimateCost(filter);

      if (!success) {
        enqueueSnackbar(msg, { variant: 'error' });
      }

      const { estimate_cost: estimateCost, audience } = data || {};
      setResult({
        totalFee: estimateCost || 0,
        totalPeople: audience || 0,
      });
      clearInterval(costDebounce.current);
    } catch (error) {
      console.log(error);
    } finally {
      loading.onFalse();
    }
  };

  useEffect(() => {
    if (costDebounce) {
      clearInterval(costDebounce.current);
    }

    costDebounce.current = setTimeout(() => {
      calcCost();
    }, 1000);

    return () => clearInterval(costDebounce.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excludesList, step1]);

  const handleExlcudeAdsAccount = (adsId) => {
    const index = excludesList?.indexOf(adsId);
    const temp = [...excludesList];
    if (index !== -1) {
      temp.splice(index, 1);
      setExcludesList(temp);
    } else {
      temp.push(adsId);
      setExcludesList(temp);
    }
  };

  const handleNextStep = async () => {
    const payload = { date, time, excludesList };
    onNextStep(payload);
  };

  const maxDateAllow = getDateInFuture(ADS_TIME_RANGE);

  const TABS = [
    {
      value: 'date',
      icon: <Iconify icon={'material-symbols:calendar-today-rounded'} width={20} height={20} />,
      component: (
        <CalendarPicker
          inputFormat="dd/MM/yyyy"
          onChange={(newDate) => onChangeDate(newDate)}
          date={date}
          disablePast
          maxDate={maxDateAllow}
        />
      ),
    },
    {
      value: 'time',
      icon: <Iconify icon={'ant-design:clock-circle-outlined'} width={20} height={20} />,
      component: (
        <ClockPicker
          showViewSwitcher
          openTo="hours"
          onChange={(newTime) => onChangeTime(newTime)}
          date={time}
          className="clkPickerStyle"
          minTime={fDate(date) === fDate(setTimeMin()) ? setTimeMin() : ''}
        />
      ),
    },
  ];

  return (
    <>
      <Box sx={{ py: { xs: 0, sm: 2.5 }, px: { xs: 0, sm: 3 } }}>
        <Grid container spacing={3} sx={{ minHeight: '50vh' }}>
          <Grid item xs={12} md={4}>
            <Stack direction={'column'}>
              <Stack direction="row" gap={2}>
                <Typography variant={'subtitle1'} sx={{ color: textColor.calendar ? '#212B36' : '#637381' }}>
                  {tSchedule('post_time')}: {fDate(date)} - {fTime(time)}
                </Typography>
              </Stack>
              <Stack sx={{ p: 3, maxWidth: 'min-content' }}>
                <Tabs
                  allowScrollButtonsMobile
                  variant="scrollable"
                  scrollButtons="auto"
                  value={currentTab}
                  onChange={onChangeTab}
                >
                  {TABS.map((tab) => (
                    <Tab disableRipple key={tab.value} value={tab.value} icon={tab.icon} />
                  ))}
                </Tabs>

                {TABS.map((tab) => {
                  const isMatched = tab.value === currentTab;
                  return (
                    isMatched && (
                      <Box style={{ marginTop: '0.3rem' }} key={tab.value}>
                        {tab.component}
                      </Box>
                    )
                  );
                })}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={8}>
            <Stack direction={'column'}>
              <Typography variant={'subtitle1'} sx={{ color: textColor.calendar ? '#212B36' : '#637381' }}>
                {tSchedule('list')}:
              </Typography>
              <TableTarget excludesList={excludesList} onExcludeAdsAccounts={handleExlcudeAdsAccount} />
              <Stack
                direction="row"
                justifyContent={{ xs: 'space-between', md: 'end' }}
                spacing={{ xs: 3, sm: 2 }}
                mt="2rem"
              >
                <Stack direction="row" sx={{ width: { xs: '100%', md: '50%' } }} spacing={2}>
                  <ResultBlock color="#212B36" bgColor="#FFC107">
                    <>{result?.totalPeople}</>
                    <Iconify icon={'material-symbols:person'} width={20} height={20} />
                  </ResultBlock>

                  <ResultBlock color="white" bgColor="#3366FF">
                    <>{numberWithDot(result?.totalFee)}</>
                    <Iconify icon={'mdi:dollar'} width={20} height={20} />
                  </ResultBlock>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          <Stack direction="row" justifyContent={'space-between'} spacing={1.5} sx={{ mt: 3 }} width={1}>
            <Button variant={'outlined'} onClick={onPreviousStep} ml={3}>
              {tButton('back')}
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              onClick={handleNextStep}
              loading={loading?.value}
              sx={{
                mt: 2,
              }}
            >
              {tButton('complete')}
            </LoadingButton>
          </Stack>
        </Grid>
      </Box>
      <Loading loading={loading.value} />
    </>
  );
}

function setTimeMin() {
  const oldDateObj = new Date();
  const newDateObj = new Date();
  // minus current minute by 3 minute
  newDateObj.setTime(oldDateObj.getTime() - 3 * 60 * 1000);
  return newDateObj;
}
function numberWithDot(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}
