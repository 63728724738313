import EventEmitter from 'events';

export default class EmitterService {
  constructor() {
    this.eventEmitter = new EventEmitter();
  }

  send(event, message = '') {
    this.eventEmitter.emit(event, message);
  }

  on(event, listener) {
    this.eventEmitter.on(event, listener);
  }

  off(event, listener) {
    this.eventEmitter.off(event, listener);
  }
}
