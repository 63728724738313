import axios from '../../../utils/axios';

// API SOURCE ENDPOINTS
export const SOURCE_API_ENDPOINTS = {
  entity: {
    list: 'api/v1/source/entity/',
    category: (id) => `api/v1/source/entity/?category=${id}`,
  },
  category: {
    list: 'api/v1/source/category/',
  },
  source: {
    list: 'api/v1/source/',
    create: 'api/v1/source/',
    update: (id) => `api/v1/source/${id}/`,
    detail: (id) => `api/v1/source/${id}/`,
    schedule: `api/v1/source/schedule/`,
    cloneAndSchedule: `api/v1/source/schedule/convert/`,
  },
  settings: {
    list: 'api/v1/users/setting_action/',
    create: 'api/v1/users/setting_action/',
    delete: (settingId) => `api/v1/users/setting_action/delete/${settingId}/`,
  },
};

export const createSetting = async (payload) => {
  try {
    const res = await axios.post(SOURCE_API_ENDPOINTS.settings.create, payload);
    return { success: true, data: res.data };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.details };
  }
};

export const deleteSetting = async (settingId) => {
  try {
    const res = await axios.delete(SOURCE_API_ENDPOINTS.settings.delete(settingId));
    return { success: true, data: res.data?.message };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.detail || error?.messages || error?.message || error?.details };
  }
};

export const getEntity = async () => {
  try {
    const res = await axios.get(SOURCE_API_ENDPOINTS.entity.list);
    return { success: true, data: res?.data };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.details };
  }
};

export async function getLatestSources(keyword = '', pageNo = '1', otherQS = '') {
  try {
    let customUrl = `${SOURCE_API_ENDPOINTS.source.list}?filter[search]=${keyword}&${otherQS}`;
    if (pageNo) {
      customUrl = `${customUrl}&page=${pageNo}`;
    }

    const response = await axios.get(customUrl);
    if (response?.data) {
      const { results, count, next, previous } = response?.data;
      const sources = results.map((item) => {
        const { product_data: result } = item;
        return {
          ...result,
          ...result.bds_details,
          ...result.mxh_details,
          ...result.pnt_details,
          ...result.web_details,
          label: result?.title,
          images: result?.images || [],
          title: result?.title,
          createdAt: result?.created_at,
          city: result?.city_name,
          description: result?.description,
          source: {
            ...item,
          },
        };
      });
      return { sources, count, next, previous };
    }
    return { sources: [], count: 0, next: null, previous: null };
  } catch (error) {
    console.log({ error });
    return { sources: [], count: 0, next: null, previous: null };
  }
}

export const getSourceDetail = async (id) => {
  try {
    const res = await axios.get(SOURCE_API_ENDPOINTS.source.detail(id));
    return { success: true, data: res?.data };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.details };
  }
};

export const createSource = async (params) => {
  try {
    const res = await axios.get(SOURCE_API_ENDPOINTS.source.create, params);
    return { success: true, msg: res.data.messages };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.details };
  }
};

export const updateSource = async (params, id) => {
  try {
    const res = await axios.get(SOURCE_API_ENDPOINTS.source.update(id), params);
    return { success: true, msg: res.data.messages };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.details };
  }
};

export const scheduleSource = async (params) => {
  try {
    const {
      schedule_payload: schedulePayload,
      setting_schedule_payload: settingSchedulePayload,
      parent_uuid: parentUUID,
      parentType,
      other_types: otherTypes,
    } = params;

    if (settingSchedulePayload) {
      await axios.post(SOURCE_API_ENDPOINTS.source.schedule, {
        setting_schedule_payload: settingSchedulePayload,
      });
    }
    if (schedulePayload) {
      const listPromises = [parentType, ...otherTypes]?.map(async (typeClone) => {
        const res = await axios.post(SOURCE_API_ENDPOINTS.source.cloneAndSchedule, {
          parent_uuid: parentUUID,
          schedule_payload: schedulePayload,
          type_clone: typeClone,
        });
        return res;
      });
      await Promise.all([...listPromises]);
    }
    return { success: true, msg: 'Hệ thống đang tiến hành lên lịch có thể mất từ 3-5 phút' };
  } catch (error) {
    console.log(error);
    return { success: false, msg: error?.messages || error?.message || error?.details };
  }
};
