import _ from 'lodash';
import axios from 'axios';
import { useEffect, useRef } from 'react';
import { delayFunc, parseJSON } from '../../utils/others';
import axiosInstance from '../../utils/axios';
import useRefCustom from '../useRefCustom';
import useToolFunction from '../useToolFunction';
import { ENVIRONMENT, IS_LOCALHOST } from '../../config';

const DEFAULT_VALUE = {};
const LOCAL_STORAGE_NAME = 'tool-group-stats';
const LOCAL_STORAGE_NAME_V2 = 'tool-group-stats-v2';
// Time expire from the first crawl
const EXPIRE_TIME = 7 * 24 * 60 * 60 * 1000;
const CF_WORKER_ENV = ENVIRONMENT === 'dev' ? '-dev' : '-master';
const CF_WORKER = IS_LOCALHOST ? 'http://127.0.0.1:8787' : `https://fbtool${CF_WORKER_ENV}.uptin.workers.dev`;
const FBTOOL_GROUP_STATS_UPDATE_ENDPOINT = `${CF_WORKER}/api/group/stats/update`;
const FBTOOL_GROUP_STATS_GET_ENDPOINT = `${CF_WORKER}/api/group/stats/get`;
const FBTOOL_GROUP_STATS_COUNT_ENDPOINT = `${CF_WORKER}/api/group/stats/count`;
const FBTOOL_GROUP_STATS_SYNC_SIZE = 100;

function useGroupStats() {
  const { getGroupActivity } = useToolFunction();

  const [, setGroupStats, groupStatsRef] = useRefCustom(DEFAULT_VALUE);

  const [, setGroupIdsNeedCrawl, groupIdsNeedCrawlRef] = useRefCustom([]);

  const [, setGroupsJoined, groupsJoinedRef] = useRefCustom([]);

  const findGroupInfo = (groupsJoined, uid) => groupsJoined?.find((item) => item?.uid === uid);

  localStorage.removeItem(LOCAL_STORAGE_NAME);

  const onLoadPreviousData = async (groupsJoined) => {
    try {
      const data = localStorage.getItem(LOCAL_STORAGE_NAME_V2);
      const localStats = parseJSON(data, {});
      // remove key for testing
      delete localStats['504684031881831'];
      let serverStats = {};
      const missingStats = [];
      for (const group of groupsJoined) {
        if (!localStats[group?.uid]) {
          missingStats.push(group?.uid);
        }
      }
      // query for missingStats from CF D1
      const chunks = _.chunk(missingStats, 100);
      for (const chunk of chunks) {
        const stats = await axios.post(FBTOOL_GROUP_STATS_GET_ENDPOINT, { uid: chunk });
        serverStats = _.merge(serverStats, stats.data);
      }
      const totalStats = _.merge(localStats, serverStats);
      localStorage.setItem(LOCAL_STORAGE_NAME_V2, JSON.stringify(totalStats));
      setGroupStats(totalStats);
    } catch (error) {
      console.log(error);
    }
  };

  const setNewStats = (groupId, value) => {
    try {
      if (!value || !groupId) {
        return;
      }
      const current = groupStatsRef?.current || {};

      current[groupId] = { ...value, crawledAt: new Date() };

      setGroupStats({ ...current });
      localStorage.setItem(LOCAL_STORAGE_NAME_V2, JSON.stringify(current));
    } catch (error) {
      console.log(error);
    }
  };

  const loopTasks = async (callback) => {
    try {
      // We currently use ref so it will directly set new value when you pop item from an array
      const groupId = groupIdsNeedCrawlRef?.current?.length !== 0 ? groupIdsNeedCrawlRef?.current?.pop() : null;
      if (!groupId) {
        return;
      }

      const data = await getGroupActivity(groupId);
      if (data?.stop) {
        console.log('[STOP] get group stats');
        return;
      }
      if (data) {
        callback(groupId, data);
        setNewStats(groupId, data);
      }
      await delayFunc(3000);
      await loopTasks(callback);
    } catch (error) {
      console.log(error);
    }
  };

  const asyncGetGroupsData = async (groupsJoined, groupIds, callback) => {
    try {
      setGroupsJoined(groupsJoined || []);
      setGroupIdsNeedCrawl(groupIds);
      await loopTasks(callback);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Splits an array into chunks of the specified size.
   * @param {Array} array - The array to split.
   * @param {number} size - The size of each chunk.
   * @returns {Array} - An array of chunks.
   */
  const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  const saveGroupStats = async (groupsJoined, groupsNeedCrawl) => {
    try {
      // only save newly changed data
      const groupStatsLocal = parseJSON(localStorage.getItem(LOCAL_STORAGE_NAME_V2), {});
      const groupStats = {};
      groupsNeedCrawl.forEach((groupUid) => {
        const data = groupStatsLocal[groupUid];
        if (data) {
          // Append group name
          data.uid = groupUid;
          const groupInfo = findGroupInfo(groupsJoined, groupUid);
          if (groupInfo) {
            data.image = groupInfo?.image;
            data.name = groupInfo?.name;
            data.link = groupInfo?.link;
          }
          groupStats[groupUid] = data;
        }
      });
      // console.log('groupStats', groupStats);
      const chunks = [];
      const keys = Object.keys(groupStats);
      for (let i = 0; i < keys.length; i += FBTOOL_GROUP_STATS_SYNC_SIZE) {
        const chunkKeys = keys.slice(i, i + FBTOOL_GROUP_STATS_SYNC_SIZE);
        const chunk = {};
        chunkKeys.forEach((key) => {
          chunk[key] = groupStats[key];
        });
        chunks.push(chunk);
      }
      for (const chunk of chunks) {
        const upsert = await axios.post(FBTOOL_GROUP_STATS_UPDATE_ENDPOINT, chunk);
        console.log('upsert status', upsert.status);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getGroupStats = () => groupStatsRef?.current;

  return {
    onLoadPreviousData,
    asyncGetGroupsData,
    saveGroupStats,
    getGroupStats,
  };
}

export default useGroupStats;
