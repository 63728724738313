import { uniqBy } from 'lodash';
import qs from 'qs';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle, useState } from 'react';
import useAuth from '../../../hooks/useAuth';
import TableEmptyRows from '../TableEmptyRows';
import TableNoData from '../TableNoData';
import CustomTableRow from './CustomTableRow';
import Loading from '../../Loading';
import useBoolean from '../../../hooks/useBoolean';
import CustomMobileRow from './CustomMobileRow';
import useResponsive from '../../../hooks/useResponsive';
import Scrollbar from '../../Scrollbar';
import EmptyContent from '../../EmptyContent';

// ============================================================

const DEFAULT_WIDTH = 'auto';
const DEFAULT_UNIQUE = 'id';

// ============================================================

const CustomTable = forwardRef(
  (
    {
      data,
      displayCheckBox = false,
      rowUniqueKey = DEFAULT_UNIQUE,
      showEmptyRow = true,
      showMobileMode = true,
      totalRows = 0,
      next,
      prev,
      columns,
      actions,
      fetchData,
    },
    ref
  ) => {
    const isMobile = useResponsive('down', 'sm');

    const { configs } = useAuth();

    const loading = useBoolean();

    // Pagination
    const [page, setPage] = useState(0);

    const [rowsPerPage, setRowsPerPage] = useState(configs?.paginator_size || 10);

    const emtyRows = rowsPerPage - data?.length;

    // Collapse
    const [dense, setDense] = useState(false);

    const denseHeight = dense ? 56 : 76;

    const onChangeDense = (event) => {
      setDense(event.target.checked);
    };

    // Selected
    const [selectedArray, setSelectedArray] = useState([]);

    const handleSelected = (row) => {
      // check exist in selectedArray
      const index = selectedArray.findIndex((item) => item[rowUniqueKey] === row[rowUniqueKey]);
      if (index !== -1) {
        selectedArray.splice(index, 1);
        setSelectedArray([...selectedArray]);
      } else {
        setSelectedArray([...selectedArray, row]);
      }
    };
    // handle selected all for case: pagination
    const handleSelectedAll = (_, checked) => {
      if (checked) {
        setSelectedArray(uniqBy([...selectedArray, ...data], rowUniqueKey));
      } else {
        const filtered = selectedArray.filter(
          (item) => data.findIndex((row) => row[rowUniqueKey] === item[rowUniqueKey]) === -1
        );
        setSelectedArray([...filtered]);
      }
    };

    // Data checker
    const isNotFound = !data || !data?.length;

    const isSelectedAll = data?.every(
      (item) => selectedArray.findIndex((row) => row[rowUniqueKey] === item[rowUniqueKey]) !== -1
    );

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const resetData = async () => {
      try {
        loading.onTrue();
        await fetchData();
        setPage(0);
      } catch (error) {
        console.log(error);
      } finally {
        loading.onFalse();
      }
    };

    const onChangePageCustom = async (_, newPage) => {
      try {
        loading.onTrue();
        if (newPage > page && next) {
          const { page } = qs.parse(new URL(next).search.substring(1));
          const pageNo = page ? `${page}` : '1';
          await fetchData(pageNo);
        }
        if (newPage < page && prev) {
          const { page } = qs.parse(new URL(prev).search.substring(1));
          const pageNo = page ? `${page}` : '1';
          await fetchData(pageNo);
        }
        setPage(newPage);
      } catch (error) {
        console.log(error);
      } finally {
        loading.onFalse();
      }
    };

    useImperativeHandle(ref, () => ({
      resetData,
    }));

    return (
      <>
        {showMobileMode && isMobile && (
          <>
            {data?.map((row, index) => {
              const selected = selectedArray?.findIndex((item) => item[rowUniqueKey] === row[rowUniqueKey]) !== -1;
              return (
                <CustomMobileRow
                  key={row?.id || index}
                  selected={selected}
                  actions={actions}
                  columns={columns}
                  row={row}
                  displayCheckBox={displayCheckBox}
                  onCheck={() => {
                    handleSelected(row);
                  }}
                />
              );
            })}
            {isNotFound && <EmptyContent title="Không có dữ liệu" sx={{ padding: '33px 8px' }} />}
          </>
        )}
        {(!isMobile || !showMobileMode) && (
          <Scrollbar>
            <TableContainer>
              <Table size={dense ? 'small' : 'medium'}>
                <TableHead>
                  <TableRow>
                    {displayCheckBox && (
                      <TableCell padding="checkbox">
                        <Checkbox checked={totalRows > 0 && isSelectedAll} onChange={handleSelectedAll} />
                      </TableCell>
                    )}
                    {columns?.map((column) => (
                      <TableCell
                        key={column.key}
                        align={column?.align || 'left'}
                        sx={{ width: column?.width || DEFAULT_WIDTH, minWidth: column?.minWidth || DEFAULT_WIDTH }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                    {actions && <TableCell align={'right'} />}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map((row, index) => {
                    const selected =
                      selectedArray?.findIndex((item) => item[rowUniqueKey] === row[rowUniqueKey]) !== -1;
                    return (
                      <CustomTableRow
                        key={row?.id || index}
                        selected={selected}
                        actions={actions}
                        columns={columns}
                        row={row}
                        displayCheckBox={displayCheckBox}
                        onCheck={() => {
                          handleSelected(row);
                        }}
                      />
                    );
                  })}
                  {showEmptyRow && !isNotFound && <TableEmptyRows height={denseHeight} emptyRows={emtyRows} />}

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        )}
        <Box sx={{ position: 'relative' }}>
          <TablePagination
            rowsPerPageOptions={[configs?.paginator_size || 10]}
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePageCustom}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <FormControlLabel
            control={<Switch checked={dense} onChange={onChangeDense} />}
            label={'Collapse'}
            sx={{ px: 3, py: 1.5, top: 0, position: 'absolute' }}
          />
        </Box>
        <Loading loading={loading.value} />
      </>
    );
  }
);

CustomTable.propTypes = {
  data: PropTypes.array,
  rowUniqueKey: PropTypes.string,
  displayCheckBox: PropTypes.bool,
  showEmptyRow: PropTypes.bool,
  showMobileMode: PropTypes.bool,
  columns: PropTypes.array,
  actions: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  totalRows: PropTypes.number,
  // Pagination
  next: PropTypes.string,
  prev: PropTypes.string,
  // Functions
  fetchData: PropTypes.func,
};

export default CustomTable;
