import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '0,0' : '0,0.00');
}

export function fPercent(number, format = '0.0%') {
  return numeral(number / 100).format(format);
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export function fAbbreviate(number, seperator = ',') {
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 1,
    notation: 'compact',
    compactDisplay: 'short',
  })
    .format(number)
    .replace('.', seperator);
}

export function numberWithCommas(x, seperator = ',') {
  if (!x) {
    return '0';
  }
  return x.toString().replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, seperator);
}

export function randomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function extractNumbers(text) {
  try {
    const match = text?.match(/[\d.,]+/g);

    if (match) {
      // Parse the matched string into a number by removing commas and periods
      return parseFloat(match[0].replace(/[,.]/g, ''));
    }
    return null;
  } catch (error) {
    return null;
  }
}
