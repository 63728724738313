import { jsonrepair } from 'jsonrepair';
import { uniqBy } from 'lodash';
import { extractNumbers } from '../formatNumber';
import { fDateTime } from '../formatTime';
import {
  convertStoryIdToFeedBackId,
  findPropertyByName,
  getAllImageUris,
  getContentOfScript,
  parseJSON,
  sumWith,
} from '../others';

/* eslint-disable */
export default class ExtractResponse {
  extractFbDstg(response) {
    try {
      const { raw } = response;
      const regex = /"token":"([^"]+)"/;
      const match = raw?.match(regex);

      if (match) {
        return match[1];
      } else {
        console.log('Token not found.');
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  extractProfile(response) {
    try {
      const { data } = response;

      const {
        id: profileUUID,
        profile_picture: { uri: avatar },
        name: username,
      } = data?.viewer?.actor || {};

      return (
        {
          profileUUID,
          avatar,
          username,
        } || null
      );
    } catch (error) {
      return null;
    }
  }

  extractUserProfileUID(response) {
    try {
      const { data } = response;

      const user = data?.user?.profile_header_renderer?.user;

      let name = user?.name;
      if (user?.alternate_name) {
        name = `${name} (${user?.alternate_name})`;
      }

      return (
        {
          id: user?.id,
          image: user?.profilePicLarge?.uri,
          name,
          url: user?.url,
        } || null
      );
    } catch (error) {
      return null;
    }
  }

  extractTagInGroup(response) {
    try {
      const { data } = response;
      const list = data?.comet_group_typeahead_search;
      return (list || [])?.filter((item) => item?.node);
    } catch (error) {
      return [];
    }
  }

  extractPresets(response) {
    try {
      const {
        categorized_text_format_presets: categorizedPresets,
        ranked_composer_text_format_presets: rankedPresets,
      } = response?.data?.viewer;

      const presets1 = rankedPresets?.map((item) => item?.preset);

      const presets2 = categorizedPresets?.reduce((res, item) => {
        const { name } = item;
        const data = item?.ranked_text_format_presets?.map((item) => ({ ...item?.preset, name }));
        res.push(...data);
        return res;
      }, []);

      return uniqBy([...presets1, ...presets2], 'preset_id');
    } catch (error) {
      return [];
    }
  }

  extractVideoUploadConfigs(response) {
    try {
      const { data } = response;

      const videoUploaderConfig = data?.viewer?.comet_composer_video_uploader_config || '';

      return JSON.parse(jsonrepair(videoUploaderConfig));
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  extractCheckPageProfile(response) {
    try {
      const userData = response?.data?.user?.comet_profile_entity_menu?.user || {};
      const delegatePageId = userData?.delegate_page?.id;

      const isPage = userData?.profile_type_name_for_content === 'PAGE';
      return { isPage, delegatePageId };
    } catch (error) {
      return { isPage: false };
    }
  }

  extractGroupsJoined(response) {
    try {
      const { data } = response;

      const {
        nonAdminGroups: {
          groups_tab: {
            tab_groups_list: { edges: otherGroups },
          },
        },
        adminGroups: {
          groups_tab: {
            tab_groups_list: { edges: yourGroups },
          },
        },
      } = data || {};

      return { results: [...yourGroups, ...otherGroups] || [] };
    } catch (error) {
      return null;
    }
  }

  extractFriends(response) {
    try {
      const { data } = response;

      const {
        user: {
          friends: { edges: friends },
        },
      } = data || {};

      return friends?.map((item) => item?.node);
    } catch (error) {
      return [];
    }
  }

  extractUsersOnBusiness(response) {
    try {
      const {
        o0: {
          data: { messaging_actors: users },
        },
      } = response;

      return users?.map((item) => ({
        uid: item?.id,
        name: item?.name,
        link: item?.url || `https://www.facebook.com/${item?.id}`,
        image: item?.big_image_src?.uri,
        customUID: item?.id,
      }));
    } catch (error) {
      return [];
    }
  }

  extractFollowers(response) {
    try {
      const { data } = response;
      const mainData = data?.node?.pageItems;

      const haveNextPage = mainData?.page_info?.has_next_page;
      const nextCursor = mainData?.page_info?.end_cursor;

      const list = mainData?.edges || [];

      return {
        data:
          list?.map((item) => {
            const {
              node,
              image: { uri },
              title: { text },
            } = item?.node;
            return {
              profile_picture: { uri },
              ...node,
              name: text,
            };
          }) || [],
        nextCursor: haveNextPage ? nextCursor : null,
      };
    } catch (error) {
      return {};
    }
  }

  extractAccessToken(response) {
    try {
      const { raw } = response;

      if (!raw) {
        return null;
      }
      const accessToken = raw.match(/(access_token=)+([A-Z])\w+/g);
      if (accessToken[0]) {
        return accessToken[0].split('=')[1];
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  extractCommentInPost(response) {
    try {
      const { data } = response;

      const feedback = data?.feedback;

      if (!feedback) {
        return null;
      }

      const commentInfo = feedback?.top_level_comments;

      const comments = commentInfo?.nodes?.map((item) => {
        const { __typename, id, name, profile_picture: profilePicture } = item?.author;
        return {
          feedbackId: item?.id,
          type: __typename,
          id,
          name,
          picture: profilePicture?.uri,
          body: item?.body,
        };
      });

      const pagination = commentInfo?.page_info || {};

      return {
        id: feedback?.id,
        owner: feedback?.owning_profile,
        comments,
        total_comments: commentInfo?.total_count || 0,
        pagination,
      };
    } catch (error) {
      return null;
    }
  }

  extractPostsInProfile(response) {
    try {
      const { data, paging } = response;

      return {
        data: data?.map((item) => item?.id) || [],
        paging,
      };
    } catch (error) {
      return null;
    }
  }

  extractReactionInPost(response) {
    try {
      const { data, paging } = response;

      const nextCursor = paging?.cursors?.after;

      return {
        data: data?.map((item) => item?.id) || [],
        nextCursor,
      };
    } catch (error) {
      return null;
    }
  }

  extractReactionInFeedback(response) {
    try {
      const { data } = response;

      const mainData = data?.node?.reactors;
      const haveNextPage = mainData?.page_info?.has_next_page;
      const nextCursor = mainData?.page_info?.end_cursor;

      const list = mainData?.edges || [];

      return {
        data: list?.map((item) => item?.node?.id) || [],
        nextCursor: haveNextPage ? nextCursor : null,
      };
    } catch (error) {
      return null;
    }
  }

  extractMessages(response) {
    try {
      const {
        viewer: {
          message_threads: { nodes },
        },
      } = response;

      return nodes || [];
    } catch (error) {
      return [];
    }
  }

  extractPageInfo(response) {
    try {
      const main = response?.data?.page || {};
      const categories = (main?.category_text || '')?.split(' · ');
      return { ...main, categories };
    } catch (error) {
      return null;
    }
  }

  extractPageCategory(response) {
    try {
      return response?.data?.page_creation_category_typeahead_search?.results?.nodes?.map((item) => ({
        id: item.category_id,
        name: item?.category_name,
      }))?.[0];
    } catch (error) {
      return null;
    }
  }

  extractFeedbackData(response) {
    try {
      const { data } = response;

      const storyItems = data?.node?.group_feed?.edges?.filter((item) => item?.node?.__typename === 'Story');

      let feedBackIds = storyItems?.map((item) => ({
        content: item?.node?.comet_sections?.content?.story?.message?.text || '',
        link: item?.node?.comet_sections?.content?.story?.wwwURL,
        id: item?.node?.feedback?.id,
        cursor: item?.cursor,
      }));
      const cursors = feedBackIds?.map((item) => item?.cursor).filter((item) => item);

      return { feedBackIds, cursors };
    } catch (error) {
      return { feedBackIds: [], cursors: null };
    }
  }

  extractTimelinePosts(response) {
    try {
      const { data } = response;

      const list = data?.node?.timeline_list_feed_units?.edges || [];

      let feedBackIds = list?.map((item) => ({
        content: item?.node?.comet_sections?.content?.story?.message?.text || '',
        link: item?.node?.comet_sections?.content?.story?.wwwURL,
        id: item?.node?.feedback?.id,
        cursor: item?.cursor,
      }));
      const cursors = feedBackIds?.map((item) => item?.cursor).filter((item) => item);

      return { feedBackIds, cursors };
    } catch (error) {
      return { feedBackIds: [], cursors: null };
    }
  }

  extractPages(response) {
    try {
      const {
        data: {
          node: {
            sorted_liked_and_followed_pages: { edges, page_info },
          },
        },
      } = response;

      const pages = (edges || [])?.map((item) => item?.node);

      if (pages) {
        const temp = pages?.map((item) => {
          return {
            uid: item?.id,
            name: item?.name,
            link: item?.url || `https://www.facebook.com/${item?.id}`,
            image: item?.profile_picture?.uri,
            customUID: item?.id,
            isPage: true,
          };
        });

        const uniqueList = _.uniqBy(temp, 'uid');
        return { pages: [...uniqueList], pagination: page_info };
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  extractPokeData(response, userId) {
    try {
      const { data } = response;
      return data?.user_poke?.user?.id == userId;
    } catch (error) {
      return false;
    }
  }

  extractRemoveFriend(response, friendId) {
    try {
      const { data } = response;
      return data?.friend_remove?.unfriended_person?.id === friendId;
    } catch (error) {
      return false;
    }
  }

  extractRemoveSaleformat(response, storyId) {
    try {
      const { data } = response;
      return data?.product_item_removal_from_story?.story?.id === storyId;
    } catch (error) {
      return false;
    }
  }

  extractLikeData(response) {
    try {
      const { data } = response;
      return data?.feedback_react;
    } catch (error) {
      return null;
    }
  }

  extractCommentOfFeedbackData(response) {
    try {
      const { data } = response;

      const commentItems = data?.node?.comment_rendering_instance_for_feed_location?.comments?.edges || [];

      const pagination = data?.node?.comment_rendering_instance_for_feed_location?.comments?.page_info;

      const comments = commentItems?.map((item) => {
        // Actor
        const author = item?.node?.author;
        const actor = {
          ...author,
          profile_url: author?.url,
          profile_picture: { uri: author?.profile_picture_depth_0?.uri },
          isAnonymous: author?.__isEntity === 'GroupAnonAuthorProfile',
        };

        const content = {
          message: { ...item?.node?.body },
          url: item?.node?.feedback?.url,
        };

        return {
          content,
          actor,
          feedback: {
            id: item?.node?.feedback?.id,
            expansion_token: item?.node?.feedback?.expansion_info?.expansion_token,
            replies: {
              total_count: item?.node?.feedback?.replies_fields?.total_count,
              count: item?.node?.feedback?.replies_fields?.count,
            },
          },
        };
      });

      return { comments, pagination };
    } catch (error) {
      return { comments: [], pagination: null };
    }
  }

  extractPostsOfSearchInGroup(response) {
    try {
      const { data } = response;

      const postItems = data?.serpResponse?.results?.edges || [];

      const pagination = data?.serpResponse?.results?.page_info;

      const posts = postItems?.reduce((res, item) => {
        if (item?.node?.role !== 'GROUP_POSTS') {
          return res;
        }

        const story = item?.relay_rendering_strategy?.view_model?.click_model?.story;

        // Actor
        const actorData = story?.comet_sections?.context_layout?.story?.comet_sections?.actor_photo?.story;
        const actorInfo = actorData?.actors[0];
        const author = item?.node?.author;
        const actor = {
          ...actorInfo,
          profile_url: actorInfo?.profile_url,
          profile_picture: { uri: actorInfo?.profile_picture?.uri },
          isAnonymous: author?.__isEntity === 'GroupAnonAuthorProfile',
        };

        const content = story?.comet_sections?.content?.story?.comet_sections?.message_container?.story || {};
        content.url = story?.comet_sections?.content?.story?.wwwURL;

        const post = {
          content,
          actor,
          feedback: {
            id: story?.feedback?.id,
          },
        };

        return [...res, post];
      }, []);

      return { posts, pagination };
    } catch (error) {
      return { posts: [], pagination: null };
    }
  }

  extractRepliesOfComment(response) {
    try {
      const { data } = response;

      const replyItems = data?.node?.replies_connection?.edges || [];

      const pagination = data?.node?.replies_connection?.page_info;

      let replies = replyItems?.map((item) => ({
        content: item?.node?.body,
        link: item?.node?.feedback?.url,
        actor: item?.node?.author,
        feedback: {
          id: item?.node?.feedback?.id,
          expansion_token: item?.node?.feedback?.expansion_info?.expansion_token,
          replies: {
            total_count: item?.node?.feedback?.replies_fields?.total_count,
            count: item?.node?.feedback?.replies_fields?.count,
          },
        },
      }));

      return { replies, pagination };
    } catch (error) {
      return { replies: [], pagination: null };
    }
  }

  extractSearchPosts(response) {
    try {
      const { data: res } = response;
      const posts = res?.serpResponse?.results?.edges?.map((item) => {
        const { story } = item?.relay_rendering_strategy.view_model.click_model || {};
        const actorInfo = story?.comet_sections?.context_layout?.story?.comet_sections?.actor_photo?.story;
        const actor = actorInfo?.actors[0];
        actor.isAnonymous = actor?.__isEntity === 'GroupAnonAuthorProfile';
        // Detect page
        const trackingInfo = story?.comet_sections?.feedback?.story?.comet_feed_ufi_container?.story?.tracking || '';
        // actor.isPage = trackingInfo?.indexOf(`"page_id_type":"page"`) !== -1;
        const group = actorInfo?.to;
        const content = story?.comet_sections?.content?.story?.comet_sections?.message_container?.story || {};
        content.url = story?.comet_sections?.content?.story?.wwwURL;
        return {
          actor,
          group,
          content,
          feedback: story?.feedback,
        };
      });
      const pagination = res?.serpResponse?.results?.page_info;
      return { posts, pagination };
    } catch (error) {
      return { posts: [], pagination: null };
    }
  }

  extractDeclareVideoUploadInfomation(response) {
    try {
      if (!response) {
        return null;
      }
      const {
        lid,
        payload: { start_offset: startOffset, end_offset: endOffset, video_id: videoId },
      } = response;
      return { lid, startOffset, endOffset, videoId };
    } catch (error) {
      return null;
    }
  }

  extractPendingPosts(response) {
    try {
      const { data, errors } = response;

      const isRateLimit = (errors || [])?.some((item) => item?.message?.toLowerCase()?.indexOf('rate limit') !== -1);

      if (isRateLimit) {
        return { pendingPosts: [], pagination: null, isRateLimit };
      }

      const postItems = data?.node?.viewer_content?.contents?.edges || [];

      const pagination = data?.node?.viewer_content?.contents?.page_info;

      const pendingPosts = postItems?.map((item) => {
        const { id: storyId, comet_sections: cometSections } = item?.node || {};
        const actorId = cometSections?.content?.story?.actors[0]?.id;
        return { storyId, actorId };
      });

      return { pendingPosts, pagination };
    } catch (error) {
      return { pendingPosts: [], pagination: null };
    }
  }

  extractRemovePendingPost(response) {
    try {
      const { data } = response;
      return data?.story_delete?.deleted_story_id;
    } catch (error) {
      return null;
    }
  }

  extractParticipateApproved(response) {
    try {
      const { data, errors } = response;
      const isRateLimit = (errors || [])?.some((item) => item?.message?.toLowerCase()?.indexOf('rate limit') !== -1);

      return { isApproved: data?.group?.participation_model_info === null, isRateLimit };
    } catch (error) {
      console.log(error);
      return { isApproved: false };
    }
  }
  // TikTok
  static extractUserList(users) {
    try {
      if (!users) {
        return [];
      }
      return users
        ?.map((item) => ({ ...item?.user, stats: item?.stats }))
        ?.map((item) => ({ ...item, uid: item?.uniqueId, name: item?.nickname, image: item?.avatarMedium }));
    } catch (error) {
      return [];
    }
  }

  extractSearchVideos(data) {
    try {
      if (!data) {
        return [];
      }
      return data
        ?.reduce((res, item) => [...res, ...(item?.item_list || [])], [])
        ?.map((item) => {
          const { desc, stats, id } = item || {};
          return {
            author: { ...item?.author, stats: item?.authorStats },
            id,
            desc,
            stats,
          };
        });
    } catch (error) {
      return [];
    }
  }

  extractUserVideos(data) {
    try {
      if (!data) {
        return [];
      }
      return data
        ?.reduce((res, item) => [...res, ...(item?.itemList || [])], [])
        ?.map((item) => {
          const { desc, stats, id } = item || {};
          return {
            author: { ...item?.author, stats: item?.authorStats },
            id,
            desc,
            stats,
          };
        });
    } catch (error) {
      return [];
    }
  }

  extractCommentsOfVideo(data) {
    try {
      if (!data) {
        return [];
      }
      const formated = data
        ?.reduce((res, item) => [...res, ...(item?.comments || [])], [])
        ?.map((item) => {
          const {
            cid,
            text,
            user: { unique_id: uniqueId, uid, nickname, avatar_thumb: avatarThumb },
          } = item || {};

          return {
            cid,
            uniqueId,
            uid,
            nickname,
            image: avatarThumb?.url_list?.[0],
            text,
          };
        });
      return _.uniqBy(formated, 'cid');
    } catch (error) {
      return [];
    }
  }

  extractGroupActivity(response) {
    try {
      // for (;;);{"__ar":1,"error":1357004,"errorSummary":"Sorry, something went wrong","errorDescription":"Please try closing and re-opening your browser window.","isNotCritical":1,"payload":null,"lid":"7430784746753368733"}
      const responseText = response?.raw;
      const requireLogin = ['please log in', 'log in to continue', 'rate limit']?.some(
        (msg) => responseText?.toLowerCase()?.indexOf(msg) !== -1
      );

      if (requireLogin) {
        console.log(responseText);
        return { stop: true };
      }

      const { data } = parseJSON(responseText, {});
      const {
        group_new_members_info_text: newMembers,
        group_total_members_info_text: totalMembers,
        number_of_posts_in_last_day: dailyPosts,
        number_of_posts_in_last_month: postsMonth,
      } = data?.group?.if_viewer_can_see_activity_section || {};

      const description = data?.group?.description_with_entities?.text || '';

      return {
        postsMonth,
        dailyPosts,
        totalMembers: extractNumbers(totalMembers) || 0,
        newMembers: extractNumbers(newMembers) || 0,
        description,
      };
    } catch (error) {
      console.log(error);
      return {};
    }
  }
  static extractSubDataOfScript(data) {
    try {
      const requireArray = data?.require[0][3][0]?.__bbox?.require;
      const requireItem = requireArray?.find((item) =>
        ['RelayPrefetchedStreamCache', 'next']?.every((txt) => item?.indexOf(txt) !== -1)
      );
      return requireItem[3][1]?.__bbox?.result;
    } catch (error) {
      return null;
    }
  }

  static SPYTryGetActor(data) {
    try {
      // Scenario 1:
      const actorInfo = data?.node?.comet_sections?.context_layout?.story?.comet_sections?.actor_photo?.story;
      let actor = actorInfo?.actors?.[0];

      // Scenario 2:
      if (!actor) {
        actor = data?.attachments?.[0]?.media?.creation_story?.comet_sections?.actor_photo?.story?.actors?.[0];
      }

      // Scenario 3:
      if (!actor) {
        actor = data?.tahoe_sidepane_renderer?.video?.creation_story?.comet_sections?.actor_photo?.story?.actors?.[0];
      }

      // Scenario 4: Reels
      if (!actor) {
        // data►video►creation_story►short_form_video_context►video_owner►
        actor = data?.video?.creation_story?.short_form_video_context?.video_owner;
        if (actor) {
          actor.profile_picture = actor?.displayPicture;
          actor.profile_url = actor?.url;
        }
      }

      return actor;
    } catch (error) {
      console.log('SPYTryGetActor', error);
      return null;
    }
  }

  static SPYTryGetContent(data, response) {
    try {
      let cometSections = data?.node?.comet_sections;

      let content = cometSections?.content?.story?.comet_sections?.message_container?.story || {};

      if (_.isEmpty(content)) {
        cometSections = data?.attachments?.[0]?.media?.creation_story?.comet_sections;
        content = cometSections?.message?.story || {};
      }

      if (_.isEmpty(content)) {
        cometSections = data?.tahoe_sidepane_renderer?.video?.creation_story;
        content = cometSections?.comet_sections?.message?.story || {};
      }

      // reels
      if (_.isEmpty(content)) {
        cometSections = data?.video?.creation_story || {};
        content.message = cometSections?.message;
      }

      content.url =
        cometSections?.content?.story?.wwwURL ||
        cometSections?.metadata?.[0]?.story?.url ||
        cometSections?.url ||
        cometSections?.short_form_video_context?.shareable_url;

      // Images
      content.images = [];
      const mediaData = data?.node?.comet_sections?.content?.story?.attachments?.[0]?.styles?.attachment || {};
      const singleImage =
        mediaData?.media?.photo_image?.uri ||
        mediaData?.media?.large_share_image?.uri ||
        cometSections?.short_form_video_context?.video?.first_frame_thumbnail;
      if (singleImage) {
        content.images = [singleImage];
      } else {
        const images = (mediaData.all_subattachments?.nodes || [])?.reduce((res, item) => {
          const link = item?.media?.image?.uri;
          if (link) {
            return [...res, link];
          }
          return res;
        }, []);

        content.images = [...images];
      }
      // Try to get all images
      const jsonString = JSON.stringify(cometSections);
      // With response contains more than 1mb text data we need focus to the first half content
      const allImages = getAllImageUris(jsonString?.slice(0, Math.floor(jsonString?.length / 2)));
      content.images = _.uniq([...content.images, ...allImages]);

      // Thumbnail
      if (content?.images?.length === 0) {
        const videoThumbnailData = getContentOfScript(response, ['preferred_thumbnail']);
        const extracted = ExtractResponse.extractSubDataOfScript(videoThumbnailData);
        if (extracted) {
          const preferredThumbnail = findPropertyByName(extracted, 'preferred_thumbnail');
          const thumbnail = preferredThumbnail?.image?.uri;
          if (thumbnail) {
            content?.images?.push(thumbnail);
          }
        }
      }

      return content;
    } catch (error) {
      return null;
    }
  }

  static SPYTryGetFeedback(data, response) {
    try {
      let feedbackId = data?.node?.feedback?.id;

      if (!feedbackId) {
        const feedbackData = getContentOfScript(response, ['"feedback"']);
        const feedback = findPropertyByName(feedbackData, 'feedback');
        if (feedback?.id) {
          feedbackId = feedback?.id;
        } else {
          const storyId = data?.attachments?.[0]?.media?.creation_story?.comet_sections?.message?.story?.id;
          if (storyId) {
            feedbackId = convertStoryIdToFeedBackId(storyId);
          }
        }
      }

      if (!feedbackId) {
        feedbackId = data?.tahoe_sidepane_renderer?.video?.feedback?.id;
      }

      return { id: feedbackId };
    } catch (error) {
      return null;
    }
  }

  static SPYTryGetStats(response) {
    try {
      // reaction_count , comments.total_count, share_count.count
      let data = getContentOfScript(response, ['"i18n_reaction_count"']);
      let reactionData1 = findPropertyByName(data, 'if_viewer_cannot_see_seen_by_member_list');
      let reactionData2 = findPropertyByName(data, 'reaction_count');
      let shareData = findPropertyByName(data, 'share_count');
      let commentData = findPropertyByName(data, 'comments');

      if (Object.keys(data).length === 0) {
        data = getContentOfScript(response, ['unified_reactors']);
        const extracted = ExtractResponse.extractSubDataOfScript(data);
        reactionData2 = findPropertyByName(extracted, 'unified_reactors');
        shareData = findPropertyByName(data, 'feedback');
        commentData = findPropertyByName(data, 'feedback');
      }

      return {
        comment_count: commentData?.total_count || commentData?.total_comment_count || 0,
        share_count: shareData?.count || shareData?.share_count_reduced || 0,
        reaction_count: reactionData1?.reaction_count?.count || reactionData2?.count || 0,
      };
    } catch (error) {
      console.log(error);
      return { comment_count: 0, share_count: 0, reaction_count: 0 };
    }
  }

  extractPostAtLinkData(response) {
    try {
      let actorData = getContentOfScript(response, ['actor_photo']);
      if (Object.keys(actorData).length === 0) {
        actorData = getContentOfScript(response, ['video_owner']);
      }
      actorData = ExtractResponse.extractSubDataOfScript(actorData);
      const actor = ExtractResponse.SPYTryGetActor(actorData?.data);
      const content = ExtractResponse.SPYTryGetContent(actorData?.data, response);
      const feedback = ExtractResponse.SPYTryGetFeedback(actorData?.data, response);
      const stats = ExtractResponse.SPYTryGetStats(response);
      const res = {
        actor,
        content,
        feedback,
        stats,
      };
      return res;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  extractReatorsOfPost(response) {
    try {
      const { data } = response;
      const { edges, page_info: pagination } = data?.node?.reactors || {};

      const res = (edges || [])?.map((item) => item?.node);

      return { reactors: res, pagination };
    } catch (error) {
      return { reactors: [], pagination: null };
    }
  }

  extractResharesOfPost(response) {
    try {
      const { data } = response;
      const { edges, page_info: pagination } = data?.node?.reshares || {};

      const res = (edges || [])?.map((item) => {
        const node = item?.node;
        return {
          actor: node?.comet_sections?.context_layout?.story?.comet_sections?.actor_photo?.story?.actors?.[0],
          content: node?.comet_sections?.content?.story?.comet_sections?.message_container?.story || {},
          feedback: node?.feedback?.id,
        };
      });

      return { reshares: res, pagination };
    } catch (error) {
      return { reshares: [], pagination: null };
    }
  }

  extractShareLinkOfPost(response) {
    try {
      const { data } = response;
      return data?.xfb_create_share_url_wrapper?.share_url_wrapper?.wrapped_url;
    } catch (error) {
      return null;
    }
  }

  extractAdsPostsLink(response) {
    try {
      const { data } = response || {};

      const links = [];

      // Case newsfeed
      const node = data?.node || {};
      const isSponsoredPost = node?.sponsored_data?.__typename === 'SponsoredData' && node?.__typename === 'Story';
      const sponsorLink = node?.comet_sections?.content?.story?.wwwURL;

      // Exclude reel link
      if (isSponsoredPost && sponsorLink && sponsorLink?.indexOf(`/reel/`) === -1) {
        const trackingInfo = parseJSON(node?.comet_sections?.call_to_action?.story?.tracking || '', {});
        const publicTime = findPropertyByName(trackingInfo, 'publish_time');

        let createdAt = new Date();
        if (publicTime && typeof publicTime === 'number') {
          createdAt = new Date(publicTime * 1000);
        }
        const pageId = trackingInfo?.page_id;
        links.push({ url: sponsorLink, createdAt, pageId, ad: { id: node?.sponsored_data?.ad_id } });
      }

      // Case search engine
      const searchEdges = data?.serpResponse?.results?.edges || [];

      const adPosts = searchEdges?.filter((item) => item?.node?.role === 'SEARCH_ADS');

      const result = adPosts.reduce((res, item) => {
        const { story } = item?.relay_rendering_strategy?.view_model || {};
        // Detect page
        const trackingInfo = parseJSON(story?.tracking || '', {});
        const publicTime = findPropertyByName(trackingInfo, 'publish_time');

        let createdAt = new Date();
        if (publicTime && typeof publicTime === 'number') {
          createdAt = new Date(publicTime * 1000);
        }
        const url = story?.url;
        if (url && createdAt && url?.indexOf(`/reel/`) === -1) {
          const pageId = trackingInfo?.page_id;
          res.push({ url, createdAt, pageId, ad: { id: story?.sponsored_data?.ad_id } });
        }
        return res;
      }, links);

      return result;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  extractUserStats(response) {
    try {
      // console.log(response);
      const {
        overview: overviewResp,
        traffic: trafficResp,
        viewer: viewerResp,
        follower: followerResp,
      } = response || {};

      // Overview
      let overview = {};
      overview.like = sumWith(overviewResp?.like_history || [], 'value');
      overview.profileView = sumWith(overviewResp?.pv_history || [], 'value');
      overview.share = sumWith(overviewResp?.share_history || [], 'value');
      overview.video = sumWith(overviewResp?.vv_history || [], 'value');
      overview.comment = sumWith(overviewResp?.comment_history || [], 'value');

      // Viewer
      let viewer = {};
      viewer.total = sumWith(viewerResp?.unique_viewer || [], 'value');
      viewer.country = {
        name: viewerResp?.viewer_country_city_percent?.country_percent_list?.[0]?.country_name || 'unknown',
        percent: (viewerResp?.viewer_country_city_percent?.country_percent_list?.[0]?.country_vv_percent || 0) * 100,
      };
      viewer.gender = viewerResp?.viewer_gender_percent?.value || [];
      viewer.age = viewerResp?.viewer_age_distribution?.value || [];

      // Follower
      let follower = {};
      follower.total = followerResp?.followerNum?.follower_num?.value || 0;
      follower.country = {
        name:
          followerResp?.followerLocation?.follower_location_percent?.country_percent_list?.[0]?.country_name ||
          'unknown',
        percent:
          (followerResp?.followerLocation?.follower_location_percent?.country_percent_list?.[0]?.country_vv_percent ||
            0) * 100,
      };
      follower.gender = followerResp?.followerGender?.follower_gender_percent?.value || [];
      follower.age = followerResp?.followerAge?.follower_age_distribution?.value || [];

      // Traffic
      const traffic = trafficResp?.video_page_percent?.value || [];

      return { overview, viewer, follower, traffic };
    } catch (error) {
      console.log(error);
      return {};
    }
  }

  extractTiktokDataAtLinkData(response) {
    try {
      const actorData = getContentOfScript(response?.raw, ['webapp.video-detail'], '');
      console.log(actorData);
      const {
        itemInfo: {
          itemStruct: { author, desc, stats, id, video, createTime },
        },
      } = actorData?.__DEFAULT_SCOPE__?.['webapp.video-detail'] || {};

      let createdAt = new Date();
      if (createTime) {
        try {
          createdAt = new Date(parseInt(createTime) * 1000);
        } catch (error) {}
      }
      createdAt = fDateTime(createdAt, 'yyyy-MM-dd HH:mm:ss');

      return { author: { ...author, stats: {} }, video, videoId: id, desc, stats, createdAt };
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  extractTiktokAds(response) {
    try {
      const { itemList } = response?.data || {};
      return itemList
        ?.filter((item) => item?.ad_info)
        ?.map((item) => {
          const { author, desc, authorStats, stats, id, createTime, video } = item;
          // Ads skip ads from tiktok sponsor if uid of author can't find on tiktok. Because tiktok hidden owner after ad is publishing
          if (author?.uniqueId?.indexOf(' ') !== -1) {
            return null;
          }
          let createdAt = new Date();
          if (createTime) {
            createdAt = new Date(createTime * 1000);
          }
          createdAt = fDateTime(createdAt, 'yyyy-MM-dd HH:mm:ss');
          return { author: { ...author, stats: authorStats }, video, videoId: id, desc, stats, createdAt };
        })
        ?.filter((item) => item);
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  extractFacebookLocale(response) {
    try {
      const parsedData = getContentOfScript(response?.raw, ['IntlCurrentLocale'], '');

      const data = parsedData?.require?.[0]?.[3]?.[0]?.__bbox?.define || [];
      const index = data?.findIndex((item) => item && item?.[0] === 'IntlCurrentLocale');

      if (index !== -1) {
        const localeIndex = data[index]?.findIndex((item) => item?.code);
        if (localeIndex !== -1) {
          return data[index]?.[localeIndex]?.code;
        }
      }
      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}
