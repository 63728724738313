import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useMemo, useRef } from 'react';
import { WEBSOCKET_TIMEOUT } from '../config';
// hooks
import useAuth from '../hooks/useAuth';
import useIdleTimer from '../hooks/useIdleTimer';
import useRefCustom from '../hooks/useRefCustom';
import { updateTimeReNew } from '../redux/slices/dataExpiration';
import axios from '../utils/axios';
import { delayFunc } from '../utils/others';
import { WebSocketService } from '../utils/websocket';

const initialState = {
  wsClient: null,
  // Reset websocket instance
  advanceLogout: () => {},
  checkActiveOfWS: async () => {},
  keepAlive: {
    turnOn: () => {},
    turnOff: () => {},
  },
};

const WebSocketContext = createContext(initialState);

// Time check user idle (seconds) -> default: 5 minutes equal time expire of websocket key from BE
const idleTime = WEBSOCKET_TIMEOUT * 60 - 10;

const PING_TIMER = 55 * 1000;

// Renew/ retry create websocket connection per 1 hours 30 minutes
const RENEW_WS_TIME = (60 + 30) * 60 * 1000;

// ---------------------- PROPS VALIDATE ---------------------
WebSocketProvider.propTypes = {
  children: PropTypes.any,
};
// -----------------------------------------------------------

function WebSocketProvider({ children }) {
  const { user, configs: commonConfigs, logout } = useAuth();
  const { isIdle } = useIdleTimer({ idleTime });

  /**
   * @type {[null|WebSocketService, React.Dispatch<React.SetStateAction<null|WebSocketService>>]}
   */
  const [wsClient, setWebSocketClient, wsClientRef] = useRefCustom(null);

  const [configs, setConfigs, configsRef] = useRefCustom(null);

  const firstConnectTime = useRef(null);

  const pingRef = useRef(null);

  const renewWebsocketRef = useRef(null);

  const [keepAlive, setKeepAlive, keepAliveRef] = useRefCustom(false);

  const onOpen = () => {
    if (!firstConnectTime.current) {
      firstConnectTime.current = Date.now();
    }
  };

  const onClose = () => {
    setWebSocketClient(null);
  };

  const connectWebsocket = useCallback(
    (websocketKey) => {
      if (!configsRef?.current?.aws_websocket_endpoint || !websocketKey) {
        return;
      }
      // AWS API Gateway `ws://localhost:8080` ||
      const url = `${configsRef?.current?.aws_websocket_endpoint?.replace(
        'https',
        'wss'
      )}?websocket_key=${websocketKey}`;
      const temp = new WebSocketService(url, { onOpen, onClose });
      setWebSocketClient(temp);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [configs]
  );

  const checkActiveOfWS = async () => {
    if (wsClientRef?.current) {
      return;
    }
    await renewWSConnection();
    await delayFunc(2 * 1000);
  };

  const renewWSConnection = async () => {
    try {
      const user = await axios.get('api/v1/users/me/');
      firstConnectTime.current = null;
      const { websocket_key: websocketKey } = user?.data;
      if (websocketKey) {
        if (wsClientRef?.current) {
          wsClientRef?.current?.close();
        }
        await delayFunc(2 * 1000);
        connectWebsocket(websocketKey);
        console.log('Renewed WS at %o', Date());
      }
    } catch (error) {
      console.log('renewWebsocket error:', error);
    }
  };

  const renewWebsocket = useCallback(() => {
    if (renewWebsocketRef?.current) {
      clearInterval(renewWebsocketRef?.current);
    }
    renewWebsocketRef.current = setInterval(renewWSConnection, RENEW_WS_TIME);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsClient]);

  useEffect(() => {
    renewWebsocket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setConfigs(commonConfigs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonConfigs]);

  const tryConnectWebsocket = useCallback(
    (websocketKey = null) => {
      try {
        if (isIdle) {
          if (wsClientRef?.current && !keepAliveRef?.current) {
            console.log('User idled');
            // wsClientRef?.current?.close();
          }
        } else if (
          !wsClientRef?.current &&
          user &&
          websocketKey &&
          !firstConnectTime.current &&
          configsRef?.current?.aws_websocket_endpoint
        ) {
          connectWebsocket(websocketKey);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isIdle, user, configs, keepAlive]
  );

  useEffect(() => {
    if (user) {
      tryConnectWebsocket(user?.websocket_key);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIdle, user, configs]);

  useEffect(() => {
    (async () => {
      if (!isIdle && user && !wsClientRef?.current && firstConnectTime.current) {
        const diff = Date.now() - firstConnectTime.current;
        const defaultWebsocketKeyTime = idleTime * 1000;
        if (diff >= defaultWebsocketKeyTime) {
          // Enhance here so what we do?
          /**
           * 1. Don't reload page get new websocket -> Add new api for BE to generate websocket token (websocket token have timelife current 5 minutes)
           * 2. All components have subscribed websocket should ve up to date when user reconnect again
           * Flow descriptions:
           * - Create new redux slice
           * - When user access any route. Web will update path into slice(Benefit is if user have online on this route, we only need update data in that component)
           * - Layouts related to websocket must be up to date.
           */
          console.log('start fecth new data ...');
          updateTimeReNew(Date.now());
          firstConnectTime.current = null;
          const user = await axios.get('api/v1/users/me/');
          const { websocket_key: websocketKey } = user?.data;
          tryConnectWebsocket(websocketKey);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIdle, user, wsClient]);

  const advanceLogout = async () => {
    // use this function if logout user
    try {
      console.log('advance logout');
      await logout();
      firstConnectTime.current = null;
      wsClientRef?.current?.close();
    } catch (error) {
      console.log(error);
    }
  };

  const turnOnKeepAlive = () => {
    setKeepAlive(true);
  };
  const turnOffKeepAlive = () => {
    setKeepAlive(false);
  };

  // Ping timer
  useEffect(() => {
    if (!isIdle && user && wsClientRef?.current?.ws) {
      pingRef.current = setInterval(() => {
        console.log('Try to ping server!');
        wsClientRef?.current?.ws?.send(JSON.stringify({ action: 'ping' }));
      }, PING_TIMER);
    } else {
      clearInterval(pingRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIdle, user, wsClient]);

  return (
    <WebSocketContext.Provider
      value={useMemo(
        () => ({
          wsClient,
          advanceLogout,
          checkActiveOfWS,
          keepAlive: {
            turnOn: turnOnKeepAlive,
            turnOff: turnOffKeepAlive,
          },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [wsClient]
      )}
    >
      {children}
    </WebSocketContext.Provider>
  );
}

export { WebSocketProvider, WebSocketContext };
