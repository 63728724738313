import { MenuItem, Select, Stack } from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';
import Iconify from '../../../components/Iconify';
import useLocales from '../../../hooks/useLocales';
import useTTContext from '../../../hooks/useTTContext';
import { fAbbreviate, fPercent } from '../../../utils/formatNumber';
import StatItem from './StatItem';

const ArrowIcon = (props) => <Iconify icon={'gridicons:dropdown'} {...props} />;

function UserStats(props) {
  const { userStats, isFetching, onLoadStats } = useTTContext();

  const { translate: tStat } = useLocales('tiktok.stats');

  const [timeRange, setTimeRange] = useState(7);

  // Overview
  const overview = userStats?.overview || {};

  // Follower
  const follower = userStats?.follower || {};
  const followerMale = follower?.gender?.find((item) => item?.key === 'Male');
  const followerFemale = follower?.gender?.find((item) => item?.key === 'Female');
  const followerCountry = follower?.country || {};
  const followerAge = _.maxBy(follower?.age || [], (o) => o.value);

  // Traffic
  const traffic = userStats?.traffic || [];

  // Viewer
  const viewer = userStats?.viewer || {};
  const viewerMale = viewer?.gender?.find((item) => item?.key === 'Male');
  const viewerFemale = viewer?.gender?.find((item) => item?.key === 'Female');
  const viewerCountry = viewer?.country || {};
  const viewerAge = _.maxBy(viewer?.age || [], (o) => o.value);

  const handleChange = (event) => {
    setTimeRange((prev) => {
      const newValue = event.target.value;
      if (prev !== newValue) {
        onLoadStats(newValue);
      }
      return newValue;
    });
  };

  return (
    !_.isEmpty(userStats) && (
      <Stack direction="column" px={2} gap={1}>
        <Select
          disabled={isFetching}
          size="small"
          value={timeRange}
          onChange={handleChange}
          IconComponent={(props) => ArrowIcon({ ...props, color: (theme) => theme.palette.primary.main })}
          sx={{
            mr: 'auto',
            color: (theme) => theme.palette.primary.main,
            '& .MuiSelect-select, .MuiMenuItem-root': {
              fontSize: '1rem',
            },
            outline: 'none',
            '&,&:hover': {
              borderColor: (theme) => theme.palette.primary.main,
              outlineColor: (theme) => theme.palette.primary.main,
            },
          }}
        >
          <MenuItem value={7}>{tStat('days', { days: 7 })}</MenuItem>
          <MenuItem value={30}>{tStat('days', { days: 30 })}</MenuItem>
        </Select>
        <StatItem
          title={tStat('overview.title')}
          subItems={[
            tStat('overview.video', { video: fAbbreviate(overview?.video || 0) }),
            tStat('overview.profileView', { profileView: fAbbreviate(overview?.profileView || 0) }),
            tStat('overview.like', { like: fAbbreviate(overview?.like || 0) }),
            tStat('overview.comment', { comment: fAbbreviate(overview?.comment || 0) }),
            tStat('overview.share', { share: fAbbreviate(overview?.share || 0) }),
          ]}
        />
        <StatItem
          title={tStat('viewer.title')}
          subItems={[
            tStat('viewer.total', { total: fAbbreviate(viewer?.total || 0) }),
            tStat('viewer.male', { male: fPercent((viewerMale?.value || 0) * 100) }),
            tStat('viewer.female', { female: fPercent((viewerFemale?.value || 0) * 100) }),
            tStat('viewer.age', { age_percent: fPercent((viewerAge?.value || 0) * 100), age: viewerAge?.key || '' }),
            tStat('viewer.country_percent', {
              country_percent: fPercent(viewerCountry?.percent || 0),
              country: viewerCountry?.name || '',
            }),
          ]}
        />
        <StatItem
          title={tStat('follower.title')}
          subItems={[
            tStat('follower.total', { total: fAbbreviate(follower?.total || 0) }),
            tStat('follower.male', { male: fPercent((followerMale?.value || 0) * 100) }),
            tStat('follower.female', { female: fPercent((followerFemale?.value || 0) * 100) }),
            tStat('follower.age', {
              age_percent: fPercent((followerAge?.value || 0) * 100),
              age: followerAge?.key || '',
            }),
            tStat('follower.country_percent', {
              country_percent: fPercent(followerCountry?.percent || 0),
              country: followerCountry?.name || '',
            }),
          ]}
        />
        <StatItem
          title={tStat('traffic.title')}
          subItems={['For You', 'Personal Profile', 'Search', 'Follow', 'Sound']?.map((key, index) => {
            const matched = traffic?.find((item) => item?.key === key);
            const MAP = [
              tStat('traffic.trend'),
              tStat('traffic.profile'),
              tStat('traffic.search'),
              tStat('traffic.follower'),
              tStat('traffic.music'),
            ];
            return `${fPercent((matched?.value || 0) * 100)} ${MAP[index]}`;
          })}
        />
      </Stack>
    )
  );
}

UserStats.propTypes = {};

export default UserStats;
