import _ from 'lodash';
import useRefCustom from '../useRefCustom';

const DEFAULT_VALUE_GROUP = {};
const DEFAULT_VALUE_POST = [];

function useErrorsDetect(maximumGroupIssues = 5, maximumPostIssues = 10) {
  const [, setGroupIssues, groupIssuesRef] = useRefCustom(DEFAULT_VALUE_GROUP);
  const [, setPostIssues, postIssuesRef] = useRefCustom(DEFAULT_VALUE_POST);

  const onResetGroupIssues = () => {
    setGroupIssues(DEFAULT_VALUE_GROUP);
  };

  const onResetPostIssues = () => {
    setPostIssues(DEFAULT_VALUE_POST);
  };

  const markAsPostIssue = (groupId) => {
    let temp = postIssuesRef?.current || DEFAULT_VALUE_POST;
    temp = _.uniq([...temp, groupId]);
    setPostIssues([...temp]);
  };

  const markAsGroupIssue = (groupId, value = 1) => {
    const temp = groupIssuesRef?.current || DEFAULT_VALUE_GROUP;
    temp[groupId] = (temp[groupId] || 0) + value;
    setGroupIssues(temp);
  };

  const checkExceededGroupIssues = (groupId) => {
    const temp = groupIssuesRef?.current || DEFAULT_VALUE_GROUP;
    const amountIssues = temp[groupId] || 0;
    return amountIssues >= maximumGroupIssues;
  };

  const checkExceededPostIssues = () => {
    const temp = postIssuesRef?.current || DEFAULT_VALUE_POST;
    return temp?.length >= maximumPostIssues;
  };

  return {
    markAsGroupIssue,
    markAsPostIssue,
    onResetGroupIssues,
    onResetPostIssues,
    checkExceededGroupIssues,
    checkExceededPostIssues,
  };
}

export default useErrorsDetect;
