import { useContext } from 'react';
import { CommonToolContext } from '../contexts/CommonToolContext';

const useCommonToolContext = () => {
  const context = useContext(CommonToolContext);

  if (!context) {
    throw new Error('CommonToolContext context must be use inside CommonToolProvider');
  }

  return context;
};

export default useCommonToolContext;
