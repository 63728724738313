import { Box } from '@mui/material';
import useAdsCampaign from '../../../../../hooks/useAdsCampaign';
import AudienceChoice from './AudienceChoice';

CampaignTarget.propTypes = {};

export default function CampaignTarget() {
  const { onNextStep, step1 } = useAdsCampaign();

  const handleNextStep = (data) => {
    onNextStep(data);
  };

  return (
    <>
      <Box sx={{ py: 0.5, px: 0.625 }}>
        <AudienceChoice onChangeValue={handleNextStep} currentValues={step1} />
      </Box>
    </>
  );
}
